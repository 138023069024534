import { sortAlphabetical } from '@/lib/utils';
import axios from 'axios';

const module = {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    set(state: { items: any }, payload: any) {
      state.items = payload;
    },
    add(state: { items: any[] }, payload: any) {
      state.items.push(payload);
    },
    remove(state: { items: any[] }, payload: any) {
      state.items = state.items.filter(
        (item: { _id: any }) => item._id !== payload,
      );
    },
  },
  actions: {
    async list({ commit }: any) {
      try {
        const { data } = await axios.get('device-categories/');
        const items = sortAlphabetical(data, 'name');
        commit('set', items);
        return items;
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    },
    async create({ commit }: any, object: any) {
      try {
        const result = await axios.post('device-categories/', object);
        commit('add', result.data);
        return result.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async remove({ commit }: any, id: any) {
      try {
        const res = await axios.delete(`device-categories/${id}`);
        commit('remove', id);
        return res.data;
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};

export default module;
