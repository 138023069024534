<template>
  <section class="b2c_content a_margin-top-40">
    <div class="b2c_content_container a_max-width-1000">
      <div class="b2c_content_1-1">
        <print-table-arrangement
          v-for="(item, index) in value"
          v-model="value[index]"
          :key="index"
        />
      </div>
    </div>
  </section>
</template>
<script>
import PrintTableArrangement from './PrintTableArrangement';

export default {
  components: {
    PrintTableArrangement,
  },
  props: ['value'],
  data() {
    return {};
  },
};
</script>
