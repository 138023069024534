<template>
  <div class="b2c_tab_wrapper">
    <h3>
      <small>Preview positionering</small>
    </h3>
    <div>
      <!-- <button @click="loadSVGData" class="button v_brand_secondary" :disabled="!images.svg">laad data uit SVG</button> -->
      <!-- <div class="b2c_form" v-if="value.folds.items.length > 0"> -->

      <div class="b2c_form">
        <div class="b2c_form_left">
          <!-- <div class="b2c_form_left_img"> -->
          <design-rotator
            ref="designRotator"
            v-model="value.folds"
            :rect="value.rect"
            :image-fold="images.fold"
            :image-inside="images.imageOfInside"
            :image-over="
              value.imageOfOver
                ? trimSlash(config.API_URI) + value.imageOfOver
                : ''
            "
            :image-device="
              value.imagesOfDevice && value.imagesOfDevice.length
                ? trimSlash(config.API_URI) + value.imagesOfDevice[0]
                : ''
            "
            :extra-image="
              value.extraImageFiles && value.extraImageFiles.length
                ? trimSlash(config.API_URI) + value.extraImageFiles[0]
                : ''
            "
            :show-fold-lines="showFoldLines && value.folds.items.length > 0"
            :show-rotation-line="showRotationLine"
            style="border: 1px solid grey"
          />
          <!-- </div> -->
          <div class="a_flex a_align-items_center a_margin-top-20">
            <!-- <label class="a_margin-right-20">
                            Rotatie
                            <input type="range" class="a_width-100">
                        </label>-->
            <label>
              {{
                value.folds.items.length > 0
                  ? 'Rotatie offset'
                  : 'Horizontale offset '
              }}
              <input
                v-model="offset"
                type="range"
                min="0"
                max="100"
                step="0.1"
                class="a_width-100"
              />
            </label>
          </div>
          <div v-if="value.folds.items.length > 0">
            <br />
            <input
              id="show_rotation_line"
              v-model="showRotationLine"
              type="checkbox"
              name="show_rotation_line"
            />
            <label for="show_rotation_line">Toon rotatielijn</label>
            <input
              id="show_fold_line"
              v-model="showFoldLines"
              type="checkbox"
              name="show_fold_line"
            />
            <label for="show_fold_line">Toon vouwlijn</label>
            <br />
            <label for="fold-rounding-amount">Percentage ronding</label>
            <input
              id="fold-rounding-amount"
              v-model="rounding"
              type="number"
              min="0"
              max="100"
              step="1"
              name="fold-rounding-amount"
            />
          </div>
        </div>
        <div v-if="value.folds.items.length > 0" class="b2c_form_right">
          <div
            v-for="(fold, i) in value.folds.items"
            :key="'foldLine' + i"
            style="width: 500px"
            class="b2c_form_fold-line"
          >
            <div class="b2c_form_fold-line_inner">
              <div class="b2c_form_fold-line_inner_left">
                <div class="b2c_form_slider">
                  <label>x positie</label>
                  <input
                    :value="fold.value"
                    type="range"
                    min="0"
                    max="1"
                    step="0.001"
                    @change="setFoldLine(i, +$event.target.value)"
                  />
                  <input
                    :value="fold.value"
                    type="number"
                    min="0"
                    max="1"
                    step="0.001"
                    @change="setFoldLine(i, +$event.target.value)"
                  />
                </div>
                <div class="b2c_form_slider">
                  <label>rotatie</label>
                  <input
                    :value="fold.rotation"
                    type="range"
                    min="0"
                    max="360"
                    step="1"
                    @change="setRotation(i, +$event.target.value)"
                  />
                  <input
                    :value="fold.rotation"
                    type="number"
                    min="0"
                    max="360"
                    step="1"
                    @change="setRotation(i, +$event.target.value)"
                  />
                </div>
              </div>
              <div class="b2c_form_fold-line_inner_right">
                <button
                  class="button v_ghost_brand_secondary v_is-icon v_smallest a_no-margin"
                  @click="removeFold(i)"
                >
                  <icon :icon-id="'icon_ui_close'" />
                </button>
                {{ i + 1 }}
              </div>
            </div>
          </div>
          <button
            class="button v_ghost_brand_secondary v_expanded v_smaller"
            @click="addFoldLine"
          >
            Nieuwe vouwlijn
          </button>
        </div>
      </div>
      <hr />
      <!-- <h3><small>Folded Fysieke afmetingen SVG</small></h3>
            <p class="a_text_brand_gray">De fysieke afmetingen in MM. Deze kun je hierboven terug zien met de linialen.</p>
            <div class="a_flex a_align-items_center">
                <label>
                    Breedte:
                    <input @change="resetSelectedMockups" type="number" v-model="value.foldedPhysicalSize.width" placeholder="Breedte in mm">
                </label>
                <span class="a_margin-left-10 a_margin-right-40"><small>MM</small></span>
                <label>
                    Hoogte:
                    <input @change="resetSelectedMockups" type="number" v-model="value.foldedPhysicalSize.height" placeholder="Hoogte in mm">
                </label>
                <span class="a_margin-left-10 a_margin-right-40"><small>MM</small></span>
            </div>    
            <hr>-->
    </div>
    <div v-if="value.folds.items.length === 0">Geen vouwlijnen gevonden</div>
    <br />
    <h3>
      <small>Presentatie weergave</small>
    </h3>
    <p class="a_text_brand_gray">Selecteer maximaal twee mockup scenes</p>
    <section>
      <button
        :disabled="!value._id"
        class="button v_ghost_brand_secondary v_is-icon v_icon-size-24 v_smallest a_margin-top-20"
        @click="renderSelected"
      >
        Render alle geselecteerde templates ({{ value.mockups.length }})
      </button>
      <p v-if="!value._id" class="a_text_brand_gray" style="font-size: 80%">
        Renderen kan pas als de template een keer is opgeslagen
      </p>
    </section>
    <div class="b2c_items" data-s-amount="3">
      <div
        v-for="(item, i) in mockups"
        v-if="isMockupMatched(item)"
        :key="'mockup' + i"
        class="b2c_item"
        data-s-amount-item=""
      >
        <div class="b2c_item_container">
          <div class="b2c_item_image-container">
            <img
              :src="`${basePath}/uploads/mockups/${item._id}/thumbnail.jpeg`"
            />
          </div>
          <div class="b2c_item_content-container">
            <div class="b2c_item_name">
              <label :for="item.name">{{ item.name }}</label>
            </div>
            <input
              ref="mockups"
              :disabled="!isSelected(item) && value.mockups.length >= 2"
              :name="item.name"
              :id="`mockup_${item._id}`"
              type="checkbox"
              @change="onMockupSelect(item, $event)"
            />
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="a_flex a_justify_flex-end a_align-items_center">
      <button class="button v_brand_secondary" @click="$emit('save')">
        Opslaan
      </button>
    </div>
  </div>
</template>
<script>
import config from '@/config';
import templateHelper from '@/lib/template-helper';
import DesignRotator from './DesignRotator';
import { mapActions } from 'vuex';

export default {
  props: ['value', 'mockups', 'show'],
  data() {
    return {
      images: {
        svg: '',
        imageOfOveraly: '',
        imageOfInside: '',
        fold: '',
      },
      showFoldLines: true,
      showRotationLine: true,
      basePath: config.API_URI.substr(0, config.API_URI.length - 1),
    };
  },
  computed: {
    rounding: {
      get() {
        return this.value.folds.rounding * 100;
      },
      set(value) {
        this.value.folds.rounding = +value / 100;
      },
    },
    offset: {
      get() {
        return this.value.folds.items.length > 0
          ? this.value.folds.offset * 100
          : (this.value.folds.offset + 1) * 50;
      },
      set(value) {
        this.value.folds.offset =
          this.value.folds.items.length > 0 ? value / 100 : value / 50 - 1;
      },
    },
    config() {
      return config;
    },
    designExporter() {
      return this.config.DESIGN_EXPORTER;
    },
  },
  watch: {
    'value.mockups': {
      handler() {
        this.applySelectionFromMockupsList();
      },
      deep: true,
    },
    show(value) {
      if (value) {
        this.loadSVGData();
        this.applySelectionFromMockupsList();
      }
    },
  },
  methods: {
    ...mapActions('popup', ['openPopup']),
    renderSelected() {
      const imagesHtml = this.value.mockups
        .map(
          mockup => `
                ${mockup}:
                <img src="${this.designExporter}mockup/?mockupId=${mockup}&templateId=${this.value._id}&force=true"/>
                <hr>
            `,
        )
        .join('');
      this.openPopup({
        header: 'Test renders',
        subHeader:
          'Scroll door de afbeeldingen. Het renderen kan even duren.. Even geduld a.u.b.',
        message: `<div style="height:500px; overflow:scroll">${imagesHtml}</div>`,
      });
    },
    trimSlash(url) {
      return url.replace(/\/$/, '');
    },
    loadSVGData() {
      if (this.value.folds.items.length > 0) {
        this.$refs.designRotator.loadFromSVG();
      }
    },
    addFoldLine() {
      this.value.folds.items.push({
        value: this.value.folds.items[this.value.folds.items.length - 1].value,
        rotation: 90,
        animationDelay: 0,
      });
    },
    setFoldLine(num, value) {
      this.value.folds.items[num].value = value;
    },
    setRotation(num, value) {
      this.value.folds.items[num].rotation = value;
    },
    removeFold(state, num) {
      this.value.folds.items.splice(num, 1);
    },
    resetSelectedMockups() {
      this.value.mockups.forEach(item => {
        item._selected = false;
        item._dom.checked = false;
      });
      this.value.mockups = [];
    },
    isMockupMatched(mockup) {
      if (!this.value.foldedPhysicalSize || !mockup.safeZone) {
        return false;
      }
      const physicalSize = this.value[
        mockup.case.fold === 'open' || this.value.folds.items.length === 0
          ? 'physicalSize'
          : 'foldedPhysicalSize'
      ];
      const digitalSize = {
        width: templateHelper.pxToMM(physicalSize.width),
        height: templateHelper.pxToMM(physicalSize.height),
      };
      // console.log(this.value.name, digitalSize.height, this.value.safeZone.height);
      return (
        digitalSize.width <= mockup.safeZone.width + 1 &&
        digitalSize.height <= mockup.safeZone.height + 1
      );
      /* const size = mockup.caseFold === 'open' ? this.value.physicalSize : this.value.foldedPhysicalSize;
                    return size.width <= templateHelper.pxToMM(mockup.safeZone.width) && size.height <= templateHelper.pxToMM(mockup.safeZone.height); */
    },
    onMockupSelect(item, event) {
      item._dom = event.target;
      // item._selected = !item._selected && event.target.checked;
      item._selected = event.target.checked;
      if (item._selected === true) {
        this.value.mockups.push(item._id);
      } else {
        this.value.mockups.splice(this.value.mockups.indexOf(item._id), 1);
      }
    },
    isSelected(item) {
      return this.value.mockups.indexOf(item._id) >= 0;
    },
    applySelectionFromMockupsList() {
      this.mockups.forEach(item => {
        // eslint-disable-next-line no-undef
        const checkbox = $(`#mockup_${item._id}`);
        if (checkbox.length > 0) {
          // this.$set(item, '_selected', this.value.mockups.indexOf(item._id) >= 0);
          checkbox[0].checked = this.value.mockups.indexOf(item._id) >= 0;
        }
      });
    },
  },

  components: {
    DesignRotator,
  },
};
</script>
