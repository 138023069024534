<template>
  <modal
    :open.sync="modal.open"
    :loading.sync="modal.loading"
    :error-message.sync="modal.errorMessage"
    :success-message.sync="modal.successMessage"
    :header="'Template Clone'"
    :sub-header="'.'"
    :action-button-text="'Clone'"
    :icon="'icon_templates_templates'"
    @action="save"
  >
    <div v-if="value.varianceOf">
      Je gaat een variatie clonen. Er zal een nieuwe variatie ontstaan binnen
      het default hoesje
    </div>
    <div v-if="!value.varianceOf">
      Je gaat een default template clonen. Er zal een nieuwe template ontstaan
      met dezelfde eigenschappen.
    </div>
    <hr />
    <label v-if="!value.varianceOf">
      Naam
      <input
        v-show="!modal.successMessage"
        v-model="fields.name"
        type="text"
        placeholder="Template naam, bijvoorbeeld 'Dieren'"
      />
    </label>
    <label v-if="value.varianceOf">
      Variatie Naam
      <input
        v-show="!modal.successMessage"
        v-model="fields.varianceName"
        type="text"
        placeholder="Variance naam, bijvoorbeeld 'Dieren'"
      />
    </label>
  </modal>
</template>
<script>
import { mapActions } from 'vuex';
import Modal from '@/components/elements/Modal';

export default {
  props: ['value'],
  data() {
    return {
      modal: {
        open: false,
        loading: false,
        done: false,
        successMessage: '',
        errorMessage: '',
      },
      fields: {
        name: '',
        varianceName: '',
      },
    };
  },
  computed: {
    // ...mapState('TemplateCategory', ['modal']),
  },
  methods: {
    ...mapActions('template', ['duplicate']),
    open() {
      this.modal.open = true;
      if (this.value.varianceOf) {
        this.fields.name = this.value.name;
        this.fields.varianceName = `Kopie van ${this.value.varianceName}`;
      } else {
        this.fields.name = `Kopie van ${this.value.name}`;
        this.fields.varianceName = 'default';
      }
    },
    async save() {
      try {
        if (!this.fields.name) {
          this.modal.errorMessage = 'vul een naam in a.u.b.';
          return false;
        }
        this.modal.loading = true;
        await this.duplicate({
          id: this.value._id,
          ...this.fields,
        });
        this.modal.successMessage = 'De template is succesvol gecloned.';
      } catch (error) {
        console.log(error);
        this.modal.errorMessage = error.message;
      }
      this.modal.loading = false;
      return true;
    },
  },
  components: {
    Modal,
  },
};
</script>
