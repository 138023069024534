<template>
  <div>
    <page-title
      :title="'Bestellingen'"
      :icon="'icon_orders'"
      :description="
        'Laatste PCD opgeslagen designs en de mogelijkheid om deze toe te voegen aan een print tafel.'
      "
    />
    <search-bar
      ref="searchBar"
      :categories="categories"
      :devices="devices"
      :templates="templates"
      @categoryChanged="query.category = $event"
      @deviceChanged="query.device = $event"
      @templateChanged="query.template = $event"
      @designChanged="query.design = $event"
    />
    <section
      v-show="active === 'auto-generate'"
      class="b2c_search-bar a_margin-top-60"
    >
      <div class="b2c_search-bar_left">
        <h2 class="a_margin-bottom-0 a_margin-right-10">
          Laatste Opgeslagen designs
        </h2>
        <div v-if="!loading" class="b2c_pill">
          {{ totalOrdersCount }} designs
        </div>
      </div>
      <div class="b2c_search-bar_left">
        <button
          :disabled="loading"
          class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0 a_margin-right-10"
          @click="setActive('self-select')"
        >
          Stel tafel samen
        </button>
        <button
          :disabled="loading"
          class="button v_brand_secondary v_smaller a_margin-bottom-0"
          @click="$refs.composer.open(designs)"
        >
          Auto genereer print tafels
        </button>
      </div>
    </section>
    <section
      v-show="active === 'self-select'"
      class="b2c_search-bar a_margin-top-60"
    >
      <div class="b2c_search-bar_left">
        <label class="a_margin-bottom-0 a_margin-right-20">filter:</label>
        <select
          v-model="selectedPrintTable"
          class="a_margin-bottom-0 a_margin-right-10"
          style="min-width: 160px;"
        >
          <option
            v-for="(item, index) in printTables"
            :key="index"
            :value="item._id"
            >{{ item.name }}</option
          >
        </select>
        <!-- <input type="text" placeholder="Zoek order" class="a_margin-bottom-0 a_margin-right-10" > -->
        <button
          class="button v_brand_secondary v_is-icon v_smaller a_no-margin"
          @click="
            setActive('auto-generate');
            selectedPrintTable = '';
          "
        >
          <icon :icon-id="'icon_ui_close'" />
        </button>
      </div>
      <div class="b2c_search-bar_left">
        <!-- <button @click="setActive('generated-tables')" class="button v_brand_secondary v_smaller a_margin-bottom-0"> -->
        <button
          class="button v_brand_secondary v_smaller a_margin-bottom-0"
          @click="$refs.composer.open(designs.filter(item => item._selected))"
        >
          Genereer print tafels
        </button>
      </div>
    </section>
    <section
      v-show="active === 'generated-tables'"
      class="b2c_search-bar a_margin-top-60"
    >
      <div class="b2c_search-bar_left">
        <h2 class="a_margin-bottom-0">Gegenereerde tafels</h2>
        <p class="a_margin-bottom-0 a_margin-left-10 a_margin-right-20">
          Op basis van 18 orders
        </p>
        <button
          class="button v_brand_secondary v_is-icon v_smaller a_no-margin"
          @click="setActive('auto-generate')"
        >
          <icon :icon-id="'icon_ui_close'" />
        </button>
      </div>
      <div class="b2c_search-bar_left" />
    </section>
    <center v-if="loading">
      <br />
      <br />
      <h4>Loading ...</h4>
      <hr />
    </center>
    <list
      v-if="!loading"
      v-show="active === 'auto-generate' || active === 'self-select'"
      v-model="designs"
      :templates="templates"
      :print-table="selectedPrintTable"
      :active.sync="active"
      :show-plus="false"
    />
    <paginator
      v-show="active !== 'generated-tables'"
      :page="$route.params.page"
      :limit="$route.query.limit"
      :total="totalOrdersCount"
      :params="{}"
      route-name="print-orders"
    />
    <print-table-arrangements
      v-show="active === 'generated-tables'"
      v-model="printTableArraangements"
    />
    <composer-dialog ref="composer" @arranged="onArranged" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import * as _ from 'lodash';
import PageTitle from '@/components/elements/PageTitle';
import List from './partials/List';
import PrintTableArrangements from './partials/PrintTableArrangements';
import ComposerDialog from './ComposerDialog';
import SearchBar from './partials/SearchBar';
import Paginator from '@/components/elements/Paginator';

export default {
  data() {
    return {
      active: 'auto-generate',
      printTableArraangements: [],
      selectedPrintTable: '',
      totalOrdersCount: 1,
      // loading: true,
      query: {
        category: undefined,
        device: undefined,
        template: undefined,
        design: undefined,
      },
    };
  },
  watch: {
    $route(to) {
      this.gotoPage(to.params.page || 1);
    },
    selectedPrintTable() {
      this.designs.forEach(item => {
        item._selected = false;
      });
    },
    query: {
      async handler() {
        await this.gotoPage(1);
      },
      deep: true,
    },
  },
  async created() {
    this.setLoading(true);
    try {
      await Promise.all([
        this.getPrintTables(),
        this.getTemplates({
          relations: [
            {
              path: 'device',
              populate: {
                path: 'category',
              },
            },
          ],
        }),
      ]);
      if (this.printTables.length > 0) {
        // this.selectedPrintTable = this.printTables[0]._id;
      }
    } catch (e) {
      this.openPopup({
        header: 'Er was een probleem bij het ophalen van Templates',
        message: e.message,
      });
    }
  },
  async mounted() {
    try {
      this.setLoading(true);
      if (!this.$route.params.page || !this.$route.query.limit) {
        this.$router.push({
          name: 'print-orders',
          params: {
            page: parseInt(this.$route.params.page || 1),
          },
          query: {
            limit: parseInt(this.$route.query.limit || 8),
          },
        });
      } else {
        await this.gotoPage(this.$route.params.page);
      }
    } catch (e) {
      this.openPopup({
        header: 'Er was een probleem bij het ophalen van Templates',
        message: e.message,
      });
    }
    this.setLoading(false);
  },
  computed: {
    ...mapState('printTable', {
      printTables: 'items',
    }),
    ...mapState('template', {
      templates: 'items',
    }),
    ...mapState('design', {
      designs: 'items',
    }),
    ...mapState('loading', ['loading']),
    devices() {
      return _.uniqBy(
        this.templates.map(item => item.device),
        '_id',
      );
    },
    categories() {
      return _.uniqBy(
        this.devices.map(item => item.category),
        '_id',
      );
    },
  },
  methods: {
    ...mapActions('modal', ['openModal']),
    ...mapActions('popup', ['openPopup']),
    ...mapActions('printTable', {
      getPrintTables: 'list',
    }),
    ...mapActions('deviceCategory', {
      getCategories: 'list',
    }),
    ...mapActions('device', {
      getDevices: 'list',
    }),
    ...mapActions('template', {
      getTemplates: 'list',
    }),
    ...mapActions('design', {
      getDesigns: 'list',
    }),
    ...mapActions('loading', ['setLoading']),
    setActive(slug) {
      this.active = slug;
    },
    setOrderActive(item) {
      if (!item.orderIsActive) {
        item.orderIsActive = true;
      } else {
        item.orderIsActive = false;
      }
    },
    onArranged(sets) {
      this.setActive('generated-tables');
      this.printTableArraangements = sets;
    },
    async gotoPage() {
      this.setLoading(true);
      this.$forceUpdate();
      const query = {
        meta: true,
        relations: ['template'],
        page: parseInt(this.$route.params.page || 1) - 1,
        limit: parseInt(this.$route.query.limit || 8),
        sort: '-updatedAt',
        filters: [],
      };
      if (this.query.design) {
        query.filters.push({
          column: '_id',
          operator: '=',
          values: [this.query.design],
        });
      } else if (this.query.template) {
        query.filters.push({
          column: 'template',
          operator: '=',
          values: [this.query.template._id],
        });
      } else if (this.query.device) {
        this.query.category &&
          query.filters.push({
            column: 'template',
            operator: 'in',
            values: this.templates
              .filter(template => template.device._id === this.query.device._id)
              .map(template => template._id),
          });
      } else if (this.query.category) {
        const devices = this.devices
          .filter(device => device.category._id === this.query.category._id)
          .map(device => device._id);
        query.filters.push({
          column: 'template',
          operator: 'in',
          values: this.templates
            .filter(template => devices.indexOf(template.device._id) >= 0)
            .map(template => template._id),
        });
      }
      const result = await this.getDesigns(query);
      this.totalOrdersCount = result.meta.recordsCount;
      this.$forceUpdate();
      this.setLoading(false);
    },
  },
  components: {
    PageTitle,
    List,
    PrintTableArrangements,
    ComposerDialog,
    SearchBar,
    Paginator,
  },
};
</script>
