<template>
  <div v-if="mockup">
    <section class="b2c_content">
      <div class="b2c_content_container">
        <div class="b2c_content_1-1">
          <router-link
            to="/mockups/tool"
            class="button v_transparent a_text_brand_secondary v_has-icon-left v_smallest a_margin-bottom-40"
          >
            <span class="text-container">terug naar het overzicht</span>
            <span class="svg-container">
              <icon :icon-id="'icon_ui_arrow-left'" />
            </span>
          </router-link>
          <header class="b2c_header">
            <div class="b2c_header_top">
              <div class="b2c_header_top_left">
                <div class="b2c_header_switch a_flex a_align-items_center">
                  <h1 class="s_elem-has-icon-left a_fill_default">
                    Mockup
                    <icon :icon-id="'icon_mockuptool'" />
                  </h1>
                </div>
              </div>
              <div class="b2c_header_top_right">
                <button
                  class="button v_ghost_brand_primary v_smaller a_margin-left-10 a_opacity-0 a_no-pointer-events"
                >
                  ...
                </button>
              </div>
            </div>
          </header>
        </div>
      </div>
    </section>
    <section class="b2c_search-bar">
      <div class="b2c_search-bar_left">
        <h2 class="a_margin-bottom-0 a_margin-right-20">
          {{ mockup.name }}
        </h2>
        <p class="a_margin-bottom-0 a_text_brand_gray">
          {{ mockup._id }}
        </p>
        <!-- <input type="text" placeholder="Zoek een mockup" class="a_margin-bottom-0 a_margin-right-10" data-toggle="dropdownMockups">

                <div class="dropdown-pane" id="dropdownMockups" data-dropdown data-close-on-click="true">  
                    <ul class="b2c_inline-list">
                        <li><button>Mockup 1</button></li>
                        <li><button>Mockup 2</button></li>
                        <li><button>Mockup 3</button></li>
                        <li><button>Mockup 4</button></li>
                        <li><button>Mockup 5</button></li>
                        <li><button>Mockup 6</button></li>
                    </ul>
                </div>-->
      </div>
      <div class="b2c_search-bar_left">
        <a
          :href="`${mockupToolURI}#/?id=${mockup._id}&token=${token}`"
          target="_blank"
          class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0 a_margin-right-10"
          >Mockup Aanpassen</a
        >
        <button
          target="_blank"
          class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0"
          @click="$refs.delete.open(mockup)"
        >
          <icon :icon-id="'icon_ui_close'" />Mockup Verwijderen
        </button>
      </div>
    </section>
    <section>
      <img
        :src="`${basePath}/uploads/mockups/${mockupId}/thumbnail.jpeg`"
        class="a_margin-top-20"
        width="256"
      />
      <label for="force-rendering" class="a_margin-top-20">
        forceer preview rendering
        <input id="force-rendering" v-model="forceRendering" type="checkbox" />
      </label>
      <label style="max-width:400px" for="">
        render met designId
        <input v-model="designId" type="text" />
      </label>
    </section>
    <section>
      <button
        class="button v_ghost_brand_secondary v_is-icon v_icon-size-24 v_smallest a_margin-top-20"
        @click="renderSelected"
      >
        Render alle geselecteerde templates ({{ selectedTemplates.length }})
      </button>
      <button
        class="button v_brand_secondary v_is-icon v_icon-size-24 v_smallest a_margin-top-20 a_margin-left-10"
        @click="globalRerenderPopup"
      >
        re-Render alle mockups met global designs
      </button>
    </section>
    <section>
      <label for="show-selected-templates" class="a_margin-top-20">
        <input
          id="show-selected-templates"
          v-model="showSelectedTemplates"
          type="checkbox"
        />
        Laat alleen geselecteerde templates zien
      </label>
      <label for="show-templates-without-full-mockups">
        <input
          id="show-templates-without-full-mockups"
          v-model="showTemplatesWithoutFullMockups"
          type="checkbox"
        />
        Laat alleen templates zien die nog geen 2 mockups hebben
      </label>
      <templates-list
        :templates="showSelectedTemplates ? selectedTemplates : templates"
        :design-id="designId"
        :mockup-id="mockup && mockup._id"
        :force-rendering="forceRendering"
        :show-templates-without-full-mockups="showTemplatesWithoutFullMockups"
      />
    </section>
    <!-- modals -->
    <delete ref="delete" @success="init" />
    <modal-global-render
      ref="modalGlobalRender"
      type="mockup"
      @render="globalRerender"
    />
  </div>
</template>

<script>
import config from '@/config';
import { mapActions, mapGetters } from 'vuex';
import Delete from './Delete';
import TemplatesList from './TemplatesList';
import ModalGlobalRender from '@/components/elements/ModalGlobalRender';

export default {
  props: ['mockupId'],
  data() {
    return {
      mockup: null,
      templates: null,
      forceRendering: false,
      designId: null,
      showSelectedTemplates: false,
      showTemplatesWithoutFullMockups: false,
    };
  },
  watch: {
    mockupId() {
      this.init();
    },
  },
  activated() {
    this.init();
  },
  computed: {
    ...mapGetters('auth', ['token']),
    basePath() {
      return config.API_URI.substr(0, config.API_URI.length - 1);
    },
    mockupToolURI() {
      return config.MOCKUP_TOOL;
    },
    designExporter() {
      return config.DESIGN_EXPORTER;
    },
    selectedTemplates() {
      if (!this.templates) {
        return [];
      }
      return this.templates.filter(template =>
        template.mockups.includes(this.mockupId),
      );
    },
  },
  methods: {
    ...mapActions('mockup', {
      getMockup: 'read',
    }),
    ...mapActions('template', {
      getTemplates: 'list',
    }),
    ...mapActions('popup', ['openPopup']),
    ...mapActions('global', ['globalRender']),
    async init() {
      this.loadMockup();
      this.loadTemplates();
    },
    async loadMockup() {
      try {
        this.mockup = await this.getMockup(this.mockupId);
      } catch (e) {
        this.openPopup({
          header: `Er was een probleem bij het ophalen van Mockup ${this.mockupId}`,
          message: e.message,
        });
        console.log(e);
      }
    },
    async loadTemplates() {
      try {
        this.templates = await this.getTemplates({
          relations: [
            {
              path: 'device',
              populate: {
                path: 'category',
              },
            },
          ],
        });
        this.templates = this.templates.filter(template => !template.deleted);
      } catch (e) {
        this.openPopup({
          header: 'Er was een probleem bij het ophalen van Templates',
          message: e.message,
        });
        console.log(e);
      }
    },
    renderSelected() {
      const imagesHtml = this.selectedTemplates
        .map(
          template => `
                    ${template._id}:
                    <img src="${this.designExporter}mockup/?mockupId=${this.mockupId}&templateId=${template._id}&force=true"/>
                    <hr>
                `,
        )
        .join('');
      this.openPopup({
        header: 'Test renders',
        subHeader:
          'Scroll door de afbeeldingen. Het renderen kan even duren.. Even geduld a.u.b.',
        message: `<div style="height:500px; overflow:scroll">${imagesHtml}</div>`,
      });
    },
    async globalRerenderPopup() {
      let total = 0;
      const data = await this.globalRender({
        id: this.mockupId,
        skipRender: true,
        type: 'mockup',
      });

      data.forEach(mockup => {
        total += mockup.assets.length;
      });
      const message = `Dit is een vrij zware taak voor de server. Er zullen ${total} renders worden gemaakt. Het advies is om deze taak te draaien wanneer het niet druk is in de webshop. Je kunt als je geen haast hebt het renderen uitstellen tot middernacht.`;
      this.$refs.modalGlobalRender.open(message);
    },
    async globalRerender({ delay, force }) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const data = await this.globalRender({
          delay,
          id: this.mockupId,
          force,
          skipRender: false,
          type: 'mockup',
        });
        this.openPopup({
          header: 'Succes',
          message: 'De render is gestart..',
        });
      } catch (e) {
        console.error(e);
        this.openPopup({
          header: 'Foutmelding',
          message: 'Er ging iets fout bij het aanmaken van de rerender' + e,
          closeButtonText: 'Sluiten',
        });
      }
      //https://pcd-backend.stagi.nl/templates/5b2df96059577d000c614e63/global?force=true&batchId=6546546
    },
  },
  components: {
    Delete,
    TemplatesList,
    ModalGlobalRender,
  },
};
</script>
