import axios from 'axios';
import WebFont from 'webfontloader';
/**
 * @param {string[]} names
 */
function loadGoogleFont(names) {
  return new Promise((resolve, reject) => {
    WebFont.load({
      google: {
        families: names,
      },
      fontactive: resolve,
      fontinactive: reject,
      timeout: 2000,
    });
  });
}

const module = {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    set(state, payload) {
      state.items = payload;
    },
    add(state, payload) {
      state.items.push(payload);
    },
    remove(state, payload) {
      state.items = state.items.filter(item => item._id !== payload);
    },
  },
  actions: {
    async load(fontNames) {
      try {
        await loadGoogleFont(fontNames);
      } catch (error) {
        throw new Error(`Fail on loading fonts: ${JSON.stringify(fontNames)}.`);
      }
    },
    async list({ commit }) {
      try {
        const res = await axios.get('fonts/');
        commit('set', res.data);
        module.actions.load(res.data.map(item => item.name));
        return res.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async create({ commit }, object) {
      try {
        await module.actions.load([object.name]);
        const result = await axios.post('fonts/', object).catch(error => {
          let message = error.response.data.message;
          switch (error.response.status) {
            case 409:
              message = 'Dit font is al eerder toegevoegd';
              break;
            default:
              break;
          }
          throw message;
        });
        commit('add', result.data);
        return result.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async remove({ commit }, id) {
      try {
        const res = await axios.delete(`fonts/${id}`);
        commit('remove', id);
        return res.data;
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};

export default module;
