/* eslint-disable @typescript-eslint/no-empty-function */
const module = {
  namespaced: true,
  state: {
    open: false,
    header: '',
    subHeader: '',
    icon: '',
    message: '',
    closeButtonText: '',
    actionButtonText: '',
    closeCallback: () => {},
    actionCallback: () => {},
  },
  getters: {},
  mutations: {
    setOpen(state, payload) {
      state.open = payload;
    },
    setPopupState(
      state,
      {
        subHeader = '',
        header = '',
        message = '',
        icon = '',
        closeButtonText = 'Sluiten',
        actionButtonText = '',
        closeCallback = () => {},
        actionCallback = () => {},
      },
    ) {
      state.header = header;
      state.subHeader = subHeader;
      state.icon = icon;
      state.message = message;
      state.closeButtonText = closeButtonText;
      state.actionButtonText = actionButtonText;
      state.closeCallback = closeCallback;
      state.actionCallback = actionCallback;
    },
  },
  actions: {
    openPopup({ commit }, payload) {
      commit('setPopupState', payload);
      commit('setOpen', true);
    },
    close({ commit }) {
      commit('setOpen', false);
    },
  },
};

export default module;
