import config from '@/config';
import axios from 'axios';

export class B2CRepository {
  static async getDesignSales(designId: string): Promise<number> {
    const { data } = await axios.get(`${config.SALES_URI}${designId}`);
    if (data && data.sales) {
      return data.sales;
    }
    return 0;
  }
}
