<template>
  <div
    v-if="total > limit"
    class="b2c_content_container a_max-width-1000 b2c_pagination a_margin-top-20"
  >
    <div class="b2c_pagination_filters a_margin-right-20">
      <select v-model="limit" class="v_transparent">
        <option :value="8">8 per pagina</option>
        <option :value="16">16 per pagina</option>
        <option :value="32">32 per pagina</option>
      </select>
    </div>
    <div class="b2c_pagination_links">
      <router-link
        v-for="(page, index) in paginationLinks"
        :disabled="page === '...'"
        :key="index"
        :to="to(page)"
        tag="button"
        >{{ page }}</router-link
      >
    </div>
    <!--  -->
  </div>
</template>

<script>
export default {
  props: ['limit', 'page', 'total', 'routeName', 'params'],
  computed: {
    paginationLinks() {
      const pages = this.paginate(
        parseInt(this.page),
        Math.round(this.total / this.limit),
      );
      return pages;
    },
  },
  watch: {
    limit() {
      this.$router.push(this.to(1));
    },
  },
  methods: {
    to(page) {
      return {
        name: this.routeName,
        params: {
          ...this.params,
          page,
        },
        query: {
          limit: this.limit,
        },
      };
    },
    paginate(current, last) {
      const delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [];
      let l;
      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i);
        }
      }
      for (const i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push('...');
          }
        }
        rangeWithDots.push(i);
        l = i;
      }
      return rangeWithDots;
    },
  },
};
</script>

<style lang="scss"></style>
