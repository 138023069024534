<template>
  <div>
    <section class="b2c_content">
      <div class="b2c_content_container">
        <div class="b2c_content_1-1">
          <header class="b2c_header">
            <div class="b2c_header_top">
              <div class="b2c_header_top_left">
                <div class="b2c_header_switch a_flex a_align-items_center">
                  <h1 class="s_elem-has-icon-left a_fill_default">
                    Generator
                    <icon :icon-id="'icon_mockuptool'" />
                  </h1>
                </div>
              </div>
              <div class="b2c_header_top_right">
                <button
                  class="button v_ghost_brand_primary v_smaller a_margin-left-10 a_opacity-0 a_no-pointer-events"
                >
                  ...
                </button>
              </div>
            </div>

            <div class="b2c_header_description">
              <p>Op deze pagina kunnen losse renders worden gegenereerd.</p>
            </div>
          </header>
        </div>
      </div>
    </section>

    <!-- <section class="b2c_search-bar a_margin-top-60">
            <div class="b2c_search-bar_left">
                <h2 class="a_margin-bottom-0 a_margin-right-20">Mockups overzicht</h2>

                <input type="text" placeholder="Zoek een mockup" class="a_margin-bottom-0 a_margin-right-10" data-toggle="dropdownTemplates">

                <div class="dropdown-pane" id="dropdownTemplates" data-dropdown data-close-on-click="true">  
                    <ul class="b2c_inline-list">
                        <li><button>Mockup 1</button></li>
                        <li><button>Mockup 2</button></li>
                        <li><button>Mockup 3</button></li>
                        <li><button>Mockup 4</button></li>
                        <li><button>Mockup 5</button></li>
                        <li><button>Mockup 6</button></li>
                    </ul>
                </div>
                
            </div>
            <div class="b2c_search-bar_left">
                <a target="_blank" class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0">
                    Nieuwe mockup scene
                </a>
            </div>
        </section> -->

    <section class="b2c_content_container a_max-width-800">
      <label for="">
        template ID
        <input v-model="templateId" type="text" />
      </label>
      <label for="">
        design ID
        <input v-model="designId" type="text" />
      </label>
      <label for="">
        mockup ID
        <input v-model="mockupId" type="text" />
      </label>
      <hr />
      <!-- <input type="text" placeholder="Zoek een mockup" class="a_margin-bottom-0 a_margin-right-10" data-toggle="dropdownTemplates"> -->

      <p>Render Type</p>
      <label for="" style="display:inline;">
        <input
          v-model="renderType"
          value="render"
          name="render-type"
          type="radio"
        />
        Plat
      </label>
      <label for="" style="display:inline;" class="a_margin-left-20">
        <input
          v-model="renderType"
          value="preview"
          name="render-type"
          type="radio"
        />
        Preview
      </label>
      <label for="" style="display:inline;" class="a_margin-left-20">
        <input
          v-model="renderType"
          value="mockup"
          name="render-type"
          type="radio"
        />
        Mockup
      </label>

      <hr />
      <div>
        <label for="">
          <input id="" v-model="force" type="checkbox" name="" /> forceer render
        </label>
        <label for="">
          afmeting
          <input
            id=""
            v-model="size"
            :disabled="renderType === 'mockup'"
            type="number"
            max="1000"
            name=""
          />
        </label>
        <label for="">
          rotatie
          <input
            id=""
            v-model="rotation"
            :disabled="renderType !== 'preview'"
            type="number"
            max="360"
            name=""
          />
        </label>
        <label :disabled="renderType !== 'preview'">
          <input
            id=""
            :disabled="renderType !== 'preview'"
            v-model="reflection"
            type="checkbox"
            name=""
          />
          reflectie
        </label>
        <label :disabled="renderType !== 'preview'">
          <input
            id=""
            :disabled="renderType !== 'preview'"
            v-model="shadow"
            type="checkbox"
            name=""
          />
          schaduw
        </label>
        <label for="" style="display:inline;" class="a_margin-left-20">
          <input
            :disabled="renderType !== 'preview'"
            v-model="state"
            value="open"
            name="state"
            type="radio"
          />
          Open
        </label>
        <label for="" style="display:inline;" class="a_margin-left-20">
          <input
            :disabled="renderType !== 'preview'"
            v-model="state"
            value="closed"
            name="state"
            type="radio"
          />
          Gesloten
        </label>
      </div>
      <button
        :disabled="rendering"
        class="button v_brand_secondary v_smaller a_margin-bottom-0"
        @click="render"
      >
        Render
      </button>
      <span v-show="rendering">Momentje Aub</span>
    </section>

    <img :src="imageUrl" alt="" />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import config from '@/config';

export default {
  data() {
    return {
      templateId: '',
      designId: '',
      mockupId: '',
      templates: null,
      renderType: 'render',
      size: 750,
      rotation: 0,
      reflection: true,
      shadow: true,
      state: 'closed',
      rendering: false,
      force: true,
      imageUrl: '',
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  activated() {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  created() {},
  mounted() {
    // this.dropdownPrintTables = new Foundation.Dropdown(($('#dropdownTemplates')), {
    // vOffset: 20,
    // });
  },
  methods: {
    ...mapActions('popup', ['openPopup']),
    async render() {
      if (!this.templateId && !this.designId && !this.mockupId) {
        return this.openPopup({
          message: 'Geef minstens een template, design of mockup ID op',
        });
      }
      this.rendering = true;
      let url = '';
      url = `${config.DESIGN_EXPORTER}${this.renderType}/?size=${this.size}`;
      if (this.templateId) {
        url = `${url}&templateId=${this.templateId}`;
      }
      if (this.designId) {
        url = `${url}&designId=${this.designId}`;
      }
      if (this.mockupId) {
        url = `${url}&mockupId=${this.mockupId}`;
      }

      if (this.renderType === 'preview') {
        url = `${url}&reflection=${this.reflection ? 'true' : 'false'}`;
        url = `${url}&shadow=${this.shadow ? 'true' : 'false'}`;
        url = `${url}&rotation=${this.rotation}`;
        url = `${url}&state=${this.state}`;
      }

      if (this.renderType === 'render') {
        url = `${url}&type=png`;
      }

      console.log(url);
      try {
        const res = await fetch(`${url}&${this.force ? 'force=true' : ''}`, {
          mode: 'no-cors',
        });
        console.log(res);
        this.openPopup({
          header: 'Render',
          message: `<img src="${url}">`,
        });
      } catch (e) {
        this.openPopup({
          header: 'Er was een probleem bij het renderen.',
          message: e.message,
        });
      }
      this.rendering = false;

      return true;
    },
  },
  components: {},
};
</script>

<style scoped lang="scss"></style>
