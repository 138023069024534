<template>
  <div>
    <page-title
      :title="'Emoji'"
      :icon="'icon_heart'"
      :description="
        'Deze pagina betreft een overview van de laatste toegevoegde emojis voor het PCD platform.'
      "
    />
    <section class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <search-dropdown
          :placeholder="'Zoek category'"
          :data="emojiCategories"
          v-model="search"
          @match="onSearchMatch"
        />
        <button
          class="button v_brand_secondary v_is-icon v_smaller a_no-margin"
          @click="clearSearchDropdown"
        >
          <icon :icon-id="'icon_ui_close'" />
        </button>
      </div>
      <div class="b2c_search-bar_left">
        <button
          class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0 a_margin-right-10"
          @click="$refs.bulkUpload.open()"
        >
          Bulk Upload
        </button>
        <button
          class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0"
          @click="$refs.newEmojiCategory.open()"
        >
          Nieuwe emoji
        </button>
      </div>
    </section>
    <section v-show="selectedCategory" class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <h2 class="a_margin-bottom-0 a_margin-right-20">
          {{ selectedCategory && selectedCategory.name }}
        </h2>
        <p class="a_margin-bottom-0 a_text_brand_gray">
          <span>{{ filteredEmojis.length }} emoji</span>
          <span
            class="delete_category a_margin-left-20"
            @click="$refs.deleteEmojiCategory.open(selectedCategory)"
            >emoji verwijderen</span
          >
        </p>
      </div>
      <div class="b2c_search-bar_left">
        <button
          class="button v_brand_secondary a_margin-bottom-0"
          @click="$refs.newEmoji.open(selectedCategory)"
        >
          Emoji toevoegen
        </button>
      </div>
    </section>

    <section class="b2c_content a_margin-top-40">
      <div class="b2c_content_container a_max-width-1000">
        <div class="b2c_content_1-1">
          <hr />
          <div class="b2c_items" data-s-amount="4">
            <div
              v-for="emoji in filteredEmojis"
              class="b2c_item"
              data-s-amount-item
              :key="emoji.name"
            >
              <div class="b2c_item_container">
                <div class="b2c_item_image-container">
                  <!-- <img :src="emoji.thumbnail ? basePath + emoji.thumbnail : 'static/img/misc/no-image.svg'"> -->
                  <img
                    :src="
                      emoji.thumbnail
                        ? basePath + emoji.thumbnail
                        : 'static/img/misc/no-image.svg'
                    "
                  />
                </div>
                <div class="b2c_item_content-container">
                  <div class="b2c_item_name">
                    {{ emoji.name }}
                  </div>
                  <button
                    class="button v_ghost_brand_light-gray v_is-icon v_smallest a_no-margin a_fill_brand_red"
                    @click="$refs.deleteEmoji.open(emoji)"
                  >
                    <icon :icon-id="'icon_ui_close'" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- EMOJI CATEGORY MODALS -->
    <new-emoji-category ref="newEmojiCategory" />
    <delete-emoji-category
      ref="deleteEmojiCategory"
      @success="onCategoryDeleted"
    />
    <!-- EMOJI MODALS -->
    <new-emoji ref="newEmoji" />
    <bulk-upload ref="bulkUpload" />
    <delete-emoji ref="deleteEmoji" />
  </div>
</template>
<script>
import config from '@/config';
import { mapState, mapActions } from 'vuex';
// import Modal from '@/components/elements/Modal';
import SearchDropdown from '@/components/elements/SearchDropdown';
import PageTitle from '@/components/elements/PageTitle';
import NewEmojiCategory from '../emoji-categories/New';
import DeleteEmojiCategory from '../emoji-categories/Delete';
import NewEmoji from './New';
import BulkUpload from './BulkUpload';
import DeleteEmoji from './Delete';

export default {
  name: 'assets-emojis',
  data() {
    return {
      search: '',
      selectedCategory: '',
      basePath: config.STORAGE_URL,
    };
  },
  async created() {
    try {
      await Promise.all([this.getEmojiCategories(), this.getEmojis()]);
    } catch (e) {
      this.openPopup({
        header: 'Er was een probleem bij het ophalen van de emoji',
        message: e.message,
      });
    }
  },
  computed: {
    ...mapState('emojiCategory', {
      emojiCategories: 'items',
    }),
    ...mapState('emoji', {
      emojis: 'items',
    }),
    // return emoji based on selected category
    filteredEmojis() {
      if (!this.selectedCategory) return [];
      return this.emojis.filter(
        emoji => emoji.category === this.selectedCategory._id,
      );
    },
  },
  methods: {
    ...mapActions('popup', ['openPopup']),
    ...mapActions('emojiCategory', {
      getEmojiCategories: 'list',
    }),
    ...mapActions('emoji', {
      getEmojis: 'list',
    }),
    onSearchMatch(match) {
      this.selectedCategory = match;
    },
    emojiFileChange(name, files) {
      // eslint-disable-next-line no-console
      console.log(name, files);
      if (files) {
        this.newEmojiUri = files[0];
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        this.newEmojiImage = reader.result;
      };
      reader.readAsDataURL(this.newEmojiUri);
    },
    onCategoryDeleted() {
      this.search = '';
      this.selectedCategory = null;
    },
    clearSearchDropdown() {
      this.search = '';
      this.selectedCategory = '';
    },
  },
  components: {
    // Modal,
    PageTitle,
    SearchDropdown,
    NewEmojiCategory,
    DeleteEmojiCategory,
    NewEmoji,
    DeleteEmoji,
    BulkUpload,
  },
};
</script>

<style scoped lang="scss">
.delete_category {
  color: red;
  cursor: pointer;
}
</style>
