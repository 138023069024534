<template>
  <modal
    :open.sync="modal.open"
    :loading.sync="modal.loading"
    :error-message.sync="modal.errorMessage"
    :success-message.sync="modal.successMessage"
    :header="'Font Toevoegen'"
    :sub-header="'Voeg een nieuwe Font toe.'"
    :action-button-text="'Font Toevoegen'"
    :icon="'icon_text'"
    @action="save"
  >
    <input
      v-show="!modal.successMessage"
      v-model="fields.name"
      type="text"
      placeholder="Font naam, bijvoorbeeld 'Open Sans'"
    />
  </modal>
</template>
<script>
import { mapActions } from 'vuex';
import Modal from '@/components/elements/Modal';

export default {
  name: 'NewFont',
  data() {
    return {
      modal: {
        open: false,
        loading: false,
        done: false,
        successMessage: '',
        errorMessage: '',
      },
      fields: {
        name: '',
      },
    };
  },
  methods: {
    ...mapActions('font', ['create']),
    open() {
      this.modal.open = true;
      this.fields.name = '';
    },
    async save() {
      try {
        if (!this.fields.name) {
          this.modal.errorMessage = 'vul een naam in a.u.b.';
          return false;
        }
        this.modal.loading = true;
        await this.create(this.fields);
        this.modal.successMessage = 'De Font is succesvol aangemaakt.';
      } catch (error) {
        this.modal.errorMessage = error.message;
      }
      this.modal.loading = false;
      return true;
    },
  },
  components: {
    Modal,
  },
};
</script>
