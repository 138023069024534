<template>
  <nav class="b2c_page-tabs">
    <template v-for="(link, i) in links">
      <router-link :to="link.link" :key="i" class="b2c_page-tabs_link">
        {{ link.name }}
      </router-link>
    </template>
  </nav>
</template>

<script>
export default {
  name: 'PageTabs',
  props: ['links'],
};
</script>

<style lang="scss">
.b2c_page-tabs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  border-bottom: solid 1px $brand-light-gray;
}
.b2c_page-tabs_link {
  padding: 14px 0;
  margin-left: 3em;
  white-space: nowrap;
  color: $brand-secondary;
  letter-spacing: 1px;
  font-size: 90%;
  &.s_active {
    border-bottom: solid 1px $brand-secondary;

    &:hover {
      border-bottom-color: $brand-secondary-darker;
    }
  }

  &:hover,
  &:focus {
    color: $brand-secondary-darker;
  }
}
</style>
