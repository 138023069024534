import { fabric } from 'fabric';
import $ from 'jquery';
import _ from 'lodash';
import parse from 'parse-svg-path';
import abs from 'abs-svg-path';
import normalize from 'normalize-svg-path';
import isSvgPath from 'is-svg-path';
import nearestNormalAspectRatio from 'nearest-normal-aspect-ratio';

function getBounds(path) {
  // ES6 string tpl call
  if (Array.isArray(path) && path.length === 1 && typeof path[0] === 'string')
    path = path[0];

  // svg path string
  if (typeof path === 'string') {
    if (!isSvgPath(path)) throw Error('String is not an SVG path.');
    path = parse(path);
  }

  if (!Array.isArray(path))
    throw Error('Argument should be a string or an array of path segments.');

  path = abs(path);
  path = normalize(path);

  if (!path.length) return [0, 0, 0, 0];

  const bounds = [Infinity, Infinity, -Infinity, -Infinity];

  for (let i = 0, l = path.length; i < l; i++) {
    const points = path[i].slice(1);

    for (let j = 0; j < points.length; j += 2) {
      if (points[j + 0] < bounds[0]) bounds[0] = points[j + 0];
      if (points[j + 1] < bounds[1]) bounds[1] = points[j + 1];
      if (points[j + 0] > bounds[2]) bounds[2] = points[j + 0];
      if (points[j + 1] > bounds[3]) bounds[3] = points[j + 1];
    }
  }

  return bounds;
}

function imageCreator({ width = 500, height = 500 }) {
  const tempCanvas = new fabric.StaticCanvas(null, {});
  tempCanvas.setDimensions({
    width,
    height,
  });

  function loadSVGFromString(svgString) {
    return new Promise((resolve, reject) => {
      if (!svgString) {
        reject('no svg string specified');
      }
      fabric.loadSVGFromString(svgString, objects => {
        if (!objects.length) {
          reject();
        }
        resolve(objects);
      });
    });
  }

  // creates overlay
  // returns an base64 png string and it's boundaries
  async function createOverlayImage(svg, rect) {
    const objects = await loadSVGFromString(svg);
    const background = objects.find(object => object.id === 'background');
    if (!background) {
      return Promise.reject('geen background laag in SVG');
    }
    background.fill = '#ff6600';
    tempCanvas.clear();
    tempCanvas.add(background);
    let boundingRect;
    if (rect && rect.width) {
      background.left = rect.x;
      background.top = rect.y;
      background.scaleX = rect.width / background.width;
      background.scaleY = rect.height / background.height;
    } else {
      if (background.height > background.width) {
        background.scaleToHeight(height * 0.9);
      } else {
        background.scaleToWidth(width * 0.9);
      }
      background.center().setCoords();
      boundingRect = background.getBoundingRect();
    }
    tempCanvas.renderAll();
    return Promise.resolve({
      image: tempCanvas.toDataURL(),
      digitalRect: boundingRect
        ? {
            x: boundingRect.left / width,
            y: boundingRect.top / height,
            width: boundingRect.width / width,
            height: boundingRect.height / height,
          }
        : rect,
    });
  }

  async function calculateRect(svg) {
    const objects = await loadSVGFromString(svg);
    const background = objects.find(object => object.id === 'background');
    if (!background) {
      return Promise.reject('geen background laag in SVG');
    }
    tempCanvas.clear();
    tempCanvas.add(background);
    if (background.height > background.width) {
      background.scaleToHeight(height * 0.9);
    } else {
      background.scaleToWidth(width * 0.9);
    }
    background.center().setCoords();
    const boundingRect = background.getBoundingRect();
    return {
      x: boundingRect.left / width,
      y: boundingRect.top / height,
      width: boundingRect.width / width,
      height: boundingRect.height / height,
    };
  }

  async function renderImage(svg, rect, colour) {
    const objects = await loadSVGFromString(svg);
    const background = objects.find(object => object.id === 'background');
    if (!background) {
      return Promise.reject('geen background laag in SVG');
    }
    background.fill = colour;
    tempCanvas.clear();
    tempCanvas.add(background);
    if (rect && rect.width) {
      background.left = rect.x * width;
      background.top = rect.y * height;
      background.scaleX = (rect.width * width) / background.width;
      background.scaleY = (rect.height * height) / background.height;
    } else {
      if (background.height > background.width) {
        background.scaleToHeight(height * 0.9);
      } else {
        background.scaleToWidth(width * 0.9);
      }
      background.center().setCoords();
    }
    tempCanvas.renderAll();
    return Promise.resolve(tempCanvas.toDataURL());
  }

  async function createInsideImage(svg, rect, colour) {
    const objects = await loadSVGFromString(svg);
    const background = objects.find(object => object.id === 'background');
    if (!background) {
      return Promise.reject('geen background laag in SVG');
    }
    tempCanvas.clear();
    tempCanvas.add(background);
    if (rect && rect.width) {
      background.left = rect.x * width;
      background.top = rect.y * height;
      background.scaleX = (rect.width * width) / background.width;
      background.scaleY = (rect.height * height) / background.height;
    } else {
      if (background.height > background.width) {
        background.scaleToHeight(height * 0.9);
      } else {
        background.scaleToWidth(width * 0.9);
      }
      background.center().setCoords();
    }
    background.fill = colour;
    tempCanvas.renderAll();
    return Promise.resolve(tempCanvas.toDataURL());
  }

  // function loadImage(imgUrl) {
  //     return new Promise((resolve, reject) => {
  //         fabric.Image.fromURL(imgUrl, (img) => {
  //             if (!img) return reject('no image');
  //             return resolve(img);
  //         });
  //     });
  // }

  async function createFoldImage(svg, rect) {
    const foldIds = ['foldLipOutside', 'foldLipInside', 'foldSideLines'];
    const objects = await loadSVGFromString(svg);
    const background = objects.find(object => object.id === 'background');
    const folds = objects.filter(object => foldIds.indexOf(object.id) !== -1);
    const lipArea = objects.find(object => object.id === 'layout2');
    const lipClip = objects.find(object => object.id === 'lipclip');
    const bleed = objects.find(object => object.id === 'bleed');

    // console.log('has lipara', lipArea);

    if (!background) {
      return Promise.reject('geen background laag in SVG');
    }
    tempCanvas.clear();
    let selectedObjects = [background];
    if (lipArea) {
      selectedObjects = selectedObjects.concat(lipArea);
    }
    if (lipClip) {
      selectedObjects = selectedObjects.concat(lipClip);
    }
    selectedObjects = selectedObjects.concat(folds);
    const group = new fabric.Group(selectedObjects, {});
    // const image = await loadImage('/img/misc/fold-repeater.png');
    // console.log('image', image);
    tempCanvas.add(group);
    // tempCanvas.add(image);
    if (rect && rect.width) {
      group.left = rect.x * width;
      group.top = rect.y * height;
      group.scaleX =
        (rect.width * width) / (group.width / (bleed.width / background.width));
      group.scaleY = (rect.height * height) / group.height;
    } else if (background.height > background.width) {
      background.scaleToHeight(height * 0.9);
    } else {
      background.scaleToWidth(width * 0.9);
    }
    group.center().setCoords();
    tempCanvas.renderAll();
    return Promise.resolve(tempCanvas.toDataURL());
  }

  return {
    createOverlayImage,
    createFoldImage,
    createInsideImage,
    renderImage,
    calculateRect,
    // getFoldCoordinates,
  };
}

function getPhysicalSize(svg) {
  const background = $(svg).find('#background');
  const [left, top, right, bottom] = getBounds(background.attr('d'));
  return {
    width: (right - left) / (72 / 25.4),
    height: (bottom - top) / (72 / 25.4),
  };
}

function getAspectRatio(svg) {
  if (!$(svg).find('#layout1').length) {
    // eslint-disable-next-line
    return alert('"layout1" rechthoek is niet gevonden');
  }
  if (
    !$(svg)
      .find('#layout1')
      .find('rect').length
  ) {
    // eslint-disable-next-line
    return alert(
      'Er is geen rectangle gevonden binnen layout1. Zorg dat deze aanwezig is.',
    );
  }
  const layoutWidth = parseFloat(
    $(svg)
      .find('#layout1')
      .find('rect')
      .attr('width'),
  );
  const layoutHeight = parseFloat(
    $(svg)
      .find('#layout1')
      .find('rect')
      .attr('height'),
  );
  const ratio = nearestNormalAspectRatio(layoutWidth, layoutHeight, 4, 4);
  console.log('ratio', ratio);
  return ratio;
}

function pxToMM(value) {
  return value / (72 / 25.4);
}

async function getLipInfo(svg) {
  const background = $(svg).find('#background');
  const lipSide = $(svg).find('#lipside rect');
  let output = {
    side: 'none',
    size: 0,
  };
  if (!background.length) {
    // eslint-disable-next-line
    return alert('background is niet gevonden');
  }
  if (background.length > 0 && lipSide.length > 0) {
    if (!background.attr('d')) {
      throw new Error(
        'background is geen (compound) path. Zorg ervoor dat de background een losse path is en niet in een layer zit.',
      );
    }
    const backgroundBounds = getBounds(background.attr('d'));
    backgroundBounds[2] -= backgroundBounds[0];
    backgroundBounds[3] -= backgroundBounds[1];
    const halfLineX = backgroundBounds[0] + backgroundBounds[2] / 2;
    const lipSideX = parseFloat(lipSide.attr('x'));
    const lipSideWidth = parseFloat(lipSide.attr('width'));
    output =
      lipSideX < halfLineX
        ? {
            side: 'left',
            size: pxToMM(lipSideX - backgroundBounds[0] + lipSideWidth),
          }
        : {
            side: 'right',
            size: pxToMM(
              backgroundBounds[2] - (lipSideX - backgroundBounds[0]),
            ),
          };
  }

  return output;
}

async function getFoldCoordinates(svg) {
  if (!$(svg).find('#foldSideLines').length) {
    return console.warn('deze template heeft geen foldlines');
  }
  const foldLines = [];
  const background = $(svg).find('#background');
  // const foldLipOutside = $(svg).find('#foldLipOutside');
  // const foldLipInside = $(svg).find('#foldLipInside');
  const foldSideLines = $(svg).find('#foldSideLines');
  if (!background) {
    Promise.reject('geen laag "background" gevonden');
  }
  const backgroundBounds = getBounds(background.attr('d'));
  backgroundBounds[2] -= backgroundBounds[0];
  backgroundBounds[3] -= backgroundBounds[1];

  function normalize(val) {
    return (val - backgroundBounds[0]) / backgroundBounds[2];
  }

  // if (foldLipOutside) {
  //     const line = foldLipInside
  //     const foldLipOutsideX = +foldLipOutside.find('line').attr('x1');
  //     foldLines.push(normalize(foldLipOutsideX) - spacing);
  //     foldLines.push(normalize(foldLipOutsideX));
  // }
  // if (foldLipInside) {
  //     const foldLipInsideX = +foldLipInside.find('line').attr('x1');
  //     foldLines.push(normalize(foldLipInsideX) - spacing);
  //     foldLines.push(normalize(foldLipInsideX));
  // }
  if (foldSideLines) {
    if (foldSideLines.find('g').length) {
      throw new Error(
        'Er is een groep gevonden binnen de foldSideLines. Er mogen alleen lijnen aanwezig zijn. Elke lijn moet uit 1 path bestaan',
      );
    }
    foldSideLines.find('line').each((index, line) => {
      const foldSideLineX = +$(line).attr('x1');
      foldLines.push(normalize(foldSideLineX));
      // foldLines.push(normalize(foldSideLineX) + (spacing / 2));
    });
    console.log('fold sidelines', foldLines);
    foldLines.sort();
  }

  return foldLines;
}

function getFoldedPhysicalSize(svg, physicalSize) {
  let lines = $(svg).find('#foldSideLines>line');
  if (lines.length === 0) {
    console.log('foldSideLines does not exist');
    return physicalSize;
  }
  lines = lines.map((index, item) => {
    const line = $(item);
    return {
      x: pxToMM(line.attr('x1')),
      y: pxToMM(line.attr('y1')),
      height: pxToMM(line.attr('y2') - line.attr('y1')),
    };
  });
  lines = _.sortBy(lines, 'height');
  const lipSide =
    _.last(lines).height > _.first(lines).height ? 'left' : 'right';
  lines = _.sortBy(lines, 'x');
  const foldedPhysicalSize = {
    height: physicalSize.height,
  };
  foldedPhysicalSize.width =
    lipSide === 'left'
      ? physicalSize.width - _.last(lines).x
      : _.first(lines).x;
  return foldedPhysicalSize;
}

async function getBleedSize(svg) {
  if (!$(svg).find('#bleed').length) {
    throw new Error(
      'Geen bleed laag gevonden. Het kan zijn dat de naam in svg verkeerd is opgeslagen',
    );
  }
  const bleedBB = getBounds(
    $(svg)
      .find('#bleed>path')
      .attr('d'),
  );
  const backgroundBB = getBounds(
    $(svg)
      .find('#background')
      .attr('d'),
  );
  bleedBB[2] -= bleedBB[0];
  backgroundBB[2] -= backgroundBB[0];
  return pxToMM(bleedBB[2] - backgroundBB[2]) / 2.0;
}

async function checkForTextArea(svg) {
  if (!$(svg).find('#textArea').length) {
    throw new Error(
      'Geen textArea laag gevonden. Controleer of deze aanwezig is',
    );
  }
}

export default {
  getPhysicalSize,
  getFoldedPhysicalSize,
  getFoldCoordinates,
  getLipInfo,
  imageCreator,
  getAspectRatio,
  pxToMM,
  getBleedSize,
  checkForTextArea,
};
