<template>
  <div>
    <section class="b2c_content">
      <div class="b2c_content_container a_max-width-2000">
        <div class="b2c_content_1-1">
          <router-link
            :to="'/designers/pcd-designers'"
            class="button v_transparent a_text_brand_secondary v_has-icon-left v_smallest a_margin-bottom-40"
          >
            <span class="text-container">naar het overzicht van designers</span>
            <span class="svg-container">
              <icon :icon-id="'icon_ui_arrow-left'" />
            </span>
          </router-link>
          <header class="b2c_header">
            <div class="b2c_header_top">
              <div v-if="user.b2cId" class="b2c_header_top_left">
                <div class="b2c_header_switch a_flex a_align-items_center">
                  <!-- <div class="b2c_avatar v_smaller a_margin-right-20">
                                        <div class="b2c_avatar_image" style="background-image: url(img/avatars/1.jpg);"></div>
                                    </div>-->
                  <h1>
                    {{ user.name }} {{ user.family }}
                    <small>({{ userId }})</small>
                  </h1>
                </div>
                <p>
                  {{ user.state === 'designer' ? 'Designer' : 'geen designer' }}
                  ({{ user.email }})
                </p>
                <a
                  v-if="user.state === 'designer'"
                  :href="`${config.DESIGNER_URI}${userId}`"
                  target="_blank"
                  >Naar publieke designer pagina</a
                >
              </div>
              <!-- <div class="b2c_header_top_right a_block">
                               <button class="button v_ghost_brand_secondary v_smaller v_has-icon-right a_margin-bottom-0 a_margin-right-10" data-toggle="dropdownSettings">
                                    <span class="text-container">
                                        instellingen
                                    </span>
                                    <icon :iconId="'icon_caret'"></icon>
                                </button>
                                <button class="button v_brand_red v_smaller v_has-icon-right a_margin-bottom-0 a_margin-right-10">
                                    gebruiker afwijzen
                                    <icon :iconId="'icon_cross'"></icon>
                                </button>
                                <button class="button v_brand_green v_smaller v_has-icon-right a_margin-bottom-0">
                                    gebruiker goedkeuren
                                    <icon :iconId="'icon_check'"></icon>
                                </button>
                            </div>-->
            </div>
            <!-- <div class="b2c_header_description">
                            <p>Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.</p>
                        </div>-->
          </header>
          <div
            id="dropdownSettings"
            class="dropdown-pane"
            data-dropdown=""
            data-position="bottom"
            data-alignment="right"
            data-close-on-click="true"
          >
            <ul class="b2c_inline-list">
              <li>
                <button>Gebruiker blokkeren</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <center v-if="loading">
      <br />
      <br />
      <h4>Loading ...</h4>
      <!-- <hr /> -->
    </center>
    <section v-if="!loading" class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <h2 class="a_margin-bottom-0 a_margin-right-20">Recente ontwerpen</h2>
        <!-- <div class="b2c_pill">2 nieuwe</div> -->
      </div>
      <div class="b2c_search-bar_left" />
    </section>
    <section v-if="!loading" class="b2c_content a_margin-top-40">
      <div class="b2c_content_container a_max-width-1000">
        <div class="b2c_content_1-1">
          <div class="b2c_items" data-s-amount="4">
            <div
              v-for="item in designs"
              :key="item._id"
              class="b2c_item"
              data-s-amount-item=""
            >
              <div class="b2c_item_container">
                <div class="b2c_item_image-container">
                  <img
                    :src="
                      [
                        basePath,
                        'uploads/designs',
                        item._id,
                        'thumbnail.jpeg',
                      ].join('/')
                    "
                  />
                </div>
                <div class="b2c_item_content-container">
                  <div class="b2c_item_name">
                    <div class="a_margin-bottom-10" style="font-size: 12px">
                      {{ item._id }}
                    </div>
                    <a
                      :href="`${pcdUri}?designId=${item._id}`"
                      class="button v_ghost_brand_light-gray v_is-icon v_smallest a_no-margin a_fill_brand_red"
                      target="blank"
                    >
                      <icon :icon-id="'icon_edit'" />
                    </a>
                    <button
                      class="button v_ghost_brand_light-gray v_is-icon v_smallest a_no-margin a_fill_brand_red"
                      @click="$refs.deleteDesign.open(item)"
                    >
                      <icon :icon-id="'icon_ui_close'" />
                    </button>
                    <a
                      v-show="item.submissionState === 'accepted'"
                      :href="`${config.GLOBAL_URI}&id=${item._id}`"
                      target="blank"
                      class="button v_smallest a_no-margin"
                      >maak globaal</a
                    >
                    <div
                      v-if="item.submissionState === 'accepted'"
                      class="a_margin-top-10"
                    >
                      verkocht: {{ item.sales }}x
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <paginator
      :page="$route.params.page"
      :limit="$route.query.limit"
      :total="totalDesignsCount"
      :params="{ userId: $route.params.userId }"
      route-name="designers-pcd-designer-detail-paged"
    />
    <section v-if="!loading" class="b2c_content a_margin-top-40">
      <div class="b2c_content_container a_max-width-1000">
        <div class="b2c_content_1-1">
          <div class="b2c_items" data-s-amount="4">
            <router-link
              v-for="item in approvedDesigns"
              :key="item._id"
              to="design"
              class="b2c_item"
              data-s-amount-item=""
            >
              <div class="b2c_item_container">
                <div class="b2c_item_image-container">
                  <img
                    :src="
                      [
                        basePath,
                        'uploads/designs',
                        item._id,
                        'thumbnail.jpeg',
                      ].join('/')
                    "
                  />
                </div>
                <div class="b2c_item_content-container">
                  <div class="b2c_item_name">{{ item.template.name }}</div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <delete-design ref="deleteDesign" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import config from '@/config';
import DeleteDesign from './DeleteDesign';
import Paginator from '@/components/elements/Paginator';

export default {
  name: 'DesignersPcdDesignerDetail',
  props: ['userId'],
  data() {
    return {
      totalDesignsCount: 1,
      config,
      loading: false,
      basePath: config.API_URI.substr(0, config.API_URI.length - 1),
      user: {},
      approvedDesigns: [],
      pendingDesigns: [
        // {
        //     name: 'Design 1',
        //     imgsrc: 'img/covers/cover-1.png',
        // },
        // {
        //     name: 'Design 2',
        //     imgsrc: 'img/covers/cover-2.png',
        // },
      ],
    };
  },
  watch: {
    $route(to) {
      this.gotoPage(to.params.page || 1);
    },
  },
  async mounted() {
    // eslint-disable-next-line no-undef
    this.dropdownSettings = new Foundation.Dropdown($('#dropdownSettings'), {
      // vOffset: 20,
    });
    try {
      this.setLoading(true);
      await this.getUser(this.userId).then(user => {
        this.user = user;
      });
      if (!this.$route.params.page || !this.$route.query.limit) {
        this.$router.push({
          name: 'designers-pcd-designer-detail-paged',
          params: {
            userId: this.$route.params.userId,
            page: parseInt(this.$route.params.page || 1),
          },
          query: {
            limit: parseInt(this.$route.query.limit || 16),
          },
        });
      } else {
        this.gotoPage(this.page);
      }
      // this.limit = parseInt(this.$route.query.limit || 16);
      // await this.gotoPage(this.$route.params.page || 1);
    } catch (e) {
      this.openPopup({
        header: 'Er was een probleem bij het ophalen van Templates',
        message: e.message,
      });
    }
    this.setLoading(false);
  },
  computed: {
    ...mapState('design', {
      designs: 'items',
    }),
    pcdUri() {
      return config.PCD_URI;
    },
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    ...mapActions('popup', ['openPopup']),
    ...mapActions('auth', {
      getUser: 'read',
    }),
    ...mapActions('design', {
      getDesigns: 'list',
      remove: 'remove',
    }),
    deleteDesign(id) {
      this.remove(id);
    },
    getDesignSales() {
      this.designs.forEach(async design => {
        if (design.submissionState === 'accepted') {
          const sales = await this.getSales(design._id);
          this.$set(design, 'sales', sales);
        }
      });
    },
    async makeGlobal(designId, templateId, ratio) {
      console.log(templateId, ratio);
      // make design id global by prerendering all assets.
      const query = {
        filters: [
          {
            column: 'layoutRatio',
            operator: '=',
            values: [ratio],
          },
        ],
      };
      const response = await fetch(
        `${config.API_URI}templates/?query=${JSON.stringify(query)}`,
      );
      const templates = await response.json();
      console.log(templates);

      // render flat designs based on designId and templateId
      // await Promise.all(
      //     templates.map(async (template) => {
      //         const url = `http://pcd-design-exporter.stijlbre.uk/render?type=png&designId=${designId}&templateId=${template._id}`;
      //         return fetch(url, { mode: 'no-cors' });
      //     }),
      // );
      console.log('finished flat renders');

      await Promise.all(
        templates.reduce((arr, template) => {
          console.log(template._id);
          return arr.concat(
            template.mockups.map(mockup => {
              const url = `http://pcd-design-exporter.stijlbre.uk/mockup?designId=${designId}&templateId=${template._id}&mockupId=${mockup}`;
              console.log(url);
              return fetch(url, {
                mode: 'no-cors',
              });
            }),
          );
        }, []),
      );

      console.log('finished mockup rendering');
    },
    async getSales(id) {
      try {
        const { data } = await axios.get(`${config.SALES_URI}${id}`);
        if (data && data.sales) {
          return data.sales;
        }
        return 0;
      } catch (e) {
        console.error("couldn't get design sales for " + id);
      }
    },
    async gotoPage() {
      this.setLoading(true);
      const result = await this.getDesigns({
        meta: true,
        filters:
          this.userId === 'admin'
            ? []
            : [
                {
                  column: 'user',
                  operator: '=',
                  values: [this.userId],
                },
              ],
        page: parseInt(this.$route.params.page || 1) - 1,
        limit: parseInt(this.$route.query.limit || 16),
        relations: ['template'],
        sort: '-updatedAt',
      });
      this.getDesignSales();
      this.totalDesignsCount = result.meta.recordsCount;
      this.$forceUpdate();
      this.setLoading(false);
    },
  },
  destroyed() {
    this.dropdownSettings.destroy();
  },
  components: {
    DeleteDesign,
    Paginator,
  },
};
</script>
<style scoped lang="scss">
$columns: (
  designer-name: (
    width: 25%,
  ),
  designer-since: (
    width: 25%,
  ),
  designer-amount: (
    width: 15%,
  ),
  designer-email: (
    width: 20%,
  ),
  designer-btn: (
    width: 15%,
  ),
  designer-new-name: (
    width: 60%,
  ),
  designer-new-btn: (
    width: 40%,
  ),
);
@mixin generate-columns {
  @each $label, $column in $columns {
    [data-t='header-#{$label}'],
    [data-t='item-#{$label}'] {
      padding: 0 $default-padding / 2;
      flex: 0 0 map-get($column, width);
      max-width: map-get($column, width);
    }
  }
}
@include generate-columns();
[data-t='header-datum'],
[data-t='item-datum'],
[data-t='header-designer-btn'],
[data-t='item-designer-btn'] {
  text-align: right;
}
[data-t='header-designer-amount'],
[data-t='item-designer-amount'] {
  text-align: center;
}
</style>
