<template>
  <div>
    <section class="b2c_content">
      <div class="b2c_content_container">
        <div class="b2c_content_1-1">
          <header class="b2c_header">
            <div class="b2c_header_top">
              <div class="b2c_header_top_left">
                <div class="b2c_header_switch a_flex a_align-items_center">
                  <h1 class="s_elem-has-icon-left a_fill_default">
                    Force
                    <icon :icon-id="'icon_mockuptool'" />
                  </h1>
                </div>
              </div>
              <div class="b2c_header_top_right">
                <button
                  class="button v_ghost_brand_primary v_smaller a_margin-left-10 a_opacity-0 a_no-pointer-events"
                >
                  ...
                </button>
              </div>
            </div>

            <div class="b2c_header_description">
              <p>Gebruik deze pagina om een link opnieuw te renderen</p>
            </div>
          </header>
        </div>
      </div>
    </section>

    <section class="b2c_content_container a_max-width-800">
      <label for="">
        Link
        <textarea v-model="link" rows="4" />
      </label>
      <label for="">
        Query
        <textarea :disabled="true" v-model="query" rows="4" />
      </label>
      <button
        :disabled="!query"
        class="button v_brand_secondary v_smaller a_margin-bottom-0"
        @click="addForce"
      >
        Forceren toevoegen
      </button>
      <br /><br />
      <label for="">
        Nieuwe Link
        <textarea :disabled="true" v-model="forced" rows="4" />
      </label>
      <button
        :disabled="!forced || imageSrc !== ''"
        class="button v_brand_secondary v_smaller a_margin-bottom-0"
        @click="renderImage"
      >
        Nieuwe Link Renderen
      </button>
      <hr v-if="imageSrc" />
      <img v-if="imageSrc" :src="imageSrc" alt="" />
      <hr />
    </section>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      link: '',
      query: '',
      forced: '',
      imageSrc: '',
    };
  },
  watch: {
    link() {
      if (this.link) {
        try {
          const base64 = this.link.substring(this.link.lastIndexOf('/') + 1);
          this.query = atob(
            base64
              .split('')
              .reverse()
              .join(''),
          );
          // this.query = base64.split('').reverse().join('');
        } catch (error) {
          alert('de "url" is geen juiste render link!');
          console.log(error);
        }
      } else {
        this.query = '';
      }
      this.forced = '';
      this.imageSrc = '';
    },
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    addForce() {
      try {
        // if (this.link.indexOf('/mockups/') >= 0) {
        //     const base64 = btoa(JSON.stringify({
        //         ...JSON.parse(this.query),
        //         force: true,
        //     })).split('').reverse().join('');
        //     this.forced = this.link.substring(0, this.link.lastIndexOf('/') + 1) + base64;
        // } else {
        const baseUrl = this.link.substring(0, this.link.lastIndexOf('/') + 1);
        const base64 = this.link.substring(this.link.lastIndexOf('/') + 1);
        this.forced = `${baseUrl}force/${base64}`;
        // }
      } catch (error) {
        alert('de "Query" klopt niet!');
        console.log(error);
      }
    },
    async renderImage() {
      try {
        this.setLoading(true);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const imageData = await fetch(this.forced);
        this.imageSrc = this.forced.replace('force/', '');
      } catch (error) {
        alert(
          'Er is iets fout gegaan tijdens het renderen! Kijk in de console voor meer info.',
        );
        console.log(error);
      }
      this.setLoading(false);
    },
  },
};
</script>

<style scoped lang="scss">
img {
  border: 1px solid #afafaf;
  border-radius: 6px;
  max-width: 100%;
}
</style>
