<template>
  <modal
    :open.sync="modal.open"
    :loading.sync="modal.loading"
    :error-message.sync="modal.errorMessage"
    :success-message.sync="modal.successMessage"
    :header="'Composer'"
    :action-button-text="''"
  >
    <center>
      <h2>Arranging orders on Print Tables</h2>
      please wait ...
      <br />
      <br />
      <h4>{{ loaded ? loaded : '100' }}%</h4>
    </center>
  </modal>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import _ from 'lodash';
import Modal from '@/components/elements/Modal';

export default {
  data() {
    return {
      modal: {
        open: false,
        loading: true,
        done: false,
        successMessage: '',
        errorMessage: '',
      },
      loaded: 0,
    };
  },
  computed: {
    ...mapState('template', {
      products: 'products',
    }),
  },
  methods: {
    ...mapActions('template', {
      getTemplate: 'read',
    }),
    ...mapActions('printTable', {
      getPrintTable: 'read',
    }),
    async open(designs) {
      this.modal.open = true;
      const templates = new Map();
      this.loaded = 0;
      try {
        for (const design of designs) {
          if (templates.has(design.template._id)) {
            templates.get(design.template._id).designs.push(design);
          } else {
            // const template = await this.getTemplate(design.template._id);
            design.template.designs = [design];
            templates.set(design.template._id, design.template);
          }
          this.loaded += Math.round(50 / designs.length);
        }
        let printTables = new Map();
        for (const [, template] of templates) {
          const product = _.find(this.products, {
            _id: template._id,
          });
          template.location = product ? product.location : undefined;
          if (printTables.has(template.printTable)) {
            printTables
              .get(template.printTable)
              .designs.push(...template.designs);
          } else {
            const printTable = await this.getPrintTable(template.printTable);
            printTable.designs = template.designs;
            printTables.set(printTable._id, printTable);
          }
          this.loaded += Math.round(50 / templates.keys().length);
        }
        this.loaded = 100;
        const sets = [];
        printTables = [...printTables.values()];
        printTables.forEach(printTable => {
          // sort by location
          printTable.designs = _.orderBy(printTable.designs, [
            'template.location',
            'orderline',
          ]);
          printTable.designs = _.orderBy(printTable.designs, ['order'], 'desc');
          // printTable.designs.sort((a, b) => a.template.location ? a.template.location.localeCompare(b.template.location) : 1);
        });

        // sorting print tables
        /**
         * Can we give priority to designs of the same order
         * So in this case an order of one client with multiple designs were placed on 7 tables
         * It would be better if this is on one table
         */
        const list = this.$route.query.list
          ? JSON.parse(this.$route.query.list)
          : undefined;
        if (_.isArray(list)) {
          const orderlineKeys = _.uniq(list.map(item => item.orderline))
            .sort()
            .map(orderline => `ol_${orderline}`);
          printTables.forEach(printTable => {
            orderlineKeys.forEach(orderlineKey => {
              printTable[orderlineKey] = printTable.designs.filter(
                item => `ol_${item.orderline}` === orderlineKey,
              ).length;
            });
          });
          printTables = _.orderBy(printTables, orderlineKeys).reverse();
          console.table(
            printTables.map(printTable =>
              _.pick(printTable, ['name', ...orderlineKeys]),
            ),
          );
        }

        // !--
        printTables.forEach(printTable => {
          const size = printTable.columns * printTable.rows;
          const count = printTable.designs.length / size;
          for (let i = 0; i < count; i += 1) {
            sets.push({
              printTable,
              // items: _.orderBy(printTable.designs.splice(0, size), ['template.location']),
              // items: _.orderBy(printTable.designs.splice(0, size), ['orderline']),
              items: printTable.designs.splice(0, size),
            });
          }
        });
        this.modal.open = false;
        this.$emit('arranged', sets);
      } catch (error) {
        console.log(error);
        this.loading.open = false;
      }
    },
  },
  components: {
    Modal,
  },
};
</script>
