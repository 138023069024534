<template>
  <modal
    :open.sync="modal.open"
    :loading.sync="modal.loading"
    :error-message.sync="modal.errorMessage"
    :success-message.sync="modal.successMessage"
    :header="'Mockup Verwijderen'"
    :action-button-text="'Mockup Verwijderen'"
    @action="removeIt"
  >
    <p>Deze mockup is gelinkt aan de volgende templates:</p>
    <ul>
      <li v-for="template in templates" :key="template._id">
        <router-link
          :to="`/templates/templates?id=${template._id}`"
          class="v_ghost_brand_primary v_smallest a_no-margin"
          target="_blank"
        >
          <strong>{{ template.device.name }}</strong>
          / {{ template.name }}
        </router-link>
      </li>
    </ul>
    <p>Weet je zeker dat je de Mockup "{{ target.name }}" wil verwijderen?</p>
  </modal>
</template>
<script>
import { mapActions } from 'vuex';
import Modal from '@/components/elements/Modal';

export default {
  data() {
    return {
      modal: {
        open: false,
        loading: false,
        done: false,
        successMessage: '',
        errorMessage: '',
      },
      target: {},
      templates: [],
    };
  },
  methods: {
    ...mapActions('mockup', ['remove', 'findTemplates']),
    ...mapActions('loading', ['setLoading']),
    async open(target) {
      this.setLoading(true);
      try {
        this.templates = await this.findTemplates({
          mockupId: target._id,
          query: {
            select: ['name', 'varianceName'],
            relations: ['device'],
          },
        });
        this.modal.open = true;
        this.target = target;
      } catch (error) {
        alert('Loading linked templates to the selected mockup failed!');
      }
      this.setLoading(false);
    },
    async removeIt() {
      try {
        this.modal.loading = true;
        await this.remove(this.target._id);
        this.modal.successMessage = 'De Mockup is succesvol verwijderd.';
        this.$emit('success');
      } catch (error) {
        this.modal.errorMessage = error.message;
      }
      this.modal.loading = false;
      return true;
    },
  },
  components: {
    Modal,
  },
};
</script>
