<template>
  <modal
    :action-button-text="actionButtonText"
    :cancel-button-text="closeButtonText"
    :header="header"
    :sub-header="subHeader"
    :icon="icon"
    :open="open"
    @action="_action()"
    @close="_close(true)"
  >
    <div class="message" v-html="message" />
  </modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Modal from '@/components/elements/Modal';

export default {
  name: 'Popup',
  components: {
    Modal,
  },
  computed: {
    ...mapState('popup', [
      'actionButtonText',
      'actionCallback',
      'closeButtonText',
      'closeCallback',
      'header',
      'subHeader',
      'icon',
      'open',
      'message',
      'component',
    ]),
    closeText() {
      return 'sl';
    },
  },
  methods: {
    ...mapActions('popup', ['close']),
    _close() {
      this.close(true);
      this.closeCallback();
    },
    _action() {
      this.close(true);
      this.actionCallback();
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  max-height: 50vh;
  overflow-y: scroll;
}
</style>
