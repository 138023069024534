<template>
  <div class="b2c_item" data-s-amount-item="">
    <div class="b2c_item_container">
      <div class="b2c_item_image-container">
        <a :href="`${config.PCD_URI}?designId=${value._id}`" target="_blank">
          <img :src="thumbnailSrc" width="50" height="50" alt="" />
        </a>
      </div>
      <div class="b2c_item_content-container">
        <div class="b2c_item_content-container_inner">
          <ul>
            <li>
              <span style="color:#aaa">status:</span>
              <span :style="{ color: stateColor }">{{ state }}</span>
            </li>
            <li>
              <router-link :to="'pcd-designers/user/' + value.user">
                bekijk gebruiker
              </router-link>
            </li>
            <li v-if="value.submissionState === 'accepted'">
              aantal verkopen:
              {{ value.sales }}
            </li>
          </ul>
          <div class="b2c_item_toggle">
            <button
              :disabled="value.submissionState === 'accepted'"
              class="b2c_item_toggle_btn v_accepted"
              @click="setState('accepted', $event)"
            >
              <icon :icon-id="'icon_check'" />
            </button>
            <button
              :disabled="value.submissionState === 'rejected'"
              class="b2c_item_toggle_btn v_rejected"
              @click="setState('rejected', $event)"
            >
              <icon :icon-id="'icon_cross'" />
            </button>
          </div>
          <button
            v-if="value.submissionState === 'accepted'"
            class="button v_transparent_body a_fill_brand_secondary v_icon-size-20 v_has-icon-left a_margin-top-20"
            @click="$emit('re-render', value._id)"
          >
            Re-render
            <icon :icon-id="'icon_re-render'" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import config from '@/config';

export default {
  props: ['value'],
  data() {
    return {
      config,
    };
  },
  computed: {
    thumbnailSrc() {
      const basePath = config.API_URI.substr(0, config.API_URI.length - 1);
      return [
        basePath,
        'uploads/designs',
        this.value._id,
        'thumbnail.jpeg',
      ].join('/');
    },
    state() {
      return (
        {
          none: 'geen',
          requested: 'aangevraagd',
          accepted: 'geaccepteerd',
          rejected: 'afgewezen',
        }[this.value.state] || ''
      );
    },
    stateColor() {
      return (
        {
          none: '#cccccc',
          requested: '#ff6600',
          accepted: '#00cc00',
          rejected: '#cc0000',
        }[this.state] || ''
      );
    },
  },
  methods: {
    ...mapActions('design', ['updateState']),
    async setState(state) {
      const prevState = this.value.submissionState;
      try {
        this.value.submissionState = state;
        await this.updateState(this.value);
      } catch (error) {
        this.value.submissionState = prevState;
        console.log(error);
      }
    },
  },
};
</script>
