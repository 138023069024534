<template>
  <section class="b2c_content">
    <div class="b2c_content_container">
      <div class="b2c_content_1-1">
        <header class="b2c_header">
          <div class="b2c_header_top">
            <div class="b2c_header_top_left">
              <div class="b2c_header_switch a_flex a_align-items_center">
                <h1 class="s_elem-has-icon-left a_fill_default">
                  {{ title }}
                  <icon :icon-id="icon" />
                </h1>
              </div>
            </div>
            <div class="b2c_header_top_right">
              <button
                class="button v_ghost_brand_primary v_smaller a_margin-left-10 a_opacity-0 a_no-pointer-events"
              >
                ...
              </button>
            </div>
          </div>
          <div class="b2c_header_description">
            <p>{{ description }}</p>
          </div>
        </header>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'PageTitle',
  props: ['title', 'description', 'icon'],
};
</script>
