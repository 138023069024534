async function requestPermission() {
  return new Promise((resolve, reject) => {
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      reject('This browser does not support desktop notification');
    }

    // Let's check if the user is okay to get some notification
    else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      resolve(true);
    }

    // Otherwise, we need to ask the user for permission
    // Note, Chrome does not implement the permission static property
    // So we have to check for NOT 'denied' instead of 'default'
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission(function(permission) {
        // If the user is okay, let's create a notification
        if (permission === 'granted') {
          resolve(true);
        }
      });
    } else {
      reject(`Permission is ${Notification.permission}`);
    }
  });
}

export async function notify(title: string, body: string) {
  try {
    await requestPermission();
    const notification = new Notification(title, {
      body,
      icon: './img/logo.png',
    });
  } catch (error) {
    alert(error);
  }
}
