<template>
  <div>
    <div v-if="enableRotation" style="width:400px; margin-left: 50px">
      <span style="margin-left: 8px"
        >Sleep over het hoesje om deze te draaien. Klik op het hoesje om deze te
        openen / sluiten.</span
      >
      <!-- <vue-slider :tooltip="false" :real-time="true" @callback="onSlider" :width="400" :max="360" ref="slider" v-model="sliderValue"></vue-slider> -->
    </div>
    <div id="case-container" ref="case-container" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import CaseRotator from '@/lib/case-rotator';
import Hammer from 'hammerjs';

const SHADOW_BLUR = 5;
const SHADOW_OPACITY = 0.3;
const REFLECTION_ENABLED = false;
const SHADOW_ENABLED = false;

export default {
  props: [
    'showFoldLines',
    'showRotationLine',
    'imageFold',
    'rect',
    'imageInside',
    'imageOver',
    'imageDevice',
    'extraImage',
    'value',
    'image',
    'width',
    // 'template',
    'enableRotation',
  ],
  data() {
    return {
      cr: {},
      open: false,
      containerScale: 1,
      sliderValue: 1,
      seamlessLip: false,
    };
  },
  watch: {
    imageFold(newValue) {
      // debugger;
      // console.log(newValue);
      this.cr.updateImage(newValue, true);
      this.cr.rotate(this.cr.rotation);
    },
    imageInside(newValue) {
      // debugger;
      // console.log(newValue);
      this.cr.setInsideImage(newValue);
    },
    imageOver() {
      // debugger;
      this.loadTemplate();
    },
    enableRotation(newVal) {
      if (newVal === false) {
        this.cr.rotate(0);
      }
    },
    showFoldLines(val) {
      if (val) {
        this.cr.showFoldIndicators();
      } else {
        this.cr.hideFoldIndicators();
      }
    },
    showRotationLine(val) {
      if (val) {
        this.cr.showRotationOffsetPivot();
      } else {
        this.cr.hideRotationOffsetPivot();
      }
    },
    value: {
      handler() {
        // debugger;
        // console.log(newValue);
        if (this.cr.rotationPivot) {
          this.updateTemplate();
        }
      },
      deep: true,
    },
    // rotations() { this.loadTemplate(); },
    // offset() { this.loadTemplate(); },
    // rect() { this.loadTemplate(); },
  },
  mounted() {
    // this.loadTemplate();
    // eslint-disable-next-line no-undef
    this.cr = new CaseRotator($('#case-container'), {
      noElementData: true,
    });
    this.cr.setShadowBlur(SHADOW_BLUR);
    this.cr.setShadowOpacity(SHADOW_OPACITY);
    this.cr.enableReflection(REFLECTION_ENABLED);
    this.cr.enableShadow(SHADOW_ENABLED);
    this.setupDragRotation();
  },
  computed: {
    ...mapState('system', {
      systemConfigs: 'configs',
    }),
    roundedFolds() {
      if (this.value.rounding !== 0) {
        return this.items.reduce((arr, fold) => {
          // ignore the last fold, as this is always max
          arr.push(fold.value - this.value.rounding / 2);
          arr.push(fold.value + this.value.rounding / 2);
          return arr;
        }, []);
      }
      return this.folds;
    },
    roundedRotations() {
      if (this.value.rounding !== 0) {
        return this.items.reduce((arr, fold) => {
          arr.push(fold.rotation / 2);
          arr.push(fold.rotation / 2);
          return arr;
        }, []);
      }
      return this.rotations;
    },
    rotations() {
      return this.items.map(item => item.rotation);
    },
    folds() {
      return this.items.map(item => item.value);
    },
    items() {
      return this.value.items.length > 0
        ? this.value.items
        : [
            {
              value: 0,
              rotation: 0,
            },
          ];
    },
  },
  methods: {
    loadFromSVG() {
      this.loadTemplate();
    },
    openClose() {
      this.open = !this.open;
      if (this.open) {
        this.cr.foldIn();
      } else {
        this.cr.foldOut();
      }
      return true;
    },
    onSlider(val) {
      this.cr.rotate(val);
    },
    denormalizeFolds(folds, rect) {
      if (!rect) {
        return console.error('rect not set');
      }
      if (!folds) {
        return console.error('folds not set');
      }
      console.log('templateAssetsSize', this.systemConfigs.templateAssetsSize);
      const denormalizedFolds = folds.map(fold => {
        fold =
          (rect.x * this.systemConfigs.templateAssetsSize +
            rect.width * this.systemConfigs.templateAssetsSize * fold) /
          (this.systemConfigs.templateAssetsSize / 500);
        return fold;
      });
      denormalizedFolds.push(1 * 500);
      console.log(denormalizedFolds);
      return denormalizedFolds;
    },
    fixRotations(rotations) {
      const fixedRotations = rotations.map(rotation => rotation);
      fixedRotations.unshift(0);
      return fixedRotations;
    },
    loadTemplate() {
      console.log('load template');
      // console.log($, CaseRotator);
      this.cr.setInsideImage(this.imageInside);
      this.cr.setExtraImage(this.extraImage);
      this.cr.updateImage(this.imageFold, true);
      this.cr.setOverlayImage(this.imageOver);
      this.cr.setDeviceImage(this.imageDevice);
      this.cr.rotate(0);
      this.updateTemplate();
      if (this.rect) {
        this.cr.setShadowVerticalOffset(
          this.rect.height + this.rect.y - SHADOW_BLUR,
        );
        this.cr.setReflectionVerticalOffset(this.rect.height);
        this.cr.setShadowHorizontalOffset(0);
      }
    },
    updateTemplate() {
      if (
        // this.value.items.length &&
        typeof this.value.offset === 'number' &&
        this.rect.width
      ) {
        this.cr.updateTemplate(
          this.denormalizeFolds(this.roundedFolds, this.rect),
          this.fixRotations(this.roundedRotations),
          this.value.offset * 500,
        );
        this.cr.refreshFolds();
        if (this.showRotationLine) {
          this.cr.showRotationOffsetPivot();
        }
        if (this.showFoldLines) {
          this.cr.showFoldIndicators();
        }
      }
    },
    setupDragRotation() {
      const container = new Hammer.Manager(this.$refs['case-container']);
      const Pan = new Hammer.Pan();
      const Tap = new Hammer.Tap();
      container.add(Pan);
      container.add(Tap);

      let lastRotation = this.cr.rotation;
      container.on('pan', e => {
        this.cr.rotate(lastRotation + e.deltaX);
      });
      container.on('panend', () => {
        lastRotation = this.cr.rotation;
      });
      container.on('tap', () => {
        this.openClose();
      });
    },
  },
  name: 'DesignRotator',
  components: {},
};
</script>
<style lang="scss">
#case-container {
  img {
    pointer-events: none;
  }
}
.slice {
  position: absolute;
  width: 500px;
  height: 500px;
  z-index: 0;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

.slice > img,
.slice > svg {
  position: absolute;
  width: 500px;
  height: 500px;
  z-index: 0;
  -webkit-transform-style: inherit;
  -moz-transform-style: inherit;
  -ms-transform-style: inherit;
  transform-style: inherit;
  -moz-transform: translateZ(0px);
  transform: translateZ(0px);
  -webkit-transform: translateZ(0px);
}

.device-slice {
  transform: translateZ(-4px) !important;
}
.back-slice {
  transform: translateZ(-3px) !important;
}
.extra-slice {
  transform: translateZ(-2px) !important;
}
.render-slice {
  transform: translateZ(-1px) !important;
}
.over-slice {
  transform: translateZ(0px) !important;
}

img.back-slice.black {
  filter: brightness(0);
}

.cover-animate {
  transition: all 2s;
}

.slicesContainer {
  position: absolute;
  width: 500px;
  height: 500px;
  -moz-perspective-origin: 100% -10%;
  perspective-origin: 100% -10%;

  -moz-transform: perspective(1750px);
  transform: perspective(1750px);
  -moz-perspective: 1750px;
  perspective: 1750px;
  z-index: 333;
}

.slicesContainer.reflection {
  z-index: 111;
}

.slicesContainer.reflection img {
  filter: blur(0px);
}

.slicesContainer.shadow img {
  height: 15px;
}

.reflective-surface {
  width: 500px;
  height: 500px;
  position: absolute;
  left: 0;
  top: 0;
  background: -webkit-linear-gradient(
    top,
    rgba(250, 250, 250, 0.6) 80%,
    rgba(250, 250, 250, 1) 100%
  );
  z-index: 111;
}

.slicesHolder {
  position: absolute;
  width: 500px;
  height: 500px;

  -moz-transform-origin-x: 250px;
  -webkit-transform-origin-x: 250px;
  transform-origin: 250px;

  transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;

  display: block;
}

#case-container {
  user-select: none;
  position: relative;
  overflow: hidden;
  height: 500px;
  width: 500px;
}

#case-pivot-point {
  position: absolute;
  border-left: 2px dotted #ccc;

  height: 500px;
  width: 2px;
  left: 250px;
}

.case-fold-indicator {
  position: absolute;
  background-color: #d936a8;
  height: 500px;
  width: 2px;
}

.case-fold-indicator-2 {
  position: absolute;
  background-color: #d936a8;
  height: 500px;
  width: 2px;
  transform: rotateY(90deg);
  -webkit-transform: rotateY(90deg);
  -moz-transform: rotateY(90deg);
}

#case-pivot-point {
  position: absolute;
  border-left: 2px dotted #ccc;

  height: 500px;
  width: 2px;
  left: 250px;
}

.hide {
  display: none;
}
</style>
