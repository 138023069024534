import store from './store';
import { vm } from './main';
import axios from 'axios';
import config from './config';

axios.defaults.baseURL = config.API_URI;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

axios.interceptors.response.use(
  response => response,
  async error => {
    // Do something with response error
    if (error.response.status === 401) {
      setTimeout(async () => {
        // don't show login popup on login page
        if (vm.$route.name === 'login') {
          return false;
        }
        console.log('401 Unauthorized, logging out ...');
        store.dispatch('popup/close');
        await store.dispatch('popup/openPopup', {
          header: 'U bent niet geautoriseerd',
          message: 'Log opnieuw in om verder te gaan en herhaal uw handeling.',
          actionButtonText: 'Log in',
          actionCallback() {
            store.dispatch('modal/openModal', 'login');
          },
        });
        return true;
      }, 1);
    }
    return Promise.reject(error.response.data.message);
  },
);
