<template>
  <div>
    <page-title
      :title="'Templates'"
      :icon="'icon_templates_templates'"
      :description="'Beheer het aanmaken en aanpassen van templates'"
    />
    <search-bar
      ref="searchBar"
      :categories="categories"
      :devices="devices"
      :templates="templates"
      @categoryChanged="onCategoryChanged($event)"
      @deviceChanged="onDeviceChanged($event)"
      @templateChanged="resetVariance($event)"
      @onNewClicked="resetVariance()"
    />
    <section v-if="device._id" class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <!-- <h2 class="a_margin-bottom-0 a_margin-right-20">{{category.name}}</h2>
        <p class="a_margin-bottom-0 a_margin-right-20">{{device.name}}</p>-->
        <!-- Zet de variatie instelling van deze template -->
        <!-- <button @click="openModal('modal-templates-new-category')" class="button v_ghost_brand_secondary v_is-icon v_icon-size-25 v_smallest a_margin-right-10 a_margin-bottom-0">
                    <icon :iconId="'icon_settings'"></icon>
        </button>-->
        <label class="a_margin-bottom-0 a_margin-right-20">Name</label>
        <input
          :disabled="template != variance"
          v-model="template.name"
          class="a_margin-bottom-0 a_margin-right-10"
          type="text"
        />
        <!-- Stap terug, opnieuw categorie en toestel zoeken -->
        <!-- <button class="button v_brand_secondary v_is-icon v_smaller a_no-margin">
                    <icon :iconId="'icon_ui_close'"></icon>
        </button>-->
      </div>
      <div v-if="template._id" class="b2c_search-bar_left">
        <label class="a_margin-bottom-0 a_margin-right-20"
          >Selecteer variatie</label
        >
        <select
          v-model="selectedVariance"
          class="a_margin-bottom-0 a_margin-right-10"
          @change="resetVariance(selectedVariance)"
        >
          <option :value="template"
            >{{ template.isTheActive ? '✔' : '&nbsp;&nbsp;' }} default</option
          >
          <option v-for="(item, index) in variances" :value="item" :key="index"
            >{{ item.isTheActive ? '✔' : '&nbsp;&nbsp;' }}
            {{ item.varianceName }}</option
          >
        </select>
        <a
          :href="`${config.PCD_URI}?templateId=${variance._id}`"
          target="_blank"
          class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0"
          >Check op PCD</a
        >
        &nbsp;
        <button
          class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0"
          @click="newVariance"
        >
          Voeg variatie toe
        </button>
        &nbsp;
        <button
          :disabled="!templateRemoveAllowed"
          class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0"
          @click="$refs.delete.open(variance)"
        >
          Verwijder variatie
        </button>
        &nbsp;
        <button
          class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0"
          @click="$refs.duplicate.open()"
        >
          Clone variatie
        </button>
      </div>
    </section>
    <item
      v-if="device._id"
      ref="item"
      v-model="variance"
      :device="device"
      :mockups="mockups"
      :variances="variances"
      @saving="saving($event)"
      @saved="
        resetVariance(
          ($refs.searchBar.template = selectedVariance = $event),
          true,
        )
      "
    />
    <delete
      ref="delete"
      @success="
        resetVariance(null, true);
        $refs.searchBar.template = '';
      "
    />
    <duplicate ref="duplicate" v-model="variance" />
  </div>
</template>
<script>
// import _ from 'lodash';
import { mapActions, mapState } from 'vuex';
import _ from 'lodash';
import PageTitle from '@/components/elements/PageTitle';
import config from '@/config';
import SearchBar from './SearchBar';
import Duplicate from './Duplicate';
import Item from './Item';
import Delete from './Delete';

import DEFAULT_GRID from '@/lib/default-grid';

export default {
  name: 'Index',
  data() {
    return {
      category: {},
      device: {},
      template: {},
      variance: {},
      selectedVariance: {},
      loading: false,
      config,
      DEFAULT_GRID,
    };
  },
  async created() {
    this.setLoading(true);
    try {
      await this.sync();
    } catch (error) {
      console.error(error);
    }
    try {
      await Promise.all([
        this.getCategories(),
        this.getMockups(),
        this.getDevices(),
        this.getTemplates(),
      ]);
    } catch (e) {
      this.openPopup({
        header: 'Er was een probleem bij het ophalen van de templates',
        message: e.message,
      });
    }
    this.setLoading(false);
  },
  computed: {
    ...mapState('deviceCategory', {
      categories: 'items',
    }),
    ...mapState('mockup', {
      mockups: 'items',
    }),
    ...mapState('device', {
      devices: 'items',
    }),
    ...mapState('template', {
      templates: 'items',
    }),
    templateRemoveAllowed() {
      return (
        this.variance.varianceOf ||
        _.filter(this.templates, {
          varianceOf: this.template._id,
        }).length === 0
      );
    },
    variances() {
      const output = _.filter(this.templates, {
        varianceOf: this.template._id,
      });
      // console.log(output);
      return output;
    },
  },
  methods: {
    ...mapActions('system', ['sync']),
    ...mapActions('popup', ['openPopup']),
    ...mapActions('loading', ['setLoading']),
    ...mapActions('deviceCategory', {
      getCategories: 'list',
    }),
    ...mapActions('mockup', {
      getMockups: 'list',
    }),
    ...mapActions('device', {
      getDevices: 'list',
    }),
    ...mapActions('template', {
      getTemplates: 'list',
      duplicate: 'duplicate',
      createVariance: 'createVariance',
    }),
    saving(val) {
      this.setLoading(val);
    },
    resetVariance(value, skipConfirmation) {
      if (value === this.variance) {
        return true;
      }
      // eslint-disable-next-line
      if (
        !skipConfirmation &&
        this.variance &&
        this.variance.svg &&
        !confirm('Je onopgeslagen data kan verloren gaan. Weet je het zeker?')
      ) {
        this.selectedVariance = this.variance;
        this.$refs.searchBar.category = this.category;
        this.$refs.searchBar.device = this.device;
        this.$refs.searchBar.template = this.template;
        return false;
      }

      this.variance = value || {
        _id: '',
        name: 'New Template',
        varianceName: 'default',
        rect: {},
        physicalSize: {},
        transparent: false,
        // bleed: 3,
        folds: {
          rounding: 0.01,
          offset: 0.5,
          items: [],
        },
        disabledRenderingAt: '',
        foldedPhysicalSize: {
          width: 0,
          height: 0,
        },
        hasLip: 'none',
        lipSize: 0,
        mockups: [],
        isTheActive: false,
        published: false,
        extraImageFiles: [],
        extraImageColors: [],
        imagesOfDevice: [],
        ..._.cloneDeep(this.DEFAULT_GRID),
      };

      if (!this.variance.grid) {
        this.variance = {
          ...this.variance,
          ..._.cloneDeep(this.DEFAULT_GRID),
        };
      }

      if (!this.variance.extraImageFiles) {
        this.variance.extraImageFiles = [];
      }
      if (!this.variance.extraImageColors) {
        this.variance.extraImageColors = [];
      }
      if (!this.variance.imagesOfDevice) {
        this.variance.imagesOfDevice = [];
      }
      this.selectedVariance = this.variance;
      if (!this.variance.varianceOf) {
        this.template = this.variance;
      }
      if (this.device._id) {
        this.$refs.item.active = 'device';
      }

      if (typeof this.variance.grid === 'string') {
        this.variance.grid = JSON.parse(this.variance.grid);
      }

      return true;
    },
    async newVariance() {
      this.variance = await this.createVariance(this.template);
    },
    onCategoryChanged(value) {
      if (this.resetVariance(null)) {
        this.category = value;
        this.device = {};
      }
    },
    onDeviceChanged(value) {
      if (this.resetVariance(null)) {
        this.device = value;
      }
    },
    async duplicateVariance() {
      this.setLoading(true);
      try {
        this.variance = await this.duplicate(this.variance._id);
        this.$forceUpdate();
      } catch (error) {
        alert('Error on duplication');
      }
      this.setLoading(false);
    },
  },
  components: {
    PageTitle,
    SearchBar,
    Item,
    Delete,
    Duplicate,
  },
};
</script>

<style scoped lang="scss"></style>
