<template>
  <div class="b2c_tab_wrapper">
    <label>
      Variatie Naam
      <span style="color: #aaa">{{ value._id }}</span>
      <input
        :disabled="!value.varianceOf"
        v-model="value.varianceName"
        type="text"
        placeholder="Voeg een notitie toe"
      />
    </label>
    <button
      v-if="value._id && !value.isTheActive"
      ref="btnActive"
      class="button v_ghost_brand_secondary v_expanded v_smaller"
      @click="activate"
    >
      Zet als actieve variatie
    </button>
    <hr />
    <h3>
      <small>SVG Template</small>
    </h3>
    <div class="b2c_form">
      <div class="b2c_form_left">
        <div
          ref="svg"
          class="b2c_form_left_img svg-wrapper"
          v-html="images.svg"
        >
          <!-- <img :src="'data:image/svg+xml;charset=utf-8,' + images.svg" alt> -->
        </div>
      </div>
      <div class="b2c_form_right">
        <h4>
          <small>Upload de SVG</small>
        </h4>
        <label
          for="upload_svg"
          class="button v_ghost_brand_secondary v_expanded v_smaller"
          >{{ images.svg ? 'Vervang ' : 'Upload' }} SVG</label
        >
        <input
          id="upload_svg"
          type="file"
          accept=".svg, .SVG"
          class="show-for-sr"
          @change="
            setSVG($event.target.files);
            $event.target.value = '';
          "
        />
        <a
          v-if="images.svg"
          :href="stringToBase64(images.svg, 'image/svg+xml')"
          download="template.svg"
          class="button v_ghost_brand_secondary v_expanded v_smaller"
          target="_self"
          >Download Afbeelding</a
        >
        <!-- <a @click="images.svg = '';value.svg = '';" class="button v_ghost_brand_secondary v_expanded v_smaller" target="_self" v-if="images.svg">Verwijder Afbeelding</a> -->
        <input
          id="upload_overlay"
          type="file"
          accept=".png, .PNG"
          class="show-for-sr"
          @change="setFile($event.target.files, 'imageOfOver')"
        />
        <p v-show="!images.svg">
          <small>Nog geen SVG geselecteerd</small>
        </p>
        <!-- <h4><small>2. Genereer een achterkant</small></h4>
                <button class="button v_ghost_brand_secondary v_expanded v_smaller" :disabled="!images.svg">
                    Genereer achterkant
                </button>-->
        <!-- <h4><small>2. Download .png bestand</small></h4>
                <a :href="downloadable.imageOfOver" download="overlay-image.png" class="button v_ghost_brand_secondary v_expanded v_smaller" target="_self" :disabled="!images.svg || !downloadable.imageOfOver">Download .png</a>-->
      </div>
    </div>
    <hr />
    <!-- <h3><small>Template Lip</small></h3>
        <p class="a_text_brand_gray">Of deze template wel of geen lipje heeft.</p>
        <div class="a_flex a_align-items_center">
            <select v-model="value.lipSide">
                <option :value="true">Heeft lipje</option>
                <option :value="false">Heeft geen lipje</option>
            </select>
        </div>
        <hr>-->
    <h3>
      <small>Layout Ratio</small>
    </h3>
    <p class="a_text_brand_gray">
      De ratio van deze template en bij welke layouts deze daarmee behoort.
    </p>
    <div class="a_flex a_align-items_center">
      <label style="width: 100%">
        Ratio:
        <select v-model="value.layoutRatio">
          <option>1:1</option>
          <option>1:2</option>
          <option>2:1</option>
          <option>1:3</option>
          <option>2:3</option>
          <option>3:2</option>
          <option>3:1</option>
        </select>
      </label>
    </div>
    <hr />
    <!-- <h3><small>Fysieke afmetingen SVG</small></h3>
        <p class="a_text_brand_gray">De fysieke afmetingen in MM. Deze kun je hierboven terug zien met de linialen.</p>
        <div class="a_flex a_align-items_center">
            <label>
                Breedte:
                <input type="number" :value="value.physicalSize.width && +value.physicalSize.width.toFixed(2)" placeholder="Breedte in mm">
            </label>
            <span class="a_margin-left-10 a_margin-right-40"><small>MM</small></span>
            <label>
                Hoogte:
                <input type="number" :value="value.physicalSize.height && +value.physicalSize.height.toFixed(2)" placeholder="Hoogte in mm">
            </label>
            <span class="a_margin-left-10 a_margin-right-40"><small>MM</small></span>
        </div>
        <hr>
        <h3><small>Upload overlay afbeelding</small></h3>-->
    <h3>
      <small>Rendering Rectangle</small>
    </h3>
    <p class="a_text_brand_gray">
      De rendering rectangle bepaald binnen welke gegenereerd wordt binnen de
      afmetingen. De waarde kan van 0 tot 1 lopen
    </p>
    <div class="a_flex a_align-items_center">
      <label>
        X:
        <input
          v-model.number="value.rect.x"
          type="number"
          placeholder="X in px"
        />
      </label>
      <span class="a_margin-left-10 a_margin-right-40">
        <small>%</small>
      </span>
      <label>
        Y:
        <input
          v-model.number="value.rect.y"
          type="number"
          placeholder="Y in %"
        />
      </label>
      <span class="a_margin-left-10 a_margin-right-40">
        <small>%</small>
      </span>
      <label>
        Breedte:
        <input
          v-model.number="value.rect.width"
          type="number"
          step="0.001"
          placeholder="Breedte in %"
        />
      </label>
      <span class="a_margin-left-10 a_margin-right-40">
        <small>%</small>
      </span>
      <label>
        Hoogte:
        <input
          v-model.number="value.rect.height"
          type="number"
          disabled
          step="0.001"
          placeholder="Hoogte in %"
        />
      </label>
      <span class="a_margin-left-10 a_margin-right-40">
        <small>%</small>
      </span>
    </div>
    <button
      width="100%"
      class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0"
      @click="renderImages()"
    >
      Toepassen
    </button>
    <button
      width="100%"
      class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0"
      @click="resetDesignRect()"
    >
      Reset
    </button>
    <hr />
    <!-- <h3><small>Transparant Hoesje</small></h3>
        <input v-model="value.transparent" type="checkbox"/> Transparant hoesje
        <hr>-->
    <h3>
      <small>Overlay afbeelding</small>
    </h3>
    <p class="a_text_brand_gray">
      Upload een overlay afbeelding met de hiervoor gedownloade .png bestand.
    </p>
    <div class="b2c_form">
      <div class="b2c_form_left">
        <div class="b2c_form_left_img">
          <img :src="images.imageOfOver" alt style="background-color: grey" />
        </div>
      </div>
      <div class="b2c_form_right">
        <label
          for="upload_overlay"
          class="button v_ghost_brand_secondary v_expanded v_smaller"
          >{{ images.imageOfOver ? 'Vervang ' : 'Upload' }} afbeelding</label
        >
        <input
          id="upload_overlay"
          type="file"
          accept=".png, .PNG"
          class="show-for-sr"
          @change="setFile($event.target.files, 'imageOfOver')"
        />
        <a
          v-if="images.svg"
          class="button v_ghost_brand_secondary v_expanded v_smaller"
          target="_self"
          @click="generateImage('imageOfOver')"
          >Genereer Afbeelding</a
        >
        <a
          v-if="images.imageOfOver"
          :href="downloadable.imageOfOver"
          download="over-image.png"
          class="button v_ghost_brand_secondary v_expanded v_smaller"
          target="_self"
          >Download Afbeelding</a
        >
        <a
          v-if="images.imageOfOver"
          class="button v_ghost_brand_secondary v_expanded v_smaller"
          target="_self"
          @click="
            images.imageOfOver = '';
            value.imageOfOver = '';
          "
          >Verwijder Afbeelding</a
        >
      </div>
    </div>
    <hr />
    <h3>
      <small>Upload afbeelding binnenkant</small>
    </h3>
    <p class="a_text_brand_gray">
      Upload een binnenkant afbeelding met de hiervoor gedownloade .png bestand.
    </p>
    <div class="b2c_form">
      <div class="b2c_form_left">
        <div class="b2c_form_left_img" style="background-color: #848484">
          <img v-if="images.imageOfInside" :src="images.imageOfInside" />
        </div>
      </div>
      <div class="b2c_form_right">
        <label
          for="upload_binnenkant"
          class="button v_ghost_brand_secondary v_expanded v_smaller"
          >{{ images.imageOfInside ? 'Vervang ' : 'Upload' }} afbeelding</label
        >
        <input
          id="upload_binnenkant"
          type="file"
          accept=".png, .PNG"
          class="show-for-sr"
          @change="setFile($event.target.files, 'imageOfInside')"
        />
        <a
          v-if="images.svg"
          class="button v_ghost_brand_secondary v_expanded v_smaller"
          target="_self"
          @click="generateImage('imageOfInside')"
          >Genereer Afbeelding</a
        >
        <a
          v-if="images.imageOfInside"
          :href="downloadable.imageOfInside"
          download="indide-image.png"
          class="button v_ghost_brand_secondary v_expanded v_smaller"
          target="_self"
          >Download Afbeelding</a
        >
        <a
          v-if="images.imageOfInside"
          class="button v_ghost_brand_secondary v_expanded v_smaller"
          target="_self"
          @click="
            images.imageOfInside = '';
            value.imageOfInside = '';
          "
          >Verwijder Afbeelding</a
        >
      </div>
    </div>
    <hr />
    <div class="extra-images">
      <h3>
        <small>Upload achtergrond afbeelding</small>
      </h3>
      <p class="a_text_brand_gray">
        Selecteer een achtergrond afbeelding. Als er meerdere achtergronden
        worden tegevoegd, selecteer dan ook voor iedere achtergrond een kleur.
      </p>
      <div
        v-for="(item, index) in value.extraImageFiles"
        :key="index"
        class="extra-image"
      >
        <h4>achtergrond afbeelding {{ index + 1 }}</h4>
        <div class="b2c_form">
          <div class="b2c_form_left">
            <div class="b2c_form_left_img" style="background-color: #848484">
              <img
                v-if="images.extraImages[index]"
                :src="images.extraImages[index]"
              />
            </div>
          </div>
          <div class="b2c_form_right">
            <!-- <h4><small>Upload</small></h4> -->
            <!-- <label class="v_ghost_brand_secondary v_expanded v_smaller">
                            Color
                            <input type="text" v-model="value.extraImages[index].color" placeholder="Color">
                        </label>-->
            <label
              :for="`upload_extraImage_${index}`"
              class="button v_ghost_brand_secondary v_expanded v_smaller"
              >{{
                images.extraImages[index] ? 'Vervang ' : 'Upload'
              }}
              afbeelding</label
            >
            <input
              :id="`upload_extraImage_${index}`"
              type="file"
              accept=".png, .PNG"
              class="show-for-sr"
              @change="setFile($event.target.files, 'extraImages', true, index)"
            />
            <a
              v-if="images.svg"
              class="button v_ghost_brand_secondary v_expanded v_smaller"
              target="_self"
              @click="generateImage('extraImages', index)"
              >Genereer Afbeelding</a
            >
            <a
              v-if="images.extraImages[index]"
              :href="downloadable.extraImages[index]"
              download="extra-image.png"
              class="button v_ghost_brand_secondary v_expanded v_smaller"
              target="_self"
              >Download Afbeelding</a
            >
            <a
              class="button v_ghost_brand_secondary v_expanded v_smaller"
              target="_self"
              @click="
                extraImageColorEnabled.splice(index, 1);
                value.extraImageFiles.splice(index, 1);
                value.extraImageColors.splice(index, 1);
                images.extraImages.splice(index, 1);
                downloadable.extraImages.splice(index, 1);
              "
              >Verwijder Afbeelding</a
            >
            <!-- <input type="checkbox" :checked="value.extraImageColors[index] !== ''" @change="value.extraImageColors[index] = value.extraImageColors[index] !== '' ? '' : '#FF00FF'">&nbsp;Color -->
            <label>
              <input
                v-model="extraImageColorEnabled[index]"
                type="checkbox"
                @change="
                  value.extraImageColors[index] = extraImageColorEnabled[index]
                    ? '#11F0C9'
                    : ''
                "
              />Color
            </label>
            <chrome-picker
              v-if="extraImageColorEnabled[index]"
              :value="value.extraImageColors[index]"
              style="width: 100%"
              @input="value.extraImageColors[index] = $event.hex"
            />
          </div>
        </div>
        <hr />
      </div>
      <a
        class="button v_ghost_brand_secondary v_expanded v_smaller"
        target="_self"
        @click="
          extraImageColorEnabled.push(false);
          value.extraImageFiles.push('');
          value.extraImageColors.push('#11F0C9');
          images.extraImages.push('');
          downloadable.extraImages.push('');
        "
        >Voeg achtergrond afbeelding toe</a
      >
    </div>
    <hr />
    <div class="images-of-devices">
      <h3>
        <small>Upload device afbeelding</small>
      </h3>
      <p class="a_text_brand_gray">TODO: write a description.</p>
      <div
        v-for="(item, index) in value.imagesOfDevice"
        :key="index"
        class="extra-image"
      >
        <h4>device afbeelding {{ index + 1 }}</h4>
        <div class="b2c_form">
          <div class="b2c_form_left">
            <div class="b2c_form_left_img" style="background-color: #848484">
              <img
                v-if="images.imagesOfDevice[index]"
                :src="images.imagesOfDevice[index]"
              />
            </div>
          </div>
          <div class="b2c_form_right">
            <!-- <h4><small>Upload</small></h4> -->
            <!-- <label class="v_ghost_brand_secondary v_expanded v_smaller">
                            Color
                            <input type="text" v-model="value.imagesOfDevice[index].color" placeholder="Color">
                        </label>-->
            <label
              :for="`upload_imagesOfDevice_${index}`"
              class="button v_ghost_brand_secondary v_expanded v_smaller"
              >{{
                images.imagesOfDevice[index] ? 'Vervang ' : 'Upload'
              }}
              afbeelding</label
            >
            <input
              :id="`upload_imagesOfDevice_${index}`"
              type="file"
              accept=".png, .PNG"
              class="show-for-sr"
              @change="
                setFile($event.target.files, 'imagesOfDevice', true, index)
              "
            />
            <a
              v-if="images.svg"
              class="button v_ghost_brand_secondary v_expanded v_smaller"
              target="_self"
              @click="generateImage('imagesOfDevice', index)"
              >Genereer Afbeelding</a
            >
            <a
              v-if="images.imagesOfDevice[index]"
              :href="downloadable.imagesOfDevice[index]"
              download="image-of-device.png"
              class="button v_ghost_brand_secondary v_expanded v_smaller"
              target="_self"
              >Download Afbeelding</a
            >
            <a
              class="button v_ghost_brand_secondary v_expanded v_smaller"
              target="_self"
              @click="
                value.imagesOfDevice.splice(index, 1);
                images.imagesOfDevice.splice(index, 1);
                downloadable.imagesOfDevice.splice(index, 1);
              "
              >Verwijder Afbeelding</a
            >
          </div>
        </div>
        <hr />
      </div>
      <a
        class="button v_ghost_brand_secondary v_expanded v_smaller"
        target="_self"
        @click="
          value.imagesOfDevice.push('');
          images.imagesOfDevice.push('');
          downloadable.imagesOfDevice.push('');
        "
        >Voeg device afbeelding toe</a
      >
    </div>
    <!-- <hr>
        <h3>
            <small>Upload device afbeelding</small>
        </h3>
        <div class="b2c_form">
            <div class="b2c_form_left">
                <div class="b2c_form_left_img" style="background-color: #848484;">
                    <img v-if="images.imageOfDevice" :src="images.imageOfDevice">
                </div>
            </div>
            <div class="b2c_form_right">
                <label for="upload_imageOfDevice" class="button v_ghost_brand_secondary v_expanded v_smaller">{{images.imageOfDevice ? 'Vervang ' : 'Upload'}} afbeelding</label>
                <input type="file" accept=".png,.PNG" @change="setFile($event.target.files, 'imageOfDevice')" id="upload_imageOfDevice" class="show-for-sr">
                <a @click="generateImage('imageOfDevice')" class="button v_ghost_brand_secondary v_expanded v_smaller" target="_self" v-if="images.svg">Genereer Afbeelding</a>
                <a :href="downloadable.imageOfDevice" download="extra-image.png" class="button v_ghost_brand_secondary v_expanded v_smaller" target="_self" v-if="images.imageOfDevice">Download Afbeelding</a>
                <a @click="images.imageOfDevice = '';value.imageOfDevice = '';" class="button v_ghost_brand_secondary v_expanded v_smaller" target="_self" v-if="images.imageOfDevice">Verwijder Afbeelding</a>
            </div>
        </div>
        <hr>-->
    <h3>
      <small>Selecteer print tafel</small>
    </h3>
    <p class="a_text_brand_gray">
      De template past binnen de tafels met een * voor de naam
    </p>
    <select v-model="value.printTable">
      <option
        v-for="(item, index) in printTables"
        :key="index"
        :value="item._id"
      >
        {{ fitsPrintTable(item) ? '*' : '' }} {{ item.name }}
      </option>
    </select>
    <hr />
    <h3>
      <small>Renderen uitschakelen</small>
    </h3>
    <p class="a_text_brand_gray">
      Wanneer renderen is uitgeschakeld zullen designs met een nieuwere datum
      dan de aangegeven datum niet meer mee worden genomen in globale renders
    </p>
    <label>
      <input v-model="disabledRenderingAt" type="checkbox" />
      Renderen uitschakelen</label
    >
    <input
      v-model="disabledRenderingAt"
      :disabled="!disabledRenderingAt"
      type="datetime-local"
    />
    <hr />
    <div class="a_flex a_justify_flex-end a_align-items_center">
      <p class="a_margin-right-20">Volgende stap:</p>
      <button class="button v_brand_secondary" @click="$emit('next')">
        Naar presentatie
      </button>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { Chrome } from 'vue-color';
import axios from 'axios';
import templateHelper from '@/lib/template-helper';
import config from '@/config';
import _ from 'lodash';
import moment from 'moment';

const imageRequest = axios.create({
  headers: {
    'Content-Type': 'image',
    Authorization: '',
  },
});

let imageCreator;

export default {
  props: ['value', 'variances'],
  data() {
    return {
      rectRatio: null,
      extraImageColorEnabled: [],
      disabledRenderingAt: this.value.disabledRenderingAt,
      images: {
        svg: '',
        imageOfOver: '',
        fold: '',
        imageOfInside: '',
        // extraImage: '',
        extraImages: [],
        // imageOfDevice: '',
        imagesOfDevice: [],
      },
      downloadable: {
        imageOfOver: '',
        imageOfInside: '',
        // extraImage: '',
        extraImages: [],
        // imageOfDevice: '',
        imagesOfDevice: [],
      },
    };
  },
  async created() {
    try {
      await Promise.all([this.getPrintTables(), this.getSystemConfigs()]);
      imageCreator = templateHelper.imageCreator({
        width: this.systemConfigs.templateAssetsSize,
        height: this.systemConfigs.templateAssetsSize,
      });
    } catch (e) {
      this.openPopup({
        header: 'Er is iets fout gegaan bij het ophalen van de print tafels',
        message: e.message,
      });
    }
  },
  computed: {
    ...mapState('system', {
      systemConfigs: 'configs',
    }),
    ...mapState('printTable', {
      printTables: 'items',
    }),
    // base64OverallyImage() {
    //     return /^\/uploads/.test(this.value.imageOfOver)? `${this.value.imageOfOver}` : this.value.imageOfOver;
    // }
  },
  watch: {
    disabledRenderingAt(value) {
      this.value.disabledRenderingAt = value ? value : undefined;
    },
    value(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      if (newValue.svg && newValue.svg !== oldValue.svg) {
        this.setLoading(true);
        if (newValue.imageOfInside) {
          this.images.imageOfInside =
            config.API_URI + newValue.imageOfInside.substr(1);
          this.getBase64(this.images.imageOfInside).then(result => {
            this.downloadable.imageOfInside = result;
          });
        } else {
          this.images.imageOfInside = '';
        }
        if (newValue.imageOfOver) {
          this.images.imageOfOver =
            config.API_URI + newValue.imageOfOver.substr(1);
          this.getBase64(this.images.imageOfOver).then(result => {
            this.downloadable.imageOfOver = result;
          });
        } else {
          this.images.imageOfOver = '';
        }
        // if (newValue.extraImage) {
        //     this.images.extraImage = newValue.extraImage ? config.API_URI + newValue.extraImage.substr(1) : '';
        //     this.getBase64(this.images.extraImage).then((result) => { this.downloadable.extraImage = result; });
        // } else {
        //     this.images.extraImage = '';
        // }
        if (_.isArray(newValue.extraImageFiles)) {
          newValue.extraImageFiles.forEach((extraImage, index) => {
            this.extraImageColorEnabled[index] =
              newValue.extraImageColors[index] !== '';
            this.images.extraImages[index] = extraImage
              ? config.API_URI + extraImage.substr(1)
              : '';
            this.getBase64(this.images.extraImage).then(result => {
              this.downloadable.extraImages[index] = result;
            });
          });
        } else {
          this.images.extraImages = [];
        }
        // if (newValue.imageOfDevice) {
        //     this.images.imageOfDevice = newValue.imageOfDevice ? config.API_URI + newValue.imageOfDevice.substr(1) : '';
        //     this.getBase64(this.images.imageOfDevice).then((result) => { this.downloadable.imageOfDevice = result; });
        // } else {
        //     this.images.imageOfDevice = '';
        // }
        if (_.isArray(newValue.imagesOfDevice)) {
          newValue.imagesOfDevice.forEach((imageOfDevice, index) => {
            this.images.imagesOfDevice[index] = imageOfDevice
              ? config.API_URI + imageOfDevice.substr(1)
              : '';
            this.getBase64(this.images.imageOfDevice).then(result => {
              this.downloadable.imagesOfDevice[index] = result;
            });
          });
        } else {
          this.images.imagesOfDevice = [];
        }
        this.loadSVG(newValue.svg)
          .then(async content => {
            this.images.svg = content;
            this.images.fold = await imageCreator.createFoldImage(
              this.images.svg,
              this.value.rect,
            );
            // const { image } = await imageCreator.createOverlayImage(this.images.svg);
            // this.downloadable.imageOfOver = image;
            // this.downloadable.imageOfInside = await imageCreator.createInsideImage(this.images.svg, this.value.rect, '#333');
            this.setLoading(false);
            this.$emit('svg-changed', this.images);
          })
          .catch(() => {
            this.openPopup({
              header: 'Foutmelding',
              message:
                'Het laden van de SVG is mislukt. Upload de SVG opnieuw en klik op opslaan',
            });
            this.setLoading(false);
          });
      } else {
        this.images.svg = '';
        this.images.fold = '';
        this.images.imageOfOver = '';
        this.images.imageOfInside = '';
        // this.images.extraImage = '';
        this.images.extraImages = [];
        // this.images.imageOfDevice = '';
        this.images.imagesOfDevice = [];
        this.$emit('svg-changed', this.images);
      }
      this.disabledRenderingAt = moment(this.value.disabledRenderingAt).format(
        'YYYY-MM-DDTkk:mm',
      );
    },
    'value.rect.width': function rectWidthChange(newVal, oldVal) {
      if (
        oldVal === undefined ||
        this.value.rect.height === undefined ||
        !this.rectRatio
      ) {
        return false;
      }
      this.value.rect.height = newVal / this.rectRatio;
      return true;
    },
  },
  methods: {
    ...mapActions('popup', ['openPopup']),
    ...mapActions('template', ['loadSVG', 'setAsActive']),
    ...mapActions('loading', ['setLoading']),
    ...mapActions('system', {
      getSystemConfigs: 'read',
    }),
    ...mapActions('printTable', {
      getPrintTables: 'list',
    }),
    fitsPrintTable(table) {
      return (
        this.value.physicalSize.width < table.boundingBox.width &&
        this.value.physicalSize.height < table.boundingBox.height
      );
    },
    // async generateInsideImage() {
    //     const insideImage = await imageCreator.createInsideImage(this.svg);
    //     this.value.imageInside = insideImage;
    // },
    setFile(files, target, base64 = true, index) {
      return new Promise((resolve, reject) => {
        switch (target) {
          case 'extraImages':
            this.value.extraImageFiles[index] =
              files && files.length > 0 ? files[0] : null;
            if (this.value.extraImageFiles[index]) {
              const reader = new FileReader();
              reader[base64 ? 'readAsDataURL' : 'readAsText'](
                this.value.extraImageFiles[index],
              );
              reader.onload = () => {
                resolve(
                  (this.images.extraImages[
                    index
                  ] = this.downloadable.extraImages[index] = reader.result),
                );
                this.$forceUpdate();
              };
            } else {
              reject((this.images.extraImages[index] = ''));
            }
            break;
          case 'imagesOfDevice':
            this.value.imagesOfDevice[index] =
              files && files.length > 0 ? files[0] : null;
            if (this.value.imagesOfDevice[index]) {
              const reader = new FileReader();
              reader[base64 ? 'readAsDataURL' : 'readAsText'](
                this.value.imagesOfDevice[index],
              );
              reader.onload = () => {
                resolve(
                  (this.images.imagesOfDevice[
                    index
                  ] = this.downloadable.imagesOfDevice[index] = reader.result),
                );
                this.$forceUpdate();
              };
            } else {
              reject((this.images.imagesOfDevice[index] = ''));
            }
            break;
          default:
            this.value[target] = files && files.length > 0 ? files[0] : null;
            if (this.value[target]) {
              const reader = new FileReader();
              reader[base64 ? 'readAsDataURL' : 'readAsText'](
                this.value[target],
              );
              reader.onload = () => {
                resolve((this.images[target] = reader.result));
                this.$forceUpdate();
              };
            } else {
              reject((this.images[target] = ''));
            }
            break;
        }
      });
    },
    async setSVG(files) {
      if (files.length < 1) {
        this.openPopup({
          header: 'Foutmelding',
          message: 'Geen bestand geselecteerd',
        });
        return;
      }
      this.setLoading(true);
      // TODO: this might need an extra warning
      // as all fields are automatically populated after SVG select
      // error handling, maybe convert some methods to Promises
      try {
        const svg = await this.setFile(files, 'svg', false);
        await templateHelper.checkForTextArea(svg);
        this.images.svg = svg;
        const lipInfo = await templateHelper.getLipInfo(this.images.svg);
        this.value.lipSide = lipInfo.side;
        this.value.lipSize = lipInfo.size;
        this.value.bleed = await templateHelper.getBleedSize(this.images.svg);
        // this.value.hasLip = !!this.value.lipSide;
        this.value.physicalSize = templateHelper.getPhysicalSize(
          this.images.svg,
        );
        this.value.foldedPhysicalSize = templateHelper.getFoldedPhysicalSize(
          this.images.svg,
          this.value.physicalSize,
        );
        this.value.layoutRatio = templateHelper.getAspectRatio(this.images.svg);
        this.value.rect = await imageCreator.calculateRect(this.images.svg);
        this.rectRatio = this.value.rect.width / this.value.rect.height;
        await this.renderImages();
        const folds = await templateHelper.getFoldCoordinates(this.images.svg);
        if (folds && folds.length) {
          this.value.folds.items = folds.map(item => {
            return {
              value: item,
              rotation: 90,
              animationDelay: 0,
            };
          });
        }
        this.$emit('svg-changed', this.images);
      } catch (error) {
        console.error(error);
        this.openPopup({
          header: 'Foutmelding',
          message: error.message,
        });
      }
      this.setLoading(false);
    },
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1]);
      } else {
        byteString = unescape(dataURI.split(',')[1]);
      }

      // separate out the mime component
      const mimeString = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0];

      // write the bytes of the string to a typed array
      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], {
        type: mimeString,
      });
    },
    async getBase64(url) {
      if (!url) {
        return;
      }
      const response = await imageRequest.get(url + `?${Math.random()}`, {
        responseType: 'arraybuffer',
        'Content-Type': 'image',
        //   'Access-Control-Allow-Origin': '*'
      });
      return `data:${response.headers[
        'content-type'
      ].toLowerCase()};base64,${new Buffer(response.data, 'binary').toString(
        'base64',
      )}`;
    },
    stringToBase64(data, mimeType) {
      return `data:${mimeType.toLowerCase()};base64,${new Buffer(
        data,
        'binary',
      ).toString('base64')}`;
    },
    async renderImages() {
      this.openPopup({
        header: 'Let op',
        message: 'Wil je alle afbeeldingen opnieuw genereren?',
        actionButtonText: 'Genereer afbeeldingen opnieuw',
        closeButtonText: 'Sluiten',
        actionCallback: async () => {
          await this.generateImage('imageOfOver');
          await this.generateImage('imageOfInside');
          // await this.generateImage('extraImage');
          // await this.generateImage('imageOfDevice');
          await this.generateImage('fold');
        },
      });
      // if (this.value.imageOfInside && !confirm('Wil je alle afbeeldingen opnieuw genereren?')) return;
      // const {
      //     image,
      //     digitalRect,
      // } = await imageCreator.createOverlayImage(this.images.svg, this.value.rect);
      // this.value.rect = digitalRect;
      // this.images.imageOfOver = image;
      // this.images.imageOfInside = await imageCreator.createInsideImage(this.images.svg, this.value.rect, '#333');
      // this.images.extraImage = await imageCreator.createInsideImage(this.images.svg, this.value.rect, '#fff');
      // this.downloadable.imageOfInside = this.images.imageOfInside;
      // this.downloadable.imageOfOver = this.images.imageOfOver;
      // this.downloadable.extraImage = this.images.extraImage;
      // this.value.imageOfInside = this.dataURItoBlob(this.images.imageOfInside);
      // this.value.imageOfOver = this.dataURItoBlob(this.images.imageOfOver);
      // this.images.fold = await imageCreator.createFoldImage(this.images.svg, this.value.rect);
    },
    async generateImage(name, index) {
      this.setLoading(true);
      let image;
      switch (name) {
        case 'imageOfInside':
          image = await imageCreator.renderImage(
            this.images.svg,
            this.value.rect,
            '#333',
          );
          break;
        case 'imageOfOver': {
          image = await imageCreator.renderImage(
            this.images.svg,
            this.value.rect,
            '#ff6600',
          );
          break;
        }
        case 'imagesOfDevice':
        case 'extraImages':
          // case 'extraImage':
          image = await imageCreator.renderImage(
            this.images.svg,
            this.value.rect,
            '#fff',
          );
          break;
        // case 'imageOfDevice':
        //     image = await imageCreator.renderImage(this.images.svg, this.value.rect, '#fff');
        //     break;
        case 'fold':
          image = await imageCreator.createFoldImage(
            this.images.svg,
            this.value.rect,
          );
          break;
        default:
          break;
      }
      if (name === 'extraImages') {
        this.images.extraImages[index] = image;
        this.downloadable.extraImages[index] = image;
        this.value.extraImageFiles[index] = this.dataURItoBlob(image);
      } else if (name === 'imagesOfDevice') {
        this.images.imagesOfDevice[index] = image;
        this.downloadable.imagesOfDevice[index] = image;
        this.value.imagesOfDevice[index] = this.dataURItoBlob(image);
      } else {
        this.images[name] = image;
        this.downloadable[name] = image;
        this.value[name] = this.dataURItoBlob(image);
      }
      this.$forceUpdate();
      this.setLoading(false);
    },
    async resetDesignRect() {
      this.value.rect = await imageCreator.calculateRect(this.images.svg);
    },
    async activate() {
      this.$refs.btnActive.disabled = true;
      await this.setAsActive(this.value._id);
      // this.$refs.btnActive.disabled = false;
    },
  },
  components: {
    ChromePicker: Chrome,
  },
};
</script>
<style lang="scss">
.svg-wrapper svg {
  height: 500px;
  width: 500px;
}
</style>
