<template>
  <section>
    <badge>
      <b>{{ state.rendering }}</b>
      Rendering
      <br />
    </badge>
    <p v-if="timeLeft && timeLeft !== Infinity" class="a_margin-top-10">
      nog ongeveer {{ timeLeft }} uur
    </p>
    <div />
    <div v-if="state.queues">
      <b v-if="state.queues.hastily.batches.length">In huidige rij</b>
      <ul>
        <li v-for="(batch, index) in state.queues.hastily.batches" :key="index">
          {{ batch }}
        </li>
      </ul>
      <b v-if="state.queues.postponed.batches.length">In uitgestelde rij</b>
      <ul>
        <li
          v-for="(batch, index) in state.queues.postponed.batches"
          :key="index"
        >
          {{ batch }}
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
import { mapActions } from 'vuex';
import Badge from '@/components/elements/Badge';

export default {
  data() {
    return {
      state: {
        batchesRemaining: [],
        rendering: '0 / 0',
      },
      total: null,
      current: null,
      fetchedTimeStamps: [],
      timeLeft: null,
      interval: null,
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  async created() {
    this.state = await this.getQueueState();
    if (!this.interval) {
      this.interval = setInterval(async () => {
        if (this.$route.path === '/settings') {
          this.state = await this.getQueueState();
          if (this.state.queues.hastily.remained) {
            this.current = +this.state.rendering.split(' / ')[0];
            this.total = +this.state.rendering.split(' / ')[1];
            this.fetchedTimeStamps.push({
              current: this.current,
              total: this.total,
              timeStamp: new Date().getTime(),
            });
            this.calculateTime();
          } else {
            this.current = null;
            this.total = null;
            this.fetchedTimeStamps = [];
          }
        }
      }, 2500);
    }
  },
  methods: {
    ...mapActions('system', ['getQueueState']),
    calculateTime() {
      // console.log(this.fetchedTimeStamps)
      // calculate renders per second.
      if (this.fetchedTimeStamps.length >= 2) {
        const duration =
          this.fetchedTimeStamps[this.fetchedTimeStamps.length - 1].timeStamp -
          this.fetchedTimeStamps[0].timeStamp;
        const imageRendered =
          this.fetchedTimeStamps[this.fetchedTimeStamps.length - 1].current -
          this.fetchedTimeStamps[0].current;
        if (duration && imageRendered) {
          const rendersPerSecond = duration / imageRendered;
          const imagesLeft =
            this.fetchedTimeStamps[this.fetchedTimeStamps.length - 1].total -
            this.fetchedTimeStamps[this.fetchedTimeStamps.length - 1].current;
          const hoursLeft = (imagesLeft * rendersPerSecond) / 60 / 60 / 1000;
          this.timeLeft = hoursLeft.toFixed(2);
          const hrs = parseInt(Number(hoursLeft));
          const min = Math.round((Number(hoursLeft) - hrs) * 60);
          this.timeLeft = hrs + ':' + `0${min}`.slice(-2);
        }
      }
    },
  },
  components: {
    Badge,
  },
};
</script>
