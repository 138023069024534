<template>
  <div>
    <section class="b2c_content">
      <div class="b2c_content_container">
        <div class="b2c_content_1-1">
          <header class="b2c_header">
            <div class="b2c_header_top">
              <div class="b2c_header_top_left">
                <div class="b2c_header_switch a_flex a_align-items_center">
                  <h1 class="s_elem-has-icon-left a_fill_default">
                    Home
                    <icon :icon-id="'icon_home'" />
                  </h1>
                </div>
              </div>
              <div class="b2c_header_top_right">
                <button
                  class="button v_ghost_brand_primary v_smaller a_margin-left-10 a_opacity-0 a_no-pointer-events"
                >
                  ...
                </button>
              </div>
            </div>

            <!-- <div class="b2c_header_description">
                            <p>Er staat weer een nieuw overzicht voor je klaar van de meest recente print opdrachten, bestellingen, designers of aanmeldingen.</p>
                        </div> -->
          </header>
        </div>
      </div>
    </section>

    <section class="b2c_list a_margin-top-60">
      <div class="b2c_list_container a_max-width-1000">
        <hr />

        <div class="b2c_list_heading ">
          <div class="b2c_list_heading-left">
            <h2 class="a_margin-bottom-0 a_margin-right-20">
              Laatste bestellingen
            </h2>

            <!-- <div class="b2c_pill">12 nieuwe</div> -->
          </div>
          <div class="b2c_list_heading-right a_text_brand_primary-lighter">
            <router-link
              to="print/orders"
              class="button v_transparent a_text_brand_secondary v_has-icon-right v_smaller a_margin-bottom-0"
            >
              <span class="text-container">
                Naar print
              </span>
              <span class="svg-container">
                <icon :icon-id="'icon_ui_arrow-right'" />
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="b2c_content a_margin-top-40">
      <div class="b2c_content_container a_max-width-1000">
        <div class="b2c_content_1-1">
          <div class="b2c_items" data-s-amount="4">
            <router-link
              v-for="item in pendingDesigns"
              :key="item._id"
              to="designers/pcd-designer/design"
              class="b2c_item"
              data-s-amount-item
            >
              <div class="b2c_item_container">
                <div class="b2c_item_image-container">
                  <img :src="item.imgsrc" />
                </div>
                <div class="b2c_item_content-container">
                  <div class="b2c_item_content-container_inner">
                    <div class="b2c_avatar v_smallest">
                      <div class="b2c_avatar_name">Paul van de Laar</div>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'Home',
  data() {
    return {
      pendingDesigns: [
        {
          name: 'Design 1',
          imgsrc: 'img/covers/cover-1.png',
        },
        {
          name: 'Design 2',
          imgsrc: 'img/covers/cover-2.png',
        },
        {
          name: 'Design 1',
          imgsrc: 'img/covers/cover-3.png',
        },
        {
          name: 'Design 2',
          imgsrc: 'img/covers/cover-4.png',
        },
        {
          name: 'Design 1',
          imgsrc: 'img/covers/cover-5.png',
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
// $columns: (
//     naam: (
//         width: 30%,
//     ),
//     order: (
//         width: 30%,
//     ),
//     ordernummer: (
//         width: 20%,
//     ),
//     datum: (
//         width: 20%,
//     ),
// );

// @mixin generate-columns {
//     @each $label,
//     $column in $columns {
//         [data-t="header-#{$label}"],
//         [data-t="item-#{$label}"] {
//             padding: 0 $default-padding / 2;
//             flex: 0 0 map-get($column, width);
//             max-width: map-get($column, width);
//         }
//     }
// }
// @include generate-columns();

// [data-t="header-datum"],
// [data-t="item-datum"] {
//     text-align: right;
// }
</style>
