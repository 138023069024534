<template>
  <div>
    <page-title
      :title="'Afbeeldingen'"
      :icon="'icon_heart'"
      :description="
        'Deze pagina betreft een overview van de laatste toegevoegde cliparts voor het PCD platform.'
      "
    />
    <section class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <search-dropdown
          :placeholder="'Zoek category'"
          :data="clipartCategories"
          v-model="search"
          @match="onSearchMatch"
        />
        <button
          class="button v_brand_secondary v_is-icon v_smaller a_no-margin"
          @click="clearSearchDropdown"
        >
          <icon :icon-id="'icon_ui_close'" />
        </button>
      </div>
      <div class="b2c_search-bar_left">
        <button
          class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0"
          @click="$refs.newClipartCategory.open()"
        >
          Nieuwe categorie
        </button>
      </div>
    </section>
    <section v-show="selectedCategory" class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <h2 class="a_margin-bottom-0 a_margin-right-20">
          {{ selectedCategory && selectedCategory.name }}
        </h2>
        <p class="a_margin-bottom-0 a_text_brand_gray">
          <span>{{ filteredCliparts.length }} afbeeldingen</span>
          <span
            class="delete_category a_margin-left-20"
            @click="$refs.deleteClipartCategory.open(selectedCategory)"
            >categorie verwijderen</span
          >
        </p>
      </div>
      <div class="b2c_search-bar_left">
        <button
          class="button v_brand_secondary a_margin-bottom-0"
          @click="$refs.newClipart.open(selectedCategory)"
        >
          Clipart toevoegen
        </button>
      </div>
    </section>

    <section class="b2c_content a_margin-top-40">
      <div class="b2c_content_container a_max-width-1000">
        <div class="b2c_content_1-1">
          <hr />
          <div class="b2c_items" data-s-amount="4">
            <div
              v-for="(clipart, index) in filteredCliparts"
              :key="index"
              class="b2c_item"
              data-s-amount-item
            >
              <div class="b2c_item_container">
                <div class="b2c_item_image-container">
                  <!-- <img :src="clipart.thumbnail ? basePath + clipart.thumbnail : 'img/misc/no-image.svg'"> -->
                  <img
                    :src="
                      clipart.thumbnail
                        ? basePath + clipart.thumbnail
                        : 'img/misc/no-image.svg'
                    "
                  />
                </div>
                <div class="b2c_item_content-container">
                  <div class="b2c_item_name">
                    {{ clipart.name }}
                  </div>
                  <button
                    class="button v_ghost_brand_light-gray v_is-icon v_smallest a_no-margin a_fill_brand_red"
                    @click="$refs.deleteClipart.open(clipart)"
                  >
                    <icon :icon-id="'icon_ui_close'" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- CLIPART CATEGORY MODALS -->
    <new-clipart-category ref="newClipartCategory" />
    <delete-clipart-category
      ref="deleteClipartCategory"
      @success="onCategoryDeleted"
    />
    <!-- CLIPART MODALS -->
    <new-clipart ref="newClipart" />
    <delete-clipart ref="deleteClipart" />
  </div>
</template>
<script>
import config from '@/config';
import { mapState, mapActions } from 'vuex';
import SearchDropdown from '@/components/elements/SearchDropdown';
import PageTitle from '@/components/elements/PageTitle';
import NewClipartCategory from '../clipart-categories/New';
import DeleteClipartCategory from '../clipart-categories/Delete';
import NewClipart from './New';
import DeleteClipart from './Delete';

export default {
  name: 'AssetsCliparts',
  data() {
    return {
      search: '',
      selectedCategory: '',
      basePath: config.STORAGE_URL,
    };
  },
  async created() {
    try {
      await Promise.all([this.getClipartCategories(), this.getCliparts()]);
    } catch (e) {
      this.openPopup({
        header: 'Er was een probleem bij het ophalen van de afbeeldingen',
        message: e.message,
      });
    }
  },
  computed: {
    ...mapState('clipartCategory', {
      clipartCategories: 'items',
    }),
    ...mapState('clipart', {
      cliparts: 'items',
    }),
    // return clipart based on selected category
    filteredCliparts() {
      if (!this.selectedCategory) {
        return [];
      }
      return this.cliparts.filter(
        clipart => clipart.category === this.selectedCategory._id,
      );
    },
  },
  methods: {
    ...mapActions('popup', ['openPopup']),
    ...mapActions('clipartCategory', {
      getClipartCategories: 'list',
    }),
    ...mapActions('clipart', {
      getCliparts: 'list',
    }),
    onSearchMatch(match) {
      this.selectedCategory = match;
    },
    clipartFileChange(name, files) {
      console.log(name, files);
      if (files) {
        this.newClipartUri = files[0];
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        this.newClipartImage = reader.result;
      };
      reader.readAsDataURL(this.newClipartUri);
    },
    onCategoryDeleted() {
      this.search = '';
      this.selectedCategory = null;
    },
    clearSearchDropdown() {
      this.search = '';
      this.selectedCategory = '';
    },
  },
  components: {
    PageTitle,
    SearchDropdown,
    NewClipartCategory,
    DeleteClipartCategory,
    NewClipart,
    DeleteClipart,
  },
};
</script>

<style scoped lang="scss">
.delete_category {
  color: red;
  cursor: pointer;
}
</style>
