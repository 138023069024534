var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.templates)?_c('section',{staticClass:"b2c_order-list a_margin-top-20"},[_c('div',{staticClass:"b2c_order-list_container"},[_vm._m(0),_vm._l((_vm.templates),function(item){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.showTemplatesWithoutFullMockups ? item.mockups.length !== 2 : true
        ),expression:"\n          showTemplatesWithoutFullMockups ? item.mockups.length !== 2 : true\n        "}],key:item._id,staticClass:"b2c_order-list_item",class:{ s_available: item.mockups.length !== 2 }},[_c('div',{staticClass:"b2c_order-list_item_top"},[_c('div',{attrs:{"data-t":"item-naam"}},[_c('input',{staticClass:"a_margin-bottom-0 a_margin-right-10",attrs:{"disabled":!item.mockups ||
                  (item.mockups.length === 2 && !_vm.isChecked(item)),"type":"checkbox"},domProps:{"checked":_vm.isChecked(item)},on:{"change":function($event){return _vm.changeCheck($event.target.checked, item)}}}),_c('img',{attrs:{"src":[
                  _vm.basePath,
                  'uploads/templates',
                  item._id,
                  'thumbnail.jpeg' ].join('/'),"width":"50","height":"50","alt":""}}),_c('span',[_vm._v(_vm._s(((item.device && item.device.category && item.device.category.name) + " " + (item.device && item.device.name) + " " + (item.name) + " ")))])]),_c('div',{attrs:{"data-t":"item-category"}},[_vm._v(_vm._s(item.varianceName))]),_c('div',{attrs:{"data-t":"item-state"}},[_c('router-link',{staticClass:"v_ghost_brand_primary v_smallest a_no-margin",attrs:{"to":("/templates/templates?id=" + (item._id))}},[_vm._v(_vm._s(item._id))])],1),_c('div',{attrs:{"data-t":"item-btn"}},[_c('a',{staticClass:"button v_ghost_brand_secondary v_is-icon v_icon-size-24 v_smallest a_no-margin",attrs:{"href":(_vm.designExporter + "mockup/?mockupId=" + _vm.mockupId + "&templateId=" + (item._id) + (_vm.forceRendering ? '&force=true' : '') + (_vm.designId ? '&designId=' + _vm.designId : '')),"target":"_blank"}},[_vm._v("Preview op mockup")])])])])})],2)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"b2c_order-list_header"},[_c('div',{attrs:{"data-t":"header-naam"}},[_vm._v("Template:")]),_c('div',{attrs:{"data-t":"header-category"}},[_vm._v("Variatie:")]),_c('div',{attrs:{"data-t":"header-id"}},[_vm._v("ID")]),_c('div',{attrs:{"data-t":"header-btn"}})])}]

export { render, staticRenderFns }