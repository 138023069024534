<template>
  <div class="b2c_item" data-s-amount-item>
    <div class="b2c_item_container">
      <div
        :style="{ 'background-color': value.value }"
        class="b2c_item_font-container"
      >
        <br />
        <br />
        <br />
      </div>
      <div class="b2c_item_content-container">
        <div class="b2c_item_name">
          {{ value.name }}
        </div>
        <button
          class="button v_ghost_brand_light-gray v_is-icon v_smallest a_no-margin a_fill_brand_red"
          @click="$refs.delete.open(value)"
        >
          <icon :icon-id="'icon_ui_close'" />
        </button>
      </div>
    </div>
    <delete ref="delete" />
  </div>
</template>
<script>
import Delete from './DeleteColor';

export default {
  name: 'Font',
  components: {
    Delete,
  },
  props: ['value'],
};
</script>
