<template>
  <modal
    :open.sync="modal.open"
    :loading.sync="modal.loading"
    :error-message.sync="modal.errorMessage"
    :success-message.sync="modal.successMessage"
    :header="fields._id ? 'Layout Wijzigen' : 'Layout Toevoegen'"
    :sub-header="'Voeg een nieuwe Layout toe.'"
    :action-button-text="fields._id ? 'Layout Wijzigen' : 'Layout Toevoegen'"
    :icon="'icon_layouts'"
    @action="save"
  >
    <label
      >ratio
      <select v-model="fields.ratio">
        <option>1:1</option>
        <option>1:2</option>
        <option>1:3</option>
        <option>2:1</option>
        <option>2:3</option>
        <option>3:1</option>
        <option>3:2</option>
      </select>
    </label>
    <label for="">
      <input v-model="fields.isPrimary" type="checkbox" />Eerste Layout
    </label>
    <label for="upload_svg" class="button v_brand_secondary a_margin-bottom-0"
      >SVG Bestand selecteren</label
    >
    <input
      id="upload_svg"
      type="file"
      capture="camera"
      accept="image/svg+xml"
      name="svg"
      class="a_margin-bottom-0 show-for-sr"
      @change="setFile($event.target.files, 'svg', 'imageSVG')"
    />
    <hr />
    <img :src="imageSVG" style="max-width:200px; max-height:200px" />
    <!-- <br/>
        <label for="upload_thumbnail" class="button v_brand_secondary a_margin-bottom-0">Thumbnail Bestand selecteren</label>
        <input id="upload_thumbnail" type="file" capture="camera" accept="image/*" @change="setFile($event.target.files, 'thumbnail', 'imageThumbnail')" name="thumbnail" class="a_margin-bottom-0 show-for-sr">
        <img :src="imageThumbnail" style="max-width:200px; max-height:200px" /> -->
  </modal>
</template>
<script>
import { mapActions } from 'vuex';
import Modal from '@/components/elements/Modal';
import config from '@/config';

export default {
  data() {
    return {
      baseUrl: config.API_URI,
      modal: {
        open: false,
        loading: false,
        done: false,
        successMessage: '',
        errorMessage: '',
      },
      fields: {
        _id: null,
        // name: '',
        ratio: '',
        file: null,
        isPrimary: false,
        // thumbnail: null,
      },
      value: null,
      imageSVG: '',
      // imageThumbnail: '',
    };
  },
  methods: {
    ...mapActions('layout', ['create', 'update']),
    open(value) {
      this.modal.open = true;
      if (value) {
        this.fields._id = value._id;
        this.fields.ratio = value.ratio;
        this.fields.svg = value.svg;
        this.fields.isPrimary = value.isPrimary;
        this.imageSVG = this.baseUrl.slice(0, -1) + this.fields.svg;
        this.value = value;
      } else {
        this.fields._id = null;
        this.fields.ratio = '';
        this.fields.svg = null;
        this.imageSVG = '';
      }
    },
    async save() {
      try {
        // if (!this.fields.name) {
        //     this.modal.errorMessage = 'vul een naam in a.u.b.';
        //     return false;
        // }
        if (!this.fields.ratio) {
          this.modal.errorMessage = 'vul een ratio in a.u.b.';
          return false;
        }
        if (!this.fields.svg) {
          this.modal.errorMessage = 'vul een svg in a.u.b.';
          return false;
        }
        this.modal.loading = true;
        if (this.fields._id) {
          await this.update(this.fields);
          this.value.ratio = this.fields.ratio;
          this.value.isPrimary = this.fields.isPrimary;
        } else {
          await this.create(this.fields);
        }
        this.modal.successMessage = 'De Layout is succesvol aangemaakt.';
      } catch (error) {
        this.modal.errorMessage = error.message;
      }
      this.modal.loading = false;
      return true;
    },
    setFile(files, target, imageTarget) {
      this.fields[target] = files && files.length > 0 ? files[0] : null;
      if (this.fields[target]) {
        const reader = new FileReader();
        reader.readAsDataURL(this.fields[target]);
        reader.onload = () => {
          this[imageTarget] = reader.result;
        };
      } else {
        this[imageTarget] = '';
      }
    },
  },
  components: {
    Modal,
  },
};
</script>
