/*eslint-disable */
/******************************************************************************

	Project: 	Case Rotator
	Version: 	v1.0
	Client: 	B2CTelcom
	Author: 	Jeroen Peerbolte @ Stijlbreuk

******************************************************************************/

function CaseRotator(containerObj, options) {
  this.containerObj = containerObj;
  this.opened = false;
  this.options = options;
  this.src = '';
  this.overSrc = '';
  this.backSrc = '';
  this.deviceSrc = '';
  this.folds = [];
  this.rotations = [];
  this.offset = 0;
  this.openRotations;
  this.rotation = 0;
  this.slices;
  this.slicesHolder;
  this.slicesContainer;
  this.slicesReflection;
  this.slicesShadow;
  this.rotationPivot;
  this.animate = true;
  this.shadow = {
    enabled: true,
    blur: 0,
    opacity: 1,
    xOffset: 0,
    yOffset: 0,
  };
  this.reflection = {
    yOffset: 0,
  };

  if (!options.noElementData) {
    this.imgObj = containerObj.find('img');
    this.src = this.imgObj.attr('src');
    this.createCaseRotator(this.imgObj);
    //set initial folds
    this.changeFolds(this.folds, this.rotations, this.offset);
    //setup pivot point
    this.sliceImage();
  }
}

/******************************************************************************

                            PUBLIC FUNCTIONS

******************************************************************************/

CaseRotator.prototype.refreshFolds = function() {
  this.opened ? this.foldOut() : this.foldIn();
};

CaseRotator.prototype.foldIn = function() {
  this.opened = false;
  this.changeFolds(this.folds, this.rotations, this.offset);
};

CaseRotator.prototype.foldOut = function() {
  this.opened = true;
  this.openRotations = [];
  for (var i = 0; i < this.folds.length; i++) {
    this.openRotations[i] = 0;
  }
  this.changeFolds(this.folds, this.openRotations, 0);
};

CaseRotator.prototype.rotate = function(rotation) {
  this.rotation = rotation % 360;
  if (!this.slicesHolder) return;
  this.slicesHolder.css({
    '-webkit-transform': 'rotateY(' + this.rotation + 'deg)',
    '-moz-transform': 'rotateY(' + this.rotation + 'deg)',
    transform: 'rotateY(' + this.rotation + 'deg)',
    'z-index': '222',
  });

  $('.slicesContainer.reflection .slicesHolder').css({
    '-webkit-transform': 'rotateY(' + this.rotation + 'deg)',
    '-moz-transform': 'rotateY(' + this.rotation + 'deg)',
    transform:
      'rotateY(' +
      this.rotation +
      'deg)' +
      'translateY(' +
      this.reflection.yOffset +
      'px)' +
      'scale(1,-1)',
  });

  $('.slicesContainer.shadow .slicesHolder').css({
    '-webkit-transform': 'rotateY(' + this.rotation + 'deg)',
    '-moz-transform': 'rotateY(' + this.rotation + 'deg)',
    transform:
      'rotateY(' +
      this.rotation +
      'deg) translateY(' +
      this.shadow.yOffset +
      'px)',
  });
};

CaseRotator.prototype.zoom = function(zoomAmount) {
  zoomAmount = zoomAmount / 50;
  this.slicesContainer.css({
    '-webkit-transform': 'scale(' + zoomAmount + ',' + zoomAmount + ')',
    '-moz-transform': 'scale(' + zoomAmount + ',' + zoomAmount + ')',
    transform: 'scale(' + zoomAmount + ',' + zoomAmount + ')',
  });
};

CaseRotator.prototype.showFoldIndicators = function() {
  $('.case-fold-indicator').removeClass('hide');
  $('.case-fold-indicator-2').removeClass('hide');
};

CaseRotator.prototype.hideFoldIndicators = function() {
  $('.case-fold-indicator').addClass('hide');
  $('.case-fold-indicator-2').addClass('hide');
};

//Show or hide pivot line to show center of rotation.

CaseRotator.prototype.showRotationOffsetPivot = function() {
  this.rotationPivot && this.rotationPivot.removeClass('hide');
};

CaseRotator.prototype.hideRotationOffsetPivot = function() {
  this.rotationPivot && this.rotationPivot.addClass('hide');
};

CaseRotator.prototype.disableAnimate = function() {
  //this.slices.removeClass('cover-animate');
  this.animate = false;
};

CaseRotator.prototype.updateImage = function(image) {
  this.src = image;
  this.sliceImage();
  this.opened ? this.foldOut() : this.foldIn();
  this.rotate(this.rotation);
};

CaseRotator.prototype.updateTemplate = function(slices, rotations, offset) {
  this.folds = slices;
  this.rotations = rotations;
  this.offset = offset || 0;
  this.openRotations = [];
  this.opened ? this.foldOut() : this.foldIn();
  if (this.src) {
    this.sliceImage();
  }
};

CaseRotator.prototype.setInsideImage = function(url) {
  this.backSrc = url;
};

CaseRotator.prototype.setOverlayImage = function(url) {
  this.overSrc = url || '';
};

CaseRotator.prototype.setExtraImage = function(url) {
  this.extraSrc = url || '';
};

CaseRotator.prototype.setDeviceImage = function(url) {
  this.deviceSrc = url || '';
};

/******************************************************************************

                            PRIVATE FUNCTIONS

******************************************************************************/

CaseRotator.prototype.setupPivotPoint = function() {
  this.rotationPivot = $('<div id="case-pivot-point" class="hide"></div>');
  this.slicesContainer.append(this.rotationPivot);
};

CaseRotator.prototype.createCaseRotator = function(imgObj) {
  var rawCaseFoldsData = imgObj.attr('data-case-slices');
  var rawCaseRotationData = imgObj.attr('data-case-rotations');
  var rawOffsetData = imgObj.attr('data-case-offset');
  this.backSrc = imgObj.attr('data-img-inside');

  imgObj.addClass('hide');

  this.folds = this.convertRawData(rawCaseFoldsData);
  this.rotations = this.convertRawData(rawCaseRotationData);
  this.offset = this.convertRawData(rawOffsetData);
  this.openRotations = [];
};

CaseRotator.prototype.convertRawData = function(data) {
  if (typeof data !== typeof undefined && data !== false) {
    return data.split(',');
  }
};

CaseRotator.prototype.setBlur = function(val) {
  $('.slicesContainer.reflection img ').css({ filter: 'blur(' + val + 'px)' });
};

CaseRotator.prototype.enableShadow = function(val) {
  this.shadow.enabled = val;
  this.setShadow();
};

CaseRotator.prototype.setShadowBlur = function(val) {
  this.shadow.blur = val;
  this.setShadow();
};

CaseRotator.prototype.setShadowHorizontalOffset = function(val) {
  this.shadow.xOffset = val;
  this.setShadow();
};

CaseRotator.prototype.setShadowVerticalOffset = function(val) {
  this.shadow.yOffset = val;
  this.setShadow();
};

CaseRotator.prototype.setShadowOpacity = function(val) {
  this.shadow.opacity = val;
  this.setShadow();
};

CaseRotator.prototype.setReflectionVerticalOffset = function(val) {
  this.reflection.yOffset = val;
};

CaseRotator.prototype.enableReflection = function(val) {
  $('#reflective-surface').css({ opacity: val ? '1' : '0' });
  $('.reflection').css({ display: val ? 'block' : 'none' });
};

CaseRotator.prototype.sliceImage = function() {
  this.slices = [];
  // console.log('image obj', this.imageObj);
  $('#case-container').html('');
  // $('#case-container').append(this.imageObj);

  this.slicesHolder = $('<div></div>');
  this.slicesHolder.addClass('slicesHolder');

  this.slicesContainer = $('<div></div');
  this.slicesContainer.addClass('slicesContainer');

  this.setupPivotPoint();

  for (var i = 0; i < this.folds.length; i++) {
    this.addFold();
  }
  this.slicesContainer.append(this.slicesHolder);

  this.slicesReflection = this.slicesContainer.clone();
  this.slicesShadow = this.slicesContainer.clone();
  // this.slicesPerspectiveShadow = $('<div class="perspective-shadow-container"><div class="perspective-shadow"></div></div>');
  this.slicesReflection.addClass('reflection');
  this.slicesShadow.addClass('shadow');
  this.slicesContainer.addClass('main');

  this.containerObj.append(this.slicesReflection);
  this.containerObj.append(this.slicesShadow);
  // this.containerObj.append(this.slicesPerspectiveShadow);
  this.containerObj.append('<div class="reflective-surface" style=""></div>');
  this.containerObj.append(this.slicesContainer);

  this.setShadow();
};

CaseRotator.prototype.addFold = function() {
  var that = this;
  var i = this.slices.length;
  this.slices.push(this.getPart(i + 1));

  if (i == 0) {
    this.slicesHolder.append(this.slices[i]);
  } else {
    this.slices[i - 1].append(this.slices[i]);
  }
};

CaseRotator.prototype.removeFold = function(index) {
  this.slices[index + 1].remove();
  this.slices.splice(index + 1, 1);
};

CaseRotator.prototype.changeFolds = function(folds, rotations, translate) {
  for (var i = 0; i < folds.length; i++) {
    let x1 = i > 0 ? folds[i - 1] : 0;
    let x2 = i != folds.length - 1 ? folds[i] : 500;
    let translateX = i == 0 ? translate : 0;

    $('.case-rotator-slice' + (i + 1) + ' img').css({
      clip: 'rect(0px, ' + x2 + 'px, 500px, ' + x1 + 'px)',
    });
    $('.case-rotator-slice' + (i + 1) + ' svg').css({
      clip: 'rect(0px, ' + x2 + 'px, 500px, ' + x1 + 'px)',
    });
    $('.case-rotator-slice' + (i + 1)).css({
      '-webkit-transform-origin': x1 + 'px 50%',
      '-moz-transform-origin': x1 + 'px 50%',
      'transform-origin': x1 + 'px 50%',
      '-webkit-transform':
        'rotateY(' + rotations[i] + 'deg) translateX( ' + translateX + 'px)',
      '-moz-transform':
        'rotateY(' + rotations[i] + 'deg) translateX( ' + translateX + 'px)',
      transform:
        'rotateY(' + rotations[i] + 'deg) translateX( ' + translateX + 'px)',
    });
    $('.case-rotator-slice' + (i + 1))
      .find('.case-fold-indicator')
      .css({ left: x1 + 'px' });
    $('.case-rotator-slice' + (i + 1))
      .find('.case-fold-indicator-2')
      .css({ left: x1 + 'px' });
  }
};

CaseRotator.prototype.getPart = function(index) {
  var src = this.src;
  var className = 'case-rotator-slice' + index;

  var wrapper = $('<div></div>');
  wrapper.addClass(className);
  wrapper.addClass('slice');
  if (this.animate) {
    wrapper.addClass('cover-animate');
  }

  if (this.deviceSrc) {
    var deviceImg = new Image();
    deviceImg.src = this.deviceSrc;
    $(deviceImg).addClass('device-slice');
    wrapper.append(deviceImg);
  }

  if (this.backSrc) {
    var backImg = new Image();
    backImg.src = this.backSrc;
    $(backImg).addClass('back-slice');
    wrapper.append(backImg);
  } else {
    var backImg = new Image();
    backImg.src = src;
    $(backImg).addClass('back-slice');
    $(backImg).addClass('black');
    wrapper.append(backImg);
  }

  if (this.extraSrc) {
    var imgExtra = new Image();
    imgExtra.src = this.extraSrc;
    $(imgExtra).addClass('extra-slice');
    wrapper.append(imgExtra);
  }

  if (index !== 1) {
    var indicator = $('<div class="case-fold-indicator hide"></div');
    var indicator2 = $('<div class="case-fold-indicator-2 hide"></div');
  }

  // if (src.indexOf('</svg>') !== -1) {
  //     wrapper.append(src);
  // } else {
  var img = new Image();
  img.src = src;
  wrapper.append(img);
  $(img).addClass('render-slice');
  // }

  wrapper.append(indicator);
  wrapper.append(indicator2);

  if (this.overSrc) {
    var imgOver = new Image();
    imgOver.src = this.overSrc;
    $(imgOver).addClass('over-slice');
    wrapper.append(imgOver);
  }

  if (index != 1) {
    wrapper.append(indicator);
    wrapper.append(indicator2);
  }
  return wrapper;
};

CaseRotator.prototype.setShadow = function() {
  if (this.shadow.enabled) {
    $('.slicesContainer.shadow ').css({
      filter: 'blur(' + this.shadow.blur + 'px) brightness(0)',
      opacity: this.shadow.opacity,
    });
  } else {
    $('.slicesContainer.shadow').css({
      opacity: 0,
    });
  }
};

export default CaseRotator;
/*eslint-enable */
