export default {
  gridEnabled: false,
  grid: {
    split: 'vertical',
    areas: [
      {
        index: 0,
        size: 0.5,
        content: {
          url: '',
          type: 'render',
          render: '',
          fillMode: '',
          gridImageFileIndex: -1,
          scale: {
            factor: 1,
            x: 0,
            y: 0,
          },
        },
      },
      {
        index: null,
        size: 0.5,
        split: 'horizontal',
        areas: [
          {
            index: 1,
            size: 0.5,
            content: {
              url: '',
              type: 'render',
              render: '',
              fillMode: '',
              gridImageFileIndex: -1,
              scale: {
                factor: 1,
                x: 0,
                y: 0,
              },
            },
          },
          {
            index: 2,
            size: 0.5,
            content: {
              url: '',
              type: 'render',
              render: '',
              fillMode: '',
              gridImageFileIndex: -1,
              scale: {
                factor: 1,
                x: 0,
                y: 0,
              },
            },
          },
        ],
      },
    ],
  },
  gridImageFiles: [],
};
