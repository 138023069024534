<template>
  <div>
    <page-title
      title="Print Jobs Queue"
      icon="icon_design-submissions"
      description="Hier zie je het overzicht van de wachtrij van de openstaande Print Job opdrachten."
    />

    <section class="b2c_content a_margin-top-40">
      <Queue />
    </section>
  </div>
</template>
<script>
import PageTitle from '@/components/elements/PageTitle';
import Queue from './Queue';

export default {
  components: {
    PageTitle,
    Queue,
  },
};
</script>
