<template>
  <section class="b2c_content a_margin-top-40">
    <div class="b2c_content_container a_max-width-800">
      <div class="b2c_content_1-1">
        <label
          >Naam print tafel
          <input
            v-model.trim="value.name"
            type="text"
            placeholder="Bijv. TPU - center"
          />
        </label>
        <hr />
        <h4><small>Meerdere print plaatsen</small></h4>
        <div class="a_flex a_align-items_center">
          <label>
            Rijen:
            <input
              v-model.number="value.rows"
              type="number"
              placeholder="Aantal rijen"
              min="1"
              max="2"
              class="a_text-align_right"
              style="min-width: 180px;"
            />
          </label>
          <span class="a_margin-left-10 a_margin-right-40"
            ><small>rijen</small></span
          >
          <label>
            Kolommen:
            <input
              v-model.number="value.columns"
              type="number"
              placeholder="Aantal kolommen"
              min="1"
              max="4"
              class="a_text-align_right"
              style="min-width: 180px;"
            />
          </label>
          <span class="a_margin-left-10 a_margin-right-40"
            ><small>kolommen</small></span
          >
        </div>
        <h4><small>Bounding box afmeting</small></h4>
        <div class="a_flex a_align-items_center">
          <label>
            Breedte:
            <input
              v-model.number="value.boundingBox.width"
              type="number"
              placeholder="Breedte in mm"
              class="a_text-align_right"
              style="min-width: 180px;"
            />
          </label>
          <span class="a_margin-left-10 a_margin-right-40"
            ><small>MM</small></span
          >
          <label>
            Hoogte:
            <input
              v-model.number="value.boundingBox.height"
              type="number"
              placeholder="Hoogte in mm"
              class="a_text-align_right"
              style="min-width: 180px;"
            />
          </label>
          <span class="a_margin-left-10 a_margin-right-40"
            ><small>MM</small></span
          >
        </div>
        <hr />
        <h4><small>Lipje</small></h4>
        <select id="device_lip" v-model="value.lip">
          <option value="none" selected="true">Geen Lipje</option>
          <option value="right">Rechts</option>
          <option value="left">Links</option>
        </select>
        <label for="device_lip">De hoesjes hebben een lipje</label>
        <hr />
        <div v-if="value.lip !== 'none'">
          <h4><small>Distance</small></h4>
          <input
            id="device_has_lip"
            v-model.number="value.distance"
            type="number"
          />
          <label for="device_has_lip"
            >De afstand van de zijkant tot de rand van het lipje</label
          >
          <hr />
        </div>
        <div v-else>
          <h3><small>Plaatsing</small></h3>
          <input
            id="print_table_centered"
            v-model="value.centered"
            type="checkbox"
            name="print_table_centered"
          />
          <label for="print_table_centered"
            >De hoesjes zijn gecentreerd in de bounding box</label
          >
          <div class="a_flex a_align-items_center">
            <label>
              X:
              <input
                :disabled="value.centered"
                v-model.number="value.position.x"
                type="number"
                placeholder="X in mm"
                class="a_text-align_right"
                style="min-width: 180px;"
              />
            </label>
            <span class="a_margin-left-10 a_margin-right-40"
              ><small>MM</small></span
            >
            <label>
              Y:
              <input
                :disabled="value.centered"
                v-model.number="value.position.y"
                type="number"
                placeholder="Y in mm"
                class="a_text-align_right"
                style="min-width: 180px;"
              />
            </label>
            <span class="a_margin-left-10 a_margin-right-40"
              ><small>MM</small></span
            >
          </div>
          <h4><small>Rotatie</small></h4>
          <select id="device_rotation" v-model="value.rotation">
            <option :value="0" selected="true">0</option>
            <option :value="90">90</option>
            <option :value="180">180</option>
            <option :value="270">270</option>
          </select>
          <hr />
        </div>
        <div>
          <h3><small>Offset</small></h3>
          <div class="a_flex a_align-items_center">
            <label>
              X:
              <input
                v-model.number="value.offset.x"
                type="number"
                placeholder="X in mm"
                class="a_text-align_right"
                style="min-width: 180px;"
              />
            </label>
            <span class="a_margin-left-10 a_margin-right-40"
              ><small>MM</small></span
            >
            <label>
              Y:
              <input
                v-model.number="value.offset.y"
                type="number"
                placeholder="Y in mm"
                class="a_text-align_right"
                style="min-width: 180px;"
              />
            </label>
            <span class="a_margin-left-10 a_margin-right-40"
              ><small>MM</small></span
            >
          </div>
          <hr />
        </div>
        <div class="a_flex a_justify_flex-end a_align-items_center">
          <button class="button v_brand_secondary" @click="save()">
            Print tafel opslaan
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  props: ['value'],
  data() {
    return {
      // item: {
      //     boundingBox: {},
      //     position: {},
      //     lip: 'none',
      // },
    };
  },
  methods: {
    ...mapActions('popup', ['openPopup']),
    ...mapActions('printTable', ['create', 'update']),
    async save() {
      try {
        if (!this.value.name) {
          this.openPopup({
            header: 'Message',
            message: 'vul een naam in a.u.b.',
          });
          return false;
        }
        if (!this.value.rows) {
          this.openPopup({
            header: 'Message',
            message: 'vul een rows in a.u.b.',
          });
          return false;
        }
        if (!this.value.columns) {
          this.openPopup({
            header: 'Message',
            message: 'vul de kolommen in a.u.b.',
          });
          return false;
        }
        if (!this.value.lip) {
          this.openPopup({
            header: 'Message',
            message: 'vul een lipje in a.u.b.',
          });
          return false;
        }
        // if (!this.value.distance) {
        //     this.openPopup({
        //         header: 'Message',
        //         message: 'vul een distance in a.u.b.',
        //     });
        //     return false;
        // }
        // if (!this.value.centered && !this.value.position) {
        //     this.openPopup({
        //         header: 'Message',
        //         message: 'vul een position in a.u.b.',
        //     });
        //     return false;
        // }
        if (!this.value.centered) {
          this.value.centered = false;
        } else {
          this.value.position.x = 0;
          this.value.position.y = 0;
        }
        // this.modal.loading = true;
        let savedObject;
        if (this.value._id) {
          savedObject = await this.update(this.value);
        } else {
          savedObject = await this.create(this.value);
        }
        this.openPopup({
          header: 'Message',
          message: this.value._id
            ? 'De print tafel is succesvol aangepast.'
            : 'De print tafel is succesvol aangemaakt.',
        });
        this.value._id = savedObject._id;
        this.$emit('saved', this.value);
      } catch (error) {
        this.openPopup({
          header: 'Message',
          message: error.message,
        });
      }
      // this.modal.loading = false;
      return true;
    },
  },
  components: {},
};
</script>
