import axios from 'axios';

const module = {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async globalRender(
      // eslint-disable-next-line no-empty-pattern
      {},
      {
        type,
        delay = false,
        id,
        force = false,
        skipRender = true,
        forceGrids = false,
      },
    ) {
      if (!type) {
        throw Error('no type defined');
      }
      try {
        const { data } = await axios.get(
          `${type}s/${id}/global?force=${force}&delay=${delay}&skipRender=${skipRender}&forceGrids=${forceGrids}&batchId=rerender-${type}-${id}`,
        );
        console.log(data);
        return data;
      } catch (error) {
        console.error(error);
        throw new Error(error);
      }
    },
    // async renderDesign({ commit }, { delay = false, designId, force = false, skipRender = true}) {
    //     try {
    //         const { data } = await axios.get(`designs/${designId}/global?force=${force}&delay=${delay}&skipRender=${skipRender}&batchId=rerender-template${moment().format()}`);
    //         console.log(data);
    //         return data;
    //     } catch (error) {
    //         console.error(error);
    //         throw new Error(error);
    //     }
    // },
    // async renderMockups({ commit }, { delay = false, mockupId, force = false, skipRender = true}) {
    //     try {
    //         const { data } = await axios.get(`mockups/${mockupId}/global?force=${force}&delay=${delay}&skipRender=${skipRender}&batchId=rerender-template${moment().format()}`);
    //         console.log(data);
    //         return data;
    //     } catch (error) {
    //         console.error(error);
    //         throw new Error(error);
    //     }
    // },
  },
};

export default module;
