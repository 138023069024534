<template>
  <div>
    <page-tabs :links="links" />
    <router-view />
  </div>
</template>
<script>
import PageTabs from '@/components/elements/PageTabs';

export default {
  name: 'Print',
  components: {
    PageTabs,
  },
  data() {
    return {
      links: [
        {
          link: '/print/orders/pages/',
          name: 'Bestellingen',
        },
        {
          link: '/print/queue',
          name: 'Print Jobs Queue',
        },
        {
          link: '/print/print-tables',
          name: 'Print tafels',
        },
        {
          link: '/print/amazon-orders',
          name: 'Amazon Orders',
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped></style>
