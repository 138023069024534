<template>
  <div>
    <nav class="b2c_side-bar">
      <div class="b2c_side-bar_links">
        <!-- <router-link to="/home" class="b2c_side-bar_link">
                    <span class="b2c_side-bar_link_container">
                        <icon :iconId="'icon_home'"></icon>
                        <span class="b2c_side-bar_link_text">
                           Home
                        </span>
                    </span>
                </router-link> -->

        <router-link to="/assets" class="b2c_side-bar_link">
          <span class="b2c_side-bar_link_container">
            <icon :icon-id="'icon_assets'" />
            <span class="b2c_side-bar_link_text">
              Assets
            </span>
          </span>
        </router-link>

        <router-link to="/templates" class="b2c_side-bar_link">
          <span class="b2c_side-bar_link_container">
            <icon :icon-id="'icon_templates'" />
            <span class="b2c_side-bar_link_text">
              Templates
            </span>
          </span>
        </router-link>

        <router-link to="/mockups/tool" class="b2c_side-bar_link">
          <span class="b2c_side-bar_link_container">
            <icon :icon-id="'icon_mockuptool'" />
            <span class="b2c_side-bar_link_text">
              Mockups
            </span>
          </span>
        </router-link>

        <router-link
          to="/print/orders/pages/1?limit=8"
          class="b2c_side-bar_link"
        >
          <span class="b2c_side-bar_link_container">
            <icon :icon-id="'icon_print'" />
            <span class="b2c_side-bar_link_text">
              Print
            </span>
          </span>
        </router-link>

        <router-link to="/designers" class="b2c_side-bar_link">
          <span class="b2c_side-bar_link_container">
            <icon :icon-id="'icon_designers'" />
            <span class="b2c_side-bar_link_text">
              Designers
            </span>
          </span>
        </router-link>

        <router-link to="/settings" class="b2c_side-bar_link">
          <span class="b2c_side-bar_link_container">
            <icon :icon-id="'icon_settings'" />
            <span class="b2c_side-bar_link_text">
              Instellingen
            </span>
          </span>
        </router-link>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'SideBar',
  data() {
    return {};
  },
  computed: {
    ...mapState('', []),
  },
  methods: {
    ...mapActions('', []),
  },
  components: {},
};
</script>

<style lang="scss">
////
////    GENERICS
////

.b2c_side-bar {
  position: fixed;
  z-index: 222;
  background: $default-background-light;
  box-shadow: 0 0 20px rgba($brand-black, 0.1);
  width: $b2c_side-bar_width;
  height: calc(100% - #{($b2c_top-bar_height)});
  top: ($b2c_top-bar_height);
}

////
////    LINKS
////

.b2c_side-bar_links {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
}

.b2c_side-bar_link {
  $svg-size: 44px;

  position: relative;
  padding: $default-padding * 0.7 0;
  text-transform: uppercase;
  color: rgba($brand-primary, 1);
  font-size: 80%;
  letter-spacing: 0.5px;
  transition: color $default-transition-speed;

  width: 100%;
  padding: 0 $default-padding;
  // border-left: 4px solid $brand-swhite;
  border-bottom: 1px solid rgba($brand-primary, 0.1);
  flex: 1 1 auto;
  display: flex;
  justify-content: center;

  .svg-container {
    width: $svg-size * 1;
    height: $svg-size * 1;
    margin-bottom: 5px;
    @include breakpoint(medium) {
      width: $svg-size;
      height: $svg-size;
    }
  }
  svg {
    width: $svg-size;
    height: $svg-size;
    transition: fill $default-transition-speed;
    fill: rgba($brand-primary, 0.4);
  }

  @include breakpoint(large) {
    &:hover {
      color: $brand-secondary;
      svg {
        fill: $brand-secondary;
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 222;
    width: 20px;
    height: 101%;

    transform: scaleX(0);
    transform-origin: 0 50%;
    right: -16px;
    // background: url('data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgNTAwIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjZjE2ODI0OwogICAgICB9CiAgICA8L3N0eWxlPgogIDwvZGVmcz4KICA8dGl0bGU+VW50aXRsZWQtMTwvdGl0bGU+CiAgPHBvbHlnb24gY2xhc3M9ImNscy0xIiBwb2ludHM9Ijk2IDI1MCAyMCA1MDAgMCA1MDAgMCAwIDE5IDAgOTYgMjUwIi8+Cjwvc3ZnPgo=');
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 100 500"><path d="M96,250,20,500H0V0H19Z" style="fill:#f16824"/></svg>');
    background-size: cover;
  }

  &.s_active {
    z-index: 1;
    color: $brand-white;
    background: $brand-secondary;
    svg {
      fill: $brand-white;
    }
    &:hover {
      color: $brand-white;
    }

    &:after {
      transition: transform 0.2s ease-out;
      transform: scaleX(1);
    }
  }
}

.b2c_side-bar_link_container {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.b2c_side-bar_link_text {
  white-space: nowrap;
  @include breakpoint(medium) {
    white-space: inherit;
    text-align: center;
  }
}

.b2c_side-bar_icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateX(50%) translateY(-50%) scale(0.7);
}
</style>
