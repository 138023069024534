<template>
  <div>
    <section class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <input
          v-model="category.name"
          type="text"
          placeholder="Zoek categorie"
          class="a_margin-bottom-0 a_margin-right-10"
          data-toggle="dropdownCategories"
        />

        <div
          id="dropdownCategories"
          class="dropdown-pane"
          data-dropdown
          data-close-on-click="true"
        >
          <ul class="b2c_inline-list">
            <li v-for="(item, index) in categories" :key="index">
              <button
                @click="
                  $emit('categoryChanged', (category = item));
                  device = '';
                "
              >
                {{ item.name }}
              </button>
            </li>
          </ul>
        </div>

        <input
          :disabled="!category"
          v-model="device.name"
          type="text"
          placeholder="Zoek toestel"
          class="a_margin-bottom-0 a_margin-right-10"
          data-toggle="dropdownDevices"
        />

        <div
          id="dropdownDevices"
          class="dropdown-pane"
          data-dropdown
          data-close-on-click="true"
        >
          <ul class="b2c_inline-list">
            <li
              v-for="(item, index) in devices"
              v-if="item.category === category._id"
              :key="index"
            >
              <button @click="$emit('deviceChanged', (device = item))">
                {{ item.name }}
              </button>
            </li>
          </ul>
        </div>

        <button
          class="button v_brand_secondary v_is-icon v_smaller a_no-margin"
          @click="$refs.delete.open(category)"
        >
          <icon :icon-id="'icon_ui_close'" />
        </button>
      </div>
      <div class="b2c_search-bar_left">
        <button
          class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0"
          @click="$refs.new.open()"
        >
          Nieuwe Categorie
        </button>
      </div>
    </section>
    <!-- MODALS -->
    <new ref="new" />
    <delete ref="delete" @success="clear" />
  </div>
</template>
<script>
import New from '../device-categories/New';
import Delete from '../device-categories/Delete';

export default {
  components: {
    New,
    Delete,
  },
  props: ['categories', 'devices'],
  data() {
    return {
      category: {},
      device: {},
    };
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.dropdownCategories = new Foundation.Dropdown(
      // eslint-disable-next-line no-undef
      $('#dropdownCategories'),
      {
        // vOffset: 20,
      },
    );
    // eslint-disable-next-line no-undef
    this.dropdownDevices = new Foundation.Dropdown($('#dropdownDevices'), {
      // vOffset: 20,
    });
  },
  destroyed() {
    this.dropdownCategories.destroy();
    this.dropdownDevices.destroy();
  },
  methods: {
    clear() {
      this.device = '';
      this.category = '';
    },
  },
};
</script>
