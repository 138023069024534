// Remove the apollo-boost import and change to this:
import ApolloClient from 'apollo-client';
// Setup the network "links"
import { WebSocketLink } from 'apollo-link-ws';
import { HttpLink } from 'apollo-link-http';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import store from 'store';
import config from '@/config';

const GATEWAY = config.EXPORT_MANAGER_GATEWAY || `localhost:3000/graphql`;

function getAuthorizationHeader() {
  const token = store.get('token');
  return token ? `Bearer ${token}` : '';
}

const httpLink = new HttpLink({
  uri: `https://${GATEWAY}`, // use https for secure endpoint
});

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: `wss://${GATEWAY}`, // use wss for a secure endpoint
  options: {
    reconnect: true,
    connectionParams() {
      return {
        isWebSocket: true,
        headers: {
          authorization: getAuthorizationHeader(),
        },
      };
    },
  },
});

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: getAuthorizationHeader(),
    },
  };
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    // @ts-ignore
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink),
);

// Instantiate client
const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default client;
