import Vue from 'vue';
import VueLazyload from 'vue-lazyload';
import VueMoment from 'vue-moment';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import './registerServiceWorker';
import './statics';
import './axios';

import App from './App.vue';
import router from './router';
import store from './store';
import Icon from './components/elements/Icon.vue';
import Slider from './components/elements/Slider.vue';
import SliderNumberInput from './components/elements/SliderNumberInput.vue';

Vue.config.productionTip = false;
Vue.component('icon', Icon);
Vue.component('slider', Slider);
Vue.component('slider-number-input', SliderNumberInput);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

Vue.use(VueLazyload, {
  loading:
    'https://cdnjs.cloudflare.com/ajax/libs/galleriffic/2.0.1/css/loader.gif',
});

Vue.use(VueMoment);

export const vm = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

vm.$nextTick(() => {
  store.dispatch('auth/list');
});

Sentry.init({
  Vue,
  dsn: 'https://1f8c458124474ef4a328311b5647d004@sentry.breuk.dev/34',
  integrations: [new Integrations.BrowserTracing()],
  environment: window.location.host,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
