<template>
  <div>
    <page-title
      :title="'Layouts'"
      :icon="'icon_layouts'"
      :description="
        'Op deze pagina worden alle layout verhoudingen weergegeven en alle layouts die onder een specifieke verhouding vallen.'
      "
    />
    <search-bar @changed="selectedRatio = $event" />
    <section class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <h2 class="a_margin-bottom-0 a_margin-right-20">
          Layout {{ selectedRatio }}
        </h2>
        <p class="a_margin-bottom-0 a_margin-right-20 a_text_brand_gray">
          {{ filteredItems.length }} resultaten
        </p>

        <button
          class="button v_brand_secondary v_is-icon v_smaller a_no-margin"
          @click="
            '';


          "
        >
          <icon :icon-id="'icon_ui_close'" />
        </button>
      </div>
      <div class="b2c_search-bar_left" />
    </section>
    <section class="b2c_content a_margin-top-40">
      <div class="b2c_content_container a_max-width-1000">
        <div class="b2c_content_1-1">
          <hr />
          <div class="b2c_items" data-s-amount="4">
            <layout
              v-for="(item, index) in filteredItems"
              :key="item._id"
              v-model="filteredItems[index]"
              @edit="$refs.new.open($event)"
            />
          </div>
        </div>
      </div>
    </section>
    <new ref="new" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import PageTitle from '@/components/elements/PageTitle';
import SearchBar from './SearchBar';
import Layout from './Layout';
import New from './New';

export default {
  computed: {
    ...mapState('layout', ['items']),
    filteredItems() {
      return this.items.filter(item => item.ratio === this.selectedRatio);
    },
  },
  data() {
    return {
      selectedRatio: '',
    };
  },
  methods: {
    ...mapActions('popup', ['openPopup']),
  },
  components: {
    PageTitle,
    SearchBar,
    Layout,
    New,
  },
};
</script>
<style scoped lang="scss"></style>
