<template>
  <div>
    <page-tabs :links="links" />
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
import PageTabs from '@/components/elements/PageTabs';

export default {
  name: 'Assets',
  components: {
    PageTabs,
  },
  data() {
    return {
      links: [
        {
          link: '/assets/cliparts',
          name: 'Afbeeldingen',
        },
        {
          link: '/assets/emojis',
          name: 'Emojis',
        },
        {
          link: '/assets/effects',
          name: 'Effecten',
        },
        {
          link: '/assets/patterns',
          name: 'Patronen',
        },
        {
          link: '/assets/fonts',
          name: 'Lettertypes',
        },
        {
          link: '/assets/amazon',
          name: 'Amazon Designer',
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped></style>
