<template>
  <transition name="fade">
    <div v-show="loading" class="b2c_canvas-loader">
      <Spinner />
    </div>
  </transition>
</template>

<script>
import Spinner from './Spinner';

export default {
  props: ['loading'],
  components: {
    Spinner,
  },
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.b2c_canvas-loader {
  width: 100%;
  height: 100%;
  background-color: rgba($brand-primary-darker, 0.5);
  z-index: 99999;
  text-align: right;
  position: fixed;
  top: 0px;
  left: 0px;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
  }
}
</style>
