<template>
  <div class="b2c_top-bar">
    <div class="b2c_top-bar_left">
      <router-link to="/" class="b2c_top-bar_logo">
        <img src="img/logo.svg" />
      </router-link>
      <div class="b2c_top-bar_title">
        <b>Phone Case Designer Management</b>
        <br>
        <kbd
          style="font-size: smaller;background-color: black;color: white;border-radius: 6px;margin: 6px;"
        >
          v{{ version }}
        </kbd>
      </div>
    </div>
    <div class="b2c_top-bar_right">
      <span style="color: #aaa; margin: 0 20px 0 0;">{{
        decodedToken && decodedToken.email
      }}</span>
      <button
        class="button v_transparent v_has-icon-left v_icon-size-32 a_margin-bottom-0 a_margin-right-20 a_text_brand_dark-gray a_fill_brand_secondary"
        @click="signout"
      >
        uitloggen
        <icon :icon-id="'icon_logout'" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'TopBar',
  data() {
    return {
      version: process.env.APP_VERSION,
    };
  },
  computed: {
    ...mapGetters('auth', ['decodedToken']),
  },
  methods: {
    ...mapActions('modal', ['openModal']),
    ...mapActions('panel', ['openPanel']),
    ...mapActions('auth', ['logout']),
    async signout() {
      await this.logout();
      this.$router.push('login');
    },
  },
  components: {},
};
</script>

<style lang="scss">
$b2c_top-bar_logo_size_small: 80px;
$b2c_top-bar_logo_size: 96px;

////
////    GENERICS
////

.b2c_top-bar {
  position: fixed;
  top: 0;
  z-index: 444;
  width: 100vw;
  background: $brand-white;
  border-bottom: solid 1px $brand-light-gray;
  display: flex;
  justify-content: space-between;
  height: $b2c_top-bar_height;

  .button {
    white-space: nowrap;
    font-size: 90%;
  }
}
.b2c_top-bar_left {
  padding: $default-padding;
  display: flex;
  align-items: center;
  width: calc(100vw - #{$b2c_side-bar_width});
  padding-left: $b2c_top-bar_logo_size + $default-padding * 2;
}
.b2c_top-bar_right {
  display: flex;
  align-items: center;
}

////
////    LOGO
////

.b2c_top-bar_logo {
  position: absolute;
  top: 0;
  left: $default-padding;
  width: $b2c_top-bar_logo_size;
  height: $b2c_top-bar_logo_size;
  transform: translateY(-$b2c_top-bar_logo_size / 6);

  img {
    transition: transform $default-transition-speed;
    border-radius: 50%;
    box-shadow: 0 4px 4px 0 rgba(black, 0.1), 0 6px 16px 0 rgba(black, 0.09);
  }

  @include breakpoint(large) {
    transition: transform $default-transition-speed;
    &:hover {
      img {
        transform: translateY(3px);
      }
    }
  }
}
</style>
