// @ts-nocheck
import auth from './auth';
import modal from './modal';
import popup from './popup';
import router from './router';
import amazonFont from './amazon-font';
import amazonColor from './amazon-color';
import clipartCategory from './clipart-category';
import clipart from './clipart';
import emojiCategory from './emoji-category';
import emoji from './emoji';
import competition from './competition';
import font from './font';
import effect from './effect';
import pattern from './pattern';
import deviceCategory from './device-category';
import device from './device';
import layout from './layout';
import printTable from './print-table';
import mockup from './mockup';
import template from './template';
import design from './design';
import system from './system';
import loading from './loading';
import global from './global';
import queue from './queue';

export default {
  auth,
  router,
  modal,
  popup,
  clipartCategory,
  clipart,
  competition,
  font,
  effect,
  pattern,
  amazonFont,
  amazonColor,
  deviceCategory,
  device,
  layout,
  printTable,
  mockup,
  template,
  design,
  system,
  loading,
  global,
  queue,
  emoji,
  emojiCategory,
};
