<template>
  <div>
    <page-tabs :links="links" />
    <router-view />
  </div>
</template>
<script>
import PageTabs from '@/components/elements/PageTabs';

export default {
  name: 'Templates',
  components: {
    PageTabs,
  },
  data() {
    return {
      links: [
        // {
        //     link: '/templates/categories',
        //     name: 'Categorieën',
        // },
        {
          link: '/templates/templates',
          name: 'Templates',
        },
        {
          link: '/templates/layouts',
          name: 'Layouts',
        },
        {
          link: '/templates/amazon-product-images',
          name: 'Amazon Product Images',
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped></style>
