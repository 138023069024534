<template>
  <div
    :class="{ s_printed: printed }"
    class="b2c_print-table a_margin-bottom-40"
    data-columns="4"
  >
    <modal
      :open.sync="modal.open"
      :loading.sync="modal.loading"
      :error-message.sync="modal.errorMessage"
      :success-message.sync="modal.successMessage"
      :header="'Print Job aangemaakt'"
      :cancel-button-text="'Sluiten'"
      :action-button-text="'Naar detail pagina'"
      @action="goToJobDetailPage"
    >
      <p>
        Bekijk de detail pagina van de Print Job met ID {{ this.printJobId }}.
      </p>
    </modal>
    <h2>
      <small>
        Print tafel
        <b>{{ value.printTable.name }}</b>
      </small>
    </h2>
    <div class="b2c_print-table_items">
      <div
        v-for="(design, index) in value.items"
        :key="`${index}-${design._id}`"
        class="b2c_print-table_item"
      >
        <!-- <div class="b2c_print-table_item_btn">
                        <a :href="`${pcdUri}?designId=${design._id}&adminEdit=true`" target="_blank" class="button v_ghost_brand_secondary v_is-icon v_icon-size-25 v_smallest a_no-margin">
                            <icon :iconId="'icon_edit'"></icon>
                        </a>
        </div>-->
        <div class="b2c_print-table_item_image">
          <img :src="getThumbImage(design)" alt />
        </div>
        <div class="b2c_print-table_item_meta">
          {{ design.template.name }}
          {{ device(design.template) }}
          <div v-if="design.template.location">
            <kbd>{{ design.template.location }}</kbd>
          </div>
          <div v-if="design.orderline">
            <small>
              <kbd style="color: white; background-color: #00645d">
                ol: {{ design.orderline }}
              </kbd>
              <br>
              <kbd style="color: white; background-color: #00645d">
                priority: {{ design.order }}
              </kbd>
            </small>
          </div>
        </div>
      </div>
      <br />
      <div class="b2c_print-table_bottom a_margin-top-20">
        <JobButton
          mod="normal"
          title="Normal"
          :debug="false"
          :offset="offset"
          :designs="value.items"
          :print-table="value.printTable"
          class="v_ghost_brand_gray"
        />
        <JobButton
          mod="white"
          title="White"
          :debug="false"
          :offset="offset"
          :designs="value.items"
          :print-table="value.printTable"
          class="v_ghost_brand_gray"
        />
        <JobButton
          mod="merged"
          title="Normal & White"
          :debug="false"
          :offset="offset"
          :designs="value.items"
          :print-table="value.printTable"
          class="v_ghost_brand_gray"
        />
        <JobButton
          :class="[
            hasEffect ? 'v_ghost_brand_primary-lightest' : 'v_ghost_brand_gray',
          ]"
          mod="effect"
          title="Effect"
          :disabled="!hasEffect"
          :debug="false"
          :offset="offset"
          :designs="value.items"
          :print-table="value.printTable"
        />
        <JobButton
          mod="normal"
          title="Debug"
          :debug="true"
          :offset="offset"
          :designs="value.items"
          :print-table="value.printTable"
          class="v_ghost_brand_gray"
        />
        <a
          :href="toDoneUrl()"
          target="_blank"
          class="button v_ghost_brand_gray a_fill_brand_secondary v_smaller v_icon-size-25 v_has-icon-left"
          :disabled="!hasOrderLine"
          >Gereedmelden
          <icon :icon-id="'icon_check'" />
        </a>
        <button
          @click="printed = !printed"
          class="button v_ghost_brand_gray a_fill_brand_secondary v_smaller v_icon-size-25 v_has-icon-left"
          :disabled="!hasOrderLine"
          :class="[
            hasOrderLine
              ? 'v_ghost_brand_gray'
              : 'v_ghost_brand_primary-lightest',
          ]"
        >
          Geprint
          <icon :icon-id="'icon_print'" />
        </button>
        <!-- <button class="button v_brand_secondary v_icon-size-25 v_has-icon-left">
          Printen
          <icon :iconId="'icon_printer'"></icon>
        </button> -->
      </div>
      <div style="padding: 18px; padding-top: 0px; width: 100%">
        <h3>Magazijn locaties:</h3>
        <span v-for="l in locations" :key="l">
          <kbd>{{ l ? l : 'Onbekend' }}</kbd> &nbsp;
        </span>
        <div class="cells-offset">
          <h3>Compensatie positionering:</h3>
          <form>
            X: links (in mm)
            <input v-model="offset.x" type="number" placeholder="X" />
            Y: boven (in mm)
            <input v-model="offset.y" type="number" placeholder="Y" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
// import axios from 'axios';
import config from '@/config';
import _ from 'lodash';
import JobButton from './JobButton';
import Modal from '@/components/elements/Modal';

export default {
  props: ['value'],
  data() {
    return {
      printed: false,
      offset: {
        x: 0,
        y: 0,
      },
      printJobId: null,
      modal: {
        open: false,
        loading: false,
        done: false,
        successMessage: '',
        errorMessage: '',
      },
    };
  },
  computed: {
    basePath() {
      return config.API_URI.substr(0, config.API_URI.length - 1);
    },
    pcdUri() {
      return config.PCD_URI;
    },
    hasEffect() {
      return this.value && this.value.items
        ? this.value.items.reduce(
            (result, design) =>
              result || (design.effect ? design.effect.uri : false),
            false,
          )
        : false;
    },
    hasOrderLine() {
      return this.value && this.value.items
        ? this.value.items.reduce(
            (result, design) => result || (design.orderline ? true : false),
            false,
          )
        : false;
    },
    locations() {
      const unique = _.uniqBy(this.value.items, 'template.location');
      const locations = this.value
        ? unique
            .map(item =>
              item.template.location ? item.template.location : undefined,
            )
            .sort()
        : [];
      return locations;
    },
  },
  methods: {
    ...mapActions('popup', ['openPopup']),
    ...mapActions('printTable', ['toPDF']),
    ...mapActions('loading', ['setLoading']),
    // async donePrinting() {
    //     this.setLoading(true);
    //     try {
    //         const url = `${config.ORDERLINES_URI}&${this.value.items.map(item => `orderline[]=${item.orderline}`).join('&')}`;
    //         console.log(url);
    //         await axios.get(url);
    //         this.value.printed = true;
    //     } catch (error) {
    //         console.error(error);
    //         this.openPopup({
    //             header: ':(',
    //             message: 'Aanroepen van het B2C endpoint is niet gelukt!',
    //         });
    //     }
    //     this.setLoading(false);
    // },
    getThumbImage(design) {
      // const query = {"size":1000,"fold":"closed","rotation":0,"reflection":true,"shadow":true,"renderOnAllColors":true,"type":"jpeg"};
      // eslint-disable-next-line no-constant-condition
      if (false) {
        // if (design._overidedTemplate) {
        const encrypted = window
          .btoa(
            JSON.stringify({
              size: 1000,
              fold: 'closed',
              rotation: 0,
              reflection: true,
              shadow: true,
              renderOnAllColors: true,
              templateId: design.template._id,
              type: 'jpeg',
            }),
          )
          .split('')
          .reverse()
          .join('');
        return `https://pcd-backend.b2ctelecom.nl/designs/${design._id}/preview/${encrypted}`;
      } else {
        return [
          this.basePath,
          'uploads/designs',
          design._id,
          'thumbnail.jpeg',
        ].join('/');
      }
    },
    device(template) {
      // template = this.templates.find(_template => _template._id === template._id);
      if (!template || !template.device || !template.device.category) {
        // console.warn(template._id, 'has no category and device name');
        return '';
      }
      return `${template.device.category.name} - ${template.device.name} - `;
    },
    toDoneUrl() {
      return `${config.ORDERLINES_URI}&${this.value.items
        .map(item => `orderline[]=${item.orderline}`)
        .join('&')}`;
    },
    async goToJobDetailPage() {
      this.$router.push(`/print/queue/${this.printJobId}`);
    },
  },
  components: {
    Modal,
    JobButton,
  },
};
</script>
<style>
.s_printed {
  opacity: 0.4;
}
</style>

<style lang="scss" scoped>
.cells-offset {
  margin-top: 16px;
  display: block;
  input {
    display: inline;
    width: initial;
  }
}
</style>
