var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SplitGrid',{staticClass:"b2c_split-grid",style:({
    height: '498px',
  }),attrs:{"direction":_vm.area.split === 'vertical' ? 'column' : 'row',"strict-mode":false,"size-value":_vm.area.size},on:{"drag":function($event){_vm.$emit(
      'drag',
      Object.assign($event, {
        areaIndices: _vm.area.areas.map(function (ref) {
          var index = ref.index;

          return index;
  }),
      })
    )}}},[_vm._l((_vm.area.areas),function(area,index){return [_c('SplitGridGutter',{key:("gutter-" + index),attrs:{"render":index > 0 && _vm.showGutters}}),(Array.isArray(area.areas))?_c('LayoutGridRecursive',{key:("grid-recursive-" + index),attrs:{"direction":area.split === 'vertical' ? 'column' : 'row',"area":area,"size-value":area.size,"show-gutters":_vm.showGutters},on:{"drag":function($event){return _vm.$emit('drag', $event)}},scopedSlots:_vm._u([{key:"default",fn:function(slotScope){return [_vm._t("default",null,null,slotScope)]}}],null,true)}):_vm._t("default",null,{"area":area})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }