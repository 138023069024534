<template>
  <div>
    <transition name="drop">
      <div v-show="open" class="b2c_modal">
        <div class="b2c_modal_container">
          <transition name="drop">
            <div>
              <header class="b2c_header">
                <div class="b2c_header_heading">
                  <h1 class="s_elem-has-icon-left a_fill_default">
                    {{ header }}
                    <icon v-if="icon" :icon-id="icon" />
                  </h1>
                </div>

                <div class="b2c_header_description">
                  <p>{{ subHeader }}</p>
                </div>
              </header>

              <hr />
              <div :class="{ s_loading: loading }">
                <slot v-if="!successMessage" />
                <p
                  v-show="errorMessage && !successMessage"
                  class="error_message"
                >
                  {{ errorMessage }}
                </p>
                <div
                  v-show="successMessage"
                  class="success_message"
                  v-html="successMessage"
                />
              </div>
              <hr />
              <div class="b2c_button_group">
                <button
                  v-if="cancelButtonText && !successMessage"
                  :disabled="loading"
                  class="button v_ghost_brand_secondary v_has-icon-right v_smaller a_margin-left-10"
                  @click="closeModal"
                >
                  {{ cancelButtonText }}
                </button>
                <button
                  v-if="actionButtonText && !successMessage"
                  :disabled="loading"
                  class="button v_brand_secondary v_smaller a_margin-left-10"
                  @click="actionClick"
                >
                  {{ actionButtonText }}
                </button>
                <button
                  v-if="successMessage"
                  :disabled="loading"
                  class="button v_brand_secondary v_smaller a_margin-left-10"
                  @click="closeModal"
                >
                  {{ doneButtonText }}
                </button>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-show="open" class="b2c_modal_bg" @click="closeModal" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  components: {},
  props: {
    open: Boolean,
    loading: Boolean,
    header: String,
    subHeader: String,
    cancelButtonText: {
      type: String,
      default: 'Annuleren',
    },
    doneButtonText: {
      type: String,
      default: 'Sluiten',
    },
    actionButtonText: String,
    doneText: String,
    errorMessage: String,
    successMessage: String,
    icon: String,
  },
  computed: {},
  methods: {
    closeModal() {
      this.$emit('update:open', false);
      setTimeout(() => {
        this.$emit('update:loading', false);
        this.$emit('update:done', false);
        this.$emit('update:successMessage', '');
        this.$emit('update:errorMessage', '');
        this.$emit('close');
      }, 200);
    },
    actionClick() {
      this.$emit('action');
    },
  },
};
</script>

<style lang="scss" scoped>
////
////    VUE TRANSITIONS
////

.drop-enter-active {
  transition: all 0.2s ease;
}
.drop-leave-active {
  transition: all 0.2s ease;
}
.drop-enter {
  opacity: 0;
  transform: translateY(-40px);
}
.drop-enter-to,
.drop-leave {
  opacity: 1;
  transform: translateY(0);
}
.drop-leave-to {
  opacity: 0;
  transform: translateY(-40px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter,
.fade-leave-to {
  transition: opacity 0.2s ease 0.1s;
  opacity: 0;
}

////
////    GENETRICS
////

.b2c_modal {
  position: fixed;
  z-index: 5556;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.b2c_modal_container {
  background: $brand-white;
  box-shadow: 0 0 30px rgba(black, 0.3);
  padding: $default-padding;
  border-radius: 3px;
  width: 900px;
  max-width: 85vw;

  @include breakpoint(medium) {
    padding: $default-padding * 2;
  }
}

.s_loading {
  opacity: 0.5;
}

.error_message {
  color: red;
}
.success_message {
  color: $brand-primary;
}

////
////    BG
////

.b2c_modal_bg {
  position: fixed;
  z-index: 5555;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba($brand-primary, 0.9);
}
</style>
