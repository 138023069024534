<template>
  <modal
    :open.sync="modal.open"
    :loading.sync="modal.loading"
    :error-message.sync="modal.errorMessage"
    :success-message.sync="modal.successMessage"
    :header="edit ? 'Wedstrijd Aanpassen' : 'Wedstrijden Toevoegen'"
    :sub-header="
      edit ? 'Pas de wedstrijd aan' : 'Voeg een nieuwe Wedstrijden toe.'
    "
    :action-button-text="edit ? 'Wedstrijd Aanpassen' : 'Wedstrijden Toevoegen'"
    :icon="'icon_orders'"
    @action="save"
  >
    <label>
      naam
      <input v-model="fields.name" type="text" placeholder="naam" />
    </label>
    <label>
      Description
      <input v-model="fields.leadText" type="text" placeholder="Description" />
    </label>
    <label>
      Start Date
      <input
        :value="fields.startAt && fields.startAt.toISOString().split('T')[0]"
        type="date"
        placeholder="Start Date"
        @input="fields.startAt = $event.target.valueAsDate"
      />
    </label>
    <label>
      Close Date
      <input
        :value="fields.closeAt && fields.closeAt.toISOString().split('T')[0]"
        type="date"
        placeholder="Close Date"
        @input="fields.closeAt = $event.target.valueAsDate"
      />
    </label>
    <!-- <label for="upload_img" class="button v_brand_secondary a_margin-bottom-0">Select Background</label>
        <input id="upload_img" type="file" capture="camera" accept="image/*" @change="setFile($event.target.files)" name="file" class="a_margin-bottom-0 show-for-sr">
        <img :src="image" style="max-width:200px; max-height:200px" />-->
  </modal>
</template>
<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import Modal from '@/components/elements/Modal';

export default {
  data() {
    return {
      modal: {
        open: false,
        loading: false,
        done: false,
        successMessage: '',
        errorMessage: '',
      },
      fields: {
        name: '',
        background: '',
        leadText: '',
        startAt: '',
        closeAt: '',
      },
      edit: '',
      item: null,
      image: '',
    };
  },
  computed: {
    // ...mapState('Competition', ['modal']),
  },
  methods: {
    ...mapActions('competition', ['create', 'update']),
    open(item, edit) {
      this.modal.open = true;
      this.fields.name = item ? item.name : undefined;
      this.fields.leadText = item ? item.leadText : undefined;
      this.fields.startAt = item ? moment(item.startAt).toDate() : undefined;
      this.fields.closeAt = item ? moment(item.closeAt).toDate() : undefined;
      this.fields.background = item ? item.background : undefined;
      this.item = item;
      this.edit = edit;
    },
    async save() {
      try {
        if (!this.fields.name) {
          this.modal.errorMessage = 'vul een naam in a.u.b.';
          return false;
        }
        // if (!this.fields.background) {
        //     this.modal.errorMessage = 'vul een background in a.u.b.';
        //     return false;
        // }
        this.modal.loading = true;
        if (this.item) {
          this.fields._id = this.item._id;
          await this.update(this.fields);
          this.item.name = this.fields.name;
          this.item.leadText = this.fields.leadText;
          this.item.startAt = this.fields.startAt;
          this.item.closeAt = this.fields.closeAt;
          this.item.background = this.fields.background;
        } else {
          await this.create(this.fields);
        }
        if (this.edit) {
          this.modal.successMessage = 'De competition is succesvol aangepast.';
        } else {
          this.modal.successMessage = 'De competition is succesvol aangemaakt.';
        }
        this.$emit('success');
      } catch (error) {
        this.modal.errorMessage = error.message;
      }
      this.modal.loading = false;
      return true;
    },
    setFile(files) {
      this.fields.background = files && files.length > 0 ? files[0] : null;
      if (this.fields.background) {
        const reader = new FileReader();
        reader.readAsDataURL(this.fields.background);
        reader.onload = () => {
          this.image = reader.result;
        };
      } else {
        this.image = '';
      }
    },
  },
  components: {
    Modal,
  },
};
</script>
