<template>
  <div class="b2c_tab_wrapper a_max-width-1000">
    <h3 class="b2c_tab_title">
      <small>Grids</small>
    </h3>
    <label class="b2c_grid-enabled_label" for="grid-enabled">
      <input
        id="grid-enabled"
        v-model="value.gridEnabled"
        type="checkbox"
        name="grid-enabled"
      />
      grid is live
    </label>
    <div class="b2c_grid-settings_container">
      <div class="b2c_grid-layouts_container">
        <div
          v-for="grid in gridLayouts"
          :key="grid.id"
          class="b2c_grid-layout"
          @click="onSelectGridLayout(grid.id)"
        >
          <span>
            <i>grid</i>
            {{ grid.label }}
          </span>
        </div>
      </div>
      <div class="b2c_form">
        <div class="b2c_form_left">
          <div class="b2c_product-image-grid_container">
            <div class="b2c_product-image-grid">
              <LayoutGridRecursive
                ref="grid"
                :area="value.grid"
                :show-gutters="showGutters"
                :key="value.grid.split"
                @drag="onGridDrag"
              >
                <template slot="default" slot-scope="{ area }">
                  <SplitGridArea
                    :style="{ background: showBackground ? '#ff0000' : 'none' }"
                    :size-value="area.size"
                    :data-grid-area-index="area.index"
                    class="b2c_product-image-grid_area"
                    @dragover.native.prevent
                    @drop.native.prevent="onImageDrop($event, area.index, area)"
                    @click.native="onGridAreaClick(area.index)"
                  >
                    <img
                      v-if="getImageSource(area.index)"
                      :src="getImageSource(area.index)"
                      :style="calculateImageStyle(area.index)"
                      class="b2c_grid_image"
                      alt="grid-image"
                      @dragstart.prevent
                    />
                    <p v-else class="b2c_product-image-grid_no-image-text">
                      Geen afbeelding geselecteerd.
                    </p>
                    <div
                      v-if="selectedImageIndex === area.index"
                      class="b2c_product-image-grid_selected"
                    />
                  </SplitGridArea>
                </template>
              </LayoutGridRecursive>
            </div>
          </div>
          <div
            :class="{ s_disabled: selectedImageIndex == null }"
            class="b2c_image-settings_container"
          >
            <label for="image-mode">
              factor
              <slider-number-input
                v-model.number="selectedGridImage.scale.factor"
                min="0"
                max="10"
                step="0.01"
                @input="selectedGridImage.fillMode = ''"
              />
            </label>
            <label for="image-mode">
              x
              <slider-number-input
                v-model.number="selectedGridImage.scale.x"
                min="-100"
                max="100"
                step="0.1"
              />
            </label>
            <label for="image-mode">
              y
              <slider-number-input
                v-model.number="selectedGridImage.scale.y"
                min="-100"
                max="100"
                step="0.1"
              />
            </label>
            <div class="b2c_fill-modes_container">
              <button
                class="button v_ghost_brand_secondary v_smaller b2c_button_fill-mode"
                @click="selectedGridImage.fillMode = 'height'"
              >
                vul hoogte
              </button>
              <button
                class="button v_ghost_brand_secondary v_smaller b2c_button_fill-mode"
                @click="selectedGridImage.fillMode = 'width'"
              >
                vul breedte
              </button>
            </div>
          </div>
          <div class="b2c_grid-area-settings">
            <label for="show-gutters" class="b2c_input_show-">
              toon goten
              <input
                v-model="showGutters"
                type="checkbox"
                name="show-gutters"
              />
            </label>
            <label for="show-background" class="b2c_input_show-">
              toon achtergrond
              <input
                v-model="showBackground"
                type="checkbox"
                name="show-background"
              />
            </label>
          </div>
        </div>
        <div class="b2c_form_right">
          <div class="b2c_images_container">
            <h3>
              <small>Renders</small>
            </h3>
            <div class="b2c_images-previews">
              <p v-if="Object.keys(renders).length === 0">
                {{ renderStatusText }}
              </p>
              <template v-else>
                <img
                  v-for="([key, imageUrl], index) in Object.entries(renders)"
                  ref="renders"
                  :key="key"
                  :src="imageUrl"
                  class="b2c_image-preview"
                  alt
                  srcset
                  draggable="true"
                  @dragstart="
                    onImageDrag($event, {
                      type: 'render',
                      src: imageUrl,
                      data: null,
                      index,
                      render: key,
                    })
                  "
                />
              </template>
            </div>
            <div class="b2c_images_type_title">
              <h3 class="b2c_image_type_title">
                <small>Uploads</small>
              </h3>
              <button
                class="button v_ghost_brand_secondary v_smaller b2c_button_upload-image"
              >
                <label class="b2c_label_upload-image" for="grid-file-upload"
                  >upload</label
                >
              </button>
              <input
                id="grid-file-upload"
                type="file"
                accept="image/jpeg, image/png"
                style="display: none;"
                @change="onUploadImage"
              />
            </div>
            <div class="b2c_images-previews">
              <p v-if="uploads.length === 0">
                Er zijn nog geen afbeeldingen geüpload.
              </p>
              <img
                v-for="(image, index) in uploads"
                ref="uploads"
                :key="image.url || hash(image.data)"
                :src="image.url || image.data"
                class="b2c_image-preview"
                alt
                srcset
                draggable="true"
                @dragstart="
                  onImageDrag($event, {
                    type: 'upload',
                    src: image.url,
                    data: image.data,
                    index,
                  })
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div class="a_flex a_justify_flex-end a_align-items_center">
        <button class="button v_brand_secondary" @click="onSave">
          Opslaan
        </button>
      </div>
      <div
        v-if="disabled || !value.gridEnabled"
        class="b2c_tab_disabled-wrapper"
      >
        <p v-if="disabled">
          De template moet eerst opgeslagen worden voordat je een grid aan kan
          maken.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { SplitGridArea } from 'vue-split-grid';
import axios from 'axios';

import LayoutGridRecursive from './grid/LayoutGridRecursive';

import config from '@/config';
import { hash } from '@/lib/utils';

const createFindGridImage = imageIndex => (gridImage, currentArea) => {
  if (currentArea.areas && currentArea.areas.length) {
    return currentArea.areas.reduce(createFindGridImage(imageIndex), gridImage);
  }
  if (currentArea.index === imageIndex) {
    return currentArea.content;
  }
  return gridImage;
};

const flattenGridAreas = (flattenedGridAreas, currentGridArea) => {
  if (currentGridArea.index == null) {
    return [
      ...flattenedGridAreas,
      ...currentGridArea.areas.reduce(flattenGridAreas, []),
    ];
  }

  return [...flattenedGridAreas, ...[currentGridArea]];
};

export default {
  name: 'ProductImageGrid',
  components: {
    SplitGridArea,
    LayoutGridRecursive,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      gridLayouts: [
        {
          id: '1',
          label: '1',
          icon: '',
          layout: {
            split: 'vertical',
            areas: [
              {
                index: 0,
                size: 0.5,
              },
              {
                index: null,
                size: 0.5,
                areas: [
                  {
                    index: 1,
                    size: 0.5,
                  },
                  {
                    index: 2,
                    size: 0.5,
                  },
                ],
                split: 'horizontal',
              },
            ],
          },
        },
        {
          id: '2',
          label: '2',
          icon: '',
          layout: {
            split: 'vertical',
            areas: [
              {
                index: null,
                size: 0.5,
                areas: [
                  {
                    index: 0,
                    size: 0.5,
                  },
                  {
                    index: 2,
                    size: 0.5,
                  },
                ],
                split: 'horizontal',
              },
              {
                index: 1,
                size: 0.5,
              },
            ],
          },
        },
        {
          id: '3',
          label: '3',
          icon: '',
          layout: {
            split: 'vertical',
            areas: [
              {
                index: 0,
                size: 0.5,
              },
              {
                index: 1,
                size: 0.5,
              },
            ],
          },
        },
        {
          id: '4',
          label: '4',
          icon: '',
          layout: {
            split: 'horizontal',
            areas: [
              {
                index: 0,
                size: 0.5,
              },
              {
                index: 1,
                size: 0.5,
              },
            ],
          },
        },
      ],
      showGutters: true,
      showBackground: false,
      selectedImageIndex: null,
      renderStatusText: '',
      imageSettingsPlaceholder: {
        fillMode: 'width',
        scale: {
          factor: 1,
          x: 0,
          y: 0,
        },
      },
      renders: {},
      uploads: [],
    };
  },
  computed: {
    selectedGridImage() {
      if (this.selectedImageIndex == null) {
        return this.imageSettingsPlaceholder;
      }
      return this.value.grid.areas.reduce(
        createFindGridImage(this.selectedImageIndex),
        {},
      );
    },
  },
  async mounted() {
    if (this.value._id) {
      try {
        this.renderStatusText = 'Loading renders ...';
        const { data } = await axios.get(
          `${config.API_URI}templates/${this.value._id}/renderImages/`,
        );

        this.renders = data;
        if (Object.keys(data).length === 0) {
          this.renderStatusText = 'No renders found';
        } else {
          this.renderStatusText = '';
        }
      } catch (error) {
        console.log(error);
        this.renderStatusText = 'Error while loading renders';
      }
    }
  },
  methods: {
    hash,
    onGridAreaClick(areaIndex) {
      if (this.selectedImageIndex === areaIndex) {
        const {
          fillMode,
          scale: { x, y, factor },
        } = {
          ...this.selectedGridImage,
        };
        this.imageSettingsPlaceholder = {
          fillMode,
          scale: {
            x,
            y,
            factor,
          },
        };
        this.selectedImageIndex = null;
      } else {
        this.selectedImageIndex = areaIndex;
      }
    },
    onSelectGridLayout(gridId) {
      const { layout: newLayout } = this.gridLayouts.find(
        ({ id }) => id === gridId,
      );

      const previousFlattenedGridAreas = this.value.grid.areas.reduce(
        flattenGridAreas,
        [],
      );

      const findPreviousGridArea = indexToFind => {
        const gridArea = previousFlattenedGridAreas.find(
          ({ index }) => index === indexToFind,
        );
        return (
          gridArea || {
            content: {
              url: '',
              type: 'render',
              render: '',
              fillMode: 'width',
              gridImageFileIndex: -1,
              scale: {
                factor: 1,
                x: 0,
                y: 0,
              },
            },
          }
        );
      };

      const merge = (newAreas, newLayoutArea) => {
        if (newLayoutArea.index == null) {
          return [
            ...newAreas,
            {
              ...newLayoutArea,
              areas: newLayoutArea.areas.reduce(merge, []),
            },
          ];
        }

        return [
          ...newAreas,
          {
            ...newLayoutArea,
            content: findPreviousGridArea(newLayoutArea.index).content,
          },
        ];
      };

      this.value.grid = {
        split: newLayout.split,
        areas: newLayout.areas.reduce(merge, []),
      };

      const newFlattenedGridAreas = this.value.grid.areas.reduce(
        flattenGridAreas,
        [],
      );
      if (newFlattenedGridAreas.length < 3 && this.selectedImageIndex === 2) {
        this.selectedImageIndex = 1;
      }
    },
    onGridDrag({ gridTemplateStyle, areaIndices }) {
      const [firstAreaStyle, secondAreaStyle] = gridTemplateStyle.split(' ').filter(style => style.includes('fr'));

      const splitValueAndUnitRegex = /(\d+\.\d+|\d+)(\w*)/;

      const updateAreaReducer = (areas, currentArea) => {
        const index = areaIndices.indexOf(currentArea.index);

        if (index !== -1) {
          const [newSize] =
            index === 0
              ? firstAreaStyle
                  .split(splitValueAndUnitRegex)
                  .filter(part => part !== '')
              : secondAreaStyle
                  .split(splitValueAndUnitRegex)
                  .filter(part => part !== '');
          currentArea.size = parseFloat(newSize);
        }

        if (currentArea.areas && currentArea.areas.length) {
          currentArea.areas = currentArea.areas.reduce(updateAreaReducer, []);
        }

        return [...areas, currentArea];
      };

      const updatedAreas = this.value.grid.areas.reduce(updateAreaReducer, []);
      this.value.grid = {
        ...this.value.grid,
        areas: updatedAreas,
      };
    },
    getImageSource(index) {
      const content = this.value.grid.areas.reduce(
        createFindGridImage(index),
        {},
      );

      if (!content) {
        return '';
      }

      const { src, type, gridImageFileIndex } = content;
      if (type === 'upload') {
        const gridImageFile = this.value.gridImageFiles[gridImageFileIndex];
        if (!gridImageFile) {
          return '';
        }
        if (typeof gridImageFile === 'string') {
          return `${config.API_URI}${gridImageFile.substring(1)}`;
        }
        const { url, data } = gridImageFile;
        return url || data;
      }
      return src;
    },
    calculateImageStyle(imageIndex) {
      const image = this.value.grid.areas.reduce(
        createFindGridImage(imageIndex),
        {},
      );

      const positioning = {
        transform: `translateX(${image.scale.x}%) 
                    translateY(${image.scale.y}%)`,
        'transform-origin': 'top left',
      };

      if (image.fillMode === '') {
        return {
          transform: `scale(${image.scale.factor}) 
                    ${positioning.transform}`,
          'transform-origin': positioning['transform-origin'],
        };
      }
      const [heightValue, widthValue] =
        image.fillMode === 'height' ? ['100%', 'auto'] : ['auto', '100%'];
      return {
        ...positioning,
        height: heightValue,
        width: widthValue,
      };
    },
    onImageDrop($event, areaImageIndex, area) {
      const { type, src, index: imagePreviewIndex, render } = JSON.parse(
        $event.dataTransfer.getData('image-data'),
      );

      const areaWidth = this.$refs.grid.$el.querySelector(
        `[data-grid-area-index="${areaImageIndex}"]`,
      ).clientWidth;
      const originalImageWidth = (() => {
        if (type === 'render') {
          return this.$refs.renders[imagePreviewIndex].naturalWidth;
        }
        return this.$refs.uploads[imagePreviewIndex].naturalWidth;
      })();

      // If a custom upload was already used in an area, overwrite the existing gridImageFile.
      // If not, push the upload onto the gridImageFiles array
      let gridImageFileIndex =
        type === 'upload' ? area.gridImageFileIndex || -1 : -1;

      if (type === 'upload') {
        if (gridImageFileIndex === -1) {
          this.value.gridImageFiles.push(this.uploads[imagePreviewIndex]);
          gridImageFileIndex = this.value.gridImageFiles.length - 1;
        } else {
          this.value.gridImageFiles = [
            ...this.value.gridImageFiles.slice(0, gridImageFileIndex),
            this.uploads[imagePreviewIndex],
            ...this.value.gridImageFiles.slice(gridImageFileIndex + 1),
          ];
        }
      }

      const updateGridImage = (areas, currentArea) => {
        if (currentArea.index === areaImageIndex) {
          currentArea.content.src = src;
          currentArea.content.type = type;
          currentArea.content.render = type === 'render' ? render : null;
          currentArea.content.gridImageFileIndex = gridImageFileIndex;
          currentArea.content.scale = {
            factor: areaWidth / originalImageWidth,
            x: 0,
            y: 0,
          };
          return [...areas, currentArea];
        }
        if (currentArea.areas && currentArea.areas.length) {
          return [
            ...areas,
            {
              ...currentArea,
              areas: currentArea.areas.reduce(updateGridImage, []),
            },
          ];
        }
        return [...areas, currentArea];
      };

      this.value.grid = {
        ...this.value.grid,
        areas: this.value.grid.areas.reduce(updateGridImage, []),
      };
    },
    onImageDrag($event, imageData) {
      $event.dataTransfer.setData('image-data', JSON.stringify(imageData));
    },
    onUploadImage($event) {
      const files = $event.target.files;
      let image;
      if (files) {
        image = files[0];
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        this.uploads.push({
          url: '',
          data: reader.result,
          fileReference: image,
        });
      };
      reader.readAsDataURL(image);
    },
    async onSave() {
      this.updateUnusedGridImageFileIndices();
      this.value.gridImageFiles = this.value.gridImageFiles.map(
        gridImageFile => {
          if (typeof gridImageFile === 'string') {
            return gridImageFile;
          }
          const { url, fileReference } = gridImageFile;
          return url || fileReference;
        },
      );
      this.$emit('save');
    },
    updateUnusedGridImageFileIndices() {
      const flattenedGridAreas = this.value.grid.areas.reduce(
        flattenGridAreas,
        [],
      );

      const areaWithUploads = flattenedGridAreas
        .filter(({ content }) => content.gridImageFileIndex !== -1)
        .sort(
          (a, b) => a.content.gridImageFileIndex - b.content.gridImageFileIndex,
        );

      const indices = areaWithUploads.map(
        ({ content }) => content.gridImageFileIndex,
      );

      this.value.gridImageFiles = this.value.gridImageFiles.filter(
        (file, index) => indices.includes(index),
      );

      const indexMappingByAreaIndex = areaWithUploads.reduce(
        (indexMapping, area) => {
          return {
            ...indexMapping,
            [area.index]: {
              newIndex: indices.indexOf(area.content.gridImageFileIndex),
              oldIndex: area.content.gridImageFileIndex,
            },
          };
        },
        {},
      );

      const updateAreaUploadedImageIndex = (updatedAreas, currentArea) => {
        if (currentArea.index == null) {
          return [
            ...updatedAreas,
            {
              ...currentArea,
              areas: currentArea.areas.reduce(updateAreaUploadedImageIndex, []),
            },
          ];
        }

        if (indexMappingByAreaIndex[currentArea.index]) {
          return [
            ...updatedAreas,
            {
              ...currentArea,
              content: {
                ...currentArea.content,
                gridImageFileIndex:
                  indexMappingByAreaIndex[currentArea.index].newIndex,
              },
            },
          ];
        }

        return [...updatedAreas, currentArea];
      };
      this.value.grid = {
        split: this.value.grid.split,
        areas: this.value.grid.areas.reduce(updateAreaUploadedImageIndex, []),
      };
    },
  },
};
</script>
<style lang="scss">
.b2c_split-grid {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.b2c_split-grid .vsg_area {
  overflow: hidden;
}

.b2c_grid_image {
  max-width: none;
}
</style>

<style lang="scss" scoped>
.b2c_tab_wrapper {
  position: relative;
}

.b2c_tab_title {
  display: inline-block;
}

.b2c_grid-enabled_label {
  display: inline-block;

  input[type='checkbox'] {
    margin: 0 0.2rem;
  }
}

.b2c_grid-settings_container {
  position: relative;
}

.b2c_tab_disabled-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1000px;
  height: 100%;
  background-color: rgba($brand-lightest-gray, 0.8);
  display: flex;
  justify-content: center;
}

.b2c_form {
  width: 1000px;
}

.b2c_grid-layouts_container {
  display: flex;
  justify-content: space-around;
  border: solid 1px rgba($brand-primary, 0.15);
  padding: 0 1rem;
}

.b2c_grid-layout {
  display: flex;
  flex: 0 0 120px;
  padding: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100px;
  transform: translateY(1px);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba($brand-gray, 0.15);
    cursor: pointer;
  }

  &.s_selected {
    color: $brand-secondary;
  }
}

.b2c_product-image-grid_container {
  padding: 1rem;
}

.b2c_product-image-grid {
  border: 1px solid $brand-gray;
  height: 500px;
  width: 500px;
}

.b2c_product-image-grid_area {
  position: relative;
}

.b2c_product-image-grid_selected {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-shadow: inset 0 0 0 5px rgba($brand-gray, 0.4);
}

.b2c_product-image-grid_no-image-text {
  margin: 1rem;
}

.b2c_image-settings_container {
  padding: 1.6rem 1rem 0rem 1rem;
  position: relative;

  &.s_disabled {
    &::after {
      content: 'Selecteer een vlak in het grid om deze instellingen aan te kunnen passen';
      color: $brand-secondary;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-width: 1000px;
      height: 100%;
      background-color: rgba($brand-lightest-gray, 0.8);
      display: flex;
      padding-left: 1rem;
      align-content: middle;
    }
  }
}

.b2c_grid-area-settings {
  padding: 0rem 1rem;
}

.b2c_fill-modes_container {
  margin-top: 1rem;
}

.b2c_button_fill-mode {
  display: inline-block;
  margin-right: 1rem;
}

.b2c_images_container {
  padding: 1rem;
  width: 100%;
}

.b2c_images_type_title {
  display: flex;
  justify-content: space-between;
}

.b2c_button_upload-image {
  margin: 0;
  padding: 0.5em 1em;
}

.b2c_label_upload-image {
  color: $brand-secondary;
}

.b2c_button_image-upload {
  display: inline-block;
}

.b2c_image-preview {
  display: inline-block;
  width: 33%;
  padding: 0.4rem;
}
</style>
