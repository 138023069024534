<template>
  <div class="b2c_item" data-s-amount-item>
    <div class="b2c_item_container">
      <div
        :style="{ 'font-family': value.name }"
        class="b2c_item_font-container"
      >
        Pa’s wijze lynx bezag vroom het fikse aquaduct.
      </div>
      <div class="b2c_item_content-container">
        <div class="b2c_item_name">{{ value.name }}</div>
        <button
          class="button v_ghost_brand_light-gray v_is-icon v_smallest a_no-margin a_fill_brand_red"
          @click="$refs.delete.open(value)"
        >
          <icon :icon-id="'icon_ui_close'" />
        </button>
      </div>
    </div>
    <delete ref="delete" />
  </div>
</template>
<script>
import Delete from './DeleteFont';

export default {
  name: 'Font',
  components: {
    Delete,
  },
  props: ['value'],
  async created() {
    try {
      const fontUrl = (process.env.VUE_APP_API_URI + this.value.file)
        .replace('//', '/')
        .replace(':/', '://');
      const fontFace = new FontFace(this.value.name, `url(${fontUrl})`);
      const font = await fontFace.load();
      document.fonts.add(font);
    } catch (error) {
      console.error(error.message);
    }
  },
};
</script>
