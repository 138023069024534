<template>
  <modal
    :open.sync="modal.open"
    :loading.sync="modal.loading"
    :error-message.sync="modal.errorMessage"
    :success-message.sync="modal.successMessage"
    :header="'Kleur Toevoegen'"
    :sub-header="'Voeg een nieuwe kleur toe.'"
    :action-button-text="'Kleur toevoegen'"
    :icon="'icon_heart'"
    @action="save"
  >
    <input
      v-show="!modal.successMessage"
      v-model="fields.name"
      type="text"
      placeholder="Kleur naam, bijvoorbeeld 'Gold Yellow'"
    />
    <chrome-picker :value="fields.value" @input="fields.value = $event.hex" />
  </modal>
</template>
<script>
import { mapActions } from 'vuex';
import { Chrome } from 'vue-color';
import Modal from '@/components/elements/Modal';

export default {
  name: 'NewColor',
  data() {
    return {
      modal: {
        open: false,
        loading: false,
        done: false,
        successMessage: '',
        errorMessage: '',
      },
      fields: {
        name: '',
        value: '#FFC300',
      },
    };
  },
  methods: {
    ...mapActions('amazonColor', ['create']),
    open() {
      this.modal.open = true;
      this.fields.name = '';
      this.fields.value = '#FFC300';
    },
    async save() {
      try {
        if (!this.fields.name) {
          this.modal.errorMessage = 'vul een naam in a.u.b.';
          return false;
        }
        if (!this.fields.value) {
          this.modal.errorMessage = 'vul een value in a.u.b.';
          return false;
        }
        this.modal.loading = true;
        await this.create(this.fields);
        this.modal.successMessage = 'De Color is succesvol aangemaakt.';
      } catch (error) {
        this.modal.errorMessage = error.message;
      }
      this.modal.loading = false;
      return true;
    },
  },
  components: {
    ChromePicker: Chrome,
    Modal,
  },
};
</script>
