<template>
  <div>
    <section class="b2c_content">
      <div class="b2c_content_container">
        <div class="b2c_content_1-1">
          <header class="b2c_header">
            <div class="b2c_header_top">
              <div class="b2c_header_top_left">
                <div class="b2c_header_switch a_flex a_align-items_center">
                  <h1 class="s_elem-has-icon-left a_fill_default">
                    Mockup Tool
                    <icon :icon-id="'icon_mockuptool'" />
                  </h1>
                </div>
              </div>
              <div class="b2c_header_top_right">
                <button
                  class="button v_ghost_brand_primary v_smaller a_margin-left-10 a_opacity-0 a_no-pointer-events"
                >
                  ...
                </button>
              </div>
            </div>
            <div class="b2c_header_description">
              <p>
                Op deze pagina worden alle mockups weergegeven en kan de mockup
                tool worden opgestart.
              </p>
            </div>
          </header>
        </div>
      </div>
    </section>
    <section class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <h2 class="a_margin-bottom-0 a_margin-right-20">Mockups overzicht</h2>
        <p class="a_margin-bottom-0 a_text_brand_gray">
          {{ items.length }} Mockups gevonden
        </p>
        <!-- <input type="text" placeholder="Zoek een mockup" class="a_margin-bottom-0 a_margin-right-10" data-toggle="dropdownMockups">

                <div class="dropdown-pane" id="dropdownMockups" data-dropdown data-close-on-click="true">  
                    <ul class="b2c_inline-list">
                        <li><button>Mockup 1</button></li>
                        <li><button>Mockup 2</button></li>
                        <li><button>Mockup 3</button></li>
                        <li><button>Mockup 4</button></li>
                        <li><button>Mockup 5</button></li>
                        <li><button>Mockup 6</button></li>
                    </ul>
                </div>-->
      </div>
      <div class="b2c_search-bar_left">
        <a
          :href="`${mockupToolURI}#/?token=${token}`"
          target="_blank"
          class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0"
          >Nieuwe mockup scene</a
        >
      </div>
    </section>
    <!-- <section class="b2c_search-bar a_margin-top-60">
            <div class="b2c_search-bar_left">

                <h2 class="a_margin-bottom-0 a_margin-right-20">Mockup 1</h2>

                <button class="button v_brand_secondary v_is-icon v_smaller a_no-margin">
                    <icon :iconId="'icon_ui_close'"></icon>
                </button>

            </div>
            <div class="b2c_search-bar_left">
                 <button class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0">
                    Mockup scene aanpassen
                </button>
            </div>
        </section>-->
    <section class="b2c_content a_margin-top-40">
      <div class="b2c_content_container a_max-width-1000">
        <div class="b2c_content_1-1">
          <!-- <h2>Published Items</h2>
                    <hr>
                    <div class="b2c_items" data-s-amount="4">
                        <mockup v-if="item.published" v-for="(item, index) in items" :key="index" v-model="items[index]"></mockup>
                    </div> 
                    <h2>Saved Items</h2>
                    <hr>-->

          <div class="b2c_items" data-s-amount="4">
            <mockup
              v-for="(item, index) in items"
              v-if="!item.published"
              :key="index"
              v-model="items[index]"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import config from '@/config';
import Mockup from './Mockup';

export default {
  data() {
    return {
      mockupToolURI: config.MOCKUP_TOOL,
    };
  },
  async created() {
    try {
      await this.list();
    } catch (e) {
      this.openPopup({
        header: 'Er was een probleem bij het ophalen van Mockup',
        message: e.message,
      });
    }
  },
  // mounted() {
  //     this.dropdownSettings = new Foundation.Dropdown(($('#dropdownMockups')), {
  //         // vOffset: 20,
  //     });
  // },
  // destroyed() {
  //     this.dropdownSettings.destroy();
  // },
  computed: {
    ...mapState('mockup', ['items']),
    ...mapGetters('auth', ['token']),
  },
  methods: {
    ...mapActions('mockup', ['list']),
  },
  components: {
    Mockup,
  },
};
</script>

<style scoped lang="scss"></style>
