<template>
  <section class="b2c_list a_margin-top-60">
    <div class="b2c_list_container">
      <div class="b2c_list_heading">
        <div class="b2c_list_heading-left">
          <h2 class="a_margin-bottom-0 a_margin-right-20">{{ title }}</h2>
        </div>
        <div
          v-if="showSearch"
          class="b2c_list_heading-right a_text_brand_primary-lighter"
        >
          <input
            type="text"
            placeholder="Zoek een designer"
            class="a_margin-bottom-0"
            @change.prevent="search($event.target.value)"
            @keyup.prevent="search($event.target.value)"
          />
        </div>
      </div>

      <div class="b2c_list_header">
        <div
          v-for="(header, i) in headers"
          :key="header.name + i"
          :style="{ width: header.width }"
        >
          {{ header.name }}
        </div>
      </div>

      <div
        v-for="(item, i) in currentPageData"
        :key="uid + i"
        class="b2c_list_item"
      >
        <slot :item="item" :headers="headers">
          fallback
        </slot>
      </div>

      <div class="b2c_pagination a_margin-top-20">
        <div
          v-if="paginationSizes.length"
          class="b2c_pagination_filters a_margin-right-20"
        >
          <select
            class="v_transparent"
            @change="changePaginationSize($event.target.value)"
          >
            <option
              v-for="paginationSize in paginationSizes"
              :key="paginationSize"
              :value="paginationSize"
              >{{ paginationSize }} per pagina</option
            >
          </select>
        </div>
        <div v-if="numberOfPages" class="b2c_pagination_links">
          <button
            v-for="n in numberOfPages"
            :key="uid + '-page-' + n"
            :class="{ s_active: activePage === n - 1 }"
            @click="setActivePage(n - 1)"
          >
            {{ n }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    headers: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default: '',
    },
    paginationSizes: {
      type: Array,
      default() {
        return [];
      },
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPaginationSize: 10,
      activePage: 0,
      searchQuery: '',
      uid: Math.random(),
    };
  },
  computed: {
    numberOfPages() {
      if (!this.data.length || !this.currentPaginationSize) {
        return false;
      }
      return Math.ceil(this.data.length / this.currentPaginationSize);
    },
    currentPageData() {
      return this.data
        .filter(item =>
          Object.values(item).some(itemValue =>
            String(itemValue).includes(this.searchQuery),
          ),
        )
        .filter((item, i) => {
          if (
            i >= this.activePage * this.currentPaginationSize &&
            i < (this.activePage + 1) * this.currentPaginationSize
          ) {
            return true;
          }
          return false;
        });
    },
  },
  created() {
    if (this.paginationSizes.length) {
      this.currentPaginationSize = this.paginationSizes[0];
    }
  },
  methods: {
    changePaginationSize(size) {
      this.activePage = 0;
      this.currentPaginationSize = size;
    },
    search(val) {
      this.$emit('search', val);
      this.searchQuery = val;
    },
    setActivePage(num) {
      this.activePage = num;
      if (this.activePage === this.numberOfPages - 1) {
        this.$emit('navEnd');
      }
    },
  },
};
</script>

<style lang="scss"></style>
