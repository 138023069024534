import { RouteConfig } from 'vue-router';

// Pages
import Home from '@/views/Home.vue';

import Login from '@/views/Login.vue';

import Assets from '@/views/assets/Index.vue';
import AssetsCliparts from '@/views/assets/cliparts/Index.vue';
import AssetsEmojis from '@/views/assets/emojis/Index.vue';
import AssetsEffects from '@/views/assets/effects/Index.vue';
import AssetsPatterns from '@/views/assets/patterns/Index.vue';
import AssetsFonts from '@/views/assets/fonts/Index.vue';
import AssetsAmazon from '@/views/assets/amazon-assets/Index.vue';

import Templates from '@/views/templates/Index.vue';
import TemplatesCategories from '@/views/templates/devices/Index.vue';
import TemplatesLayouts from '@/views/templates/layouts/Index.vue';
import TemplatesTemplates from '@/views/templates/templates/Index.vue';
import TemplatesAmazonProductImages from '@/views/templates/amazon-product-images/Index.vue';

import Mockup from '@/views/mockups/mockup/Index.vue';
import Mockups from '@/views/mockups/Index.vue';
import MockupsTool from '@/views/mockups/tool/Index.vue';
import MockupsGenerator from '@/views/mockups/generator/Index.vue';
import MockupsForce from '@/views/mockups/force/Index.vue';

import Print from '@/views/print/Index.vue';
import PrintOrders from '@/views/print/orders/Index.vue';
import PrintOrdersComposers from '@/views/print/orders/Composer.vue';
import PrintQueue from '@/views/print/queue/Index.vue';
import PrintJob from '@/views/print/queue/Job.vue';
import PrintPrintTables from '@/views/print/print-tables/Index.vue';
import PrintAmazonOrderes from '@/views/print/amazon-orders/Index.vue';

import Designers from '@/views/designers/Index.vue';
import DesignersPCDdesigners from '@/views/designers/pcd-designers/Index.vue';
import DesignersPCDdesignerDesign from '@/views/designers/pcd-designers/Design.vue';
import DesignersPCDdesignerDetail from '@/views/designers/pcd-designers/User.vue';
import DesignersDesignSubmissions from '@/views/designers/design-inzendingen/Index.vue';
import DesignersContest from '@/views/designers/contests/Index.vue';
import DesignersGlobalWebshop from '@/views/designers/global-webshop/Index.vue';

import Settings from '@/views/settings/Index.vue';

// const routes = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home,
//   },
//   {
//     path: '/about',
//     name: 'About',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () =>
//       import(/* webpackChunkName: "about" */ '@/views/About.vue'),
//   },
// ];

const routes: Array<RouteConfig> = [
  // routes
  {
    path: '/',
    redirect: {
      name: 'assets-cliparts',
    },
  },
  {
    path: '/assets',
    redirect: {
      name: 'assets-cliparts',
    },
    component: Assets,
    children: [
      {
        name: 'assets-cliparts',
        path: 'cliparts',
        component: AssetsCliparts,
      },
      {
        name: 'assets-emojis',
        path: 'emojis',
        component: AssetsEmojis,
      },
      {
        name: 'assets-effects',
        path: 'effects',
        component: AssetsEffects,
      },
      {
        name: 'assets-patterns',
        path: 'patterns',
        component: AssetsPatterns,
      },
      {
        name: 'assets-fonts',
        path: 'fonts',
        component: AssetsFonts,
      },
      {
        name: 'assets-amazon',
        path: 'amazon',
        component: AssetsAmazon,
      },
    ],
  },
  {
    path: '/designers',
    redirect: {
      name: 'designers-pcd-designers',
    },
    component: Designers,
    children: [
      {
        name: 'designers-pcd-designers',
        path: 'pcd-designers',
        component: DesignersPCDdesigners,
      },
      {
        name: 'designers-design-submissions',
        path: 'design-submissions',
        component: DesignersDesignSubmissions,
      },
      {
        name: 'designers-design-submissions-paged',
        path: 'design-submissions/:filter/pages/:page',
        component: DesignersDesignSubmissions,
      },
      {
        name: 'designers-pcd-designer-design',
        path: 'pcd-designers/design/:designId',
        component: DesignersPCDdesignerDesign,
        props: true,
      },
      {
        name: 'designers-pcd-designer-detail-paged',
        path: 'pcd-designers/user/:userId/pages/:page',
        component: DesignersPCDdesignerDetail,
        props: true,
      },
      {
        name: 'designers-pcd-designer-detail',
        path: 'pcd-designers/user/:userId',
        component: DesignersPCDdesignerDetail,
        props: true,
      },
      {
        name: 'designers-pcd-designer-contests',
        path: 'contests/',
        component: DesignersContest,
      },
      {
        name: 'designers-global-webshop',
        path: 'global-webshop',
        component: DesignersGlobalWebshop,
      },
    ],
  },
  {
    name: 'home',
    path: '/home',
    component: Home,
  },
  {
    name: 'login',
    path: '/login',
    component: Login,
    meta: {
      noAuth: true,
      hideSidebar: true,
      hideTopbar: true,
      fullScreen: true,
    },
  },
  {
    name: 'mockups',
    path: '/mockups',
    component: Mockups,
    children: [
      {
        name: 'mockups-tool',
        path: 'tool',
        component: MockupsTool,
      },
      {
        name: 'mockups-generator',
        path: 'generator',
        component: MockupsGenerator,
      },
      {
        name: 'mockups-force',
        path: 'force',
        component: MockupsForce,
      },
      {
        name: 'mockups',
        path: 'mockup/:mockupId',
        component: Mockup,
        props: true,
      },
    ],
  },
  {
    path: '/print',
    redirect: {
      name: 'print-orders',
    },
    component: Print,
    children: [
      {
        name: 'print-orders',
        path: 'orders/pages/:page?',
        component: PrintOrders,
      },
      {
        name: 'print-orders-composer',
        path: 'orders/composer',
        component: PrintOrdersComposers,
      },
      {
        name: 'print-queue',
        path: 'queue',
        component: PrintQueue,
      },
      {
        name: 'print-jobs',
        path: 'queue/:jobId',
        component: PrintJob,
      },
      {
        name: 'print-print-tables',
        path: 'print-tables',
        component: PrintPrintTables,
      },
      {
        name: 'print-amazon-orders',
        path: 'amazon-orders',
        component: PrintAmazonOrderes,
      },
    ],
  },
  {
    name: 'settings',
    path: '/settings',
    component: Settings,
  },
  {
    path: '/templates',
    redirect: {
      name: 'templates-templates',
    },
    component: Templates,
    children: [
      {
        name: 'templates-categories',
        path: 'categories',
        component: TemplatesCategories,
      },
      {
        name: 'templates-layouts',
        path: 'layouts',
        component: TemplatesLayouts,
      },
      {
        name: 'templates-templates',
        path: 'templates',
        component: TemplatesTemplates,
      },
      {
        name: 'templates-amazon-product-images',
        path: 'amazon-product-images',
        component: TemplatesAmazonProductImages,
      },
    ],
  },
];

export default routes;
