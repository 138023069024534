import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';
import routes from './routes';

Vue.use(VueRouter);
const router = new VueRouter({
  linkActiveClass: 's_active',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (!store.getters['auth/token'] && !to.meta.noAuth) {
    return next({
      name: 'login',
    });
  }
  store.dispatch('router/fullScreen', to.meta.fullScreen);
  store.dispatch('router/showAllBars', !to.meta.hideAllBars);
  store.dispatch('router/showSidebar', !to.meta.hideSidebar);
  store.dispatch('router/showTopbar', !to.meta.hideTopbar);
  return next();
});

export default router;
