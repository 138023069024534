<template>
  <div class="b2c_item" data-s-amount-item>
    <router-link :to="`/mockups/mockup/${value._id}`">
      <div class="b2c_item_container">
        <div class="b2c_item_image-container">
          <img
            :src="`${basePath}/uploads/mockups/${value._id}/thumbnail.jpeg`"
          />
          <!-- <img :src="`${basePath}/mockups/${value._id}/preview`"> -->
        </div>
        <div class="b2c_item_content-container">
          <div class="b2c_item_name">
            {{ value.name }}
          </div>
          <!-- <a :href="`${mockupToolURI}#/${value._id}`" target="_blank" class="button v_ghost_brand_light-gray v_is-icon v_smallest a_no-margin a_fill_brand_green">
                    <icon :iconId="'icon_edit'"></icon>
                </a>
                <button @click="$refs.delete.open(value)" class="button v_ghost_brand_light-gray v_is-icon v_smallest a_no-margin a_fill_brand_red">
                    <icon :iconId="'icon_ui_close'"></icon>
                </button>-->
        </div>
      </div>
    </router-link>
  </div>
</template>
<script>
import config from '@/config';

export default {
  name: 'Effect',
  props: ['value'],
  data() {
    return {
      mockupToolURI: config.MOCKUP_TOOL,
      basePath: config.API_URI.substr(0, config.API_URI.length - 1),
    };
  },
};
</script>
