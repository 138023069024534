<template>
  <div>
    <div class="b2c_login">
      <div class="b2c_login_container">
        <div class="b2c_login_logo">
          <img src="img/logo.svg" />
        </div>
        <form action="" @submit.prevent="_login">
          <input v-model="username" type="text" placeholder="Username" />
          <input v-model="password" type="password" placeholder="Password" />
          <button
            :disabled="loading"
            class="button v_brand_secondary v_expanded"
            type="submit"
          >
            Inloggen
          </button>
        </form>
        <p style="color: red">{{ message }}</p>
        <a
          :href="config.FORGOT_PASSWORD_URI"
          target="_blank"
          class="a_text_brand_gray"
          >Wachtwoord vergeten?</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import config from '@/config';

export default {
  name: 'Home',
  data() {
    return {
      username: '',
      password: '',
      message: '',
      loading: false,
      config,
    };
  },
  methods: {
    ...mapActions('auth', ['login']),
    ...mapActions('system', ['sync']),
    async _login() {
      this.loading = true;
      try {
        const res = await this.login({
          username: this.username,
          password: this.password,
        });
        console.log('_login res', res);
        if (res.error) {
          this.message = res.error;
        } else {
          this.$router.push('/');
          this.message = '';
        }
      } catch (error) {
        console.log('ERROR', error);
        this.message = 'Geen toegang. Probeer het nogmaals';
      }
      this.loading = false;
      return true;
    },
  },
};
</script>
<style scoped lang="scss">
.b2c_login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.b2c_login_container {
  background: $brand-white;
  width: 300px;
  padding: 95px $default-padding $default-padding $default-padding;
  position: relative;
  box-shadow: 2px 2px 5px rgba(black, 0.2), 2px 2px 20px rgba(black, 0.1);
}

.b2c_login_logo {
  position: absolute;
  top: -75px;
  left: 50%;
  transform: translateX(-75px);
  img {
    width: 150px;
    height: 150px;
    transition: transform $default-transition-speed;
    border-radius: 50%;
    box-shadow: 0 4px 4px 0 rgba(black, 0.1), 0 6px 16px 0 rgba(black, 0.09);
  }
}
</style>
