<template>
  <div>
    <button
      :disabled="!value._id"
      class="button v_ghost_brand_secondary v_is-icon v_icon-size-24 v_smallest a_margin-top-20 a_margin-left-10"
      @click="globalTemplateRerenderPopup"
    >
      re-Render alle global designs met deze template
    </button>
    <modal-global-render
      ref="modalGlobalRender"
      type="template"
      @render="globalRerender"
    />
  </div>
</template>

<script>
import ModalGlobalRender from '@/components/elements/ModalGlobalRender';
import { mapActions } from 'vuex';

export default {
  props: ['value'],
  methods: {
    ...mapActions('global', ['globalRender']),
    ...mapActions('popup', ['openPopup']),
    async globalTemplateRerenderPopup() {
      let total = 0;
      const data = await this.globalRender({
        id: this.value._id,
        force: true,
        type: 'template',
        skipRender: true,
      });
      data.mockups.forEach(mockup => {
        if (!mockup.assets) {
          return;
        }
        mockup.assets.forEach(() => total++);
      });
      data.designs.forEach(design => {
        if (!design.assets) {
          return;
        }
        design.assets.forEach(() => total++);
      });

      if (total === 0) {
        return this.openPopup({
          header: 'Re-render templates',
          message: 'Er zijn geen designs gevonden voor dit template',
          closeButtonText: 'Sluiten',
        });
      }
      const message = `Dit is een vrij zware taak voor de server. Er zullen ${total} renders worden gemaakt. Het advies is om deze taak te draaien wanneer het niet druk is in de webshop. Je kunt als je geen haast hebt het renderen uitstellen tot middernacht.`;
      this.$refs.modalGlobalRender.open(message);

      // this.openPopup({
      //     header: 'Re-render templates',
      //     subHeader: 'Re-render alle global designs van deze templates.',
      //     message: ,
      //     closeButtonText: 'Annuleren',
      //     actionButtonText: 'Ja',
      //     actionCallback: this.globalRerender
      // });
    },
    async globalRerender({ delay, force }) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const data = await this.globalRender({
          type: 'template',
          delay,
          id: this.value._id,
          force,
          skipRender: false,
          forceGrids: true,
        });
        this.openPopup({
          header: 'Succes',
          message: delay
            ? 'De render is toegevoegd aan de wachtrij voor middernacht.'
            : 'De render is toegevoegd aan de wachtrij',
        });
      } catch (e) {
        this.openPopup({
          header: 'Foutmelding',
          message: 'Er ging iets fout bij het aanmaken van de rerender' + e,
          closeButtonText: 'Sluiten',
        });
      }
      //https://pcd-backend.stagi.nl/templates/5b2df96059577d000c614e63/global?force=true&batchId=6546546
    },
  },
  components: {
    ModalGlobalRender,
  },
};
</script>
