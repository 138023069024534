<template>
  <div>
    <page-title
      :title="'Amazon products Images'"
      :icon="'icon_assets'"
      :description="
        'Genereer hier afbeeldingen voor het maken van een Custom Image Product'
      "
    />
    <section class="b2c_content a_margin-top-40">
      <div class="b2c_content_container a_max-width-800">
        <label>
          SKU
          <input v-model="sku" name="SKU" type="text" placeholder="SKU" />
        </label>
        <button
          :disabled="!sku"
          class="button v_ghost_brand_secondary v_expanded v_smaller"
          @click="fetchProductDetails"
        >
          Genereren
        </button>
        <hr />
        <div v-if="productDetails.templateId">
          <h3>
            <small>Surface Image</small>
          </h3>
          <p class="a_text_brand_gray">
            De surface afbeelding, die Amazon als achtergrond gebruikt
          </p>
          <div class="b2c_form">
            <div class="b2c_form_left">
              <div class="b2c_form_left_img">
                <img v-lazy="surfaceImage" alt />
              </div>
            </div>
            <div class="b2c_form_right" />
          </div>
          <hr />
        </div>
        <div v-if="productDetails.templateId">
          <h3>
            <small>Overlay Image</small>
          </h3>
          <p class="a_text_brand_gray">
            De Overlay Image, die als masker over het geheel geplaatst wordt
          </p>
          <div class="b2c_form">
            <div class="b2c_form_left">
              <div class="b2c_form_left_img">
                <img
                  v-lazy="overlayImage"
                  v-if="productDetails.templateId"
                  alt
                />
              </div>
            </div>
            <div class="b2c_form_right" />
          </div>
          <hr />
        </div>
        <div v-if="productDetails.templateId">
          <h3>
            <small>Coördinaten</small>
          </h3>
          <p class="a_text_brand_gray">
            De coordinaten van het vak waarin ontworpen mag worden.
          </p>
          <div style="margin-bottom:20px">
            <p>
              <b style="font-size:14px">Position (pixels)</b>
            </p>
            <label style=" display: inline-block">
              X-axis :
              <br />
              <input
                :value="parsedCoordinates.x"
                style="height: 50px; width: 100px;"
              />
            </label>
            <label for style=" margin-left: 20px; display: inline-block">
              Y-axis :
              <br />
              <input
                :value="parsedCoordinates.y"
                style="height: 50px; width: 100px;"
              />
            </label>
          </div>
          <div class>
            <p>
              <b style="font-size:14px">Dimensions (pixels)</b>
            </p>
            <label style=" display: inline-block">
              Width :
              <br />
              <input
                :value="parsedCoordinates.width"
                style="height: 50px; width: 100px;"
              />
            </label>
            <label for style=" margin-left: 20px; display: inline-block">
              Height :
              <br />
              <input
                :value="parsedCoordinates.height"
                style="height: 50px; width: 100px;"
              />
            </label>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import axios from 'axios';
import config from '@/config';
import PageTitle from '@/components/elements/PageTitle';

// import productsList from './dummy_products_list';

export default {
  data: () => {
    return {
      sku: '',
      config,
      productDetails: {
        designId: '',
        templateId: '',
      },
      coordinates: undefined,
    };
  },
  computed: {
    surfaceImage() {
      return this.productDetails.designId
        ? `${this.config.API_URI}/designs/${this.productDetails.designId}/preview?size=400&fold=open&templateId=${this.productDetails.templateId}`
        : `${this.config.API_URI}/templates/${this.productDetails.templateId}/preview?fold=open&mod=overlay&size=400`;
    },
    overlayImage() {
      return this.productDetails.designId
        ? `${this.config.API_URI}/designs/${this.productDetails.designId}/preview?size=400&mod=overlay&fold=open&templateId=${this.productDetails.templateId}`
        : `${this.config.API_URI}/templates/${this.productDetails.templateId}/preview?fold=open&mod=overlay&size=400`;
    },
    parsedCoordinates() {
      const parsedCoordinates = {};
      if (!this.coordinates) {
        return {
          x: null,
          y: null,
          width: null,
          height: null,
        };
      }
      if (!this.coordinates.x) {
        parsedCoordinates.x = 0;
      }
      if (!this.coordinates.y) {
        parsedCoordinates.y = 0;
      }
      console.log(this.coordinates);
      parsedCoordinates.width = parseInt(JSON.parse(this.coordinates).width);
      parsedCoordinates.height = parseInt(JSON.parse(this.coordinates).height);
      parsedCoordinates.x = parseInt(JSON.parse(this.coordinates).x);
      parsedCoordinates.y = parseInt(JSON.parse(this.coordinates).y);
      return parsedCoordinates;
    },
  },
  methods: {
    ...mapActions('system', ['getAmazonCoordinates']),
    async fetchProductDetails() {
      try {
        const skuData = await axios.get(`${config.AUTH_URI}/ean/${this.sku}`);
        if (skuData && skuData.data && skuData.data.length) {
          this.productDetails = {
            templateId: skuData.data[0].templateID,
            designId: skuData.data[0].designID,
          };
        } else {
          alert('Issue while fetching SKU data');
        }
        this.coordinates = await this.getAmazonCoordinates(
          this.productDetails,
        ).then(res => JSON.stringify(res[0].coordinates));
        // await axios.get(this.surfaceImage);
        // await axios.get(this.overlayImage);
      } catch (error) {
        this.productDetails.templateId = undefined;
        setTimeout(() => {
          alert('Product not found !');
        }, 1);
      }
    },
    loaded(value) {
      alert(value);
    },
  },
  components: {
    PageTitle,
  },
};
</script>
<style scoped>
img[lazy='loading'] {
  width: 32px;
  height: 32px;
}
</style>
