import axios from 'axios';
import store from 'store';
import _ from 'lodash';
import config from '@/config';

const auth = {
  namespaced: true,
  state: {
    token: null,
  },
  getters: {
    token: state => {
      const token = state.token || store.get('token');
      if (token) {
        axios.defaults.headers.common.Authorization = token;
      }
      return token;
    },
    decodedToken: state => {
      const token = state.token || store.get('token');
      if (!token) {
        return null;
      }
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
    },
  },
  mutations: {
    setToken(state, token) {
      if (token) {
        store.set('token', token);
      } else {
        store.remove('token');
      }
      // eslint-disable-next-line
      axios.defaults.headers.common['Authorization'] = token;
      state.token = token;
    },
  },
  actions: {
    async list({ getters }, query) {
      const usersXHR = await axios.get(
        `users/${query ? `?query=${JSON.stringify(query)}` : ''}`,
      );
      if (!usersXHR.data.length) {
        return usersXHR.data;
      }
      const form = new FormData();
      form.append('id', `[${usersXHR.data.map(item => item.b2cId).join(',')}]`);
      const usersInfoXHR = await axios.post(
        `${config.AUTH_URI}/users/user`,
        form,
        {
          headers: {
            Authorization: getters.token,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      for (let i = 0; i < usersXHR.data.length; i += 1) {
        const user = usersXHR.data[i];
        const userInfo = _.find(usersInfoXHR.data, {
          id: user.b2cId,
        });
        delete userInfo.id;
        _.merge(user, userInfo);
      }
      return usersXHR.data;
    },
    async read({ getters }, userId) {
      const usersXHR = await axios.get(`users/${userId}`);
      const form = new FormData();
      form.append('id', `[${userId}]`);
      const usersInfoXHR = await axios.post(
        `${config.AUTH_URI}/users/user`,
        form,
        {
          headers: {
            Authorization: getters.token,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      delete usersInfoXHR.data[0].id;
      _.merge(usersXHR.data, usersInfoXHR.data[0]);
      return usersXHR.data;
    },
    // eslint-disable-next-line no-empty-pattern
    async updateState({}, { b2cId, state }) {
      try {
        const result = await axios.put(`users/${b2cId}`, {
          state,
        });
        // commit('update', result.data);
        return result.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async login({ commit }, credits) {
      const form = new FormData();
      form.append('username', credits.username);
      form.append('password', credits.password);
      const result = await axios.post(`${config.AUTH_URI}/users/login`, form);
      commit('setToken', result.data.token);
      const usersInfoXHR = await axios.get(
        `${config.AUTH_URI}/users/authenticate/`,
      );
      if (!usersInfoXHR.data.admin) {
        throw new Error();
      }
      return result.data;
    },
    async logout({ commit }) {
      commit('setToken', null);
    },
  },
};

export default auth;
