export default {
  API_URI: process.env.VUE_APP_API_URI,
  AUTH_URI: process.env.VUE_APP_AUTH_URI,
  GLOBAL_URI: process.env.VUE_APP_GLOBAL_URI,
  PCD_URI: process.env.VUE_APP_PCD_URI,
  MOCKUP_TOOL: process.env.VUE_APP_MOCKUP_TOOL,
  DESIGN_EXPORTER: process.env.VUE_APP_DESIGN_EXPORTER,
  CONTEST_URI: process.env.VUE_APP_CONTEST_URI,
  DESIGNER_URI: process.env.VUE_APP_DESIGNER_URI,
  SALES_URI: process.env.VUE_APP_SALES_URI,
  ORDERLINES_URI: process.env.VUE_APP_ORDERLINES_URI,
  FORGOT_PASSWORD_URI: process.env.VUE_APP_FORGOT_PASSWORD_URI,
  GLOBAL_DESIGNS_URI: process.env.VUE_APP_GLOBAL_DESIGNS_URI,
  STORAGE_URL: process.env.VUE_APP_STORAGE_URL,
  PRINT_STORAGE_URL: process.env.VUE_APP_PRINT_STORGE_URL,
  EXPORT_MANAGER_GATEWAY: process.env.VUE_APP_EXPORT_MANAGER_GATEWAY,
};
