<template>
  <div>
    <page-title
      :title="'Wedstrijden'"
      :icon="'icon_orders'"
      :description="'Beheer de wedstrijden.'"
    />
    <section class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <search-dropdown
          :placeholder="'Zoek Wedstrijden'"
          :data="competitions"
          v-model="search"
          @match="selectedCompetition = $event"
        />
        <button
          class="button v_brand_secondary v_is-icon v_smaller a_no-margin"
          @click="clearSearchDropdown"
        >
          <icon :icon-id="'icon_ui_close'" />
        </button>
      </div>
      <div class="b2c_search-bar_left">
        <button
          class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0"
          @click="$refs.newCompetition.open(null, false)"
        >
          Nieuwe Wedstrijd
        </button>
      </div>
    </section>

    <section v-if="selectedCompetition" class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <h2 class="a_margin-bottom-0 a_margin-right-20">
          Wedstrijd <b>{{ selectedCompetition.name }}</b> inzendingen
        </h2>
        <div v-if="!loading" class="b2c_pill">{{ newItemsCount }} nieuwe</div>
      </div>
      <div class="b2c_search-bar_left">
        <select v-model="filter" class="a_margin-bottom-0">
          <option value="all">Alles</option>
          <option value="submission">Aangevraagd</option>
          <option value="rejected">Afgewezen</option>
          <option value="accepted">Geaccepteerd</option>
        </select>
        &nbsp;&nbsp;
        <button
          class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0"
          @click="$refs.newCompetition.open(selectedCompetition, true)"
        >
          Aanpassen Wedstrijd</button
        >&nbsp;&nbsp;
        <button
          class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0"
          @click="$refs.deleteCompetition.open(selectedCompetition)"
        >
          Verwijder Wedstrijd
        </button>
      </div>
    </section>
    <center v-if="loading">
      <br /><br /><br />
      <h3>Loading ...</h3>
    </center>
    <section
      v-if="selectedCompetition && !loading"
      class="b2c_content a_margin-top-40"
    >
      <div class="b2c_content_container a_max-width-1000">
        <div class="b2c_content_1-1">
          <div class="b2c_items" data-s-amount="4">
            <div
              v-for="item in competitionDesigns"
              v-show="filter === 'all' || item.state === filter"
              :key="`competition-design-${item._id}`"
              class="b2c_item"
              data-s-amount-item
            >
              <div class="b2c_item_container">
                <div class="b2c_item_image-container">
                  <!-- <img :src="item.imgsrc"> -->
                  <a
                    :href="`${config.PCD_URI}?designId=${item.design}`"
                    target="_blank"
                  >
                    <img
                      :src="
                        [
                          basePath,
                          'uploads/designs',
                          item.design,
                          'thumbnail.jpeg',
                        ].join('/')
                      "
                      width="50"
                      height="50"
                      alt=""
                    />
                  </a>
                </div>
                <div class="b2c_item_content-container">
                  <div class="b2c_item_content-container_inner">
                    <!-- <div class="b2c_avatar v_smallest a_margin-right-20">
                                            <div class="b2c_avatar_name">Paul van de Laar</div>
                                        </div> -->
                    <ul>
                      <li>
                        <span style="color:#aaa">status:</span>
                        <span :style="{ color: getStateColour(item.state) }">{{
                          getState(item.state)
                        }}</span>
                      </li>
                      <li>
                        <span style="color:#aaa">aantal stemmen:</span
                        >{{ item.votes }}
                      </li>
                      <li>
                        <router-link :to="'pcd-designers/user/' + item.user"
                          >bekijk gebruiker</router-link
                        >
                      </li>
                      <li v-if="item.state === 'accepted'">
                        <a
                          :href="
                            `${config.CONTEST_URI}${selectedCompetition._id}/${item.design}`
                          "
                          target="blank"
                          >bezoek pagina</a
                        >
                      </li>
                    </ul>
                    <div class="b2c_item_name" />

                    <button
                      :disabled="item.loading || item.state === 'accepted'"
                      class="button accept_button v_is-icon v_smaller a_no-margin"
                      @click="setState(item, 'accepted', $event)"
                    >
                      <icon :icon-id="'icon_check'" />
                    </button>
                    <button
                      :disabled="item.loading || item.state === 'rejected'"
                      class="button reject_button v_is-icon v_smaller a_margin-bottom-0"
                      @click="setState(item, 'rejected', $event)"
                    >
                      <icon :icon-id="'icon_cross'" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- <section class="b2c_search-bar a_margin-top-60">
            <div class="b2c_search-bar_left">
                <h2 class="a_margin-bottom-0 a_margin-right-20">Wedstrijd <b>{{selectedCompetition.name}}</b> inzendingen</h2>
                <div class="b2c_pill">2 nieuwe</div>
                        
            </div>
            <div class="b2c_search-bar_left">
                <button @click="$refs.deleteCompetition.open(selectedCompetition)" class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0">
                    Remove Competition
                </button>
            </div>
        </section>

         <section v-if="selectedCompetition" class="b2c_content a_margin-top-40">
            <div class="b2c_content_container a_max-width-1000">
                <div class="b2c_content_1-1">      

                    <div class="b2c_items" data-s-amount="4">
                        <router-link to="pcd-designer/design" v-for="item in competitionDesigns" class="b2c_item" data-s-amount-item>
                            <div class="b2c_item_container">
                                <div class="b2c_item_image-container">
                                    <img :src="item.imgsrc">
                                </div>
                                <div class="b2c_item_content-container">
                                    <div class="b2c_item_content-container_inner">
                                        <div class="b2c_avatar v_smallest a_margin-right-20">
                                            <div class="b2c_avatar_image" style="background-image: url(img/avatars/1.jpg);"></div>
                                            <div class="b2c_avatar_name">Paul van de Laar</div>
                                        </div>
                                        <div class="b2c_item_name">
                                            {{ item.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>-->
    <new-competition ref="newCompetition" @success="clearSearchDropdown()" />
    <delete-competition
      ref="deleteCompetition"
      @success="
        selectedCompetition = '';
        clearSearchDropdown();
      "
    />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import config from '@/config';
import PageTitle from '@/components/elements/PageTitle';
import SearchDropdown from '@/components/elements/SearchDropdown';
import Icon from '@/components/elements/Icon';
import NewCompetition from './New';
import DeleteCompetition from './Delete';

export default {
  data() {
    return {
      search: '',
      selectedCategory: '',
      basePath: config.API_URI.substr(0, config.API_URI.length - 1),
      config,
      selectedCompetition: '',
      loading: false,
      filter: 'all',
    };
  },
  watch: {
    async selectedCompetition(competition) {
      this.loading = true;
      if (competition && competition._id) {
        await this.getCompetitionDesigns(competition._id);
      }
      this.loading = false;
    },
  },
  async created() {
    try {
      await Promise.all([this.getCompetitions()]);
    } catch (e) {
      this.openPopup({
        header: 'Er was een probleem bij het ophalen van de afbeeldingen',
        message: e.message,
      });
    }
  },
  computed: {
    ...mapState('competition', {
      competitions: 'items',
      competitionDesigns: 'designs',
    }),
    newItemsCount() {
      return this.competitionDesigns
        ? this.competitionDesigns.filter(item => item.state === 'submission')
            .length
        : 0;
    },
  },
  methods: {
    ...mapActions('popup', ['openPopup']),
    ...mapActions('competition', {
      getCompetitions: 'list',
      getCompetitionDesigns: 'listDesigns',
      updateDesign: 'updateDesign',
    }),
    clearSearchDropdown() {
      this.search = '';
      this.selectedCategory = '';
    },
    async setState(item, state, event) {
      event.preventDefault();
      item.loading = true;
      this.$forceUpdate();
      await this.updateDesign({
        item,
        state,
      });
      item.loading = false;
      this.$forceUpdate();
    },
    getState(state) {
      const states = {
        none: 'geen',
        submission: 'aangevraagd',
        accepted: 'geaccepteerd',
        rejected: 'afgewezen',
      };
      if (state) {
        return states[state];
      }
      return '';
    },
    getStateColour(state) {
      const states = {
        none: '#cccccc',
        submission: '#ff6600',
        accepted: '#00cc00',
        rejected: '#cc0000',
      };
      if (state) {
        return states[state];
      }
      return '';
    },
  },
  components: {
    PageTitle,
    SearchDropdown,
    NewCompetition,
    DeleteCompetition,
    Icon,
  },
};
</script>
<style scoped lang="scss">
$columns: (
  designer-name: (
    width: 20%,
  ),
  designer-since: (
    width: 20%,
  ),
  designer-amount: (
    width: 20%,
  ),
  designer-email: (
    width: 20%,
  ),
  designer-btn: (
    width: 30%,
  ),
  designer-new-name: (
    width: 60%,
  ),
  designer-new-btn: (
    width: 40%,
  ),
);
@mixin generate-columns {
  @each $label, $column in $columns {
    [data-t='header-#{$label}'],
    [data-t='item-#{$label}'] {
      padding: 0 $default-padding / 2;
      flex: 0 0 map-get($column, width);
      max-width: map-get($column, width);
    }
  }
}
@include generate-columns();
[data-t='header-datum'],
[data-t='item-datum'],
[data-t='header-designer-btn'],
[data-t='item-designer-btn'] {
  text-align: right;
}
.accept_button {
  background: #00cc00;
}
.reject_button {
  background: #cc0000;
}
</style>
