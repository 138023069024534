import axios from 'axios';

const module = {
  namespaced: true,
  state: {
    items: [],
    designs: [],
  },
  mutations: {
    set(state, payload) {
      state.items = payload;
    },
    setDesigns(state, payload) {
      state.designs = payload;
    },
    add(state, payload) {
      state.items.push(payload);
    },
    remove(state, payload) {
      state.items = state.items.filter(item => item._id !== payload);
    },
  },
  actions: {
    async list({ commit }) {
      try {
        const res = await axios.get('competitions/?');
        commit('set', res.data);
        return res.data;
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    },
    async create({ commit }, object) {
      try {
        const form = new FormData();
        form.append('name', object.name);
        form.append('leadText', object.leadText);
        form.append('startAt', object.startAt);
        form.append('stopAt', object.stopAt);
        form.append('background', object.background);
        const result = await axios.post('competitions/', form, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        });
        commit('add', result.data);
        return result.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async update({}, object) {
      try {
        const form = new FormData();
        form.append('name', object.name);
        form.append('leadText', object.leadText);
        form.append('startAt', object.startAt);
        form.append('closeAt', object.closeAt);
        form.append('background', object.background);
        const result = await axios.put(`competitions/${object._id}`, form, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        });
        return result.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async remove({ commit }, id) {
      try {
        const res = await axios.delete(`competitions/${id}`);
        commit('remove', id);
        return res.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async listDesigns({ commit }, id) {
      try {
        const res = await axios.get(
          `competitions/${id}/designs?query=${JSON.stringify({
            sort: 'createdAt',
          })}`,
        );
        res.data = res.data.reverse();
        commit('setDesigns', res.data);
        return res.data;
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async updateDesign({}, { item, state }) {
      try {
        const result = await axios.put(
          `competitions/${item.competition}/designs/${item.design}`,
          {
            state,
          },
        );
        item.state = state;
        return result.data;
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};

export default module;
