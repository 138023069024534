<template>
  <SplitGrid
    :direction="area.split === 'vertical' ? 'column' : 'row'"
    :strict-mode="false"
    :size-value="area.size"
    class="b2c_split-grid"
    :style="{
      height: '498px',
    }"
    @drag="
      $emit(
        'drag',
        Object.assign($event, {
          areaIndices: area.areas.map(({ index }) => index),
        }),
      )
    "
  >
    <template v-for="(area, index) in area.areas">
      <SplitGridGutter
        :render="index > 0 && showGutters"
        :key="`gutter-${index}`"
      />
      <LayoutGridRecursive
        v-if="Array.isArray(area.areas)"
        :direction="area.split === 'vertical' ? 'column' : 'row'"
        :area="area"
        :key="`grid-recursive-${index}`"
        :size-value="area.size"
        :show-gutters="showGutters"
        @drag="$emit('drag', $event)"
      >
        <template slot="default" slot-scope="slotScope">
          <slot v-bind="slotScope" name="default" />
        </template>
      </LayoutGridRecursive>
      <slot v-else :area="area" />
    </template>
  </SplitGrid>
</template>
<script>
import { SplitGrid, SplitGridGutter } from 'vue-split-grid';

export default {
  name: 'LayoutGridRecursive',
  components: {
    SplitGrid,
    SplitGridGutter,
  },
  props: {
    area: {
      type: Object,
      required: true,
    },
    showGutters: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss" scoped></style>
