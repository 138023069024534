import axios from 'axios';

const module = {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    set(state, payload) {
      state.items = payload;
    },
    add(state, payload) {
      state.items.push(payload);
    },
    remove(state, payload) {
      state.items = state.items.filter(item => item._id !== payload);
    },
  },
  actions: {
    async list({ commit }) {
      try {
        const res = await axios.get('effects/');
        commit('set', res.data);
        return res.data;
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    },
    async create({ commit }, object) {
      try {
        const form = new FormData();
        form.append('name', object.name);
        form.append('file', object.file);
        const result = await axios.post('effects/', form, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        });
        commit('add', result.data);
        return result.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async remove({ commit }, id) {
      try {
        const res = await axios.delete(`effects/${id}`);
        commit('remove', id);
        return res.data;
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};

export default module;
