<template>
  <modal
    :open.sync="modal.open"
    :loading.sync="modal.loading"
    :error-message.sync="modal.errorMessage"
    :success-message.sync="modal.successMessage"
    :header="'Print Table Verwijderen'"
    :action-button-text="'Print Table Verwijderen'"
    @action="removeIt"
  >
    <p>
      Weet je zeker dat je de Print Table "{{ target.name }}" wil verwijderen?
    </p>
  </modal>
</template>
<script>
import { mapActions } from 'vuex';
import Modal from '@/components/elements/Modal';

export default {
  data() {
    return {
      modal: {
        open: false,
        loading: false,
        done: false,
        successMessage: '',
        errorMessage: '',
      },
      target: {},
    };
  },
  methods: {
    ...mapActions('printTable', ['remove']),
    open(target) {
      this.modal.open = true;
      this.target = target;
    },
    async removeIt() {
      try {
        this.modal.loading = true;
        await this.remove(this.target._id);
        this.modal.successMessage = 'De Print Table is succesvol verwijderd.';
        this.$emit('success');
      } catch (error) {
        this.modal.errorMessage = error.message;
      }
      this.modal.loading = false;
      return true;
    },
  },
  components: {
    Modal,
  },
};
</script>
