<template>
  <div>
    <page-tabs :links="links" />
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
import PageTabs from '@/components/elements/PageTabs';

export default {
  name: 'Assets',
  components: {
    PageTabs,
  },
  data() {
    return {
      links: [
        {
          link: '/mockups/tool',
          name: 'Tool',
        },
        {
          link: '/mockups/generator',
          name: 'Generator',
        },
        {
          link: '/mockups/force',
          name: 'Force',
        },
        // {
        //     link: '/assets/patterns',
        //     name: 'Patronen',
        // },
        // {
        //     link: '/assets/fonts',
        //     name: 'Lettertypes',
        // },
      ],
    };
  },
};
</script>
<style lang="scss" scoped></style>
