<template>
  <div>
    <page-title
      :title="'Lettertypes'"
      :icon="'icon_text'"
      :description="
        'Een overzicht van alle geüploade fonts die gebruikt kunnen worden in het PCD platform.'
      "
    />
    <section class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <h2 class="a_margin-bottom-0 a_margin-right-20">
          Lettertypes overzicht
        </h2>
        <a href="https://fonts.google.com/" target="blank"
          >bezoek fonts.google.com</a
        >
      </div>
      <div class="b2c_search-bar_left">
        <button
          class="button v_brand_secondary a_margin-bottom-0"
          @click="$refs.new.open()"
        >
          Voeg lettertype toe
        </button>
      </div>
    </section>
    <section class="b2c_content a_margin-top-40">
      <div class="b2c_content_container a_max-width-1000">
        <div class="b2c_content_1-1">
          <a :href="downloadLink" target="_blank">Download alle fonts</a>
          <hr />
          <div class="b2c_items" data-s-amount="4">
            <font
              v-for="(item, index) in items"
              :key="index"
              v-model="items[index]"
            />
          </div>
        </div>
      </div>
    </section>
    <new ref="new" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import PageTitle from '@/components/elements/PageTitle';
import Font from './Font';
import New from './New';

export default {
  name: 'AssetsFonts',
  async created() {
    try {
      await this.list();
    } catch (e) {
      this.openPopup({
        header: 'Er was een probleem bij het ophalen van de lettertypes',
        message: e.message,
      });
    }
  },
  computed: {
    ...mapState('font', ['items']),
    downloadLink() {
      if (!this.items.length) {
        return '#';
      }
      return `https://fonts.google.com/?selection.family=${this.items
        .map(item => item.name)
        .join('|')}`;
    },
  },
  methods: {
    ...mapActions('popup', ['openPopup']),
    ...mapActions('font', ['list']),
  },
  components: {
    PageTitle,
    Font,
    New,
  },
};
</script>
<style scoped lang="scss"></style>
