import axios from 'axios';
import { sortAlphabetical } from '@/lib/utils';
import _ from 'lodash';

const module = {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    set(state: { items: any }, payload: any) {
      state.items = payload;
    },
    add(state: { items: any[] }, payload: any) {
      state.items.push(payload);
    },
    update(
      state: { items: any[] | _.List<{ _id: any }> | null | undefined },
      payload: { _id: any },
    ) {
      // @ts-ignore
      state.items[
        _.findIndex(state.items, {
          _id: payload._id,
        })
      ] = payload;
    },
    remove(state: { items: any[] }, payload: any) {
      state.items = state.items.filter(
        (item: { _id: any }) => item._id !== payload,
      );
    },
  },
  actions: {
    async list({ commit }: any) {
      try {
        const { data } = await axios.get('devices/');
        const items = sortAlphabetical(data, 'name');
        commit('set', items);
        return items;
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    },
    async create(
      { commit }: any,
      object: {
        name: string | Blob;
        category: string | Blob;
        frontFile: string | Blob;
        frontWidth: string | Blob;
        frontHeight: string | Blob;
        backFile: string | Blob;
        backWidth: string | Blob;
        backHeight: string | Blob;
      },
    ) {
      try {
        const form = new FormData();
        form.append('name', object.name);
        form.append('category', object.category);
        if (object.frontFile) {
          form.append('frontFile', object.frontFile);
        }
        if (object.frontWidth) {
          form.append('frontWidth', object.frontWidth);
        }
        if (object.frontHeight) {
          form.append('frontHeight', object.frontHeight);
        }
        if (object.backFile) {
          form.append('backFile', object.backFile);
        }
        if (object.backWidth) {
          form.append('backWidth', object.backWidth);
        }
        if (object.backHeight) {
          form.append('backHeight', object.backHeight);
        }
        const result = await axios.post('devices/', form, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        });
        commit('add', result.data);
        return result.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async update(
      { commit }: any,
      object: {
        _id: any;
        name: string | Blob;
        category: string | Blob;
        frontFile: string | Blob;
        frontWidth: string | Blob;
        frontHeight: string | Blob;
        backFile: string | Blob;
        backWidth: string | Blob;
        backHeight: string | Blob;
      },
    ) {
      try {
        if (!object._id) {
          throw new Error('The passed object does not have id.');
        }
        const form = new FormData();
        form.append('name', object.name);
        form.append('category', object.category);
        if (object.frontFile) {
          form.append('frontFile', object.frontFile);
        }
        if (object.frontWidth) {
          form.append('frontWidth', object.frontWidth);
        }
        if (object.frontHeight) {
          form.append('frontHeight', object.frontHeight);
        }
        if (object.backFile) {
          form.append('backFile', object.backFile);
        }
        if (object.backWidth) {
          form.append('backWidth', object.backWidth);
        }
        if (object.backHeight) {
          form.append('backHeight', object.backHeight);
        }
        const result = await axios.put(`devices/${object._id}`, form, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        });
        commit('update', result.data);
        return result.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async remove({ commit }: any, id: any) {
      try {
        const res = await axios.delete(`devices/${id}`);
        commit('remove', id);
        return res.data;
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};

export default module;
