<template>
  <section class="b2c_content a_margin-top-40">
    <div class="b2c_content_container a_max-width-800">
      <div class="b2c_content_1-1">
        <div class="a_flex a_justify_space-between a_align-items_center">
          <h2>
            {{ value.varianceName }}
            <span style="color:#aaa"
              >({{ value.isTheActive ? 'actief' : 'niet actief' }})</span
            >
          </h2>
          <div>
            <button
              v-if="value._id"
              ref="btnPublish"
              class="button v_brand_secondary a_margin-bottom-0"
              @click="publish"
            >
              {{ value.published ? 'UnPublish' : 'Publish' }}
            </button>
            <button
              class="button v_brand_secondary a_margin-bottom-0"
              @click="save"
            >
              Opslaan
            </button>
          </div>
        </div>
        <br />
        <div class="b2c_step_indicator">
          <div
            :class="{ s_active: active === 'device' }"
            class="b2c_step_indicator_item"
            @click="setActive('device')"
          >
            Template
          </div>
          <!-- <div @click="setActive('layout')" :class="{s_active: active === 'layout'}" class="b2c_step_indicator_item">Layout</div> -->
          <div
            :class="{ s_active: active === 'presentation' }"
            class="b2c_step_indicator_item"
            @click="setActive('presentation')"
          >
            presentatie
          </div>
          <div
            :class="{ s_active: active === 'product-image-grid' }"
            class="b2c_step_indicator_item"
            @click="setActive('product-image-grid')"
          >
            grid
          </div>
          <div
            :class="{ s_active: active === 'render' }"
            class="b2c_step_indicator_item"
            @click="setActive('render')"
          >
            render
          </div>
        </div>
        <device
          v-show="active === 'device'"
          :value="value"
          :variances="variances"
          @svg-changed="$refs.presentation.images = $event"
          @next="setActive('presentation')"
        />
        <product-image-grid
          v-if="active === 'product-image-grid'"
          :disabled="value._id === ''"
          v-model="value"
          @save="save"
        />
        <presentation
          v-show="active === 'presentation'"
          ref="presentation"
          v-model="value"
          :show="active === 'presentation'"
          :mockups="mockups"
          @next="setActive('device')"
          @save="save"
        />
        <render v-show="active === 'render'" v-model="value" type="mockup" />
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from 'vuex';
import Device from './partials/Device';
import Presentation from './partials/Presentation';
import ProductImageGrid from './partials/ProductImageGrid';
import Render from './partials/Render';

export default {
  props: ['value', 'device', 'mockups', 'variances'],
  data() {
    return {
      active: 'device',
    };
  },
  computed: {},
  methods: {
    ...mapActions('popup', ['openPopup']),
    ...mapActions('template', ['create', 'update', 'delete']),
    setActive(slug) {
      this.active = slug;
    },
    async publish() {
      this.$refs.btnPublish.disabled = true;
      try {
        await this.update({
          _id: this.value._id,
          published: !this.value.published,
          mockups: this.value.mockups,
        });
        this.value.published = !this.value.published;
      } catch (error) {
        this.openPopup({
          header: 'Error',
          message: error.message,
        });
      }
      this.$refs.btnPublish.disabled = false;
    },
    async save() {
      this.$emit('saving', true);
      try {
        const missedItem = [
          'name',
          'varianceName',
          'svg',
          'layoutRatio',
          /* 'imageOfOver', 'imageOfInside', */ 'printTable',
          'folds',
        ].reduce((prevMissedItem, requiredField) => {
          if (!prevMissedItem && !this.value[requiredField]) {
            return requiredField;
          }
          return prevMissedItem;
        }, false);
        // if (!missedItem && this.value.folds && this.value.folds.items && this.value.folds.items.length === 0) {
        //     missedItem = 'folds';
        // }
        if (missedItem) {
          this.openPopup({
            header: 'Error',
            message: `"${missedItem}" is leeg ! Vul deze waarde in.`,
          });
          this.$emit('saving', false);
          return;
        }
        this.value.device = this.value.device
          ? this.value.device
          : this.device._id;
        this.value.lipSize = this.value.lipSize ? this.value.lipSize : 0;
        this.value.transparent = this.value.transparent
          ? this.value.transparent
          : false;
        const result = await this[this.value._id ? 'update' : 'create'](
          this.value,
        );
        this.value._id = result._id;
        this.value.gridImageFiles = result.gridImageFiles;
        this.openPopup({
          header: 'Succes',
          message: 'De template is opgeslagen!',
        });
        this.$emit('saving', false);
        this.$emit('saved', this.value);
      } catch (error) {
        this.openPopup({
          header: 'Error',
          message: error.message,
        });
        this.$emit('saving', false);
      }
    },
  },
  components: {
    Device,
    Presentation,
    ProductImageGrid,
    Render,
  },
};
</script>
