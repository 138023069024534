<template>
  <div>
    <page-title
      :icon="'icon_assets'"
      title="Amazon Designer"
      description="Beheer hier de lettertypes die gebruikt worden door Amazon. Zorg dat er maximaal 20 lettertypes en maximaal 20 kleuren zijn. Het font formaat MOET ttf zijn."
    />
    <section class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <h2 class="a_margin-bottom-0 a_margin-right-20">Lettertypes</h2>
      </div>
      <div class="b2c_search-bar_left">
        <button
          :disabled="fonts.length >= 15"
          class="button v_brand_secondary a_margin-bottom-0"
          @click="$refs.newFont.open()"
        >
          Voeg lettertype toe
        </button>
      </div>
    </section>
    <section class="b2c_content a_margin-top-40">
      <div class="b2c_content_container a_max-width-1000">
        <div class="b2c_content_1-1">
          <div class="b2c_items" data-s-amount="4">
            <font
              v-for="(item, index) in fonts"
              :key="index"
              v-model="fonts[index]"
            />
          </div>
        </div>
      </div>
    </section>
    <NewFont ref="newFont" />
    <!-- Colors -->
    <section class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <h2 class="a_margin-bottom-0 a_margin-right-20">Kleuren</h2>
      </div>
      <div class="b2c_search-bar_left">
        <button
          :disabled="colors.length >= 20"
          class="button v_brand_secondary a_margin-bottom-0"
          @click="$refs.newColor.open()"
        >
          Voeg kleur toe
        </button>
      </div>
    </section>
    <section class="b2c_content a_margin-top-40">
      <div class="b2c_content_container a_max-width-1000">
        <div class="b2c_content_1-1">
          <div class="b2c_items" data-s-amount="4">
            <color
              v-for="(item, index) in colors"
              :key="index"
              v-model="colors[index]"
            />
          </div>
        </div>
      </div>
    </section>
    <NewColor ref="newColor" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import PageTitle from '@/components/elements/PageTitle';
import Font from './Font';
import Color from './Color';
import NewFont from './NewFont';
import NewColor from './NewColor';

export default {
  async created() {
    try {
      await this.getFonts();
      await this.getColors();
    } catch (e) {
      this.openPopup({
        header: 'Er was een probleem bij het ophalen van de lettertypes',
        message: e.message,
      });
    }
  },
  computed: {
    ...mapState('amazonFont', {
      fonts: 'items',
    }),
    ...mapState('amazonColor', {
      colors: 'items',
    }),
  },
  methods: {
    ...mapActions('popup', ['openPopup']),
    ...mapActions('amazonFont', {
      getFonts: 'list',
    }),
    ...mapActions('amazonColor', {
      getColors: 'list',
    }),
  },
  components: {
    PageTitle,
    Font,
    Color,
    NewFont,
    NewColor,
  },
};
</script>
<style scoped lang="scss"></style>
