<template>
  <div>
    <section class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <!-- <input type="text" placeholder="Zoek categorie" v-model="category.name" class="a_margin-bottom-0 a_margin-right-10" data-toggle="dropdownCategories2">

                <div class="dropdown-pane" id="dropdownCategories2" data-dropdown data-close-on-click="true">
                    <ul class="b2c_inline-list">
                        <li v-for="(item) in categories" :key="item._id">
                            <button @click="device = ''; template = '';$emit('categoryChanged', category = item);">{{item.name}}</button>
                        </li>
                    </ul>
                </div> -->
        <search-dropdown
          v-if="categories"
          :placeholder="'Zoek categorie'"
          :data="categories"
          v-model="categoryName"
          @match="categoryChanged"
        />

        <search-dropdown
          v-if="categories"
          :disabled="!category"
          :placeholder="'Zoek Device'"
          :data="sortedDevices"
          v-model="deviceName"
          @match="deviceChanged"
        />

        <search-dropdown
          v-if="sortedTemplates"
          :disabled="!template"
          :placeholder="'Zoek Template'"
          :data="sortedTemplates"
          :disable-search="true"
          v-model="templateName"
          @match="templateChanged"
        />
        <!-- <input :disabled="!category" type="text" placeholder="Zoek toestel" v-model="device.name" class="a_margin-bottom-0 a_margin-right-10" data-toggle="dropdownDevices2">

                <div class="dropdown-pane" id="dropdownDevices2" data-dropdown data-close-on-click="true">
                    <ul class="b2c_inline-list">
                        <li v-for="(item) in sortedDevices" :key="item._id" v-if="item.category === category._id">
                            <button @click="template='';$emit('deviceChanged', device = item);">{{item.name}}</button>
                        </li>
                    </ul>
                </div>
                <input v-if="sortedTemplates" :disabled="!device" type="text" placeholder="Zoek Template" v-model="template.name" class="a_margin-bottom-0 a_margin-right-10" data-toggle="dropdownTemplates1">
   
                <div v-if="sortedTemplates" class="dropdown-pane" id="dropdownTemplates1" data-dropdown data-close-on-click="true">
                    <ul class="b2c_inline-list">
                        <li v-for="(item) in sortedTemplates" :key="item._id" v-if="item.device === device._id && !item.varianceOf">
                            <button @click="$emit('templateChanged', template = item);">{{item.name}}</button>
                        </li>
                    </ul>
                </div>  -->
        |&nbsp;&nbsp;
        <input
          v-model="templateIdSearchPhrase"
          type="text"
          placeholder="TEMPLATE ID"
          class="a_margin-bottom-0 a_margin-right-10"
        />
        <button
          :disabled="!/^[0-9a-fA-F]{24}$/.test(templateIdSearchPhrase)"
          class="button v_brand_secondary v_is-icon v_smaller a_no-margin"
          @click="searchTemplateByID(templateIdSearchPhrase)"
        >
          <icon :icon-id="'icon_ui_search'" />
        </button>
      </div>
      <div class="b2c_search-bar_left">
        <button
          :disabled="!device"
          class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0"
          @click="
            template = '';
            $emit('onNewClicked');
          "
        >
          Nieuwe Template
        </button>
      </div>
    </section>
  </div>
</template>
<script>
import _ from 'lodash';
import SearchDropdown from '@/components/elements/SearchDropdown';

export default {
  components: {
    SearchDropdown,
  },
  props: ['categories', 'devices', 'templates'],
  data() {
    return {
      category: null,
      device: null,
      template: '',
      categoryName: '',
      deviceName: '',
      templateName: '',
      templateIdSearchPhrase: '',
    };
  },
  computed: {
    sortedDevices() {
      if (!this.devices.length || !this.category) {
        return [];
      }
      return this.devices.filter(
        device => device.category === this.category._id,
      );
    },
    sortedTemplates() {
      if (!this.templates.length || !this.device) {
        return [];
      }
      return this.templates
        .filter(
          template =>
            template.device === this.device._id && !template.varianceOf,
        )
        .sort(this.sortObjectByNameAlphabetically);
    },
  },
  watch: {
    templates() {
      if (
        this.templates &&
        this.templates.length > 0 &&
        this.$route.query &&
        this.$route.query.id
      ) {
        setTimeout(() => {
          this.searchTemplateByID(this.$route.query.id);
        }, 1);
      }
    },
  },
  mounted() {
    // this.dropdownCategories2 = new Foundation.Dropdown($('#dropdownCategories2'), {
    //     // vOffset: 20,
    // });
    // this.dropdownDevices2 = new Foundation.Dropdown($('#dropdownDevices2'), {
    //     // vOffset: 20,
    // });
    // this.dropdownTemplates1 = new Foundation.Dropdown($('#dropdownTemplates1'), {
    //     // vOffset: 20,
    // });
  },
  destroyed() {
    // this.dropdownCategories2.destroy();
    // this.dropdownDevices2.destroy();
    // this.dropdownTemplates1.destroy();
  },
  methods: {
    clear() {
      this.device = '';
      this.category = '';
      this.template = '';
    },
    categoryChanged(data) {
      this.$emit('categoryChanged', (this.category = data));
      this.deviceName = '';
      this.templateName = '';
    },
    deviceChanged(data) {
      this.templateName = '';
      this.$emit('deviceChanged', (this.device = data));
    },
    templateChanged(data) {
      this.$emit('templateChanged', (this.template = data));
    },
    searchTemplateByID(templateId) {
      const template = _.find(this.templates, {
        _id: templateId,
      });
      if (template) {
        const device = _.find(this.devices, {
          _id: template.device,
        });
        const category = _.find(this.categories, {
          _id: device.category,
        });
        this.categoryChanged(category);
        this.categoryName = category.name;
        this.deviceChanged(device);
        this.deviceName = device.name;
        this.templateName = template.name;
        setTimeout(() => {
          this.templateChanged(template);
        }, 1);
      } else {
        alert(`"${templateId}" doesn't exists!`);
      }
    },
  },
};
</script>
