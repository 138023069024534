/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import _ from 'lodash';
import config from '@/config';

const imageRequest = axios.create({
  headers: {
    'Content-Type': 'image',
    Authorization: '',
  },
  baseURL: config.API_URI,
});

const module = {
  namespaced: true,
  state: {
    items: [],
    products: [],
  },
  mutations: {
    set(state, payload) {
      state.items = payload;
    },
    setProducts(state, payload) {
      state.products = payload;
    },
    add(state, payload) {
      state.items.push(payload);
    },
    update(state, payload) {
      state.items[
        _.findIndex(state.items, {
          _id: payload._id,
        })
      ] = payload;
    },
    remove(state, payload) {
      state.items = state.items.filter(item => item._id !== payload);
    },
    activate(state, id) {
      const target =
        state.items[
          _.findIndex(state.items, {
            _id: id,
          })
        ];
      state.items.forEach(item => {
        if (item._id === id) {
          item.isTheActive = true;
        } else if (
          item.varianceOf === id ||
          item.varianceOf === target.varianceOf ||
          item._id === target.varianceOf
        ) {
          item.isTheActive = false;
        }
      });
    },
  },
  actions: {
    async read({ commit }, id) {
      try {
        const res = await axios.get(`templates/${id}`);
        return res.data;
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    },
    async list({ commit }, query) {
      try {
        const templates = await axios
          .get(`templates/${query ? `?query=${JSON.stringify(query)}` : ''}`)
          .then(res => res.data);
        const products = await axios
          .get(`${config.AUTH_URI}/products`)
          .then(res => res.data);
        for (const template of templates) {
          const product = _.find(products, {
            _id: template._id,
          });
          template.location = product ? product.location : undefined;
        }
        commit('setProducts', products);
        commit('set', templates);
        return templates;
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    },
    async create({ commit }, object) {
      let errorDetails;
      try {
        const form = new FormData();
        Object.keys(object).forEach(key => {
          if (_.find(['extraImageFiles', 'imagesOfDevice'], key)) {
            return;
          }
          let value = object[key];
          if (_.isPlainObject(value) || _.isArray(value)) {
            value = JSON.stringify(value);
          }
          form.append(key, value);
        });
        if (_.isArray(object.extraImageFiles)) {
          object.extraImageFiles.forEach((extraImage, index) => {
            if (extraImage) {
              form.append('extraImageFiles', extraImage);
            } else {
              throw new Error(`Extra Image # ${index + 1} is empty.`);
            }
          });
        }
        if (_.isArray(object.imagesOfDevice)) {
          object.imagesOfDevice.forEach((extraImage, index) => {
            if (extraImage) {
              form.append('imagesOfDevice', extraImage);
            } else {
              throw new Error(`Images Of Device # ${index + 1} is empty.`);
            }
          });
        }
        const result = await axios
          .post('templates/', form, {
            headers: {
              'content-type': 'multipart/form-data',
            },
          })
          .catch(error => {
            if (error.response && error.response.data) {
              errorDetails = error.response.data;
            }
          });
        commit('add', result.data);
        return result.data;
      } catch (error) {
        error.message = errorDetails.message;
        throw error;
      }
    },
    async update({ commit }, object) {
      try {
        if (!object._id) {
          throw new Error('The passed object does not have id.');
        }
        const form = new FormData();
        Object.keys(object).forEach(key => {
          if (_.find(['extraImageFiles', 'imagesOfDevice'], key)) {
            return;
          }
          let value = object[key];
          if (_.isPlainObject(value) || _.isArray(value)) {
            if (['device'].indexOf(key) >= 0) {
              value = value._id;
            } else {
              value = JSON.stringify(value);
            }
          }
          form.append(key, value);
        });
        if (_.isArray(object.extraImageFiles)) {
          for (let i = 0; i < object.extraImageFiles.length; i++) {
            const extraImage = object.extraImageFiles[i];
            if (!extraImage) {
              throw new Error(`Extra Image # ${i + 1} is empty.`);
            } else if (_.isString(extraImage)) {
              const response = await imageRequest.get(
                extraImage + `?${Math.random()}`,
                {
                  responseType: 'arraybuffer',
                },
              );
              form.append(
                'extraImageFiles',
                new Blob([new Uint8Array(response.data)], {
                  type: 'image/png',
                }),
              );
            } else {
              form.append('extraImageFiles', extraImage);
            }
          }
        }
        if (_.isArray(object.imagesOfDevice)) {
          for (let i = 0; i < object.imagesOfDevice.length; i++) {
            const imageOfDevice = object.imagesOfDevice[i];
            if (!imageOfDevice) {
              throw new Error(`Images Of Device # ${i + 1} is empty.`);
            } else if (_.isString(imageOfDevice)) {
              const response = await imageRequest.get(
                imageOfDevice + `?${Math.random()}`,
                {
                  responseType: 'arraybuffer',
                },
              );
              form.append(
                'imagesOfDevice',
                new Blob([new Uint8Array(response.data)], {
                  type: 'image/png',
                }),
              );
            } else {
              form.append('imagesOfDevice', imageOfDevice);
            }
          }
        }
        if (_.isArray(object.gridImageFiles)) {
          for (const gridImage of object.gridImageFiles) {
            let gridImageFormData;
            if (_.isString(gridImage)) {
              const response = await imageRequest.get(
                gridImage + `?${Math.random()}`,
                {
                  responseType: 'arraybuffer',
                },
              );
              gridImageFormData = new Blob([new Uint8Array(response.data)], {
                type: 'image/png',
              });
            } else {
              gridImageFormData = gridImage;
            }
            form.append('gridImageFiles', gridImageFormData);
          }
        }
        const result = await axios.put(`templates/${object._id}`, form, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        });
        commit('update', result.data);
        return result.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async remove({ commit }, id) {
      try {
        const res = await axios.delete(`templates/${id}`);
        commit('remove', id);
        return res.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async duplicate({ commit }, { id, name, varianceName }) {
      const form = new FormData();
      form.append('name', name);
      form.append('varianceName', varianceName);
      const result = await axios.post(`templates/${id}/duplicate`, form);
      commit('add', result.data);
      return result.data;
    },
    async setAsActive({ commit }, id) {
      try {
        const res = await axios.put(
          `templates/${id}/variances/activate`,
          {},
          {
            headers: {
              'content-type': 'application/json',
            },
          },
        );
        commit('activate', id);
        return res.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async loadSVG({ commit }, path) {
      try {
        const res = await axios.get(path, {
          headers: {
            Authorization: ' ',
          },
        });
        return res.data;
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    },
    createVariance({ commit }, template) {
      const name = `variance_${_.random(1000, 9999)}`;
      const output = {
        name,
        varianceName: name,
        varianceOf: template.varianceOf || template._id,
        rect: {},
        physicalSize: {},
        bleed: 11,
        folds: {
          rounding: 0,
          offset: 0,
          items: [],
        },
        foldedPhysicalSize: {},
        hasLip: template.hasLip,
        lipSize: template.lipSize,
        mockups: [].concat(template.mockups),
        printTable: template.printTable,
        isTheActive: false,
      };
      return output;
    },
  },
};

export default module;
