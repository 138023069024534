import axios from 'axios';
import _ from 'lodash';

const module = {
  namespaced: true,
  state: {
    configs: {
      createdAt: '',
      updatedAt: '',
      lastCleanupAt: '',
      facebookApiKey: '',
      instagramClientKey: '',
      instagramSecretKey: '',
      defaultProduct: '',
      maxUserFileUpload: 20,
      maxDesignImagesCount: 5,
      maxDesignFileSize: 5,
      templateAssetsSize: 500,
      thumbnails: {
        width: 120,
        fold: 'closed',
        rotation: 0,
        reflection: true,
        shadow: true,
      },
      globalAssets: {
        first: {
          size: 1000,
          fold: 'closed',
          rotation: 30,
          reflection: true,
          shadow: true,
          renderOnAllColors: false,
        },
        second: {
          size: 1000,
          fold: 'closed',
          rotation: 30,
          reflection: true,
          shadow: true,
          renderOnAllColors: false,
        },
        third: {
          size: 1000,
          fold: 'open',
          rotation: 180,
          reflection: true,
          shadow: true,
          renderOnAllColors: false,
        },
        amazon: {
          size: 1000,
          fold: 'open',
          rotation: 180,
          reflection: true,
          shadow: true,
          renderOnAllColors: false,
        },
      },
      tracer: {
        threshold: 100,
      },
    },
  },
  mutations: {
    set(state, payload) {
      state.configs = payload;
    },
  },
  actions: {
    async read({ commit }) {
      try {
        const res = await axios.get('system/configs');
        _.defaults(res.data, {
          thumbnails: {
            width: 120,
            fold: 'closed',
            rotation: 0,
            reflection: true,
            shadow: true,
          },
          globalAssets: {},
          tracer: {
            threshold: 100,
          },
        });
        _.defaults(res.data.globalAssets, {
          first: {
            size: 1000,
            fold: 'closed',
            rotation: 30,
            reflection: true,
            shadow: true,
            renderOnAllColors: false,
          },
          second: {
            size: 1000,
            fold: 'closed',
            rotation: 30,
            reflection: true,
            shadow: true,
            renderOnAllColors: false,
          },
          third: {
            size: 1000,
            fold: 'open',
            rotation: 180,
            reflection: true,
            shadow: true,
            renderOnAllColors: false,
          },
          amazon: {
            size: 400,
            fold: 'open',
            rotation: 0,
            reflection: false,
            shadow: false,
            renderOnAllColors: false,
          },
        });
        commit('set', res.data);
        return res.data;
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    },
    async update({ commit }, configs) {
      try {
        const result = await axios.put('system/configs', configs);
        commit('set', result.data);
        return result.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async sync() {
      try {
        await axios.put('system/sync', {});
      } catch (error) {
        throw new Error(error);
      }
    },
    async getQueueState() {
      try {
        const result = await axios.get('/system/queue', {});
        return result.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async getAmazonCoordinates({}, product) {
      try {
        const result = await axios.post('/system/export/amazon/coordinates', {
          products: [product],
        });
        return result.data;
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};

export default module;
