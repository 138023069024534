<template>
  <div>
    <section class="b2c_content">
      <div class="b2c_content_container">
        <div class="b2c_content_1-1">
          <header class="b2c_header">
            <div class="b2c_header_top">
              <div class="b2c_header_top_left">
                <div class="b2c_header_switch a_flex a_align-items_center">
                  <h1 class="s_elem-has-icon-left a_fill_default">
                    Designers
                    <icon :icon-id="'icon_designers'" />
                  </h1>
                </div>
              </div>
              <div class="b2c_header_top_right">
                <button
                  class="button v_ghost_brand_primary v_smaller a_margin-left-10 a_opacity-0 a_no-pointer-events"
                >
                  ...
                </button>
              </div>
            </div>

            <div class="b2c_header_description">
              <p>Beheer de designers hier.</p>
            </div>
          </header>
        </div>
      </div>
    </section>

    <section class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <h2 class="a_margin-bottom-0 a_margin-right-20">Eigen designs</h2>
      </div>
      <div class="b2c_search-bar_left">
        <router-link
          :to="`/designers/pcd-designers/user/${decodedToken.id}`"
          class="button v_brand_secondary v_smaller a_margin-bottom-0"
        >
          <span class="text-container">
            Bekijk eigen designs
          </span>
        </router-link>
      </div>
    </section>

    <paginated-list
      :show-search="false"
      :pagination-sizes="[5, 10, 40]"
      :data="newDesigners"
      :headers="newDesignersHeaders"
      title="Nieuwe PCD Designers"
      @navEnd="newPcdDesignersNavEnd"
    >
      <template slot-scope="{ item, headers }">
        <div :style="{ width: headers[0].width }">
          {{ item.name }} {{ item.family }}
        </div>
        <div :style="{ width: headers[1].width }">
          {{ getState(item.state) }}
        </div>
        <div :style="{ width: headers[2].width }">
          <button
            :disabled="item.state === 'designer'"
            class="button accept_button v_is-icon v_smaller a_no-margin"
            @click="setState(item, 'designer', index)"
          >
            <icon :icon-id="'icon_check'" />
          </button>
          <button
            :disabled="item.state === 'customer'"
            class="button reject_button v_is-icon v_smaller a_margin-bottom-0"
            @click="setState(item, 'customer', index)"
          >
            <icon :icon-id="'icon_cross'" />
          </button>
        </div>
        <div :style="{ width: headers[3].width }">
          <router-link
            :to="'pcd-designers/user/' + item.b2cId"
            class="button v_transparent a_text_brand_secondary v_has-icon-right v_smallest a_margin-bottom-0"
          >
            <span class="text-container">
              Bekijk
            </span>
            <icon :icon-id="'icon_ui_arrow-right'" />
          </router-link>
        </div>
      </template>
    </paginated-list>

    <paginated-list
      :show-search="true"
      :pagination-sizes="[5, 10, 40]"
      :data="designers"
      :headers="designersHeaders"
      title="Bestaande PCD Designers"
      @search="pcdDesignersSearch"
      @navEnd="pcdDesignersNavEnd"
    >
      <template slot-scope="{ item, headers }">
        <div :style="{ width: headers[0].width }">
          <router-link :to="'pcd-designers/user/' + item.b2cId"
            >{{ item.name }} {{ item.family }}</router-link
          >
        </div>
        <div :style="{ width: headers[1].width }">{{ item.since }}</div>
        <div :style="{ width: headers[2].width }">{{ item.amount }}</div>
        <div :style="{ width: headers[3].width }">
          <a :href="'mailto:' + item.email" target="_blank">{{ item.email }}</a>
        </div>
        <div :style="{ width: headers[4].width }">
          <!-- <router-link :to="'pcd-designers/user/' + item.b2cId" class="button v_transparent a_text_brand_secondary v_has-icon-right v_smallest a_margin-bottom-0">
                        <span class="text-container">
                            Bekijk
                        </span>
                        <icon :icon-id="'icon_ui_arrow-right'"/>
                    </router-link> -->
        </div>
      </template>
    </paginated-list>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import PaginatedList from '@/components/elements/PaginatedList';

export default {
  name: 'DesignersPcdDesigners',
  data() {
    return {
      designers: [
        // {
        //     _id: '5a7870e982020000091a4699',
        //     name: 'Paul van de Laar',
        //     since: '28-07-2017',
        //     amount: '32',
        //     email: 'Paul@gmail.com',
        // },
        // {
        //     _id: '5a7870e982020000091a4699',
        //     name: 'Werner Bastianen',
        //     since: '28-07-2017',
        //     amount: '4',
        //     email: 'Wernerbas@yahoo.nl',
        // },
        // {
        //     _id: '5a7870e982020000091a4699',
        //     name: 'Jos van de Manakker',
        //     since: '28-07-2017',
        //     amount: '12',
        //     email: 'jmanakker@hotmail.com',
        // },
        // {
        //     _id: '5a7870e982020000091a4699',
        //     name: 'Sandra de Boei',
        //     since: '28-07-2017',
        //     amount: '1',
        //     email: 'sandradb@hotmail.com',
        // },
        // {
        //     _id: '5a7870e982020000091a4699',
        //     name: 'Paul van de Laar',
        //     since: '28-07-2017',
        //     amount: '32',
        //     email: 'Paul@gmail.com',
        // },
        // {
        //     _id: '5a7870e982020000091a4699',
        //     name: 'Werner Bastianen',
        //     since: '28-07-2017',
        //     amount: '4',
        //     email: 'Wernerbas@yahoo.nl',
        // },
        // {
        //     _id: '5a7870e982020000091a4699',
        //     name: 'Jos van de Manakker',
        //     since: '28-07-2017',
        //     amount: '12',
        //     email: 'jmanakker@hotmail.com',
        // },
        // {
        //     _id: '5a7870e982020000091a4699',
        //     name: 'Sandra de Boei',
        //     since: '28-07-2017',
        //     amount: '1',
        //     email: 'sandradb@hotmail.com',
        // },
      ],
      designersHeaders: [
        {
          name: 'naam',
          key: 'name',
          width: '25%',
        },
        {
          name: 'sinds',
          key: 'since',
          width: '15%',
        },
        {
          name: 'hoeveelheid',
          key: 'amount',
          width: '15%',
        },
        {
          name: 'email',
          key: 'email',
          width: '25%',
        },
        {
          name: '',
          width: '20%',
        },
      ],
      newDesigners: [
        // {
        //     _id: '5a7870e982020000091a4699',
        //     name: 'Paul van de Laar',
        //     state: 'submission',
        // },
        // {
        //     _id: '5a795c2782020000091a4911',
        //     name: 'Werner Bastianen',
        //     state: 'submission',
        // },
      ],
      newDesignersHeaders: [
        {
          name: 'Naam',
          key: 'name',
          width: '40%',
        },
        {
          name: 'Status',
          width: '10%',
        },
        {
          name: 'Keuren',
          width: '20%',
        },
        {
          name: '',
          width: '10%',
        },
      ],
    };
  },
  async created() {
    try {
      const results = await Promise.all([
        this.getUsers({
          filters: [
            {
              column: 'state',
              operator: '=',
              values: ['submission'],
            },
          ],
        }),
        this.getUsers({
          filters: [
            {
              column: 'state',
              operator: '=',
              values: ['designer'],
            },
          ],
        }),
      ]);
      this.newDesigners = results[0];
      this.designers = results[1];
    } catch (e) {
      this.openPopup({
        header: 'Er was een probleem bij het ophalen van de designers',
        message: e.message,
      });
    }
  },
  computed: {
    ...mapGetters('auth', ['decodedToken']),
  },
  methods: {
    ...mapActions('auth', {
      getUsers: 'list',
      updateUserState: 'updateState',
    }),
    ...mapActions('popup', ['openPopup']),
    pcdDesignersNavEnd() {
      console.log('reached end of table, extra data could be loaded');
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    newPcdDesignersNavEnd() {},
    pcdDesignersSearch(val) {
      console.log(val);
    },
    async setState(item, state, event) {
      console.log('set state for', item, state, event);
      try {
        await this.updateUserState({
          b2cId: item.b2cId,
          state,
        });
        item.state = state;
      } catch (error) {
        console.log(error);
      }
      // event.preventDefault();
      // item.loading = true;
      // this.$forceUpdate();
      // await this.updateDesign({ item, state });
      // item.loading = false;
      // this.$forceUpdate();
    },
    getState(state) {
      const states = {
        none: 'geen',
        submission: 'aangevraagd',
        designer: 'geaccepteerd',
        customer: 'afgewezen',
      };
      if (state) {
        return states[state];
      }
      return '';
    },
    getStateColour(state) {
      const states = {
        none: '#cccccc',
        submission: '#ff6600',
        designer: '#00cc00',
        customer: '#cc0000',
      };
      if (state) {
        return states[state];
      }
      return '';
    },
  },
  components: {
    PaginatedList,
  },
};
</script>
<style scoped lang="scss">
.accept_button {
  background: #00cc00;
}
.reject_button {
  background: #cc0000;
}
</style>
