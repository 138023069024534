import apollo from '@/apollo';
import { sortBy } from 'lodash';
import gql from 'graphql-tag';
import { Job } from '@/types/Job';
import { ActionContext } from 'vuex';
import { downloadURI } from '@/utils/download-uri';
import { notify } from '@/utils/notifications';

const queue = new Map<string, Job>();
const jobsWaitingList = new Map<string, boolean>();
const JOB_FRAGMENT = /* GraphQL */ `
  {
    id
    timestamp
    processedOn
    finishedOn
    progress
    returnvalue
    failedReason
    data {
      requestedBy
      printTable
      designs
      options {
        mod
        debug
      }
    }
  }
`;

async function fetchJobs() {
  const {
    data: { jobs },
  } = await apollo.query<{ jobs: Job[] }>({
    query: gql`query { jobs ${JOB_FRAGMENT} }`,
  });
  return jobs;
}

async function retryJob(id: string) {
  return apollo.mutate<{ job: Job }>({
    mutation: gql`mutation { retry(id: "${id}") { id } } `,
  });
}

async function cancelJob(id: string) {
  return apollo.mutate<{ job: Job }>({
    mutation: gql`mutation { cancel(id: "${id}") { id } } `,
  });
}

function subscribeEvents(callback: (job: Job) => void) {
  apollo
    .subscribe<{ onQueueEvent: { name: string; job: Job } }>({
      query: gql`
          subscription onQueueEvent {
            onQueueEvent {
              name
              job ${JOB_FRAGMENT}
            }
          }
        `,
    })
    .subscribe(({ data }) => {
      if (data) {
        const { name, job } = data.onQueueEvent;
        console.info(name, job);
        callback(job);
      }
    });
}

export default {
  namespaced: true,
  state: {
    items: [],
    connected: false,
  },
  mutations: {
    setConnected(state: { connected: boolean }) {
      state.connected = true;
    },
    upsertJob(state: { items: Job[] }, job: Job) {
      if (job.failedReason) {
        job.status = 'failed';
      } else if (job.returnvalue) {
        job.status = 'finished';
      } else if (job.processedOn) {
        job.status = 'processing';
      } else {
        job.status = 'waiting';
      }
      queue.set(job.id, job);
      state.items = sortBy(
        Array.from(queue.values()),
        ({ timestamp }) => new Date(timestamp),
      ).reverse();
      return;
    },
    removeJob(state: { items: Job[] }, id: string) {
      const index = state.items.findIndex(value => (value.id = id));
      state.items.splice(index, 1);
    },
  },
  actions: {
    async connect({
      commit,
      dispatch,
      state: { connected, items },
    }: ActionContext<any, any>) {
      if (connected) {
        return items;
      } else {
        const jobs = await fetchJobs();
        jobs.forEach(job => commit('upsertJob', job));
        subscribeEvents((job: Job) => dispatch('handleEvent', job));
        return jobs;
      }
    },
    async retry(ctx: ActionContext<any, any>, id: string) {
      return retryJob(id);
    },
    async cancel({ commit }: ActionContext<any, any>, id: string) {
      await cancelJob(id);
      commit('removeJob', id);
    },
    handleEvent({ commit }: ActionContext<any, any>, job) {
      commit('upsertJob', job);
      console.log(job);
      switch (job.status) {
        case 'failed':
          if (jobsWaitingList.has(job.id)) {
            notify('Job Failed', job.failedReason);
          }
          break;
        case 'finished':
          if (jobsWaitingList.has(job.id) && !jobsWaitingList.get(job.id)) {
            notify('Job Finished', `ID: ${job.id}`);
            jobsWaitingList.set(job.id, job.returnvalue.url);
            downloadURI(
              job.returnvalue.url,
              job.returnvalue.path.split('/').pop(),
            );
          }
          break;
        default:
          break;
      }
    },
    waitForJob(ctx: ActionContext<any, any>, jobId) {
      jobsWaitingList.set(jobId, false);
    },
  },
};
