<template>
  <span class="svg-container">
    <svg role="img">
      <use :xlink:href="'#' + iconId" />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'Icon',
  props: ['iconId'],
};
</script>

<style lang="scss"></style>
