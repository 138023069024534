<template>
  <div class="b2c_form_slider">
    <input
      :value="value"
      v-bind="$attrs"
      type="range"
      @change="$emit('input', $event.target.value)"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped></style>
