<template>
  <div>
    <section class="b2c_content">
      <div class="b2c_content_container">
        <div class="b2c_content_1-1">
          <header class="b2c_header">
            <div class="b2c_header_top">
              <div class="b2c_header_top_left">
                <div class="b2c_header_switch a_flex a_align-items_center">
                  <h1 class="s_elem-has-icon-left a_fill_default">
                    Instellingen
                    <icon :icon-id="'icon_settings'" />
                  </h1>
                </div>
              </div>
              <div class="b2c_header_top_right">
                <button
                  class="button v_ghost_brand_primary v_smaller a_margin-left-10 a_opacity-0 a_no-pointer-events"
                >
                  ...
                </button>
              </div>
            </div>

            <div class="b2c_header_description">
              <p>Keys en instellingen</p>
            </div>
          </header>
        </div>
      </div>
    </section>

    <section class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <h2 class="a_margin-bottom-0 a_margin-right-20">Instellingen</h2>
      </div>
      <div class="b2c_search-bar_left">
        <button
          class="button v_brand_secondary v_smaller a_margin-bottom-0"
          @click="setEditMode(true)"
        >
          Aanpassen
        </button>
      </div>
    </section>

    <section class="b2c_content a_margin-top-40">
      <div class="b2c_content_container a_max-width-800">
        <div class="b2c_content_1-1">
          <h3>Batch Rendering</h3>
          <queue-state />
          <hr />

          <h3>API Keys</h3>
          <label
            >Facebook app ID
            <input
              v-model="configs.facebookApiKey"
              :disabled="!editMode"
              type="text"
              placeholder="Facebook app ID"
            />
          </label>

          <label
            >Instagram client ID
            <input
              v-model="configs.instagramClientKey"
              :disabled="!editMode"
              type="text"
              placeholder="Instagram client ID"
            />
          </label>

          <label
            >Instagram Client SECRET
            <input
              v-model="configs.instagramSecretKey"
              :disabled="!editMode"
              type="text"
              placeholder="Instagram Client SECRET"
            />
          </label>

          <hr />
          <h3>Editor</h3>
          <label
            >Standaard product ID
            <input
              v-model="configs.defaultProduct"
              :disabled="!editMode"
              type="text"
              placeholder="Default product"
            />
          </label>

          <label
            >Maximale upload grootte (in MB)
            <input
              v-model.number="configs.maxUserFileUpload"
              :disabled="!editMode"
              type="number"
              min="0"
              placeholder="Maximum file size of user uploads (MB)"
            />
          </label>

          <label
            >Maximaal aantal afbeeldingen in een design
            <input
              v-model.number="configs.maxDesignImagesCount"
              :disabled="!editMode"
              type="number"
              min="0"
              placeholder="Maximum possible images count in a design"
            />
          </label>

          <label
            >Maximale design bestandsgrootte
            <input
              v-model.number="configs.maxDesignFileSize"
              :disabled="!editMode"
              type="number"
              min="0"
              placeholder="Maximale design bestandsgrootte (MB)"
            />
          </label>

          <hr />
          <h3>Thumbnails</h3>
          <label
            >Breedte (px)
            <input
              v-model.number="configs.thumbnails.width"
              :disabled="!editMode"
              type="number"
              min="50"
              placeholder="Thumbnails With (px)"
            />
          </label>
          <label
            >Rotatie (graden)
            <input
              v-model.number="configs.thumbnails.rotation"
              :disabled="!editMode"
              type="number"
              min="0"
              max="360"
              placeholder="Rotation (degrees)"
            />
          </label>
          <label
            >Fold State
            <select v-model="configs.thumbnails.fold" :disabled="!editMode">
              <option value="open">Open</option>
              <option value="closed">Dicht</option>
            </select>
          </label>
          <label
            >Schaduw
            <select v-model="configs.thumbnails.shadow" :disabled="!editMode">
              <option :value="true">Aan</option>
              <option :value="false">Uit</option>
            </select>
          </label>
          <label
            >Reflectie
            <select
              v-model="configs.thumbnails.reflection"
              :disabled="!editMode"
            >
              <option :value="true">Aan</option>
              <option :value="false">Uit</option>
            </select>
          </label>

          <hr />
          <h3>Globale render instellingen</h3>
          <small
            >De instellingen die worden gebruikt wanneer een design globaal
            wordt gerenderd (voor bijvoorbeeld bol.com)</small
          >
          <!-- <h4 class=" a_margin-top-20">Device Colors</h4> -->
          <div
            v-for="(item, index) in ['first', 'second', 'third']"
            :key="index"
          >
            <h4 class=" a_margin-top-20">3D instelling {{ index + 1 }}</h4>
            <div class="a_margin-bottom-20">
              <label
                >Breedte (px)
                <input
                  v-model.number="configs.globalAssets[item].size"
                  :disabled="!editMode"
                  type="number"
                  min="50"
                  placeholder="Assets With (px)"
                />
              </label>
              <label
                >Rotatie (graden)
                <input
                  v-model.number="configs.globalAssets[item].rotation"
                  :disabled="!editMode"
                  type="number"
                  min="0"
                  max="360"
                  placeholder="Rotation (degrees)"
                />
              </label>
              <label
                >Fold State
                <select
                  v-model="configs.globalAssets[item].fold"
                  :disabled="!editMode"
                >
                  <option value="open">Open</option>
                  <option value="closed">Dicht</option>
                </select>
              </label>
              <label
                >Schaduw
                <select
                  v-model="configs.globalAssets[item].shadow"
                  :disabled="!editMode"
                >
                  <option :value="true">Aan</option>
                  <option :value="false">Uit</option>
                </select>
              </label>
              <label
                >Reflectie
                <select
                  v-model="configs.globalAssets[item].reflection"
                  :disabled="!editMode"
                >
                  <option :value="true">Aan</option>
                  <option :value="false">Uit</option>
                </select>
              </label>
              <label
                >Render in alle device kleuren
                <select
                  v-model="configs.globalAssets[item].renderOnAllColors"
                  :disabled="!editMode"
                >
                  <option :value="true">Aan</option>
                  <option :value="false">Uit</option>
                </select>
              </label>
            </div>
          </div>

          <h4>Amazon</h4>
          <div class="a_margin-bottom-20">
            <label
              >Breedte (px)
              <input
                v-model.number="configs.globalAssets.amazon.size"
                :disabled="!editMode"
                type="number"
                min="50"
                placeholder="Assets With (px)"
              />
            </label>
            <label
              >Rotatie (graden)
              <input
                v-model.number="configs.globalAssets.amazon.rotation"
                :disabled="!editMode"
                type="number"
                min="0"
                max="360"
                placeholder="Rotation (degrees)"
              />
            </label>
            <label
              >Fold State
              <select
                v-model="configs.globalAssets.amazon.fold"
                :disabled="!editMode"
              >
                <option value="open">Open</option>
                <option value="closed">Dicht</option>
              </select>
            </label>
            <label
              >Schaduw
              <select
                v-model="configs.globalAssets.amazon.shadow"
                :disabled="!editMode"
              >
                <option :value="true">Aan</option>
                <option :value="false">Uit</option>
              </select>
            </label>
            <label
              >Reflectie
              <select
                v-model="configs.globalAssets.amazon.reflection"
                :disabled="!editMode"
              >
                <option :value="true">Aan</option>
                <option :value="false">Uit</option>
              </select>
            </label>
            <label
              >Render in alle device kleuren
              <select
                v-model="configs.globalAssets.amazon.renderOnAllColors"
                :disabled="!editMode"
              >
                <option :value="true">Aan</option>
                <option :value="false">Uit</option>
              </select>
            </label>
          </div>

          <hr />

          <h3>Bitmap Tracer</h3>
          <p>Wordt gebruikt voor het genereren van de witte PDF</p>
          <label
            >Threshold
            <input
              v-model.number="configs.tracer.threshold"
              :disabled="!editMode"
              type="number"
              min="0"
              max="255"
              placeholder="Threshold"
            />
          </label>
          <small
            >Let op: als de waarde op 0 staat bepaalt de bitmap tracer zelf de
            threshold op basis van de afbeelding</small
          >

          <hr />
          <h3>Admin Panel Instellingen</h3>
          <label
            >Template Assets Breedte (px)
            <input
              v-model.number="configs.templateAssetsSize"
              :disabled="!editMode"
              type="number"
              min="50"
              placeholder="Template Assets With (px)"
            />
          </label>
          <small
            >Wordt gebruikt voor de afmeting van het renderen en genereren van
            assets voor de Render Server</small
          >
          <hr />

          <div
            v-if="editMode"
            class="a_flex a_justify_flex-end a_align-items_center"
          >
            <button ref="save" class="button v_brand_secondary" @click="save">
              Instellingen opslaan
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import QueueState from './partials/QueueState';

export default {
  data() {
    return {
      editMode: false,
      global: [
        {
          rotation: null,
          reflection: null,
          shadow: null,
          state: null,
        },
        {
          rotation: null,
          reflection: null,
          shadow: null,
          state: null,
        },
      ],
    };
  },
  async created() {
    try {
      await this.getConfigs();
    } catch (e) {
      this.openPopup({
        header: 'Er was een probleem bij het ophalen van System Configs',
        message: e.message,
      });
    }
  },
  computed: {
    ...mapState('system', ['configs']),
  },
  methods: {
    ...mapActions('system', {
      getConfigs: 'read',
      saveConfigs: 'update',
    }),
    ...mapActions('modal', ['openModal']),
    ...mapActions('popup', ['openPopup']),
    setEditMode() {
      if (this.editMode === false) {
        this.editMode = true;
      } else {
        this.editMode = false;
      }
    },
    async save() {
      try {
        this.$refs.save.disabled = true;
        await this.saveConfigs(this.configs);
        this.openPopup({
          header: 'Message',
          message: 'System Configs Saved Successfully !',
        });
        this.$refs.save.disabled = false;
      } catch (error) {
        this.openPopup({
          header: 'Error while saving System Configs',
          message: error.message,
        });
        this.$refs.save.disabled = false;
      }
    },
  },
  components: {
    QueueState,
  },
};
</script>
