<template>
  <div>
    <section class="b2c_content">
      <div class="b2c_content_container">
        <div class="b2c_content_1-1">
          <header class="b2c_header">
            <div class="b2c_header_top">
              <div class="b2c_header_top_left">
                <div class="b2c_header_switch a_flex a_align-items_center">
                  <h1 class="s_elem-has-icon-left a_fill_default">
                    Globale Designs Webshop
                    <icon :icon-id="'icon_design-submissions'" />
                  </h1>
                </div>
              </div>
              <div class="b2c_header_top_right">
                <button
                  class="button v_ghost_brand_primary v_smaller a_margin-left-10 a_opacity-0 a_no-pointer-events"
                >
                  ...
                </button>
              </div>
            </div>
            <div class="b2c_header_description">
              <p>Globale designs zoals deze in de webshop bekend zijn.</p>
            </div>
          </header>
        </div>
      </div>
    </section>
    <section class="b2c_search-bar a_margin-top-60">
      <ul>
        <li v-for="(designLink, index) in designLinks" :key="index">
          <span v-html="designLink" />
        </li>
      </ul>
    </section>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import config from '@/config';

export default {
  data() {
    return {
      designIds: null,
    };
  },
  async mounted() {
    await this.getDesignIds();
  },
  computed: {
    ...mapGetters('auth', ['token']),
    designLinks() {
      if (!this.designIds) {
        return [];
      }
      return this.designIds.map(
        designId =>
          `<a href="${config.API_URI}designs/${designId}/global?viewImages=true&token=${this.token}&batchId=${designId}" target="_blank">${designId}</a>`,
      );
    },
  },
  methods: {
    async getDesignIds() {
      try {
        console.log('get design ids', config.GLOBAL_DESIGNS_URI);
        const { data } = await axios.get(config.GLOBAL_DESIGNS_URI);
        this.designIds = data;
      } catch (e) {
        console.error(e);
      }
    },
  },
  name: 'GlobalWebshop',
};
</script>
<style scoped lang="scss"></style>
