<template>
  <div>
    <section class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <input
          :value="'Layout ' + category"
          type="text"
          placeholder="Zoek een layout categorie"
          class="a_margin-bottom-0 a_margin-right-10"
          data-toggle="cmb_layouts_categories"
        />
        <div
          id="cmb_layouts_categories"
          class="dropdown-pane"
          data-dropdown
          data-close-on-click="true"
        >
          <ul class="b2c_inline-list">
            <li v-for="item in categories" :key="item._id">
              <button @click="$emit('changed', (category = item))">
                Layout {{ item }}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="b2c_search-bar_left">
        <button
          class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0"
          @click="$refs.new.open()"
        >
          Nieuwe Layout
        </button>
      </div>
    </section>
    <!-- MODALS -->
    <new ref="new" />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import New from './New';

export default {
  data() {
    return {
      categories: ['1:1', '1:2', '1:3', '2:1', '2:3', '3:1', '3:2'],
      category: '',
    };
  },
  async created() {
    try {
      await Promise.all([this.getLayouts()]);
    } catch (e) {
      this.openPopup({
        header: 'Er was een probleem bij het ophalen van Layouts',
        message: e.message,
      });
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.cmbCategories = new Foundation.Dropdown($('#cmb_layouts_categories'), {
      // vOffset: 20,
    });
  },
  destroyed() {
    this.cmbCategories.destroy();
  },
  methods: {
    ...mapActions('popup', ['openPopup']),
    ...mapActions('layout', {
      getLayouts: 'list',
    }),
  },
  components: {
    New,
  },
};
</script>
