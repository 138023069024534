<template>
  <div class="b2c_item" data-s-amount-item>
    <div class="b2c_item_container">
      <div class="b2c_item_image-container">
        <img
          :src="
            value.thumbnail || value.svg
              ? basePath + (value.thumbnail || value.svg)
              : 'img/misc/no-image.svg'
          "
        />
      </div>
      <div class="b2c_item_content-container">
        <div class="b2c_item_name">
          {{ value.name }}
        </div>
        <button
          class="button v_ghost_brand_light-gray v_is-icon v_smallest a_no-margin a_fill_brand_red"
          @click="$emit('edit', value)"
        >
          <icon :icon-id="'icon_edit'" />
        </button>
        <button
          class="button v_ghost_brand_light-gray v_is-icon v_smallest a_no-margin a_fill_brand_red"
          @click="$refs.delete.open(value)"
        >
          <icon :icon-id="'icon_ui_close'" />
        </button>
      </div>
    </div>
    <delete ref="delete" />
  </div>
</template>
<script>
import config from '@/config';
import Delete from './Delete';

export default {
  components: {
    Delete,
  },
  props: ['value'],
  data() {
    return {
      basePath: config.API_URI.substr(0, config.API_URI.length - 1),
    };
  },
};
</script>
