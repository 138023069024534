/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import _ from 'lodash';
import jQuery from 'jquery';
import config from '@/config';

const module = {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    set(state, payload) {
      state.items = payload;
      state.items.forEach(item => {
        if (!item.offset) {
          item.offset = { x: 0, y: 0 };
        }
      });
    },
    add(state, payload) {
      state.items.push(payload);
    },
    update(state, payload) {
      state.items[
        _.findIndex(state.items, {
          _id: payload._id,
        })
      ] = payload;
    },
    remove(state, payload) {
      state.items = state.items.filter(item => item._id !== payload);
    },
  },
  actions: {
    async read({ commit }, id) {
      try {
        const res = await axios.get(`print-tables/${id}`);
        return res.data;
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    },
    async list({ commit }) {
      try {
        const res = await axios.get('print-tables/');
        commit('set', res.data);
        return res.data;
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    },
    async create({ commit }, object) {
      try {
        const result = await axios.post('print-tables/', object);
        commit('add', result.data);
        return result.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async update({ commit }, object) {
      try {
        if (!object._id) {
          throw new Error('The passed object does not have id.');
        }
        const result = await axios.put(`print-tables/${object._id}`, object);
        commit('update', result.data);
        return result.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async remove({ commit }, id) {
      try {
        const res = await axios.delete(`print-tables/${id}`);
        commit('remove', id);
        return res.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async toPDF({ commit }, payload) {
      try {
        window.open(
          `${config.API_URI}print-tables/${
            payload.printTable._id
          }/export?${jQuery.param({
            token: axios.defaults.headers.common.Authorization,
            cells: payload.designs.map(item =>
              JSON.stringify({
                designId: item._id,
                templateId: item.template._id,
              }),
            ),
            mod: payload.mod,
          })}`,
        );
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    },
  },
};

export default module;
