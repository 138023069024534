var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"b2c_content"},[_c('div',{staticClass:"b2c_content_container a_max-width-2000"},[_c('div',{staticClass:"b2c_content_1-1"},[_c('router-link',{staticClass:"button v_transparent a_text_brand_secondary v_has-icon-left v_smallest a_margin-bottom-40",attrs:{"to":'/designers/pcd-designers'}},[_c('span',{staticClass:"text-container"},[_vm._v("naar het overzicht van designers")]),_c('span',{staticClass:"svg-container"},[_c('icon',{attrs:{"icon-id":'icon_ui_arrow-left'}})],1)]),_c('header',{staticClass:"b2c_header"},[_c('div',{staticClass:"b2c_header_top"},[(_vm.user.b2cId)?_c('div',{staticClass:"b2c_header_top_left"},[_c('div',{staticClass:"b2c_header_switch a_flex a_align-items_center"},[_c('h1',[_vm._v(" "+_vm._s(_vm.user.name)+" "+_vm._s(_vm.user.family)+" "),_c('small',[_vm._v("("+_vm._s(_vm.userId)+")")])])]),_c('p',[_vm._v(" "+_vm._s(_vm.user.state === 'designer' ? 'Designer' : 'geen designer')+" ("+_vm._s(_vm.user.email)+") ")]),(_vm.user.state === 'designer')?_c('a',{attrs:{"href":("" + (_vm.config.DESIGNER_URI) + _vm.userId),"target":"_blank"}},[_vm._v("Naar publieke designer pagina")]):_vm._e()]):_vm._e()])]),_vm._m(0)],1)])]),(_vm.loading)?_c('center',[_c('br'),_c('br'),_c('h4',[_vm._v("Loading ...")])]):_vm._e(),(!_vm.loading)?_c('section',{staticClass:"b2c_search-bar a_margin-top-60"},[_vm._m(1),_c('div',{staticClass:"b2c_search-bar_left"})]):_vm._e(),(!_vm.loading)?_c('section',{staticClass:"b2c_content a_margin-top-40"},[_c('div',{staticClass:"b2c_content_container a_max-width-1000"},[_c('div',{staticClass:"b2c_content_1-1"},[_c('div',{staticClass:"b2c_items",attrs:{"data-s-amount":"4"}},_vm._l((_vm.designs),function(item){return _c('div',{key:item._id,staticClass:"b2c_item",attrs:{"data-s-amount-item":""}},[_c('div',{staticClass:"b2c_item_container"},[_c('div',{staticClass:"b2c_item_image-container"},[_c('img',{attrs:{"src":[
                      _vm.basePath,
                      'uploads/designs',
                      item._id,
                      'thumbnail.jpeg' ].join('/')}})]),_c('div',{staticClass:"b2c_item_content-container"},[_c('div',{staticClass:"b2c_item_name"},[_c('div',{staticClass:"a_margin-bottom-10",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item._id)+" ")]),_c('a',{staticClass:"button v_ghost_brand_light-gray v_is-icon v_smallest a_no-margin a_fill_brand_red",attrs:{"href":(_vm.pcdUri + "?designId=" + (item._id)),"target":"blank"}},[_c('icon',{attrs:{"icon-id":'icon_edit'}})],1),_c('button',{staticClass:"button v_ghost_brand_light-gray v_is-icon v_smallest a_no-margin a_fill_brand_red",on:{"click":function($event){return _vm.$refs.deleteDesign.open(item)}}},[_c('icon',{attrs:{"icon-id":'icon_ui_close'}})],1),_c('a',{directives:[{name:"show",rawName:"v-show",value:(item.submissionState === 'accepted'),expression:"item.submissionState === 'accepted'"}],staticClass:"button v_smallest a_no-margin",attrs:{"href":((_vm.config.GLOBAL_URI) + "&id=" + (item._id)),"target":"blank"}},[_vm._v("maak globaal")]),(item.submissionState === 'accepted')?_c('div',{staticClass:"a_margin-top-10"},[_vm._v(" verkocht: "+_vm._s(item.sales)+"x ")]):_vm._e()])])])])}),0)])])]):_vm._e(),_c('paginator',{attrs:{"page":_vm.$route.params.page,"limit":_vm.$route.query.limit,"total":_vm.totalDesignsCount,"params":{ userId: _vm.$route.params.userId },"route-name":"designers-pcd-designer-detail-paged"}}),(!_vm.loading)?_c('section',{staticClass:"b2c_content a_margin-top-40"},[_c('div',{staticClass:"b2c_content_container a_max-width-1000"},[_c('div',{staticClass:"b2c_content_1-1"},[_c('div',{staticClass:"b2c_items",attrs:{"data-s-amount":"4"}},_vm._l((_vm.approvedDesigns),function(item){return _c('router-link',{key:item._id,staticClass:"b2c_item",attrs:{"to":"design","data-s-amount-item":""}},[_c('div',{staticClass:"b2c_item_container"},[_c('div',{staticClass:"b2c_item_image-container"},[_c('img',{attrs:{"src":[
                      _vm.basePath,
                      'uploads/designs',
                      item._id,
                      'thumbnail.jpeg' ].join('/')}})]),_c('div',{staticClass:"b2c_item_content-container"},[_c('div',{staticClass:"b2c_item_name"},[_vm._v(_vm._s(item.template.name))])])])])}),1)])])]):_vm._e(),_c('delete-design',{ref:"deleteDesign"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown-pane",attrs:{"id":"dropdownSettings","data-dropdown":"","data-position":"bottom","data-alignment":"right","data-close-on-click":"true"}},[_c('ul',{staticClass:"b2c_inline-list"},[_c('li',[_c('button',[_vm._v("Gebruiker blokkeren")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"b2c_search-bar_left"},[_c('h2',{staticClass:"a_margin-bottom-0 a_margin-right-20"},[_vm._v("Recente ontwerpen")])])}]

export { render, staticRenderFns }