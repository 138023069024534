<template>
  <div>
    <page-tabs :links="links" />
    <router-view />
  </div>
</template>
<script>
import PageTabs from '@/components/elements/PageTabs';

export default {
  name: 'Print',
  components: {
    PageTabs,
  },
  data() {
    return {
      links: [
        {
          link: '/designers/pcd-designers',
          name: 'PCD designers',
        },
        {
          link: '/designers/design-submissions',
          name: 'Design inzendingen',
        },
        {
          link: '/designers/contests',
          name: 'Wedstrijden',
        },
        {
          link: '/designers/global-webshop',
          name: 'Globale Designs Webshop',
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped></style>
