

















































import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import axios from 'axios';
import { find } from 'lodash';
import config from '@/config';
import { Job } from '@/types/Job';

export default Vue.extend({
  props: {
    mod: String,
    title: String,
    designs: Array,
    printTable: Object,
    offset: Object,
    debug: Boolean,
    disabled: Boolean,
  },
  data: () => ({
    id: '',
    error: '',
    state: 'initial',
  }),
  computed: {
    ...mapState<Job[]>('queue', { jobs: 'items' }),
    job() {
      // @ts-ignore
      return find(this.jobs, { id: this.id });
    },
    status() {
      if (this.job) {
        return this.job.status;
      } else {
        return this.state;
      }
    },
    icon() {
      switch (this.status) {
        case 'initial':
          return 'icon_ui_arrow-download';
        case 'registration':
          return 'icon_ui_arrow-download';
        case 'waiting':
          return 'icon_ui_arrow-download';
        case 'processing':
          return 'icon_ui_arrow-download';
        case 'failed':
          return 'icon_ui_arrow-download';
        case 'finished':
          return 'icon_ui_arrow-download';
        default:
          return '';
      }
    },
    url() {
      const cells = this.designs.map(design => {
        const output = {
          designId: design._id,
          orderline: design.orderline,
          device: this.device(design.template) || undefined,
          location: design.template.location,
        } as any;
        if (design._overidedTemplate) {
          output.templateId = design.template._id;
        }
        return JSON.stringify(output);
      });
      // eslint-disable-next-line no-undef
      const params = jQuery.param({
        token: axios.defaults.headers.common.Authorization,
        cells,
        mod: this.mod,
        offsetX: this.offset.x,
        offsetY: this.offset.y,
        debug: this.debug,
      });
      return `${config.API_URI}print-tables/${this.printTable._id}/export?${params}`;
    },
  },
  async mounted() {
    await this.connect();
  },
  methods: {
    ...mapActions('queue', ['connect', 'waitForJob']),
    device(template) {
      // template = this.templates.find(_template => _template._id === template._id);
      if (!template || !template.device || !template.device.category) {
        // console.warn(template._id, 'has no category and device name');
        return '';
      }
      return `${template.device.category.name} - ${template.device.name} - `;
    },
    async onClick() {
      switch (this.status) {
        case 'initial':
          await this.registerJob();
          break;
        case 'finished':
          Object.assign(document.createElement('a'), {
            target: '_blank',
            href: this.job.returnvalue.url,
          }).click();
          this.id = '';
          this.state = 'initial';
          break;
        default:
          return alert('Wait please ...');
      }
    },
    async registerJob() {
      this.state = 'registration';
      try {
        const { id } = await fetch(this.url).then(res => res.json());
        this.id = id;
        this.waitForJob(id);
        this.$forceUpdate();
      } catch (error) {
        alert(error);
      }
    },
  },
});
