<template>
  <div>
    <page-title
      :title="'Amazon Orders'"
      :icon="'icon_assets'"
      description="Verkrijg de afbeelding van een Amazon Product Image Bestelling door hier de SVG te uploaden die te downloaden is in sellercentral"
    />

    <section class="b2c_content a_margin-top-40">
      <div class="b2c_content_container a_max-width-800">
        <label>
          SKU
          <input v-model="sku" name="SKU" type="text" placeholder="SKU" />
        </label>
        <button
          :disabled="!sku"
          class="button v_ghost_brand_secondary v_expanded v_smaller"
          @click="fetchProductDetails"
        >
          Load
        </button>
        <hr />
        <h3 v-if="productDetails.templateId">
          <small>Image Extractor</small>
        </h3>
        <div v-if="productDetails.templateId" class="b2c_form">
          <div class="b2c_form_left amazon-svg-container">
            <div
              ref="svg"
              class="b2c_form_left_img amazon-svg-wrapper"
              v-html="images.svg"
            />
            <img :src="overlayImage" class="amazon-svg-overlay" />
          </div>
          <div class="b2c_form_right">
            <h4>
              <small>Upload de SVG</small>
            </h4>
            <label
              for="upload_svg"
              class="button v_ghost_brand_secondary v_expanded v_smaller"
              >Upload SVG</label
            >
            <input
              id="upload_svg"
              type="file"
              accept=".svg, .SVG"
              class="show-for-sr"
              @change="
                setSVG($event.target.files[0]);
                $event.target.value = '';
              "
            />
            <a
              v-if="images.svg"
              class="button v_ghost_brand_secondary v_expanded v_smaller"
              @click="download"
              >Download afbeelding</a
            >
            <a
              v-show="templateUrl"
              :href="templateUrl"
              target="_blank"
              class="button v_ghost_brand_secondary v_expanded v_smaller"
              >Laad template in designer</a
            >
            <p v-show="!images.svg">
              <small>Nog geen SVG geselecteerd</small>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
/* eslint-disable no-var */
import axios from 'axios';
import download from 'downloadjs';
import { mapActions } from 'vuex';
import config from '@/config';
import PageTitle from '@/components/elements/PageTitle';
export default {
  data: () => {
    return {
      /**
       * @type {File}
       */
      file: undefined,
      sku: '',
      config,
      productDetails: {
        designId: '',
        templateId: '',
      },
      images: {
        svg: undefined,
      },
    };
  },
  computed: {
    overlayImage() {
      return this.productDetails.designId
        ? `${this.config.API_URI}/designs/${this.productDetails.designId}/preview?size=400&mod=overlay&fold=open&templateId=${this.productDetails.templateId}`
        : `${this.config.API_URI}/templates/${this.productDetails.templateId}/preview?fold=open&mod=overlay&size=400`;
    },
    templateUrl() {
      if (!this.productDetails && this.productDetails.templateId) {
        return false;
      }
      return `${this.config.PCD_URI}?templateId=${this.productDetails.templateId}`;
    },
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    download() {
      const image = window
        .$(this.images.svg)
        .find('image')
        .attr('xlink:href');
      if (image) {
        const type = image.split(';base64')[0].replace('data:', '');
        switch (type) {
          case 'image/jpeg':
            var extention = '.jpg';
            break;
          case 'image/png':
            // eslint-disable-next-line no-redeclare
            var extention = '.png';
            break;
        }
        download(image, this.file.name + extention, type);
      } else {
        alert('no image found inside the SVG');
      }
    },
    setSVG(file) {
      if (!file) {
        return;
      }
      this.file = file;
      this.setLoading(true);
      const reader = new FileReader();
      reader.readAsText(file, 'utf8');
      // debugger;
      reader.onload = () => {
        this.images.svg = reader.result;
        this.setLoading(false);
        this.$forceUpdate();
      };
    },
    async fetchProductDetails() {
      try {
        const skuData = await axios.get(`${config.AUTH_URI}/ean/${this.sku}`);
        if (skuData && skuData.data && skuData.data.length) {
          this.productDetails = {
            templateId: skuData.data[0].templateID,
            designId: skuData.data[0].designID,
          };
        } else {
          alert('Issue while fetching SKU data');
        }
        // await axios.get(this.surfaceImage);
        // await axios.get(this.overlayImage);
      } catch (error) {
        console.log(error);
        this.productDetails.templateId = undefined;
        setTimeout(() => {
          alert('Product not found !');
        }, 1);
      }
    },
  },
  components: {
    PageTitle,
  },
};
</script>
<style>
.amazon-svg-container {
  position: relative;
}
.amazon-svg-overlay {
  position: absolute;
  top: 0;
}
.amazon-svg-wrapper,
.amazon-svg-wrapper svg {
  background-color: grey;
  width: 400px !important;
  height: 400px !important;
}
</style>
