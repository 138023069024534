const modal = {
  namespaced: true,
  state: {
    opened: '',
    modalData: {},
    modalCallbacks: {},
  },
  getters: {},
  mutations: {
    open(state, payload) {
      state.opened = payload;
    },
    close(state) {
      state.opened = false;
    },
    setModalData(state, payload) {
      state.modalData[payload.name] = payload.data;
    },
    setModalCallback(state, payload) {
      state.modalCallbacks[payload.name] = payload.callback;
    },
  },
  actions: {
    openModal({ commit }, payload) {
      if (typeof payload === 'string') {
        commit('open', payload);
      } else {
        // if object
        commit('open', payload.name);
        commit('setModalData', payload);
        if (payload.callback) {
          commit('setModalCallback', payload);
        }
      }
    },
    closeModal({ commit }) {
      commit('close');
    },
  },
};

export default modal;
