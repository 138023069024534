

































































































import Vue from 'vue';
import config from '@/config';
import { mapActions, mapState } from 'vuex';
import { find } from 'lodash';
import PageTitle from '@/components/elements/PageTitle.vue';
import { Job } from '@/types/Job';
import mapJobStates from '@/utils/map-job-states.js';

export default Vue.extend({
  data() {
    return {
      basePath: config.API_URI.substr(0, config.API_URI.length - 1),
      storageUrl: config.PRINT_STORAGE_URL,
      mapJobStates,
    };
  },
  async mounted() {
    await this.connect();
  },
  computed: {
    ...mapState<Job[]>('queue', { jobs: 'items' }),
    job() {
      // @ts-ignore
      return find(this.jobs, { id: this.$route.params.jobId });
    },
  },
  methods: {
    ...mapActions('queue', ['connect']),
  },
  components: {
    PageTitle,
  },
});
