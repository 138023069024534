<template>
  <div>
    <page-title
      :title="'Categorieën'"
      :icon="'icon_templates'"
      :description="'Beheer de categorieën van de templates.'"
    />
    <!-- 
            
            Onderstaand search bar sectie is een tweetraps raket. In de eerste stap vraag je de hoofdcategorie uit (merk), op basis van een selectie kan je toestellen inladen. 
            
            In de de dropdown zouden eerst meest gezochte items kunnen staan. Na een user input zou dit een gefilterde selectie van resultaten kunnen zijn. Dit geldt ook voor de toestellen.
            
            Als nog geen user data is ingegeven kan je een nieuwe categorie aanmaken. Na een categorie selectie kan de knop 'Nieuw toestel' worden. Zo kan je aan beide levels een item toevoegen.

            De selectie kan gereset worden door de X te klikken.


        -->
    <search-bar
      ref="searchBar"
      :categories="categories"
      :devices="devices"
      @categoryChanged="
        category = $event;
        resetDevice({});
      "
      @deviceChanged="resetDevice($event)"
    />
    <!-- 

            Na een selectie van categorie en toestel verschijnt de onderstaande balk, met daarin het resultaat van de selectie (Merk en toestel). Hieronder komen vervolgens de variaties te staan. 

            Dit kunnen bestaande variaties zijn, of een nieuwe, indien er nog geen variatie bestaat.

         -->
    <section v-if="device" class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <h2 class="a_margin-bottom-0 a_margin-right-20">{{ category.name }}</h2>
        <p class="a_margin-bottom-0">{{ device.name }}</p>
      </div>
      <div class="b2c_search-bar_left">
        <button
          :disabled="!device._id"
          class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0 a_margin-right-10"
          @click="resetDevice({})"
        >
          Nieuw Device
        </button>
        <button
          class="button v_brand_secondary v_smaller a_margin-bottom-0"
          @click="$refs.device.save()"
        >
          Opslaan
        </button>
      </div>
    </section>
    <device
      v-if="device"
      ref="device"
      :value="duplicateDevice"
      :category="category"
      @deleted="deletedDevice"
    />
    <!-- DEVICE CATEGORY MODALS -->
    <delete-device-category ref="deleteDeviceCategory" />
    <!-- DEVICE MODALS -->
    <delete-device ref="deleteDevice" />
  </div>
</template>
<script>
import _ from 'lodash';
import { mapActions, mapState } from 'vuex';
import PageTitle from '@/components/elements/PageTitle';
import DeleteDeviceCategory from '../device-categories/Delete';
import SearchBar from './SearchBar';
import DeleteDevice from './Delete';
import Device from './Device';

export default {
  data() {
    return {
      category: '',
      device: '',
      editingDevice: '',
    };
  },
  async created() {
    this.fetchData();
  },
  computed: {
    ...mapState('deviceCategory', {
      categories: 'items',
    }),
    ...mapState('device', {
      devices: 'items',
    }),
    duplicateDevice() {
      return _.clone(this.device);
    },
  },
  methods: {
    ...mapActions('popup', ['openPopup']),
    ...mapActions('deviceCategory', {
      getCategories: 'list',
    }),
    ...mapActions('device', {
      getDevices: 'list',
    }),
    async fetchData() {
      try {
        await Promise.all([this.getCategories(), this.getDevices()]);
      } catch (e) {
        this.openPopup({
          header:
            'Er was een probleem bij het ophalen van Categorieën and devices',
          message: e.message,
        });
      }
    },
    resetDevice(value) {
      // eslint-disable-next-line
      if (
        this.device &&
        Object.keys(this.device).length > 0 &&
        !confirm('Je onopgeslagen data kan verloren gaan. Weet je het zeker?')
      ) {
        return;
      }
      this.device = value;
    },
    async deletedDevice() {
      this.device = null;
      this.$refs.searchBar.clear();
      await this.fetchData();
    },
  },
  components: {
    PageTitle,
    SearchBar,
    DeleteDeviceCategory,
    DeleteDevice,
    Device,
  },
};
</script>

<style scoped lang="scss">
.delete_category {
  color: red;
  cursor: pointer;
}
</style>
