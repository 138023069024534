<template>
  <div>
    <section v-if="templates" class="b2c_order-list a_margin-top-20">
      <div class="b2c_order-list_container">
        <div class="b2c_order-list_header">
          <div data-t="header-naam">Template:</div>
          <div data-t="header-category">Variatie:</div>
          <div data-t="header-id">ID</div>
          <div data-t="header-btn" />
        </div>
        <div
          v-for="item in templates"
          v-show="
            showTemplatesWithoutFullMockups ? item.mockups.length !== 2 : true
          "
          :key="item._id"
          :class="{ s_available: item.mockups.length !== 2 }"
          class="b2c_order-list_item"
        >
          <div class="b2c_order-list_item_top">
            <div data-t="item-naam">
              <input
                :checked="isChecked(item)"
                :disabled="
                  !item.mockups ||
                    (item.mockups.length === 2 && !isChecked(item))
                "
                type="checkbox"
                class="a_margin-bottom-0 a_margin-right-10"
                @change="changeCheck($event.target.checked, item)"
              />
              <img
                :src="
                  [
                    basePath,
                    'uploads/templates',
                    item._id,
                    'thumbnail.jpeg',
                  ].join('/')
                "
                width="50"
                height="50"
                alt=""
              />
              <span>{{
                `${item.device &&
                  item.device.category &&
                  item.device.category.name} ${item.device &&
                  item.device.name} ${item.name} `
              }}</span>
            </div>
            <div data-t="item-category">{{ item.varianceName }}</div>
            <div data-t="item-state">
              <router-link
                :to="`/templates/templates?id=${item._id}`"
                class="v_ghost_brand_primary v_smallest a_no-margin"
                >{{ item._id }}</router-link
              >
              <!-- <span :class="{ s_pending: item.state }" class="b2c_order-list_item_state"></span>
                            <span v-if="item.state">Pending</span>
                            <span v-else>Geprint</span>-->
            </div>
            <div data-t="item-btn">
              <a
                :href="
                  `${designExporter}mockup/?mockupId=${mockupId}&templateId=${
                    item._id
                  }${forceRendering ? '&force=true' : ''}${
                    designId ? '&designId=' + designId : ''
                  }`
                "
                target="_blank"
                class="button v_ghost_brand_secondary v_is-icon v_icon-size-24 v_smallest a_no-margin"
                >Preview op mockup</a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import config from '@/config';

export default {
  props: [
    'templates',
    'mockupId',
    'forceRendering',
    'designId',
    'showTemplatesWithoutFullMockups',
  ],
  computed: {
    basePath() {
      return config.API_URI.substr(0, config.API_URI.length - 1);
    },
    designExporter() {
      return config.DESIGN_EXPORTER;
    },
    sortedTemplates() {
      return [];
    },
  },
  methods: {
    ...mapActions('template', {
      updateTemplate: 'update',
    }),
    ...mapActions('popup', ['openPopup']),
    isChecked(template) {
      return (
        template.mockups &&
        template.mockups.length &&
        template.mockups.includes(this.mockupId)
      );
    },
    async changeCheck(active, template) {
      console.log(template.mockups);
      if (active) {
        if (
          template.mockups.length < 2 &&
          !template.mockups.includes(this.mockupId)
        ) {
          template.mockups.push(this.mockupId);
        } else {
          this.openPopup({
            header: 'Maximum bereikt',
            message: 'Het maximum aantal mockups voor deze template is bereikt',
          });
        }
      } else {
        const index = template.mockups.indexOf(this.mockupId);
        if (index !== -1) {
          template.mockups.splice(index, 1);
        }
      }
      try {
        console.log(template);
        await this.updateTemplate(template);
      } catch (e) {
        this.openPopup({
          header:
            'Er is een probleem ontstaan tijdens het updaten van de Template',
          message: e.message,
        });
      }
    },
  },
  name: 'TemplatesList',
};
</script>

<style lang="scss">
.s_available {
  border: 3px solid orange !important;
}

$columns: (
  naam: (
    width: 40%,
  ),
  category: (
    width: 20%,
  ),
  id: (
    width: 20%,
  ),
  btn: (
    width: 20%,
  ),
);
@mixin generate-columns {
  @each $label, $column in $columns {
    [data-t='header-#{$label}'],
    [data-t='item-#{$label}'] {
      padding: 0 $default-padding / 2;
      flex: 0 0 map-get($column, width);
      max-width: map-get($column, width);
    }
  }
}
@include generate-columns();
[data-t='header-btn'],
[data-t='item-btn'] {
  text-align: right;
  white-space: nowrap;
}
</style>
