<template>
  <modal
    :open.sync="modal.open"
    :loading.sync="modal.loading"
    :error-message.sync="modal.errorMessage"
    :success-message.sync="modal.successMessage"
    :header="'Re-render designs'"
    :sub-header="`Re-render alle globals met ${type}.`"
    :action-button-text="'Start Render'"
    @action="
      $emit('render', { delay, force });
      modal.open = false;
    "
  >
    <p v-html="message" />
    <label for="globalRenderModalCheckbox">
      <input id="globalRenderModalCheckbox" v-model="delay" type="checkbox" />
      Stel het renderen uit tot middernacht.
    </label>
    <label for="globalRenderForceModalCheckbox">
      <input
        id="globalRenderForceModalCheckbox"
        v-model="force"
        type="checkbox"
      />
      Forceer alle renders.
    </label>
  </modal>
</template>
<script>
import Modal from '@/components/elements/Modal';

export default {
  name: 'ModalGlobalRender',
  components: {
    Modal,
  },
  props: ['type'],
  data() {
    return {
      modal: {
        open: false,
        loading: false,
        done: false,
        successMessage: '',
        errorMessage: '',
      },
      delay: true,
      force: true,
      message: '',
    };
  },
  methods: {
    open(message) {
      this.message = message;
      this.modal.open = true;
    },
  },
};
</script>
