












































































































































import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import { uniq } from 'lodash';
import { Job } from '@/types/Job';
import mapJobStates from '@/utils/map-job-states.js';

export default Vue.extend({
  data() {
    return {
      mapJobStates,
      issuer: '',
    };
  },
  async mounted() {
    // eslint-disable-next-line no-undef
    this.dropdownIssuers = new (window as any).Foundation.Dropdown(
      // eslint-disable-next-line no-undef
      $('#dropdownIssuers'),
    );
    this.issuer = this.decodedToken?.email;
    await this.connect();
  },
  destroyed() {
    this.dropdownIssuers.destroy();
  },
  computed: {
    ...mapState<Job[]>('queue', { jobs: 'items' }),
    ...mapGetters('auth', ['decodedToken']),
    issuers() {
      return this.jobs
        ? uniq(this.jobs.map(({ data }) => data.requestedBy))
        : [];
    },
    filteredJobs() {
      if (this.issuer) {
        return this.jobs.filter(job => job.data.requestedBy === this.issuer);
      } else {
        return this.jobs;
      }
    },
  },
  methods: {
    ...mapActions('queue', ['connect', 'retry', 'cancel']),
    async retryJob(job: Job) {
      // @ts-ignore
      job.retrying = true;
      try {
        await this.retry(job.id);
      } catch (error) {
        alert(error);
      }
      // @ts-ignore
      job.retrying = false;
    },
    async cancelJob(job: Job) {
      // @ts-ignore
      job.canceling = true;
      try {
        await this.cancel(job.id);
      } catch (error) {
        alert(error);
      }
      // @ts-ignore
      job.canceling = false;
    },
  },
});
