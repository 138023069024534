<template>
  <div>
    <page-title
      :title="'Bestellingen'"
      :icon="'icon_orders'"
      :description="
        'Bestellingen vanuit de webshop en de mogelijkheid om deze toe te voegen aan een print tafel.'
      "
    />

    <section
      v-show="active === 'auto-generate'"
      class="b2c_search-bar a_margin-top-60"
    >
      <div class="b2c_search-bar_left">
        <h2 class="a_margin-bottom-0 a_margin-right-10">Bestellingen</h2>
        <div v-if="!loading" class="b2c_pill">{{ designs.length }} designs</div>
      </div>
      <div class="b2c_search-bar_left">
        <button
          class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0 a_margin-right-10"
          @click="setActive('self-select')"
        >
          Stel tafel samen
        </button>
        <button
          class="button v_brand_secondary v_smaller a_margin-bottom-0"
          @click="$refs.composer.open(designs)"
        >
          Auto genereer print tafels
        </button>
      </div>
    </section>
    <center v-if="loading">
      <br />
      <br />
      <h4>Loading ...</h4>
      <hr />
    </center>

    <section
      v-show="active === 'self-select'"
      class="b2c_search-bar a_margin-top-60"
    >
      <div class="b2c_search-bar_left">
        <label class="a_margin-bottom-0 a_margin-right-20">filter:</label>
        <select
          v-model="selectedPrintTable"
          class="a_margin-bottom-0 a_margin-right-10"
          style="min-width: 160px;"
        >
          <option
            v-for="(item, index) in printTables"
            :key="index"
            :value="item._id"
            >{{ item.name }}</option
          >
        </select>

        <input
          type="text"
          placeholder="Zoek order"
          class="a_margin-bottom-0 a_margin-right-10"
        />

        <button
          class="button v_brand_secondary v_is-icon v_smaller a_no-margin"
          @click="setActive('auto-generate')"
        >
          <icon :icon-id="'icon_ui_close'" />
        </button>
      </div>
      <div class="b2c_search-bar_left">
        <!-- <button @click="setActive('generated-tables')" class="button v_brand_secondary v_smaller a_margin-bottom-0"> -->
        <button
          class="button v_brand_secondary v_smaller a_margin-bottom-0"
          @click="$refs.composer.open(designs.filter(item => item._selected))"
        >
          Genereer print tafels
        </button>
      </div>
    </section>

    <section
      v-show="active === 'generated-tables'"
      class="b2c_search-bar a_margin-top-60"
    >
      <div class="b2c_search-bar_left">
        <h2 class="a_margin-bottom-0">Gegenereerde tafels</h2>
        <p class="a_margin-bottom-0 a_margin-left-10 a_margin-right-20">
          Op basis van 18 orders
        </p>

        <button
          class="button v_brand_secondary v_is-icon v_smaller a_no-margin"
          @click="setActive('auto-generate')"
        >
          <icon :icon-id="'icon_ui_close'" />
        </button>
      </div>
      <div class="b2c_search-bar_left" />
    </section>
    <list
      v-if="!loading"
      v-show="active === 'auto-generate' || active === 'self-select'"
      v-model="designs"
      :templates="templates"
      :print-table="selectedPrintTable"
      :active.sync="active"
      :show-plus="false"
    />
    <print-table-arrangements
      v-show="active === 'generated-tables'"
      v-model="printTableArraangements"
    />
    <composer-dialog ref="composer" @arranged="onArranged" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import PageTitle from '@/components/elements/PageTitle';
import List from './partials/List';
import PrintTableArrangements from './partials/PrintTableArrangements';
import ComposerDialog from './ComposerDialog';

export default {
  data() {
    return {
      loading: true,
      active: 'auto-generate',
      printTableArraangements: [],
      selectedPrintTable: '',
      designs: [],
      // designs: [
      //     {
      //         _id: 'uietgusyfgtsukfbsufkssu',
      //         template: '5a43345f1457d80b68644c8a',
      //         name: 'Design 1',
      //         imgsrc: 'img/covers/cover-1.png',
      //     },
      // ],
    };
  },
  watch: {
    selectedPrintTable() {
      this.designs.forEach(item => {
        item._selected = false;
      });
    },
  },
  async mounted() {
    try {
      /** @type { { designId: string; templateId: string; orderline: string; order: string; }[]; } } */
      const queryList = JSON.parse(this.$route.query.list);
      if (!_.isArray(queryList)) {
        throw new Error('Wrong Input: list in not an array');
      }
      console.table(queryList);
      const deletedTemplates = [];
      const deletedDesigns = [];
      queryList.forEach(item => {
        if (!item.designId) {
          deletedDesigns.push(item);
        }
      });
      const list = queryList.filter(item => item.designId);
      const templateOveridedOrders = list.filter(item =>
        _.isString(item.templateId),
      );

      // loading templates
      let overTemplates = [];
      if (templateOveridedOrders.length > 0) {
        overTemplates = await this.getTemplates({
          limit: 200,
          filters: [
            {
              column: '_id',
              operator: 'in',
              values: templateOveridedOrders.map(item => item.templateId),
            },
          ],
          relations: [
            {
              path: 'device',
              populate: {
                path: 'category',
              },
            },
          ],
        });
      }
      // https://stijlbreuk.slack.com/archives/G7L32GGBW/p1572509834002100
      // make it that deleted templates are detected? And that a popup is shown
      list.forEach((item, index) => {
        if (!item.templateId) {
          return;
        }
        const template = _.find(overTemplates, {
          _id: item.templateId,
        });
        if (!template) {
          item.index = index;
          deletedTemplates.push(item);
        }
      });
      deletedTemplates.forEach(item => _.pull(list, item));
      // loading designs
      const designs = await this.getDesigns({
        limit: 200,
        filters: [
          {
            column: '_id',
            operator: 'in',
            values: list.map(item => item.designId),
          },
        ],
        relations: [
          {
            path: 'template',
            populate: {
              path: 'device',
              populate: {
                path: 'category',
              },
            },
          },
        ],
      });
      // https://stijlbreuk.slack.com/archives/G7L32GGBW/p1572509834002100
      // make it that deleted designs are detected? And that a popup is shown
      list.forEach((item, index) => {
        const design = _.find(designs, {
          _id: item.designId,
        });
        if (!design) {
          item.index = index;
          deletedDesigns.push(item);
        }
      });
      deletedDesigns.forEach(item => _.pull(list, item));
      // mapping override templates to designs
      this.designs = list.map(item => {
        const design = _.clone(
          _.find(designs, {
            _id: item.designId,
          }),
          true,
        );
        design.orderline = item.orderline;
        design.order = item.order ? parseInt(item.order) : 0;
        if (item.templateId && design.template._id !== item.templateId) {
          design.template = overTemplates.find(_template => {
            if (_template._id === item.templateId) {
              design._overidedTemplate = true;
              return _template;
            }
          });
        }
        return design;
      });
      const errors = [].concat(
        deletedDesigns.map(
          item =>
            `💀 De volgende <strong>design</strong> is verwijderd: <strong>${
              item.templateId ? item.templateId : ''
            }</strong> met ordernummer: "<strong>${
              item.orderline
            }</strong>" en designId "<strong>${item.designId}</strong>"`,
        ),
        deletedTemplates.map(
          item =>
            `💀 De volgende <strong>template</strong> is verwijderd: <strong>${
              item.templateId ? item.templateId : ''
            }</strong> met ordernummer: "<strong>${
              item.orderline
            }</strong>" en designId "<strong>${item.designId}</strong>"`,
        ),
      );
      if (errors.length > 0) {
        throw new Error(errors.join('<br/>'));
      }
    } catch (e) {
      console.error(e);
      this.openPopup({
        header: 'Er was een probleem bij het ophalen van Templates',
        message: e.message,
      });
    }
    this.loading = false;
  },
  computed: {
    ...mapState('printTable', {
      printTables: 'items',
    }),
    // ...mapState('design', { designs: 'items' }),
    ...mapState('template', {
      templates: 'items',
    }),
  },
  methods: {
    ...mapActions('modal', ['openModal']),
    ...mapActions('popup', ['openPopup']),
    ...mapActions('printTable', {
      getPrintTables: 'list',
    }),
    ...mapActions('design', {
      getDesigns: 'list',
    }),
    ...mapActions('template', {
      getTemplates: 'list',
    }),
    setActive(slug) {
      this.active = slug;
    },
    setOrderActive(item) {
      if (!item.orderIsActive) {
        item.orderIsActive = true;
      } else {
        item.orderIsActive = false;
      }
    },
    onArranged(sets) {
      this.setActive('generated-tables');
      this.printTableArraangements = sets;
    },
  },
  components: {
    PageTitle,
    List,
    PrintTableArrangements,
    ComposerDialog,
  },
};
</script>
