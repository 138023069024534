<template>
  <div class="pad">
    <div class="content">
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.pad {
  background-color: #ffffff;
  display: inline-block;
  padding: 32px;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  margin: 6px;
  .content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
