<template>
  <section class="b2c_order-list a_margin-top-60">
    <div class="b2c_order-list_container">
      <div class="b2c_order-list_header">
        <div data-t="header-date">Datum:</div>
        <div data-t="header-naam">Template:</div>
        <div data-t="header-category">Variatie:</div>
        <!-- <div data-t="header-state">Status:</div> -->
        <div data-t="header-btn" />
      </div>

      <div
        v-for="(item, index) in sorted"
        v-show="!printTable || item.template.printTable === printTable"
        :key="`list-item-${index}`"
        :class="{
          s_active: item.orderIsActive,
          necessary_edit: !item.onTheActiveVariance,
        }"
        class="b2c_order-list_item"
      >
        <div class="b2c_order-list_item_top">
          <div data-t="item-date">{{ item.createdAt | formatDate }}</div>
          <div data-t="item-naam">
            <input
              v-show="active === 'self-select'"
              v-model="item._selected"
              type="checkbox"
              class="a_margin-bottom-0 a_margin-right-10"
            />

            <img
              :src="
                [basePath, 'uploads/designs', item._id, 'thumbnail.jpeg'].join(
                  '/',
                )
              "
              width="50"
              height="50"
              alt
            />
            {{ device(item.template) }}{{ item.template.name }}
          </div>
          <div data-t="item-category">{{ item.template.varianceName }}</div>
          <!-- <div data-t="item-state">
                        <span :class="{ s_pending: item.state }" class="b2c_order-list_item_state"></span>
                        <span v-if="item.state">Pending</span>
                        <span v-else>
                            -
                        </span>
					</div>-->
          <div data-t="item-btn">
            <a
              v-if="!item._overidedTemplate"
              :href="`${pcdUri}?designId=${item._id}&adminEdit=true`"
              target="_blank"
              class="button v_ghost_brand_secondary v_is-icon v_icon-size-24 v_smallest a_no-margin"
            >
              <icon :icon-id="'icon_edit'" />
            </a>
            <button
              v-show="!item.orderIsActive && showPlus"
              class="button v_transparent a_fill_brand_secondary v_is-icon v_icon-size-20 v_smallest a_margin-bottom-0 a_margin-left-10"
              @click="setOrderActive(item)"
            >
              <icon :icon-id="'icon_plus'" />
            </button>
            <button
              v-show="item.orderIsActive"
              class="button v_transparent a_fill_brand_secondary v_is-icon v_icon-size-20 v_smallest a_margin-bottom-0 a_margin-left-10"
              @click="setOrderActive(item)"
            >
              <icon :icon-id="'icon_minus'" />
            </button>
          </div>
        </div>
        <div class="b2c_order-list_item_middle">
          <div class="b2c_order-list_item_middle_img">
            <img src="img/covers/cover-1.png" />
          </div>
          <div class="b2c_order-list_item_middle_meta">
            iPhone 7 - TPU Case <br />Aantal: 1
            <div
              class="b2c_order-list_item_middle_meta_address a_margin-top-20"
            >
              <div class="b2c_order-list_item_middle_meta_address_left">
                <b>Factuuradres</b>
                <br />Jacqueline van den Heuvel <br />Hasseltplein 14 <br />5042
                AB Tilburg
              </div>
              <div class="b2c_order-list_item_middle_meta_address_right">
                <b>Afleveradres</b>
                <br />Jacqueline van den Heuvel <br />Hasseltplein 14 <br />5042
                AB Tilburg
              </div>
            </div>
          </div>
        </div>
        <div class="b2c_order-list_item_bottom">
          <button
            class="button v_ghost_brand_secondary v_smaller v_has-icon-right a_margin-bottom-0 a_margin-right-10"
          >
            Download PDF
            <icon :icon-id="'icon_ui_arrow-download'" />
          </button>
          <button
            class="button v_ghost_brand_secondary v_smaller v_has-icon-right a_margin-bottom-0 a_margin-right-10"
          >
            Download witte PDF
            <icon :icon-id="'icon_ui_arrow-download'" />
          </button>

          <label class="a_margin-bottom-0 a_margin-left-20 a_margin-right-20"
            >Variatie:</label
          >
          <select class="a_margin-bottom-0 a_margin-right-20">
            <option value="1">1 - default</option>
            <option value="2">2 - 201709</option>
            <option value="3">3 - 201710</option>
            <option value="4">4 - 201711</option>
          </select>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import config from '@/config';

export default {
  filters: {
    formatDate(rawDate) {
      function lead(str) {
        if (+str < 10) {
          return `0${str}`;
        }
        return str;
      }
      const date = new Date(rawDate);
      return `${lead(date.getDate())}-${lead(
        date.getMonth() + 1,
      )}-${date.getFullYear()} ${date.getHours()}:${lead(date.getMinutes())}`;
    },
  },
  props: ['value', 'templates', 'active', 'showPlus', 'printTable'],
  data() {
    return {
      selectedItems: [],
    };
  },
  computed: {
    basePath() {
      return config.API_URI.substr(0, config.API_URI.length - 1);
    },
    pcdUri() {
      return config.PCD_URI;
    },
    sorted() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return []
        .concat(this.value)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
  },
  methods: {
    device(template) {
      if (!template) {
        console.log(this);
      }
      template = this.templates.find(
        _template => _template._id === template._id,
      );
      if (!template || !template.device || !template.device.category) {
        // console.warn(template._id, 'has no category and device name');
        return '';
      }
      return `${template.device.category.name} - ${template.device.name} - `;
    },
  },
};
</script>

<style scoped lang="scss">
.necessary_edit {
  border: 1px solid #f22323;
  border-radius: 6px;
}

$columns: (
  date: (
    width: 20%,
  ),
  naam: (
    width: 65%,
  ),
  category: (
    width: 10%,
  ),
  btn: (
    width: 5%,
  ),
);
@mixin generate-columns {
  @each $label, $column in $columns {
    [data-t='header-#{$label}'],
    [data-t='item-#{$label}'] {
      padding: 0 $default-padding / 2;
      flex: 0 0 map-get($column, width);
      max-width: map-get($column, width);
    }
  }
}
@include generate-columns();
[data-t='header-btn'],
[data-t='item-btn'] {
  text-align: right;
  white-space: nowrap;
}
</style>
