<template>
  <div
    id="app"
    :class="{ s_show_topbar: showingTopbar, s_show_sidebar: showingSidebar }"
  >
    <symbols />

    <popup />
    <modal-login />

    <top-bar v-if="showingTopbar" />
    <side-bar v-if="showingSidebar" />

    <div
      :class="{ s_fullscreen: fullScreen }"
      class="b2c_main-container"
      role="main"
    >
      <router-view />

      <div style="height: 40vh" />
    </div>
    <loading :loading="loading" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import ModalLogin from '@/components/elements/ModalLogin';
import Popup from '@/components/elements/Popup';
import Loading from '@/components/elements/Loading';

import SideBar from '@/components/parts/SideBar';
import Symbols from '@/components/parts/Symbols';
import TopBar from '@/components/parts/TopBar';

export default {
  name: 'App',
  mounted() {
    // eslint-disable-next-line
    Foundation.Keyboard.handleKey = function an() {};
    // fixes issue with foundation dropdown on space
    // Foundation.Keyboard.register('Dropdown', {
    //     ENTER: '',
    //     SPACE: '',
    // });
  },
  methods: {
    ...mapActions('module', ['getSomething']),
  },
  computed: {
    ...mapState('router', ['fullScreen', 'showingSidebar', 'showingTopbar']),
    ...mapState('loading', ['loading']),
  },
  components: {
    ModalLogin,
    SideBar,
    Symbols,
    TopBar,
    Popup,
    Loading,
  },
};
</script>

<style lang="scss">
@import './assets/styles/fonts';

@include foundation-global-styles;
@include foundation-typography;

@include foundation-forms;
@include foundation-button;
@include foundation-dropdown;
@include foundation-switch;
@include foundation-visibility-classes;

@import './assets/styles/components';
@import './assets/styles/structure';

@import './assets/styles/adjustments';
@import './assets/styles/brand-adjustments';

.b2c_main-container {
  &.s_fullscreen {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    padding: 0;
  }
}
</style>
