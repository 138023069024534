<template>
  <div>
    <section class="b2c_content a_margin-top-40">
      <div class="b2c_content_container a_max-width-800">
        <div class="b2c_content_1-1">
          <label
            >Productnaam
            <input
              v-model="value.name"
              type="text"
              placeholder="Productnaam van het device, bijv iPhone 8"
            />
          </label>
          <!-- <hr>
                    <h3><small>Device afbeeldingen</small></h3>
                    <p>Afbeelding moet 500px bij 500px zijn. Device lijnt links links van de afbeelding uit en wordt volledig uitgevuld.</p>
                    <hr>
                    <h3><small>Device voorkant</small></h3>
                    <div class="b2c_form">
                        <div class="b2c_form_left">
                            <div class="b2c_form_left_img">
                                <img :src="images.frontFile" alt="device_front">
                            </div>
                        </div>
                        <div class="b2c_form_right">
                            
                            <label for="upload_img_device_front" class="button v_ghost_brand_secondary v_expanded v_smaller">Upload afbeelding</label>
                            <input type="file" @change="setFile($event.target.files, 'frontFile')" id="upload_img_device_front" class="show-for-sr">
                            
                            <p><small>Nog geen foto geselecteerd</small></p>

                            <h4><small>Fysieke grootte</small></h4>
                            <p><small>De fysieke grootte wordt in pixels meegegeven.</small></p>

                            <div class="a_flex a_align-items_center">
                                <label>
                                    Breedte:
                                    <input type="number" v-model="value.frontWidth" placeholder="Breedte in px">
                                </label>
                                <span class="a_margin-left-10"><small>PX</small></span>
                            </div>

                            <div class="a_flex a_align-items_center">
                                <label>
                                    Hoogte:
                                    <input type="number" v-model="value.frontHeight" placeholder="Hoogte in px">
                                </label>
                                <span class="a_margin-left-10"><small>PX</small></span>
                            </div>
                            

                        </div>
                    </div>

                    <hr>
                    <h3><small>Device achterkant</small></h3>

                    <div class="b2c_form">
                        <div class="b2c_form_left">
                            <div class="b2c_form_left_img">
                                <img :src="images.backFile" alt="device_back">
                            </div>
                        </div>

                        <div class="b2c_form_right">
                            
                            <label for="upload_img_device_back" class="button v_ghost_brand_secondary v_expanded v_smaller">Upload afbeelding</label>
                            <input type="file" @change="setFile($event.target.files, 'backFile')" id="upload_img_device_back" class="show-for-sr">
                            
                            <p><small>Nog geen foto geselecteerd</small></p>

                            <h4><small>Fysieke grootte</small></h4>
                            <p><small>De fysieke grootte wordt in pixels meegegeven.</small></p>

                            <div class="a_flex a_align-items_center">
                                <label>
                                    Breedte:
                                    <input type="number" v-model="value.backWidth" placeholder="Breedte in px">
                                </label>
                                <span class="a_margin-left-10"><small>PX</small></span>
                            </div>

                            <div class="a_flex a_align-items_center">
                                <label>
                                    Hoogte:
                                    <input type="number" v-model="value.backHeight" placeholder="Hoogte in px">
                                </label>
                                <span class="a_margin-left-10"><small>PX</small></span>
                            </div>
                            

                        </div>
                    </div> -->
          <hr />
          <div class="a_flex a_justify_flex-end a_align-items_center">
            <button
              v-if="value._id"
              class="button v_brand_secondary"
              @click="$refs.delete.open(value)"
            >
              Delete Device
            </button>
          </div>
        </div>
      </div>
    </section>
    <delete ref="delete" @success="$emit('deleted')" />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
import config from '@/config';
import Delete from './Delete';

export default {
  props: ['value', 'category'],
  data() {
    return {
      basePath: config.API_URI.substr(0, config.API_URI.length - 1),
      images: {
        backFile: '',
        frontFile: '',
      },
      errorMessage: '',
    };
  },
  watch: {
    value(value, old) {
      if (value !== old) {
        this.images.frontFile = _.isString(this.value.frontFile)
          ? this.basePath + this.value.frontFile
          : '';
        this.images.backFile = _.isString(this.value.backFile)
          ? this.basePath + this.value.backFile
          : '';
      }
    },
  },
  methods: {
    ...mapActions('popup', ['openPopup']),
    ...mapActions('device', ['create', 'update']),
    async save() {
      try {
        this.value.category = this.category._id;
        if (!this.value.name) {
          this.showMessage('vul een naam in a.u.b.');
          return false;
        }
        if (!this.value.category) {
          this.showMessage('vul een category in a.u.b.');
          return false;
        }
        // this.modal.loading = true;
        if (this.value._id) {
          if (_.isString(this.value.frontFile)) {
            delete this.value.frontFile;
          }
          if (_.isString(this.value.backFile)) {
            delete this.value.backFile;
          }
          await this.update(this.value);
        } else {
          await this.create(this.value);
        }
        this.showMessage('De Device is succesvol aangemaakt.');
      } catch (error) {
        this.showMessage(error.message);
      }
      // this.modal.loading = false;
      return true;
    },
    setFile(files, target) {
      this.value[target] = files && files.length > 0 ? files[0] : null;
      if (this.value[target]) {
        const reader = new FileReader();
        reader.readAsDataURL(this.value[target]);
        reader.onload = () => {
          this.images[target] = reader.result;
        };
      } else {
        this.images[target] = '';
      }
    },
    showMessage(text) {
      this.openPopup({
        header: 'Message',
        message: text,
      });
    },
  },
  components: {
    Delete,
  },
};
</script>
