<template>
  <div>
    <input
      :value="value"
      :placeholder="placeholder"
      :data-toggle="id"
      type="text"
      class="a_margin-bottom-0 a_margin-right-10"
      @input="!disableSearch && updateSearch($event.target.value)"
    />
    <div
      :id="id"
      ref="div_element"
      class="dropdown-pane"
      data-dropdown=""
      data-close-on-click="true"
    >
      <ul class="b2c_inline-list" v-if="filteredData">
        <li v-for="item in filteredData" :key="item._id">
          <button @click="updateSearch(item.name)">{{ item.name }}</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchDropdown',
  props: ['data', 'value', 'placeholder', 'disableSearch'],
  data() {
    return {
      id: `dropdown-${this._uid}`,
    };
  },
  computed: {
    filteredData() {
      if (!this.data) {
        return null;
      }
      if (this.disableSearch) {
        return this.data;
      }
      return this.data.filter(
        item => item.name.toLowerCase().indexOf(this.value.toLowerCase()) > -1,
      );
    },
  },
  async mounted() {
    if (!this.infoDropdown) {
      // eslint-disable-next-line no-undef
      this.infoDropdown = new Foundation.Dropdown($(this.$refs.div_element));
    }
  },
  beforeDestroy() {
    this.infoDropdown.destroy();
  },
  methods: {
    updateSearch(val) {
      const match = this.data.find(item => item.name === val);
      if (match) {
        this.$emit('match', match);
      }
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="scss" scoped></style>
