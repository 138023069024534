// loading styles
// import './assets/styles/app.scss';
import jQuery from 'jquery';

require('motion-ui');
require('what-input');
require('foundation-sites');

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
window.jQuery = window.$ = jQuery;
