import axios from 'axios';

const module = {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    set(state: { items: any }, payload: any) {
      state.items = payload;
    },
    remove(state: { items: any[] }, payload: any) {
      state.items = state.items.filter(
        (item: { _id: any }) => item._id !== payload,
      );
    },
  },
  actions: {
    async list({ commit }: any, query: { meta: any }) {
      try {
        const res = await axios
          .get(`designs/${query ? `?query=${JSON.stringify(query)}&` : ''}`)
          .then(res => res.data);
        const items = query.meta ? res.data : res;
        commit('set', items);
        // checking are the design on the active varianceof the template or not
        await Promise.all(
          items.map(
            async (design: {
              template: { _id: any };
              onTheActiveVariance: boolean;
            }) => {
              const templateId = (design.template._id
                ? design.template._id
                : design.template
              ).toString();
              const theActiveVariance = await axios
                .get(`templates/${templateId}/variances/the-active`)
                .then(res => res.data);
              design.onTheActiveVariance = templateId === theActiveVariance;
            },
          ),
        );
        return res;
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    },
    async remove({ commit }: any, id: any) {
      try {
        const res = await axios.delete(`designs/${id}`);
        commit('remove', id);
        return res.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async updateState({}, { _id, submissionState }: any) {
      try {
        const form = new FormData();
        form.append('submissionState', submissionState);
        const result = await axios.put(`designs/${_id}`, form, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        });
        // commit('update', result.data);
        return result.data;
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};

export default module;
