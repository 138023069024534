<template>
  <div>
    <page-title
      :title="'Effecten'"
      :icon="'icon_effect'"
      :description="
        'Deze pagina betreft een overview van de laatste toegevoegde effecten voor het PCD platform.'
      "
    />
    <section class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <h2 class="a_margin-bottom-0 a_margin-right-20">Effecten overzicht</h2>
        <p class="a_margin-bottom-0 a_text_brand_gray">
          {{ items.length }} Effecten gevonden
        </p>
      </div>
      <div class="b2c_search-bar_left">
        <button
          class="button v_brand_secondary a_margin-bottom-0"
          @click="$refs.new.open()"
        >
          Voeg effect toe
        </button>
      </div>
    </section>
    <section class="b2c_content a_margin-top-40">
      <div class="b2c_content_container a_max-width-1000">
        <div class="b2c_content_1-1">
          <hr />
          <div class="b2c_items" data-s-amount="4">
            <effect
              v-for="(item, index) in items"
              :key="index"
              v-model="items[index]"
            />
          </div>
        </div>
      </div>
    </section>
    <new ref="new" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import PageTitle from '@/components/elements/PageTitle';
import Effect from './Effect';
import New from './New';

export default {
  name: 'AssetsEffects',
  async created() {
    try {
      await this.list();
    } catch (e) {
      this.openPopup({
        header: 'Er was een probleem bij het ophalen van Effecten',
        message: e.message,
      });
    }
  },
  computed: {
    ...mapState('effect', ['items']),
  },
  methods: {
    ...mapActions('popup', ['openPopup']),
    ...mapActions('effect', ['list']),
  },
  components: {
    PageTitle,
    Effect,
    New,
  },
};
</script>
<style scoped lang="scss"></style>
