const router = {
  namespaced: true,
  state: {
    fullScreen: false,
    showingSidebar: true,
    showingTopbar: true,
  },
  getters: {},
  mutations: {
    fullScreen(state, payload) {
      state.fullScreen = payload;
    },
    showSidebar(state, payload) {
      state.showingSidebar = payload;
    },
    showTopbar(state, payload) {
      state.showingTopbar = payload;
    },
    showAllBars(state, payload) {
      state.showingUserbar = payload;
      state.showingTopbar = payload;
      state.showingSidebar = payload;
    },
  },
  actions: {
    fullScreen({ commit }, payload) {
      commit('fullScreen', payload);
    },
    showSidebar({ commit }, payload) {
      commit('showSidebar', payload);
    },
    showTopbar({ commit }, payload) {
      commit('showTopbar', payload);
    },
    showAllBars({ commit }, payload) {
      commit('showAllBars', payload);
    },
  },
};

export default router;
