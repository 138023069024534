<template>
  <modal
    :open.sync="modal.open"
    :loading.sync="modal.loading"
    :error-message.sync="modal.errorMessage"
    :success-message.sync="modal.successMessage"
    :header="'Emoji Bulk Upload'"
    :sub-header="
      'Geef de nieuwe emoji een naam en selecteer een afbeelding op je computer.'
    "
    :action-button-text="'Start Emoji Bulk Upload'"
    :icon="'icon_assets'"
    @action="save"
  >
    <div v-if="!file">
      <label
        for="file_upload_input"
        class="button v_brand_secondary a_margin-bottom-0"
      >
        Bestand selecteren
      </label>
      <input
        id="file_upload_input"
        type="file"
        accept="application/zip"
        name="file"
        class="a_margin-bottom-0 show-for-sr"
        @change="setFile($event.target.files)"
      />
    </div>
    <div v-else>
      <kbd class="a_margin-top-0">{{ file.name }}</kbd>
      &nbsp;
      <span class="b2c_pill">{{ items.length }} items</span>
      <hr style="margin-bottom: 7px;" />
    </div>
    <div style="max-height: 300px;overflow-y: scroll;">
      <table v-if="items.length > 0">
        <tr>
          <th>Content</th>
          <th>Category</th>
          <th>Name</th>
          <th>Status</th>
        </tr>
        <tr v-for="(item, key) in items" :key="key">
          <td>
            <img :src="item.src" :alt="item.name" style="height: 75px;" />
          </td>
          <td>{{ item.category }}</td>
          <td>{{ item.name }}</td>
          <td>
            <small class="b2c_pill">{{ item.status }}</small>
          </td>
        </tr>
      </table>
    </div>

    <br />

    <div v-if="items.length > 0 && modal.loading">
      Progress
      <div class="progressbar">
        <div :style="{ width: progress + '%' }">{{ progress }}%</div>
      </div>
    </div>
  </modal>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import Modal from '@/components/elements/Modal';
import { unzip, setOptions } from 'unzipit';
import _ from 'lodash';

export default {
  name: 'new-emoji',
  data: () => ({
    modal: {
      open: false,
      loading: false,
      done: false,
      successMessage: '',
      errorMessage: '',
    },
    file: '',
    items: [],
  }),
  created() {
    // with workers
    // setOptions({
    //   workerURL:
    //     'https://unpkg.com/unzipit@0.1.9/dist/unzipit-worker.module.js',
    //   numWorkers: 2,
    // });
  },
  computed: {
    ...mapState('emojiCategory', { emojiCategories: 'items' }),
    ...mapState('emoji', { emojis: 'items' }),
    progress() {
      const itemProgressed = item =>
        item.status == 'Done' || item.status == 'Skipped';
      const progress =
        (this.items.filter(itemProgressed).length * 100) / this.items.length;
      return progress.toFixed();
    },
  },
  methods: {
    ...mapActions('emoji', { createEmoji: 'create' }),
    ...mapActions('emojiCategory', { createEmojiCategory: 'create' }),
    open() {
      this.modal.open = true;
      this.file = '';
      this.items = [];
    },
    async save() {
      try {
        this.modal.loading = true;
        this.items.forEach(item => (item.status = 'Waiting ...'));
        for (const item of this.items) {
          try {
            item.status = 'Uploading ...';
            let category = _.find(this.emojiCategories, {
              name: item.category,
            });
            if (!category) {
              category = await this.createEmojiCategory({
                name: item.category,
              });
            }
            if (
              _.find(this.emojis, { name: item.name, category: category._id })
            ) {
              item.status = 'Skipped';
            } else {
              await this.createEmoji({
                name: item.name,
                category: category._id,
                file: item.file,
              });
              item.status = 'Done';
            }
          } catch (error) {
            item.status = `Failed: ${error.message}`;
          }
        }
        // await this.create(this.fields);
        this.modal.successMessage = 'De emoji is succesvol toegevoegd.';
      } catch (error) {
        this.modal.errorMessage = error.message;
      }
      this.modal.loading = false;
      return true;
    },
    setFile(files) {
      this.file = files && files.length > 0 ? files[0] : null;
      if (this.file) {
        this.modal.loading = true;
        const reader = new FileReader();
        reader.readAsArrayBuffer(this.file);
        reader.onload = async () => {
          await this.readFileContent(reader.result);
          this.modal.loading = false;
        };
      } else {
        this.file = '';
      }
    },
    async readFileContent(file) {
      try {
        this.items = [];
        const { entries } = await unzip(file);
        await Promise.all(
          Object.entries(entries).map(async ([name, entry]) => {
            const [category, filename] = name.split('/');
            if (
              filename.includes('/') ||
              !filename.toLowerCase().endsWith('.png')
            ) {
              console.warn(`Wrong item: ${name}`);
              return;
            }
            const file = await entry.blob();
            this.items.push({
              category,
              name: filename.replace('.png', ''),
              src: URL.createObjectURL(file),
              status: 'Ready to upload',
              file,
            });
          }),
        );
      } catch (error) {
        this.modal.errorMessage = error.message;
      }
    },
  },
  components: {
    Modal,
  },
};
</script>
<style lang="scss">
.b2c_modal {
  .s_loading {
    opacity: 1;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
}

.progressbar {
  width: 100%;
  height: 24px;
  background-color: lightgray;
  div {
    width: 0%;
    height: 100%;
    background-color: #ff9800;
    color: white;
    padding-left: 12px;
  }
}
</style>
