<template>
  <div>
    <page-title
      :title="'Print tafels'"
      :icon="'icon_printer'"
      :description="
        'Hier vind je een overzicht van alle print tafels en de mogelijkheid tot het aanmaken of aanpassen van een nieuwe print tafel.'
      "
    />

    <section class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <input
          type="text"
          placeholder="Zoek een print tafel"
          class="a_margin-bottom-0 a_margin-right-10"
          data-toggle="dropdownPrintTables"
        />

        <div
          id="dropdownPrintTables"
          class="dropdown-pane"
          data-dropdown
          data-close-on-click="true"
        >
          <ul class="b2c_inline-list">
            <li v-for="(item, index) in items" :key="index">
              <button @click="select(item)">{{ item && item.name }}</button>
            </li>
          </ul>
        </div>
      </div>
      <div class="b2c_search-bar_left">
        <button
          class="button v_ghost_brand_secondary v_smaller a_margin-bottom-0"
          @click="newItem()"
        >
          Nieuwe print tafel
        </button>
      </div>
    </section>

    <section
      v-if="selected && selected._id"
      class="b2c_search-bar a_margin-top-60"
    >
      <div class="b2c_search-bar_left">
        <h2 class="a_margin-bottom-0 a_margin-right-20">
          {{ selected && selected.name }}
        </h2>

        <button
          class="button v_brand_secondary v_is-icon v_smaller a_no-margin"
          @click="$refs.delete.open(selected)"
        >
          <icon :icon-id="'icon_ui_close'" />
        </button>
      </div>
      <!-- <div class="b2c_search-bar_left">
                <button class="button v_brand_secondary v_smaller a_margin-bottom-0">
                    Aanpassen
                </button>
            </div> -->
    </section>
    <delete ref="delete" @success="deleted" />
    <item v-model="selected" @saved="saved($event)" />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import PageTitle from '@/components/elements/PageTitle';
import Delete from './Delete';
import Item from './Item';

export default {
  data() {
    return {
      items: null,
      selected: {
        name: '',
        boundingBox: {},
        position: {
          x: 0,
          y: 0,
        },
        offset: {
          x: 0,
          y: 0,
        },
        rows: null,
        columns: null,
        lip: 'none',
        distance: 0,
      },
    };
  },
  watch: {
    selected() {
      console.log(this.selected);
    },
  },
  created() {
    this.loadPrintTables();
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.dropdownPrintTables = new Foundation.Dropdown(
      // eslint-disable-next-line no-undef
      $('#dropdownPrintTables'),
      {
        // vOffset: 20,
      },
    );
  },
  destroyed() {
    this.dropdownPrintTables.destroy();
  },
  methods: {
    ...mapActions('popup', ['openPopup']),
    ...mapActions('printTable', ['list']),
    async loadPrintTables() {
      try {
        this.items = await this.list();
        console.log('loaded items', this.items);
      } catch (e) {
        this.openPopup({
          header: 'Er was een probleem bij het ophalen van Print Tables',
          message: e.message,
        });
      }
    },
    select(item) {
      this.selected = item;
    },
    newItem() {
      this.selected = {
        name: '',
        boundingBox: {},
        position: {
          x: 0,
          y: 0,
        },
        rows: null,
        columns: null,
        lip: 'none',
        distance: 0,
      };
    },
    deleted() {
      this.newItem();
      this.loadPrintTables();
    },
    saved(savedItem) {
      console.log('saved', savedItem);
      this.selected._id = savedItem._id;
    },
  },
  components: {
    PageTitle,
    Delete,
    Item,
    // SearchBar,
    // Layout,
    // New,
  },
};
</script>

<style scoped lang="scss"></style>
