<template>
  <modal
    :open.sync="modal.open"
    :loading.sync="modal.loading"
    :error-message.sync="modal.errorMessage"
    :success-message.sync="modal.successMessage"
    :header="'Effect Toevoegen'"
    :sub-header="'Voeg een nieuwe Effect toe.'"
    :action-button-text="'Effect Toevoegen'"
    :icon="'icon_effect'"
    @action="save"
  >
    <label
      >naam
      <input v-model="fields.name" type="text" />
    </label>
    <label for="upload_img" class="button v_brand_secondary a_margin-bottom-0"
      >Bestand selecteren</label
    >
    <input
      id="upload_img"
      type="file"
      capture="camera"
      accept="image/svg+xml"
      name="file"
      class="a_margin-bottom-0 show-for-sr"
      @change="setFile($event.target.files)"
    />
    <img :src="image" style="max-width:200px; max-height:200px" />
  </modal>
</template>
<script>
import { mapActions } from 'vuex';
import Modal from '@/components/elements/Modal';

export default {
  name: 'NewEffect',
  data() {
    return {
      modal: {
        open: false,
        loading: false,
        done: false,
        successMessage: '',
        errorMessage: '',
      },
      fields: {
        name: '',
        file: null,
      },
      image: '',
    };
  },
  methods: {
    ...mapActions('effect', ['create']),
    open() {
      this.modal.open = true;
      this.fields.name = '';
      this.fields.file = null;
      this.image = '';
    },
    async save() {
      try {
        if (!this.fields.name) {
          this.modal.errorMessage = 'vul een naam in a.u.b.';
          return false;
        }
        if (!this.fields.file) {
          this.modal.errorMessage = 'vul een file in a.u.b.';
          return false;
        }
        this.modal.loading = true;
        await this.create(this.fields);
        this.modal.successMessage = 'De Effect is succesvol aangemaakt.';
      } catch (error) {
        this.modal.errorMessage = error.message;
      }
      this.modal.loading = false;
      return true;
    },
    setFile(files) {
      this.fields.file = files && files.length > 0 ? files[0] : null;
      if (this.fields.file) {
        const reader = new FileReader();
        reader.readAsDataURL(this.fields.file);
        reader.onload = () => {
          this.image = reader.result;
        };
      } else {
        this.image = '';
      }
    },
  },
  components: {
    Modal,
  },
};
</script>
