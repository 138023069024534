<template>
  <modal
    :open.sync="modal.open"
    :loading.sync="modal.loading"
    :error-message.sync="modal.errorMessage"
    :success-message.sync="modal.successMessage"
    :header="'Lettertype Toevoegen'"
    :sub-header="
      'Geef het nieuwe Lettertype een naam en selecteer een .TTF bestand op je computer.'
    "
    :action-button-text="'Lettertype Toevoegen'"
    :icon="'icon_assets'"
    @action="save"
  >
    <label>
      naam
      <input v-model="fields.name" type="text" />
    </label>
    <label>
      Related PCD font
      <select v-model="fields.pcdFont">
        <option
          v-for="(item, index) in sortedItems"
          :value="item.name"
          :key="index"
          >{{ item.name }}</option
        >
      </select>
    </label>
    <label for="upload_img" class="button v_brand_secondary a_margin-bottom-0"
      >Bestand selecteren</label
    >
    <input
      id="upload_img"
      type="file"
      accept=".ttf"
      name="file"
      class="a_margin-bottom-0 show-for-sr"
      @change="setFile($event.target.files[0])"
    />
    <div v-show="fields.file" ref="preview" class="b2c_item_font-container">
      Pa’s wijze lynx bezag vroom het fikse aquaduct.
    </div>
  </modal>
</template>
<script>
import _ from 'lodash';
import { mapState, mapActions } from 'vuex';
import Modal from '@/components/elements/Modal';

export default {
  name: 'NewFont',
  data() {
    return {
      modal: {
        open: false,
        loading: false,
        done: false,
        successMessage: '',
        errorMessage: '',
      },
      fields: {
        name: '',
        pcdFont: '',
        file: null,
      },
      image: '',
      fontName: 'Arial',
    };
  },
  async created() {
    await this.list();
  },
  computed: {
    ...mapState('font', ['items']),
    sortedItems() {
      return _.sortBy(this.items, 'name');
    },
  },
  methods: {
    ...mapActions('amazonFont', ['create']),
    ...mapActions('font', ['list']),
    open() {
      this.modal.open = true;
      this.fields.name = '';
      this.fields.pcdFont = '';
      this.fields.file = null;
    },
    setFile(file) {
      this.fields.file = file;
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const randomName = Math.random()
            .toString(36)
            .slice(2);
          const fontFace = new FontFace(
            randomName,
            `url(data:font/truetype;charset=utf-8;base64,${reader.result.replace(
              'data:application/octet-stream;base64,',
              '',
            )})`,
          );
          const font = await fontFace.load();
          document.fonts.add(font);
          this.$refs.preview.style.fontFamily = randomName;
          // console.log(this.$refs);
          this.fontName = randomName;
          this.$forceUpdate();
        };
      }
    },
    async save() {
      try {
        if (!this.fields.name) {
          this.modal.errorMessage = 'vul een naam in a.u.b.';
          return false;
        }
        if (!this.fields.pcdFont) {
          this.modal.errorMessage = 'vul een pcd font in a.u.b.';
          return false;
        }
        if (!this.fields.file) {
          this.modal.errorMessage = 'vul een file in a.u.b.';
          return false;
        }
        this.modal.loading = true;
        await this.create(this.fields);
        this.modal.successMessage = 'De Font is succesvol toegevoegd.';
      } catch (error) {
        this.modal.errorMessage = error.message;
      }
      this.modal.loading = false;
      return true;
    },
  },
  components: {
    Modal,
  },
};
</script>
<style scoped>
.b2c_item_font-container {
  font-size: 2em;
}
</style>
