import axios from 'axios';
import _ from 'lodash';

const module = {
  namespaced: true,
  state: {
    items: [],
  },
  mutations: {
    set(state, payload) {
      state.items = payload;
    },
    add(state, payload) {
      state.items.push(payload);
    },
    update(state, payload) {
      state.items[
        _.findIndex(state.items, {
          _id: payload._id,
        })
      ] = payload;
    },
    remove(state, payload) {
      state.items = state.items.filter(item => item._id !== payload);
    },
  },
  actions: {
    // eslint-disable-next-line no-empty-pattern
    async read({}, id) {
      try {
        const res = await axios.get(`mockups/${id}`);
        return res.data;
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    },
    async list({ commit }) {
      try {
        const res = await axios.get('mockups/');
        commit('set', res.data);
        return res.data;
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    },
    async findTemplates(context, object) {
      return await axios
        .get(
          `mockups/${object.mockupId}/templates/${
            object.query ? `?query=${JSON.stringify(object.query)}` : ''
          }`,
        )
        .then(res => res.data);
    },
    async create({ commit }, object) {
      try {
        const form = new FormData();
        Object.keys(object).forEach(key => {
          let value = object[key];
          if (_.isPlainObject(value) || _.isArray(value)) {
            value = JSON.stringify(value);
          }
          form.append(key, value);
        });
        const result = await axios.post('mockups/', form, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        });
        commit('add', result.data);
        return result.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async update({ commit }, object) {
      try {
        if (!object._id) {
          throw new Error('The passed object does not have id.');
        }
        const form = new FormData();
        Object.keys(object).forEach(key => {
          let value = object[key];
          if (_.isPlainObject(value) || _.isArray(value)) {
            value = JSON.stringify(value);
          }
          form.append(key, value);
        });
        const result = await axios.put(`mockups/${object._id}`, form, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        });
        commit('update', result.data);
        return result.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    async remove({ commit }, id) {
      try {
        const res = await axios.delete(`mockups/${id}`);
        commit('remove', id);
        return res.data;
      } catch (error) {
        throw new Error(error);
      }
    },
    // eslint-disable-next-line no-empty-pattern
    async loadSVG({}, path) {
      try {
        const res = await axios.get(path);
        return res.data;
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    },
  },
};

export default module;
