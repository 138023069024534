<template>
  <div>
    <section class="b2c_content">
      <div class="b2c_content_container">
        <div class="b2c_content_1-1">
          <header class="b2c_header">
            <div class="b2c_header_top">
              <div class="b2c_header_top_left">
                <div class="b2c_header_switch a_flex a_align-items_center">
                  <h1 class="s_elem-has-icon-left a_fill_default">
                    Design inzendingen
                    <icon :icon-id="'icon_design-submissions'" />
                  </h1>
                </div>
              </div>
              <div class="b2c_header_top_right">
                <button
                  class="button v_ghost_brand_primary v_smaller a_margin-left-10 a_opacity-0 a_no-pointer-events"
                >
                  ...
                </button>
              </div>
            </div>
            <div class="b2c_header_description">
              <p>Beheer designs ingezonden door Designers.</p>
            </div>
          </header>
        </div>
      </div>
    </section>
    <section class="b2c_search-bar a_margin-top-60">
      <div class="b2c_search-bar_left">
        <h2 class="a_margin-bottom-0 a_margin-right-20">
          Design inzendingen
        </h2>
        <div class="b2c_pill">{{ designsCount }} items</div>
        <!-- <div class="b2c_pill">{{newItemsCount}} nieuwe</div> -->
        <span class="a_margin-right-10 a_margin-left-20">filter</span>
        <select v-model="filter" class="a_margin-bottom-0">
          <option value="all">Alles</option>
          <option value="requested">Aangevraagd</option>
          <option value="rejected">Afgewezen</option>
          <option value="accepted">Geaccepteerd</option>
          <option value="single" disabled>Single By Id</option>
        </select>
      </div>
      <div class="b2c_search-bar_left">
        <div class="b2c_search-bar_left">
          <input
            v-model="designId"
            type="text"
            placeholder="DESIGN ID"
            class="a_margin-bottom-0 a_margin-right-10"
          />
          <button
            :disabled="!/^[0-9a-fA-F]{24}$/.test(designId)"
            class="button v_brand_secondary v_is-icon v_smaller a_no-margin"
            @click="searchDesignById(designId)"
          >
            <icon :icon-id="'icon_ui_search'" />
          </button>
        </div>
      </div>
    </section>
    <section v-if="designs" class="b2c_content a_margin-top-40">
      <div class="b2c_content_container a_max-width-1000">
        <div class="b2c_content_1-1">
          <div class="b2c_items" data-s-amount="4">
            <item
              v-for="item in designs"
              :value="item"
              :key="`competition-design-${item._id}`"
              @re-render="globalRerenderPopup($event)"
            ></item>
          </div>
        </div>
      </div>
    </section>
    <paginator
      :page="$route.params.page"
      :limit="$route.query.limit"
      :total="designsCount"
      :params="{ filter: $route.params.filter }"
      route-name="designers-design-submissions-paged"
    />
    <!-- modals -->
    <modal-global-render
      ref="modalGlobalRender"
      type="design"
      @render="globalRerender"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import _ from 'lodash';
import { B2CRepository } from '@/repositories/b2c.repository';
import Paginator from '@/components/elements/Paginator';
import ModalGlobalRender from '@/components/elements/ModalGlobalRender';
import Item from './Item';

export default {
  data() {
    return {
      designId: this.$route.query.id,
      designsCount: 1,
      filter: this.$route.params.filter || 'all',
      selectedGlobalDesign: null,
    };
  },
  async created() {
    try {
      await this.routeTo(
        this.filter,
        this.$route.params.page || 1,
        _.merge({ limit: 8 }, this.$route.query),
      );
    } catch (e) {
      this.setLoading(false);
      this.openPopup({
        header: 'Er was een probleem bij het ophalen van de designers',
        message: e.message,
      });
    }
  },
  computed: {
    ...mapState('design', { designs: 'items' }),
    newItemsCount() {
      return this.designs
        ? this.designs.filter(item => item.submissionState === 'requested')
            .length
        : 0;
    },
  },
  watch: {
    filter(value) {
      switch (value) {
        case 'single':
          break;
        default:
          this.designId = '';
          this.routeTo(this.filter, 1, { limit: this.$route.query.limit || 8 });
          break;
      }
    },
    $route(value, old) {
      if (
        value.params.page != old.params.page ||
        value.query.limit != old.query.limit
      ) {
        this.loadQuery();
      }
    },
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    ...mapActions('design', {
      getDesigns: 'list',
    }),
    ...mapActions('popup', ['openPopup']),
    ...mapActions('global', ['globalRender']),
    async routeTo(filter, page, query) {
      await this.$router
        .replace({
          name: 'designers-design-submissions-paged',
          params: { filter, page },
          query,
        })
        .catch(error => error);
      return this.loadQuery();
    },
    getDesignSales() {
      return Promise.all(
        this.designs.map(async design => {
          if (design.submissionState === 'accepted') {
            try {
              const sales = await B2CRepository.getDesignSales(design._id);
              this.$set(design, 'sales', sales);
            } catch (e) {
              console.error("couldn't get design sales of " + design._id);
            }
          }
        }),
      );
    },
    async loadQuery() {
      this.setLoading(true);
      this.designId = this.$route.query.id || this.designId;
      const filters = [];
      if (this.designId) {
        filters.push(
          {
            column: 'submissionState',
            operator: '=',
            values: ['requested', 'accepted', 'rejected'],
          },
          {
            column: '_id',
            operator: '=',
            values: [this.designId],
          },
        );
      } else {
        filters.push({
          column: 'submissionState',
          operator: '=',
          values:
            this.$route.params.filter === 'all'
              ? ['requested', 'accepted', 'rejected']
              : [this.$route.params.filter],
        });
      }
      const query = {
        meta: true,
        sort: '-updatedAt',
        filters,
        limit: +this.$route.query.limit,
        page: +this.$route.params.page - 1,
      };
      console.log(query);
      const result = await this.getDesigns(query);
      this.designsCount = result.meta.recordsCount;
      await this.getDesignSales();
      this.$forceUpdate();
      this.setLoading(false);
    },
    async searchDesignById(designId) {
      this.filter = 'single';
      this.routeTo(this.filter, 1, { id: designId });
    },
    async globalRerenderPopup(designId) {
      this.selectedGlobalDesign = designId;
      const data = await this.globalRender({
        id: designId,
        skipRender: true,
        type: 'design',
      });
      let total = 0;
      data.templates.forEach(template => {
        template.assets.forEach(() => total++);
      });

      const message = `Dit is een vrij zware taak voor de server. Er zullen ${total} renders worden gemaakt. Het advies is om deze taak te draaien wanneer het niet druk is in de webshop. Je kunt als je geen haast hebt het renderen uitstellen tot middernacht.`;
      this.$refs.modalGlobalRender.open(message);
      // this.openPopup({
      //     header: 'Re-render designs',
      //     subHeader: 'Re-render alle templats met dit design',
      //     message: `Dit is een vrij zware taak voor de server. Er zullen ${total} renders worden gemaakt. Het advies is om deze taak te draaien wanneer het niet druk is in de webshop. Weet je zeker dat je dit wilt uitvoeren?`,
      //     closeButtonText: 'Annuleren',
      //     actionButtonText: 'Ja',
      //     actionCallback: () => {
      //         this.globalRerender(designId);
      //     }
      // });
    },
    async globalRerender({ delay, force }) {
      console.log({
        delay,
      });
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const data = await this.globalRender({
          delay,
          id: this.selectedGlobalDesign,
          skipRender: false,
          force,
          type: 'design',
        });
        this.openPopup({
          header: 'Succes',
          message: 'De render is gestart.',
        });
      } catch (e) {
        this.openPopup({
          header: 'Foutmelding',
          message: 'Er ging iets fout bij het aanmaken van de rerender' + e,
          closeButtonText: 'Sluiten',
        });
      }
      //https://pcd-backend.stagi.nl/templates/5b2df96059577d000c614e63/global?force=true&batchId=6546546
    },
  },
  components: {
    Paginator,
    ModalGlobalRender,
    Item,
  },
};
</script>
<style scoped lang="scss">
$columns: (
  designer-name: (
    width: 20%,
  ),
  designer-since: (
    width: 20%,
  ),
  designer-amount: (
    width: 20%,
  ),
  designer-email: (
    width: 20%,
  ),
  designer-btn: (
    width: 30%,
  ),
  designer-new-name: (
    width: 60%,
  ),
  designer-new-btn: (
    width: 40%,
  ),
);
@mixin generate-columns {
  @each $label, $column in $columns {
    [data-t='header-#{$label}'],
    [data-t='item-#{$label}'] {
      padding: 0 $default-padding / 2;
      flex: 0 0 map-get($column, width);
      max-width: map-get($column, width);
    }
  }
}
@include generate-columns();
[data-t='header-datum'],
[data-t='item-datum'],
[data-t='header-designer-btn'],
[data-t='item-designer-btn'] {
  text-align: right;
}
</style>
