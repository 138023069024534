<template>
  <div>
    <section class="b2c_content">
      <div class="b2c_content_container a_max-width-2000">
        <div class="b2c_content_1-1">
          <!-- <router-link to="../pcd-designer/detail" class="button v_transparent a_text_brand_secondary v_has-icon-left v_smallest a_margin-bottom-40">
                        <span class="text-container">
                            naar het overzicht van Paul
                        </span>
                        <icon :iconId="'icon_ui_arrow-left'"></icon>
                    </router-link> -->

          <header class="b2c_header">
            <div class="b2c_header_top">
              <div class="b2c_header_top_left">
                <div class="b2c_header_switch a_flex a_align-items_center">
                  <img
                    :src="
                      [
                        basePath,
                        'uploads/designs',
                        designId,
                        'thumbnail.jpeg',
                      ].join('/')
                    "
                  />
                  <ul>
                    <li>design Id: {{ designId }}</li>
                    <li>layout ratio: {{ layoutRatio }}</li>
                  </ul>
                </div>
              </div>
              <hr />
              <div class="b2c_header_top_right a_block">
                <!-- <button class="button v_ghost_brand_secondary v_smaller v_has-icon-right a_margin-bottom-0 a_margin-right-10" data-toggle="dropdownSettings">
                                    <span class="text-container">
                                        instellingen
                                    </span>
                                    <icon :iconId="'icon_caret'"></icon>
                                </button> -->
                <button
                  disabled
                  class="button v_brand_red v_smaller v_has-icon-right a_margin-bottom-0 a_margin-right-10"
                >
                  design afwijzen
                  <icon :icon-id="'icon_cross'" />
                </button>
                <button
                  class="button v_brand_green v_smaller v_has-icon-right a_margin-bottom-0"
                  @click="globalize"
                >
                  globaal maken
                  <icon :icon-id="'icon_check'" />
                </button>
                <div>
                  <input id="checkbox" v-model="force" type="checkbox" />
                  <label for="checkbox">forceren</label>
                </div>
              </div>
            </div>

            <!-- <div class="b2c_header_description a_margin-top-20">
                            <div class="b2c_avatar v_smallest a_margin-right-20">
                                <div class="b2c_avatar_image" style="background-image: url(img/avatars/1.jpg);"></div>
                                <div class="b2c_avatar_name">Design door <router-link to="../pcd-designer/detail">Paul van de Laar</router-link></div>
                            </div>
                            <p>Dit design kan worden toegepast op <b>25 templates</b></p>
                        </div> -->
          </header>

          <div
            id="dropdownSettings"
            class="dropdown-pane"
            data-dropdown
            data-position="bottom"
            data-alignment="right"
            data-close-on-click="true"
          >
            <ul class="b2c_inline-list">
              <li><button>Toevoegen aan collectie</button></li>
              <li><button>Design gegevens aanpassen</button></li>
              <li><button>Design intrekken</button></li>
              <li><button>Design verwijderen</button></li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <h2>
      Platte renders
      <span v-if="totalFlatImages" style="font-size:75%"
        >({{ flatUrls.length }} / {{ totalFlatImages }})</span
      >
    </h2>
    <section class="b2c_content a_margin-top-40">
      <div class="b2c_content_container a_max-width-800">
        <div class="b2c_content_1-1">
          <img
            v-for="flatUrl in flatUrls"
            :key="flatUrl"
            :src="flatUrl"
            style="max-width:200px"
          />
        </div>
      </div>
    </section>

    <h2>
      Mockups
      <span v-if="totalMockupImages" style="font-size:75%"
        >({{ mockupUrls.length }} / {{ totalMockupImages }})</span
      >
    </h2>
    <section class="b2c_content a_margin-top-40">
      <div class="b2c_content_container a_max-width-800">
        <div class="b2c_content_1-1">
          <img
            v-for="mockupUrl in mockupUrls"
            :key="mockupUrl"
            :src="mockupUrl"
            style="max-width:300px"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import config from '@/config';
import { mapActions } from 'vuex';

export default {
  name: 'DesignersPcdDesignerDesign',
  props: ['designId'],
  data() {
    return {
      basePath: config.API_URI.substr(0, config.API_URI.length - 1),
      layoutRatio: '',
      flatUrls: [],
      mockupUrls: [],
      design: null,
      force: false,
      totalFlatImages: 0,
      totalMockupImages: 0,
    };
  },
  methods: {
    ...mapActions('design', {
      getDesigns: 'list',
    }),
    ...mapActions('loading', ['setLoading']),
    ...mapActions('popup', ['openPopup']),
    async globalize() {
      this.setLoading(true);
      this.flatUrls = [];
      this.mockupUrls = [];
      const flatUrls = [];

      const ratio = this.design.template.layoutRatio;
      const templateId = this.design.template._id;

      console.log(templateId, ratio);
      // make design id global by prerendering all assets.
      const query = {
        filters: [
          {
            column: 'layoutRatio',
            operator: '=',
            values: [ratio],
          },
        ],
      };
      const response = await fetch(
        `${config.API_URI}templates/?query=${JSON.stringify(query)}`,
      );
      const templates = await response.json();
      this.totalFlatImages = templates.length;
      console.log(templates);

      // render flat designs based on designId and templateId
      await Promise.all(
        templates.map(async template => {
          const url = `${config.DESIGN_EXPORTER}render?type=png&designId=${this.designId}&templateId=${template._id}`;
          flatUrls.push(url);
          return (async () => {
            await fetch(`${url}${this.force ? '&force=true' : ''}`, {
              mode: 'no-cors',
            });
            this.flatUrls.push(url);
          })();
        }),
      );

      console.log('finished flat renders');

      const mockupRenderMethods = templates.reduce(
        (arr, template) =>
          arr.concat(
            template.mockups.map(mockup => {
              const url = `${config.DESIGN_EXPORTER}mockup?designId=${this.designId}&templateId=${template._id}&mockupId=${mockup}`;
              return (async () => {
                await fetch(`${url}${this.force ? '&force=true' : ''}`, {
                  mode: 'no-cors',
                });
                this.mockupUrls.push(url);
              })();
            }),
          ),
        [],
      );
      this.totalMockupImages = mockupRenderMethods.length;
      await Promise.all(mockupRenderMethods);
      console.log('finished mockup rendering');
      this.setLoading(false);
    },
    async fetchData() {
      const res = await this.getDesigns({
        filters: [
          {
            column: '_id',
            operator: '=',
            values: [this.designId],
          },
        ],
        relations: ['template'],
      });

      this.design = res[0];
      this.layoutRatio = this.design.template.layoutRatio;
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    this.dropdownSettings = new Foundation.Dropdown($('#dropdownSettings'), {
      // vOffset: 20,
    });
    if (this.designId) {
      console.log(this.designId);
      this.fetchData();
    }
  },
  destroyed() {
    this.dropdownSettings.destroy();
  },
};
</script>
<style scoped lang="scss">
$columns: (
  designer-name: (
    width: 25%,
  ),
  designer-since: (
    width: 25%,
  ),
  designer-amount: (
    width: 15%,
  ),
  designer-email: (
    width: 20%,
  ),
  designer-btn: (
    width: 15%,
  ),
  designer-new-name: (
    width: 60%,
  ),
  designer-new-btn: (
    width: 40%,
  ),
);
@mixin generate-columns {
  @each $label, $column in $columns {
    [data-t='header-#{$label}'],
    [data-t='item-#{$label}'] {
      padding: 0 $default-padding / 2;
      flex: 0 0 map-get($column, width);
      max-width: map-get($column, width);
    }
  }
}
@include generate-columns();
[data-t='header-datum'],
[data-t='item-datum'],
[data-t='header-designer-btn'],
[data-t='item-designer-btn'] {
  text-align: right;
}
[data-t='header-designer-amount'],
[data-t='item-designer-amount'] {
  text-align: center;
}
</style>
