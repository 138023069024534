import { sortBy } from 'lodash';

export function hash(str: string) {
  return str
    .split('')
    .reduce(
      (prevHash, currVal) =>
        ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
      0,
    );
}

export function sortAlphabetical<T = any>(list: Array<any>, attribute: string) {
  // @ts-ignore
  return sortBy<T>(list, [item => item[attribute].toLowerCase()], ['asc']);
}
