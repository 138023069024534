<template>
  <modal
    :header="'Login'"
    :sub-header="''"
    :open="opened === 'login'"
    :cancel-button-text="'Sluiten'"
    @close="closeModal()"
  >
    <div v-show="!loading">
      <!-- <p>
                    Log in met een bestaand account.
                </p> -->
      <form @submit.prevent="login">
        <label>
          Gebruikersnaam
          <input
            v-model="loginData.username"
            autocomplete="username"
            type="text"
          />
        </label>

        <label>
          Wachtwoord
          <input
            v-model="loginData.password"
            autocomplete="current-password"
            type="password"
          />
        </label>

        <p class="s_error">
          {{ loginError }}
        </p>
        <button
          type="submit"
          class="button v_brand_secondary v_expanded_on-small"
        >
          <span class="btn-container">
            <span class="text-container">
              Inloggen
            </span>
          </span>
        </button>
      </form>
    </div>
    <div v-show="loading">
      momentje a.u.b...
    </div>
  </modal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Modal from '@/components/elements/Modal';
import config from '@/config';

export default {
  name: 'ModalLogin',
  data() {
    return {
      tab: 'login',
      loginData: {
        username: '',
        password: '',
      },
      loading: false,
      loginError: '',
    };
  },
  computed: {
    ...mapState('modal', ['opened']),
    ...mapGetters('auth', ['token']),
    registerUrl() {
      return config.NEW_ACCOUNT_URI;
    },
  },
  methods: {
    ...mapActions('modal', ['closeModal']),
    ...mapActions('popup', ['close']),
    ...mapActions('auth', {
      loginUser: 'login',
    }),
    async login() {
      this.loginError = '';
      this.loading = true;
      if (!this.loginData.username) {
        this.loginError = 'Vul a.u.b. een gebruikersnaam in';
        this.loading = false;
        return false;
      }
      if (!this.loginData.password) {
        this.loginError = 'Vul a.u.b een wachtwoord in';
        this.loading = false;
        return false;
      }
      try {
        await this.loginUser(this.loginData);
        this.loading = false;
        this.error = '';
        this.close();
        this.closeModal();
        return true;
      } catch (error) {
        console.log(error.message);
        if (error) {
          this.loginError = error.message;
        }
        this.loading = false;
        return false;
      }
    },
  },
  components: {
    Modal,
  },
};
</script>

<style scoped lang="scss">
.s_error {
  color: red;
}
.login_tab {
  padding: 10px 20px;
  border: 1px solid #efefef;
  margin-left: 1px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: inline-block;
  color: #ccc;
  &.s_active {
    border-bottom: 0px;
    color: $brand-secondary;
  }
}
.login_tabs {
  margin-bottom: 20px;
}
</style>
